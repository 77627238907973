import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import {
  Box,
  CardContent,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Checkbox,
} from "@mui/material";
import { Form, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import sssutms from "../../Main_Admin/images/sssutms.jpg";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import StudentDashboard from "../../AdminComponent/StudentDashboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import OnlyHeader from "../../AdminComponent/OnlyHeader";

const theme = createTheme({
  typography: {
    fontWeightBold: 700,
  },
});

function Std_AdmitCard() {
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [examForms, setExamForms] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedSubjects, setSelectedSubjects] = useState({
    theory: null,
    practical: null,
  });

  const [compulsorySubjects, setCompulsorySubjects] = useState([]);
  const [centerdetail, setCenterDetails] = useState(null);
  const userData = JSON.parse(sessionStorage.getItem("currentUser"));

  // ////////////////////////////////////////////////////////elective/////////////////////////////////////////////////////////

  useEffect(() => {
    // Initialize selectedSubjects with compulsory subjects that are checked by default
    const checkedCompulsorySubjects = compulsorySubjects
      .filter((subject) => subject.isChecked) // Filter compulsory subjects that are checked by default
      .map((subject) => ({
        subjectName: subject.subjectName,
        subjectType: subject.subjectType,
        subjectCode: subject.subjectCode,
      }));

    // Update state with the checked compulsory subjects
    setSelectedSubjects((prev) => ({
      ...prev,
      compulsory: checkedCompulsorySubjects,
    }));
  }, [compulsorySubjects]); // Runs when compulsorySubjects changes

  // Checkbox rendering for both compulsory and non-compulsory subjects
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const fetchExamForms = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v1/getExamForms?studentId=${userData._id}`
        );
        const result = await response.json();
        if (response.ok) {
          setExamForms(result.data);
        } else {
          console.error(result.message);
        }
      } catch (error) {
        console.error("Error fetching exam forms:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchExamForms();
  }, []);

  ///////////////////////////////////////////////for optional ////////////////////////////////////////////////////////////////

  const getSortedAndSlicedData = () => {
    const sortedData = [...examForms].sort((a, b) => {
      if (sortBy === "name") {
        return sortOrder === "asc"
          ? a.branchName.localeCompare(b.branchName)
          : b.branchName.localeCompare(a.branchName);
      }
      return 0;
    });
    const startIndex = page * rowsPerPage;
    return sortedData.slice(startIndex, startIndex + rowsPerPage);
  };

  /////////////////////exam center //////////////////

  const handleShow = async (examId) => {
    console.log(examId, "examID");
    const fetchExamCenter = async () => {
      try {
        const response = await fetch(
          `http://localhost:7786/admin/get-exam-centers?courseBranch=${userData.courseBranch}&courseName=${userData.courseName}&semester=${userData.currentSemester}&Exam_Session=${examId.sessionName}`
        );
        const result = await response.json();
        console.log(result.centers[0], "dekh result");
        if (response.ok) {
          setCenterDetails(result.centers);
          console.log(result.centers[0], "center");
        } else {
          console.error(result.message);
        }
      } catch (error) {
        console.error("Error fetching exam forms:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchExamCenter().then(() => {
      generatePDF(examForms[0]);
    });
  };
  // console.log(centerdetail, "center ki details");
  const generatePDF = async (examData) => {
    console.log(centerdetail, "centerdetailllll");
    const pdf = new jsPDF();

    // Add A4 page border
    pdf.setDrawColor(0); // Black color
    pdf.rect(5, 5, 200, 287);

    // Add University Logo
    pdf.addImage(sssutms, "JPEG", 8, 10, 20, 20);

    // Header Section
    pdf.setFont("helvetica", "sans-serif");
    pdf.setFontSize(12);
    pdf.text(
      "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
      32,
      15
    );

    pdf.setFont("helvetica", "serif");
    pdf.setFontSize(10);
    pdf.text(userData.courseName.toUpperCase(), 60, 22);
    pdf.text(
      `(${userData.courseBranch}) EXAM : ${examData.examFormId.sessionName}`,
      55,
      28
    );

    pdf.setFontSize(13);
    pdf.setFont("helvetica", "Georgia");
    pdf.text("ADMIT CARD", 85, 34);

    // Draw underline under "ADMIT CARD"
    const admitCardX = 79;
    const admitCardY = 35;
    const admitCardWidth = 38;
    pdf.line(admitCardX, admitCardY, admitCardX + admitCardWidth, admitCardY);

    // Draw line under the header
    pdf.line(5, 45, 205, 45);

    // Second Box: Student Details
    const details = [
      { label: "Enrollment No", value: userData.enrollmentNumber },
      { label: "Candidate Name", value: userData.name },
      { label: "Father's Name", value: userData.fathersname }, // Replace with dynamic value
      { label: "Mother's Name", value: userData.mothersname }, // Replace with dynamic value
      { label: "Semester/Year", value: examData.examFormId.semester },
      { label: "Status", value: examData.examFormId.category },
      { label: "Center Name", value: centerdetail.CenterName },
      { label: "Center Code", value: centerdetail.centerCode },
    ];

    const boxStartX = 10;
    const boxStartY = 50;
    let yPos = boxStartY + 10;
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(10);

    details.forEach((detail, index) => {
      const columnX = index < 3 ? boxStartX + 5 : boxStartX + 85;
      if (index === 3) yPos = boxStartY + 10;
      const wrappedText = pdf.splitTextToSize(
        `${detail.label}: ${detail.value}`,
        55
      );
      pdf.text(wrappedText, columnX, yPos);
      yPos += 12;
    });

    // Add applicant photo to the right of the details box
    const photoX = boxStartX + 160 + 5;
    const photoY = boxStartY + 5;
    pdf.addImage(
      userData.Documents.applicantPhoto,
      "JPEG",
      photoX,
      photoY,
      27,
      35
    );
    pdf.text("Student's Sig.", photoX + 5, photoY + 45);

    // Add student's signature below the photo
    pdf.addImage(
      userData.Documents.applicantSignature,
      "JPEG",
      photoX,
      photoY + 50,
      27,
      10
    );

    // Third Box: Subjects
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text(
      "Name of the Papers in Which Candidate is Appearing in the Examination",
      25,
      115
    );

    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(11);
    pdf.text(
      `Exam Time: ${centerdetail.StartTime}- ${centerdetail.EndTime}`,
      57,
      122
    );

    const headers = ["SNo", "Subject Code", "Subject Type", "Subject Name"];
    const subjects = examData.subjects.map((subject, index) => [
      `${index + 1}`,
      subject.subjectCode,
      subject.subjectType,
      subject.subjectName,
    ]);

    pdf.autoTable({
      head: [headers],
      body: subjects,
      startY: 130,
      styles: { fontSize: 10, textColor: 0 },
      headStyles: { fillColor: [230, 230, 230] },
      margin: { left: 15 },
      theme: "grid",
    });

    // Footer Instructions
    const footerY = pdf.autoTable.previous.finalY + 10;
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(10);
    pdf.text("Instructions:", 15, footerY);
    const instructions = [
      "1. Bring your examination admit card and original college ID card to the examination hall.",
      "2. Arrive at least 30 minutes before the start of the examination.",
      "3. Follow all instructions given by the invigilators during the examination.",
    ];
    instructions.forEach((instruction, i) => {
      pdf.text(instruction, 15, footerY + (i + 1) * 5);
    });

    // Exam Controller Signature
    const examControllerSignatureImage =
      "https://cdn.sssutms.ac.in/media/static/Exam_Controller_Signature.jpeg";
    pdf.addImage(
      examControllerSignatureImage,
      "JPEG",
      150,
      footerY + 26,
      40,
      10
    );
    pdf.text("Signature of Exam Controller", 150, footerY + 40);

    // Save PDF
    pdf.save(`Admit_Card_${examData.examFormId.sessionName}.pdf`);
  };

  return (
    <ThemeProvider theme={theme}>
      <StudentDashboard />
      <>
        <Box sx={{ width: "90%", marginLeft: "80px", marginTop: "50px" }}>
          {loading ? (
            <CircularProgress
              color="success"
              style={{ marginTop: "80px", marginLeft: "50%" }}
            />
          ) : (
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ justifyContent: "center", textAlign: "center" }}
              >
                ADMIT CARD
              </Typography>
              <Paper sx={{ width: "105%", overflow: "auto" }}>
                <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                  <Typography
                    variant="button"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    <strong>Enrollment No:</strong> {userData?.enrollmentNumber}
                  </Typography>
                  <Typography
                    variant="button"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    <b> Candidate Name:</b> {userData?.name}
                  </Typography>
                  <Typography
                    variant="button"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    <b> Father Name: </b>
                    {userData?.fathersname}
                  </Typography>
                </Box>

                <TableContainer sx={{ maxHeight: 450 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            left: 0,
                            color: "white",
                          }}
                        >
                          S.No.
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            minWidth: "100px",
                            left: 50,
                            color: "white",
                          }}
                        >
                          Session
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Session Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                            minWidth: "70px",
                          }}
                        >
                          Semster/Year
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            minWidth: "100px",
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Category
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            minWidth: "200px",
                            color: "white",
                          }}
                        >
                          Admit card Status
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            minWidth: "150px",
                            color: "white",
                          }}
                        >
                          Click to show
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getSortedAndSlicedData().map((form, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align="left"
                            style={{
                              position: "sticky",
                              top: 0,
                              left: 0,
                              zIndex: 1,
                              backgroundColor: "white",
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              position: "sticky",
                              left: 50,
                              top: 0,
                              fontWeight: 700,
                              zIndex: 1,

                              backgroundColor: "white",
                              color: "red",
                            }}
                          >
                            {form.examFormId.session || "N/A"}
                          </TableCell>

                          <TableCell align="left">
                            {form.examFormId.sessionName || "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            {form.examFormId.semester || "N/A"}
                          </TableCell>

                          <TableCell
                            align="left"
                            sx={{
                              fontWeight: "bold",
                              position: "sticky",
                              left: 210,
                              backgroundColor: "white",
                            }}
                          >
                            {form.examFormId.category || "N/A"}
                          </TableCell>

                          <TableCell align="left">
                            {form.isAdmitCardAppraoved === true
                              ? "Approved"
                              : "Not Approved"}
                          </TableCell>
                          <Button
                            variant="contained"
                            onClick={() => {
                              if (!form.isAdmitCardAppraoved) {
                                swal({
                                  icon: "warning",
                                  title: "Form Not Forwarded",
                                  text: "Your form has not been forwarded. Please contact to the Department.",
                                  buttons: "OK",
                                });
                              } else if (form.isAdmitCardAppraoved) {
                                handleShow(form.examFormId, form.studentId);
                              }
                            }}
                          >
                            {form.isFilled && form.isPaid
                              ? "Download"
                              : "Download"}
                          </Button>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CardContent>
          )}

          <br></br>
        </Box>
      </>
    </ThemeProvider>
  );
}
export default Std_AdmitCard;
