import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  InputAdornment,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import Sidebar from "./HodDashboard";
import DatePicker from "react-datepicker";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import * as XLSX from "xlsx";

const theme = createTheme();

function TotalStudent() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [studentData, setStudentData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedSession, setSelectedSession] = useState("");
  const [branches, setBranches] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedCollege, setSelectedCollege] = useState("");

  const [searchQuery, setSearchQuery] = useState("");

  const hod = JSON.parse(sessionStorage.getItem("AnkitHOD"));
  const branch1 = hod.Branch || "";
  const course1 = hod.courseName || "";
  const courseName = hod?.courseName || "";
  const associate = hod?.Associated_branch || [];
  const colleges = hod?.colleges || [];

  useEffect(() => {
    const associatedBranches = JSON.parse(
      sessionStorage.getItem("Associated_branch")
    );

    if (associatedBranches) {
      setAllBranches(associatedBranches);
    }
  }, []);

  const fetchData = async (
    branch = branch1,
    course = course1,
    college = selectedCollege
  ) => {
    console.log(selectedCollege, "colleges");
    try {
      const response = await axios.get(
        `https://sssutms.ac.in/apitest/hod/students/branchtotallist?branch=${branch}&course=${course}&college=${college}`
      );

      const data = response.data;

      setStudentData(data.students);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCollegeChange = (event) => {
    const college2 = event.target.value;
    setSelectedCollege(college2);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let filtered = studentData;

    filtered = studentData.filter(
      (student) =>
        student.randomId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.name.toLowerCase().includes(searchQuery.toLowerCase()) 
    );
    if (selectedCollege || selectedSession){
      filtered = filtered.filter(
        (student) => student.assignedCollege === selectedCollege || selectedSession
      );
    }
    setFilteredData(filtered);
    setPage(0);
  }, [searchQuery, studentData, selectedCollege,selectedSession])



  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleChangeBranch = (event) => {
    setSelectedBranch(event.target.value);
  };
  
  const handleChangeCourse = (e) => {
    const courseName = e.target.value;
    setSelectedCourse(courseName);

    const filteredCourse = associate.find(
      (course) => course.courseName === courseName
    );
    const filteredBranches = filteredCourse
      ? filteredCourse.branches.map((branch) => branch.branchName)
      : [];

    setBranches(filteredBranches);
    setSelectedBranch("");
  };

  const handleSessionChange = (event) => {
    const session = event.target.value;
    setSelectedSession(session);
  
  };

  const handleSearch = () => {
    fetchData(selectedBranch, selectedCourse);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const excelData = filteredData.map((student) => ({
    Session: student.admissionSession,
    Enrollment_No: student.enrollmentNumber || "Not Generated",
    Std_Id: student.randomId,
    Password: student.randomPassword,
    Admitted_Date: new Date(student.createdAt).toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }),
    Name: student.name,
    Fathers_Name: student.fathersname,
    Mothers_Name: student.mothersname,
    Email: student.email,
    Mobile: student.mobile,
    Course_Type: student.courseType,
    Course: student.courseName,
    Branch: student.courseBranch,
    Assign_Clg: student.assignedCollege || "Not Assign",
    Std_Type: student.StudentType || "Normal",
    Registration_Pay: student.isPaid,
    Enrollement_Status: student.isEnrolled,
    Enrollement_pay: student.isEnrollPaid,
  }));

  const ExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Registered Student");
    XLSX.writeFile(workbook, "Total_Std_Data.xlsx");
  };

  // /////////////////////////////////name sort/////////////////////////
  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };
  return (
    <>
      <style>
        {`
        .conttable{

            background-color: #e8e5d5;
            width: 90%;
            margin-left: 120px;
            margin-right: auto;
            margin-top: 80px;
            text-align: center;
            max-width: 1200px;

        }

        .login-input{
        width:200px;
        margin-bottom:20px;
        height:35px;}
        @media screen and (max-width: 500px){
          .custom-card-content{
            width: 100%; /* Adjust the percentage as needed */
            max-width: 1200px; /* Set a maximum width */
            margin: 0 auto; /* Center align */
          }

          h4{
            margin-top:90px;
          }
        }

    `}
      </style>

      <ThemeProvider theme={theme}>
        <Sidebar />

        <Box sx={{ width: "90%", marginLeft: "100px", marginTop: "100px" }}>
          <Paper sx={{ width: "100%", overflow: "auto" }}>
            <TextField
              style={{
                float: "right",
                marginRight: "10px",
                marginBottom: "10px",
              }}
              variant="outlined"
              placeholder="Search by Name or ID"
              value={searchQuery}
              onChange={handleSearchChange}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: 300 }}
            />
            <Button
              style={{
                float: "right",
                marginRight: "10px",
                marginBottom: "10px",
              }}
              variant="outlined"
              onClick={ExportToExcel}
            >
              Export to Excel
            </Button>

            <div style={{ display: "flex", alignItems: "center" }}>
            {/* <select
              className="form-select form-select-sm login-input"
              value={selectedSession}
              onChange={handleSessionChange}
            >
              <option value="">Select Session</option>
              <option value="">all</option>
              <option value="2024">2024</option>
              <option value="2023">2022</option>
             
            </select> */}
              <select
                className="form-select form-select-sm login-input"
                required={true}
                value={selectedCourse}
                onChange={handleChangeCourse} // Update the selected course
              >
                <option value="">Select Course</option>
                {associate.map((course, index) => (
                  <option key={index} value={course.courseName}>
                    {course.courseName}
                  </option>
                ))}
              </select>

              <select
                className="form-select form-select-sm login-input"
                required={true}
                value={selectedBranch}
                onChange={handleChangeBranch} // Update the selected branch
                disabled={!selectedCourse} // Disable until a course is selected
              >
                <option value="">Select Branch</option>
                {branches.map((branch, index) => (
                  <option key={index} value={branch}>
                    {branch}
                  </option>
                ))}
              </select>
              {( courseName === "MASTER OF BUSINESS ADMINISTRATION(MBA)" || courseName === "BACHELOR OF EDUCATION"  || courseName === "BACHELOR OF PHARMACY") && (
                <>
                <select
                  className="form-select form-select-sm login-input"
                  required={true}
                  value={selectedCollege}
                  onChange={handleCollegeChange}
                >
                  <option value="">Select College</option>
                  {colleges.map((college, index) => (
                    <option key={index} value={college.collegeName}>
                      {college.collegeName}
                    </option>
                  ))}
                </select>
                </>
              )}
              

              <Button
                type="button"
                variant="contained"
                onClick={handleSearch}
                disabled={!selectedBranch}
                sx={{
                  height: "35px",
                  backgroundColor: "#023047",
                  color: "white",
                  marginBottom: "20px",
                  marginLeft: "3px",
                }}
              >
                Submit
              </Button>
            </div>

            <TableContainer sx={{ maxHeight: "440px" }} component={Paper}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ minWidth: 700 }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#023047",
                        color: "white",                       
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                        left: 0,
                      }}
                    >
                      S.No.
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#023047",
                        color: "white",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      Session
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#023047",
                        color: "white",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        minWidth: "110px",
                      }}
                    >
                      Registered Date
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#023047",
                        color: "white",
                        position: "sticky",
                        top: 0,
                        left: 50,
                        zIndex: 2,
                        minWidth: "110px",
                      }}
                    >
                      Enrollment No.
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#023047",
                        color: "white",
                        position: "sticky",
                        top: 0,
                        left: 50,
                        zIndex: 2,
                        minWidth: "110px",
                      }}
                    >
                      Student Type
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#023047",
                        minWidth: "110px",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        color: "white",
                      }}
                    >
                      Std ID
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#023047",
                        color: "white",
                        position: "sticky",
                        minWidth: "140px",
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      Password
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#023047",
                        minWidth: "250px",
                        position: "sticky",
                        top: 0,
                        color: "white",
                        left: "150px",
                        zIndex: 2, // Increased zIndex to ensure it stays above other columns
                      }}
                      onClick={() => handleSort("name")}
                    >
                      Student Name
                      {/* /////////////name sort////////////////////////// */}
                      {sortBy === "name" && (
                        <span>{sortOrder === "asc" ? " ↑" : " ↓"}</span>
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#023047",
                        position: "sticky",
                        color: "white",
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#023047",
                        color: "white",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        // left: '220px',
                      }}
                    >
                      DOB
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#023047",
                        color: "white",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        minWidth: "190px",
                      }}
                    >
                      Father's Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#023047",
                        color: "white",
                        minWidth: "200px",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      Mother's Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#023047",
                        color: "white",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        minWidth: "120px",
                      }}
                    >
                      Contact
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#023047",
                        color: "white",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        minWidth: "260px",
                      }}
                    >
                      Course Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#023047",
                        color: "white",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        minWidth: "260px",
                      }}
                    >
                      Course Branch
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#023047",
                        color: "white",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        minWidth: "260px",
                      }}
                    >
                      College Name
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#023047",
                        color: "white",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        minWidth: "150px",
                      }}
                    >
                      Registration Fee
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#023047",
                        color: "white",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        minWidth: "150px",
                      }}
                    >
                      Enrollment Form
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#023047",
                        color: "white",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        minWidth: "150px",
                      }}
                    >
                      Enrollment Fee
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.length > 0 ? (
                    filteredData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((student, index) => (
                        <TableRow key={student.randomId}>
                          <TableCell
                            align="left"
                            style={{
                              fontSize: "16px",

                              position: "sticky",
                              // minWidth: "250px",
                              left: "0px",
                              backgroundColor: "white",
                              zIndex: 1,
                            }}
                          >
                            {page * rowsPerPage + index + 1}
                          </TableCell>

                          <TableCell align="left">
                            {student.admissionSession}
                          </TableCell>
                          <TableCell align="left">
                            <DatePicker
                              selected={
                                student?.createdAt
                                  ? new Date(student.createdAt)
                                  : null
                              }
                              readOnly
                              dateFormat="dd/MM/yyyy HH:mm"
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              className="text-left"
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              fontWeight: "700",
                              position: "sticky",

                              left: "50px",
                              backgroundColor: "white",
                              zIndex: 1,
                            }}
                          >
                            <span
                              style={{
                                color: student?.enrollmentNumber
                                  ? "black"
                                  : "red",
                              }}
                            >
                              {student?.enrollmentNumber
                                ? student?.enrollmentNumber
                                : "Not Generat"}
                            </span>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              fontWeight: "700",
                              position: "sticky",

                              left: "50px",
                              backgroundColor: "white",
                              zIndex: 1,
                            }}
                          >
                            <span
                              style={{
                                color: student?.StudentType ? "green" : "red",
                              }}
                            >
                              {student?.StudentType
                                ? student?.StudentType
                                : "Normal"}
                            </span>
                          </TableCell>
                          <TableCell align="left" style={{ fontWeight: "700" }}>
                            {student?.randomId}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: 700,
                              minWidth: "120px",
                            }}
                          >
                            {student?.randomPassword}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              fontSize: "16px",
                              fontWeight: "bold",
                              position: "sticky",
                              minWidth: "250px",
                              left: "150px",
                              backgroundColor: "white",
                              zIndex: 1,
                              color: "#e85d04",
                            }}
                          >
                            {student.name}
                          </TableCell>
                          <TableCell align="left" style={{ fontWeight: "700" }}>
                            {student.email}
                          </TableCell>
                          <TableCell align="left">
                            <DatePicker
                              selected={new Date(student.dob)}
                              readOnly
                              dateFormat="dd/MM/yyyy"
                              className="text-center"
                            />
                          </TableCell>

                          <TableCell align="left">
                            {student.fathersname}
                          </TableCell>
                          <TableCell align="left">
                            {student.mothersname}
                          </TableCell>
                          <TableCell align="left">{student.mobile}</TableCell>
                          <TableCell
                            align="left"
                            style={{ color: "#1A3636", fontWeight: "700" }}
                          >
                            {student.courseName}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ color: "#1A3636", fontWeight: "700" }}
                          >
                            {student.courseBranch}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ color: "#1A3636", fontWeight: "700" }}
                          >
                            {student.assignedCollege || "Not assign"}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              color: student?.isPaid ? "green" : "red",
                              fontWeight: 700,
                            }}
                          >
                            {student?.isPaid ? "Paid" : "Not Paid"}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              color: student?.isEnrolled ? "green" : "red",
                              fontWeight: 700,
                            }}
                          >
                            {student?.isEnrolled ? "Filled" : "Not Fill"}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              color: student?.isEnrollPaid ? "green" : "red",
                              fontWeight: 700,
                            }}
                          >
                            {student?.isEnrollPaid ? "Paid" : "Not Paid"}
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        Data Loading.......
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[50, 100, 200, 500]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

          <br></br>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default TotalStudent;

