import React, { useState } from "react";
import { FormControl } from "@mui/material";
import { Button, Row } from "react-bootstrap";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
import axios from "axios";
import AdminUpdate_Clg_Name_Branch from "./Admin_Dashboard/AdminUpdate_Clg_Name_Branch.jsx";

function Admin_Clg_Search() {
  const [userData, setUserData] = useState([]);
  const [enteredValue, setEnteredValue] = useState("");
  const [showUpdateComponent, setShowUpdateComponent] = useState(false); // New state to control visibility

  const handleSearchById = () => {
    if (!enteredValue) {
      alert("Please enter a Registration No.");
      return;
    }

    axios
      .get(`https://sssutms.ac.in/apitest/admin/search2?search=${enteredValue}`)
      .then((response) => {
 
        if (response.data && response.data.length > 0) {
          setUserData(response.data)

          setShowUpdateComponent(true); // Show the update component on successful search
        } else {
          alert("No results found."); // Inform user if no results
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };


  return (
    <>
      <AdminDashboard />

      <div>
        <div
          style={{
            width: "80%",
            height: "350px",
            padding: "10px",
            marginTop: "80px",
            marginLeft: '100px',
          }}
        >
          <h1
            style={{
              fontSize: "22px",
              fontWeight: 700,
              color: "#A02334",
              marginBottom: "8px",
              fontFamily: "'Rubik', sans-serif",
            }}
          >
            Update Course & College
          </h1>

          <form style={{ padding: "15px" }}>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <label
                style={{
                  marginRight: "10px",
                  flexShrink: 0,
                  fontWeight:700
                }}
              >
                Registration No. :
              </label>

              <FormControl style={{ flex: 1 }}>
                <input
                  style={{ marginTop: "1px" }}
                  type="number"
                  className="form-control"
                  value={enteredValue}
                  onChange={(e) => setEnteredValue(e.target.value)}
                />
              </FormControl>

              {/* Search Button */}
              <Button
                style={{
                  marginLeft: "10px",
                  backgroundColor: "#004e92",
                  width: "90px",
                  height: "35px",
                  padding: "5px",
                }}
                onClick={handleSearchById}
              >
                Search
              </Button>
            </Row>
          </form>
        </div>
<div style={{marginTop:'-300px',marginLeft:'-50px'}}>
        {/* Conditionally render the AdminUpdate_Clg_Name_Branch component */}
        {showUpdateComponent && userData.length > 0 && (
          <AdminUpdate_Clg_Name_Branch userData={userData[0]} /> // Pass the first user data
        )}
      </div></div>
    </>
  );
}

export default Admin_Clg_Search;