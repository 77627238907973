import React, { useState } from "react";
import { FormControl } from "@mui/material";
import { Tabs, Tab, Button, Row } from "react-bootstrap";
import SelectSubjectable from "./Subject_Scheme_Table";
import AdminDashboard from "./AdminDashboard";

function Subject_Scheme_Search() {
  const [showTable, setShowTable] = useState(false);
  const [key, setKey] = useState("first");

  const handleSearch = () => {
    setShowTable(true);
  };

  //   };

  return (
    <>
      <AdminDashboard />
      <style>
        {`
          .shadow-box {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            padding: 10px;
            margin-top: 5px;
            width: 110%;
            margin-left:75px;
            height:370px;
          }
          .custom-tabs .nav-item {
            height: 30px;
          }
          .custom-tabs .nav-link {
            padding: 0px;
            font-size: 14px;
          }
          .custom-tab {
            padding: 5px 10px;
            font-size: 14px;
          }
          #admission-tabs .nav-link {
            padding: 5px 10px;
            font-size: 14px;
          }
          .header {
            height: 40px;
            background-color: #001d3d;
            border-radius: 10px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-family: italic;
          }
          .login-input {
            margin-bottom:8px;
          }
          .login-input:focus {
            outline: none;
            border-bottom: 2px solid #344e41;
          }
          @media (max-width: 768px) {
            .shadow-box {
              padding: 10px;
              margin-top: 10px;
            }
            .header {
              font-size: 16px;
              height: 40px;
            }
            .login-input {
              font-size: 0.9rem;
            }
          }
          @media (max-width: 576px) {
            .shadow-box {
              margin: 10px;
              margin-Left:76px;
            }
            .container-fluid {
              width: 100%;
              padding-right: 0;
              padding-left: 0;
            }
          }
          @media (min-width: 992px) {
            .custom-laptop-style {
              margin-left: 5%;
            }
          }
        `}
      </style>

      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "20%",
            height: "300px",
            padding: "10px",
            marginTop: "60px",
          }}
        >
          <div className="shadow-box">
            <h6
              style={{
                fontSize: "15px",
                fontWeight: 700,
                color: "#A02334",
                marginBottom: "8px",
                fontFamily: "'Rubik', sans-serif",
              }}
            >
              Select Subject Scheme
            </h6>
            <Tabs
              id="admission-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="first" tabClassName="custom-tab">
                <form style={{ padding: "10px" }}>
                  <Row>
                    <FormControl>
                      <label>Semster</label>
                      <select
                        className="form-select form-select-sm login-input"
                        required
                      >
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value=""
                        >
                          Select
                        </option>
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value="2023"
                        >
                          01
                        </option>
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value="2024"
                        >
                          02
                        </option>
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value="2024"
                        >
                          03
                        </option>
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value="2024"
                        >
                          04
                        </option>
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value="2024"
                        >
                          05
                        </option>
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value="2024"
                        >
                          06
                        </option>
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value="2024"
                        >
                          07
                        </option>
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value="2024"
                        >
                          08
                        </option>
                      </select>
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl>
                      <label>Course Name</label>
                      <select
                        className="form-select form-select-sm login-input"
                        required
                      >
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value=""
                        >
                          Select
                        </option>
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value="2023"
                        >
                          Regular
                        </option>
                      </select>
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl>
                      <label> Course Branch</label>
                      <select
                        className="form-select form-select-sm login-input"
                        required
                      >
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value=""
                        >
                          Select
                        </option>
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value="2023"
                        >
                          Main Exam
                        </option>
                      </select>
                    </FormControl>
                  </Row>
                  <Row>
                  <FormControl>
                      <label> Sub Branch</label>
                      <select
                        className="form-select form-select-sm login-input"
                        required
                      >
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value=""
                        >
                          Select
                        </option>
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value="2023"
                        >
                          Main Exam
                        </option>
                      </select>
                    </FormControl>
                  </Row>{" "}
                  <br />
                  <Row>
                    <Button
                      type="button"
                      style={{
                        marginLeft: "50px",
                        backgroundColor: "#004e92",
                        width: "150px",
                        height: "30px",
                        padding: "2px",
                      }}
                      onClick={handleSearch}
                    >
                      View Records
                    </Button>
                  </Row>
                </form>
              </Tab>
            </Tabs>
          </div>
        </div>

        <div>
          {showTable > 0 && (
            <div>
              <SelectSubjectable />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default Subject_Scheme_Search;
