import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
import axios from "axios";
import {
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Table,
  Paper,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import swal from "sweetalert";
const theme = createTheme({
  typography: {
    fontWeightBold: 700,
  },
});

const ExamForm_Creation_Search = () => {
  const [sessionType, setSessionType] = useState("");
  const [subBranch, setSubBranch] = useState("");
  const [category, setCategory] = useState("");
  const [exam_type, setExamType] = useState("");
  const [session, setSession] = useState("");
  const [branch, setBranch] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [courseType, setCourseType] = useState("");
  const [course, setCourse] = useState("");
  const [fromSem, setFromSem] = useState("");
  const [toSem, setToSem] = useState("");
  const [subBranchOptions, setSubBranchOptions] = useState([]);
  const [sessionName1, setSessionName1] = useState([]);
  const [data, setData] = useState([]);
  const [examForms, setExamForms] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  useEffect(() => {
    axios
      .get("https://sssutms.ac.in/apitest/v2/admin/courses2")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    if (courseType && course && branch && fromSem) {
      axios
        .get(
          `https://sssutms.ac.in/apitest/admin/search-semester-scheme?courseType=${courseType}&courseName=${course}&courseBranch=${branch}&semesterNumber=${fromSem}`
        )
        .then((response) => {
          const options = response.data.subBranch.map((item) => item.SubBranch);
          const options2 = response.data.subBranch.map(
            (item) => item.SemesterSession
          );
          setSubBranchOptions(options);
          setSessionName1(options2);
        })
        .catch((error) => {
          console.error("Error fetching subBranch options:", error);
          setSubBranchOptions([]);
        });
    }
  }, [courseType, course, branch, fromSem]);

  const getCourseTypes = () => {
    const types = new Set(data.map((item) => item.courseType));
    return Array.from(types);
  };

  const getCourses = () => {
    if (!courseType) return [];
    const courses = data
      .filter((item) => item.courseType === courseType)
      .flatMap((item) => item.courseNames.map((course) => course.courseName));
    return courses;
  };

  const getBranches = () => {
    if (!course) return [];
    const branches = data
      .flatMap((item) => item.courseNames)
      .filter((courseItem) => courseItem.courseName === course)
      .flatMap((courseItem) =>
        courseItem.branches.map((branch) => branch.branchName)
      );
    return branches;
  };

  const handleCreateExamForm = () => {
    if (
      !fromSem ||
      !toSem ||
      !session ||
      !courseType ||
      !course ||
      !branch ||
      !sessionType ||
      !subBranch
    ) {
      swal({
        icon: "error",
        title: "Error",
        text: `"Error", "Please fill all required fields!", "error" !!`,
      });

      return;
    }

    const formData = {
      semester: fromSem,
      session,
      sessionName,
      SessionType: sessionType,
      courseType, // Dynamically included
      // courseName,
      courseName: course,
      courseBranch: branch,
      category,
      exam_type,
      SubBranch: subBranch,
      fromsem: fromSem,
      tosem: toSem,
    };

    axios
      .post("http://localhost:7786/apitest/v1/create-exam-form", formData)
      .then((response) => {
        swal("Success", response.data.message, "success");

        const { examForm, assignedStudents } = response.data;
        const enrichedstudent = assignedStudents.map((student) => ({
          ...student,
          courseType: examForm.courseType,
          courseName: examForm.courseName,
          courseBranch: examForm.courseBranch,
          session: examForm.session,
          category: examForm.category,
          exam_type: examForm.exam_type,
        }));

        setExamForms(enrichedstudent);
      })
      .catch((error) => {
        console.error("Error creating exam form:", error);
        if (error.response) {
          const { status, message } = error.response.data;
          if (status === 400) {
            swal(
              "Error",
              "exam form already created for this course!",
              "error"
            );
          } else {
            swal("Error", "Failed to create exam form!", "error");
          }
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <ThemeProvider theme={theme}>
      <AdminDashboard />
      <style>
        {`
        .custom-dropdown select {
         font-family: "Rubik", sans-serif;
         font-size: 14px;
         color: black; /* default color for the select element */
       }

       .custom-dropdown option {
       font-family: "Rubik", sans-serif;
       font-size: 14px;
       font-weight:700
        }

        .shadow-box {
         box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
         border-radius: 10px;
         padding: 20px;
         margin-top: 60px;
         width: 100%; /* Adjusted width */
         margin-left:130px;
         height:280px;
         width:1180px
      }
        .header {
        height: 50px;
        border-radius: 10px;
        color: #003049;
        font-weight:700;
        display: flex;
     
        justify-content: center;
        align-items: center;
        font-size: 20px;
        `}
      </style>

      <div className="shadow-box" fluid>
        <div className="header">Exam Form Creation Between Sem</div>

        <Row style={{ marginTop: "5px" }}>
          <Col>
            <select
              className="form-select"
              onChange={(e) => setCourseType(e.target.value)}
            >
              <option selected>Course Type</option>
              {getCourseTypes().map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </Col>
          <Col>
            <select
              className="form-select"
              onChange={(e) => setCourse(e.target.value)}
            >
              <option selected>Course Name</option>
              {getCourses().map((courseName, index) => (
                <option key={index} value={courseName}>
                  {courseName}
                </option>
              ))}
            </select>
          </Col>
          <Col>
            <select
              className="form-select"
              onChange={(e) => setBranch(e.target.value)}
            >
              <option selected>Branch</option>
              {getBranches().map((branchName, index) => (
                <option key={index} value={branchName}>
                  {branchName}
                </option>
              ))}
            </select>
          </Col>
        </Row>

        <Row style={{ marginTop: "10px" }}>
          <Col>
            <select
              className="form-select"
              onChange={(e) => setSession(e.target.value)}
            >
              <option selected>Admission Session</option>
              <option value="2024-25">2024-25</option>
            </select>
          </Col>
          <Col>
            <select
              className="form-select"
              onChange={(e) => setSessionType(e.target.value)}
            >
              <option selected>Session Type</option>
              <option value="Semester">Semester</option>
              <option value="Annual">Annaul</option>
            </select>
          </Col>
          <Col>
            <select
              className="form-select"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option selected>Category</option>
              <option value="Regular">Regular</option>
            </select>
          </Col>
          <Col>
            <select
              className="form-select"
              onChange={(e) => setExamType(e.target.value)}
            >
              <option selected>Exam Type</option>
              <option value="Main">Main</option>
              <option value="Back">Back</option>
            </select>
          </Col>
        </Row>

        <Row style={{ marginTop: "10px" }}>
          <Col>
            <select
              className="form-select"
              onChange={(e) => setFromSem(e.target.value)}
            >
              <option selected>From Semester</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </select>
          </Col>
          <Col>
            <select
              className="form-select"
              onChange={(e) => setToSem(e.target.value)}
            >
              <option selected>To Semester</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </select>
          </Col>
          <Col>
            <select
              className="form-select"
              onChange={(e) => setSubBranch(e.target.value)}
            >
              <option selected>Sub Branch</option>
              {subBranchOptions.map((subBranchName, index) => (
                <option key={index} value={subBranchName}>
                  {subBranchName}
                </option>
              ))}
            </select>
          </Col>
          <Col>
            <select
              className="form-select"
              onChange={(e) => setSessionName(e.target.value)}
            >
              <option selected>Session Name</option>

              {sessionName1.map((session, index) => (
                <option key={index} value={session}>
                  {session}
                </option>
              ))}
            </select>
          </Col>
        </Row>

        <Row style={{ marginTop: "20px" }}>
          <Col>
            <Button
              style={{
                backgroundColor: "#001d3d",
                height: "35px",
                padding: "5px",
              }}
              onClick={handleCreateExamForm}
            >
              Create Examination Form
            </Button>
          </Col>
        </Row>

        <Paper sx={{ width: "100%" }}>
          <TableContainer sx={{ maxHeight: "440px", marginTop: "20px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {[
                    { label: "S.No.", stickyLeft: true },
                    { label: "Enrollment", minWidth: "90px" },
                    { label: "Student Name", minWidth: "50px" },
                    { label: "Type" },
                    { label: "Course Name" },
                    { label: "Course Branch", minWidth: "100px" },
                    { label: "Semester", minWidth: "50px" },
                    { label: "Session", minWidth: "150px" },
                    { label: "Father's Name", minWidth: "150px" },
                  ].map((column, index) => (
                    <TableCell
                      key={index}
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        position: "sticky",
                        top: 0,
                        zIndex: column.stickyLeft ? 2 : 1,
                        ...(column.stickyLeft && { left: 0 }),
                        ...(column.minWidth && { minWidth: column.minWidth }),
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {examForms.map((form, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="left"
                      style={{
                        position: "sticky",
                        top: 0,
                        left: 0,
                        backgroundColor: "white",
                        zIndex: 1,
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{form.enrollmentNumber}</TableCell>
                    <TableCell align="left">{form.name}</TableCell>
                    <TableCell align="left">{form.courseType}</TableCell>
                    <TableCell align="left">{form.courseName}</TableCell>
                    <TableCell align="left">{form.courseBranch}</TableCell>
                    <TableCell align="left">{form.currentSemester}</TableCell>
                    <TableCell align="left">{form.admissionSession}</TableCell>
                    <TableCell align="left">{form.fathersname}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={examForms.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </ThemeProvider>
  );
};

export default ExamForm_Creation_Search;
