import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";

const Subject_Scheme_Table = () => {
  const [data, setData] = useState([]); // State to hold the data
  const [loading, setLoading] = useState(true); // State to manage loading status
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // State for rows per page

  useEffect(() => {
    
    setTimeout(() => {

      axios
        .get("http://localhost:7786/apitest/admin/v1/assigned-students") 
        .then((response) => {
          setData(response.data); 
          setLoading(false);
        })
        .catch((error) => {
          console.error("There was an error fetching the data:", error);
          setLoading(false); // Stop loading even if there was an error
        });
    }, 2000); // Simulate a 2-second delay before the API request
  }, []);

  // Define columns for the table with reduced width
  const columns = [
    {
      name: "SR.No.",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "EXAM CODE",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "BRANCH CODE",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "SUBJECT CODE",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "SUBJECT SEQUENCE",
      selector: (row) => row.website,
      sortable: true,
    },
    {
      name: "SUBJ_NAME",
      selector: (row) => row.website,
      sortable: true,
    },

    {
      name: <div style={{ display: "flex", alignItems: "center" }}>All</div>,
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="checkbox"
            style={{ marginRight: "8px", accentColor: "#0a9396" }}
            onChange={(e) =>
              console.log(
                `Checkbox for ${row.name} is ${
                  e.target.checked ? "checked" : "unchecked"
                }`
              )
            }
          />
        </div>
      ),
      sortable: false,
    },
  ];

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };

  // Slice the data for pagination
  const indexOfLastItem = currentPage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <div
        className="container mt-4"
        style={{ marginLeft: "11%", width: "105%" }}
      >
        <div>
          {/* Other content above */}
          <div style={{ marginTop: "10%" }}>
            <DataTable
              title="Subject [Select Subject As per The Scheme]"
              columns={columns}
              data={currentData}
              pagination
              paginationServer
              paginationTotalRows={data.length}
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[5, 10, 20]} // Rows per page options
              onChangePage={handlePageChange} // Page change handler
              onChangeRowsPerPage={handleRowsPerPageChange} // Rows per page change handler
              progressPending={loading} // Show loading spinner while fetching data
              persistTableHead
              defaultSortField="name"
              defaultSortAsc={true}
              highlightOnHover
            />

            <div className="changed">
              <p>
                <label>
                  {/* <input type="checkbox" defaultChecked="" /> Account Verified */}
                  <input
                    type="checkbox"
                    defaultChecked=""
                    style={{ marginLeft: "30px" }}
                  />{" "}
                  Insitute Verified
                  <input
                    type="checkbox"
                    defaultChecked=""
                    style={{ marginLeft: "20px" }}
                  />{" "}
                  Examination Fees Paid
                  <input
                    type="checkbox"
                    defau
                    ltChecked=""
                    style={{ marginLeft: "20px" }}
                  />{" "}
                  Admit Card Ok
                  <input
                    type="checkbox"
                    defaultChecked=""
                    style={{ marginLeft: "20px" }}
                  />{" "}
                  Show Exam Form
                  <input
                    type="checkbox"
                    defaultChecked=""
                    style={{ marginLeft: "20px" }}
                  />{" "}
                  Mercy Chance
                </label>
              </p>
            </div>

            <button
              style={{
                marginLeft: "35%",
                backgroundColor: "#004e92",
                width: "150px",
                height: "30px",
                padding: "2px",
              }}
              type="button"
              class="btn btn-secondary"
            >
              SEARCH
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subject_Scheme_Table;
