import React, { useState, useEffect } from "react";
import { FormControl } from "@mui/material";
import { Tabs, Tab, Button, Form, Row } from "react-bootstrap";


import AdminDashboard from "../AdminDashboard";
import Showschemetable from "./Show_SchemeTable";

function Show_Scheme() {
  const [courseData, setCourseData] = useState({});
  const [selectedType, setSelectedType] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [loading, setLoading] = useState(false);

  const session = new Date().getFullYear();
  const [selectedSemester, setSelectedSemester] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [key, setKey] = useState("first");

  const [selectedSession, setSelectedSession] = useState("");

  const [selectedValues, setSelectedValues] = useState({
    selectedType: "",
    selectedCourse: "",
    selectedBranch: "",
    selectedSemester: "",
    selectedSession: "",
  });

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v2/admin/courses?session=${session}`
        );
        const data = await response.json();
        const formattedData = {};

        data.forEach((course) => {
          if (!formattedData[course.courseType]) {
            formattedData[course.courseType] = {
              courses: [],
              branches: {},
            };
          }

          course.courseNames.forEach((courseName) => {
            formattedData[course.courseType].courses.push(
              courseName.courseName
            );
            formattedData[course.courseType].branches[courseName.courseName] =
              courseName.branches.map((branch) => branch.branchName);
          });
        });

        setCourseData(formattedData);
      } catch (error) {
        console.error("Error fetching course data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [session]);

  const handleTypeChange = (e) => {
    const value = e.target.value;
    setSelectedType(value);
    setSelectedCourse("");
    setSelectedBranch("");
    setSelectedValues((prevValues) => ({
      ...prevValues,
      selectedType: value,
    }));
  };

  const handleCourseChange = (e) => {
    const value = e.target.value;
    setSelectedCourse(value);
    setSelectedValues((prevValues) => ({
      ...prevValues,
      selectedCourse: value,
    }));
  };

  const handleBranchChange = (e) => {
    const value = e.target.value;
    setSelectedBranch(value);
    setSelectedValues((prevValues) => ({
      ...prevValues,
      selectedBranch: value,
    }));
  };

  const handleSemesterChange = (e) => {
    const value = e.target.value;
    setSelectedSemester(value);
    setSelectedValues((prevValues) => ({
      ...prevValues,
      selectedSemester: value,
    }));
  };

  const handleSessionChange = (e) => {
    const value = e.target.value;
    setSelectedSession(value);
    setSelectedValues((prevValues) => ({
      ...prevValues,
      selectedSession: value,
    }));
  };

  const handleSearch = () => {
    console.log("Selected Values:", selectedValues);
    setShowTable(true);
  };

  return (
    <>
    <AdminDashboard/>
      <style>
        {`
          * {
            font-family: 'Poppins', Sans-serif;
            font-size: 15px;
          }
          .shadow-box {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            padding: 10px;
            margin-top: 5px;
            width: 110%;
            margin-left: 75px;
            height: 480px;
          }
          .custom-tabs .nav-item {
            height: 30px;
          }
          .custom-tabs .nav-link {
            padding: 0px;
            font-size: 14px;
          }
          .custom-tab {
            padding: 5px 10px;
            font-size: 14px;
          }
          #admission-tabs .nav-link {
            padding: 5px 10px;
            font-size: 14px;
          }
          .header {
            height: 40px;
            background-color: #001d3d;
            border-radius: 10px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-family: italic;
          }
          .login-input {
            margin-bottom: 8px;
          }
          .login-input:focus {
            outline: none;
            border-bottom: 2px solid #344e41;
          }
          @media (max-width: 768px) {
            .shadow-box {
              padding: 10px;
              margin-top: 10px;
            }
            .header {
              font-size: 16px;
              height: 40px;
            }
            .login-input {
              font-size: 0.9rem;
            }
          }
          @media (max-width: 576px) {
            .shadow-box {
              margin: 10px;
              margin-Left: 76px;
            }
            .container-fluid {
              width: 100%;
              padding-right: 0;
              padding-left: 0;
            }
          }
          @media (min-width: 992px) {
            .custom-laptop-style {
              margin-left: 5%;
            }
          }
        `}
      </style>

      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "20%",
            height: "300px",
            padding: "10px",
            marginTop: "60px",
          }}
        >
          <div className="shadow-box">
            <h6
              style={{
                fontSize: "15px",
                fontWeight: 700,
                color: "#A02334",
                marginBottom: "8px",
                fontFamily: "'Rubik', sans-serif",
              }}
            >
              SCHEME
            </h6>
            <Tabs
              id="admission-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="first" tabClassName="custom-tab">
                <form style={{ padding: "10px" }}>
                  <Row>
                    <FormControl>
                      <label>Course Type</label>
                      <Form.Select
                        value={selectedType}
                        onChange={handleTypeChange}
                        style={{ height: "35px", fontSize: "14px" }}
                      >
                        <option value="">Select Type</option>
                        {Object.keys(courseData).map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </Form.Select>
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl>
                      <label>Course</label>
                      <Form.Select
                        value={selectedCourse}
                        onChange={handleCourseChange}
                        style={{ height: "35px", fontSize: "14px" }}
                        disabled={!selectedType}
                      >
                        <option value="">Select Course</option>
                        {selectedType &&
                          courseData[selectedType].courses
                            .filter(
                              (course) =>
                                course !==
                                "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)"
                            )
                            .map((course) => (
                              <option key={course} value={course}>
                                {course}
                              </option>
                            ))}
                      </Form.Select>
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl>
                      <label>Branch</label>
                      <Form.Select
                        value={selectedBranch}
                        onChange={handleBranchChange}
                        style={{ height: "35px", fontSize: "14px" }}
                        disabled={!selectedCourse}
                      >
                        <option value="">Select Branch</option>
                        {selectedCourse &&
                          courseData[selectedType].branches[selectedCourse].map(
                            (branch) => (
                              <option key={branch} value={branch}>
                                {branch}
                              </option>
                            )
                          )}
                      </Form.Select>
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl>
                      <label>Semester</label>
                      <Form.Select
                        value={selectedSemester}
                        onChange={handleSemesterChange}
                        disabled={!selectedBranch}
                      >
                        <option value="">Select Semester</option>
                        {["1", "2", "3", "4", "5", "6", "7", "8"].map(
                          (sem, index) => (
                            <option key={index} value={sem}>
                              {sem} Semester
                            </option>
                          )
                        )}
                      </Form.Select>
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl>
                      <label>Semester Session</label>
                      <Form.Select
                        value={selectedSession}
                        onChange={handleSessionChange}
                        style={{ height: "35px", fontSize: "14px" }}
                      >
                        <option value="">Select Session</option>
                        <option>DEC-24</option>
                      </Form.Select>
                    </FormControl>
                  </Row>

                  <Row>
                    <Button
                      type="button"
                      style={{
                        marginLeft: "50px",
                        backgroundColor: "#004e92",
                        width: "150px",
                        height: "30px",
                        padding: "2px",
                        marginTop: "20px",
                      }}
                      onClick={handleSearch}
                    >
                      View Scheme
                    </Button>
                  </Row>
                </form>
              </Tab>
            </Tabs>
          </div>
        </div>

        <div style={{ marginLeft: "10%" }}>
          {showTable > 0 && (
            <div>
            <Showschemetable
                courseType={selectedType}
                courseBranch={selectedBranch}
                courseName={selectedCourse}
                semesterNumber={selectedSemester}
                semesterSession={selectedSession}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Show_Scheme;
