import React, { useState } from "react";
import Website from "./Website";
import b from "../images/sssutms.jpg";
import { Link } from "react-router-dom";

function MainPage() {
  const [isPopupVisible, setPopupVisible] = useState(true);
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  return (
    <>
      <style>
        {`


 .tabs-container {
            display: flex;
            // justify-content: center;
            // margin: 20px 0;
            //  margin-left:10px;
             gap:1px;
            margin-top:10px;
          }

          .tab {
            padding: 10px 20px;
            cursor: pointer;
            font-weight: bold;
            font-size: 14px;
            border: 1px solid #ddd;
            border-bottom: none;
            background-color: #f9f9f9;
            color: #444;
            transition: all 0.3s ease;
          }

          .tab:hover {
            background-color: #ddd;
          }

          .tab.active {
            background-color: #DC3545;
            color: white;
          }
        .addclass {
        height: 300px;
        width: 300px;
       }
      .spann{
       color: #C80036;
       font-weight: 700;
       width:500px;
       font-size: 21px;
       font-family:"Poppins",Sans-serif;
       }


      .hh5{
      color:#433878;
      margin-left:-30px;
      font-weight:700;
      text-align:left;
      font-family: "Poppins", sans-serif;
      font-size:20px;
      margin-top:-15px;
     }

    .popup-container {
      position: fixed;
      // top: -20px;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      margin-top:40px;
    }

    .popup-content {
      background-color: white;
      padding: 20px;
      border-radius: 5px;
      position: relative;
      width: 100%;
      height: 500px;
      max-width: 600px;
      text-align: center;
    }

    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 20px;
      cursor: pointer;
    }

    img {
      max-width:100%;
      object-fit: cover;
    }

    // .d-flex {
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    // }

    .main-content {
      padding: 20px;
    }


    .sticker {
      background-color: orange; /* Sticker background color */
      color: white;          /* Text color */
      font-size: 10px;       /* Sticker text size */
      padding: 2px 6px;      /* Padding for the sticker */
      border-radius: 5px;    /* Rounded corners */
      position: absolute;
      top: -30px;            /* Adjust the position relative to the text */
      right: -260px;          /* Adjust the sticker position */



      cursor: pointer;
    font-weight: bold;
    display: inline-block;
    position: relative;
    animation: blink 1s infinite;
    transition: transform 0.3s ease;
    }

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 1rem;
      padding: 3rem 0;
    }

    .wrapper {
      padding: 2rem;
      height: 300px;
      margin-top:-30px;
      width: 850px;
      border-radius: 15px;
    }

    h1 {
      font-size: 1.1rem;
      font-family: sans-serif;
    }

    .noti {
      background-color: #D8DFEA;
      height: 40px;
      padding: 5px;
      width: 550px;
      margin-left: -20px;
      border-radius: 5px;
      margin-bottom: 10px;
    }

    .sessions {
      margin-top: 2rem;
      border-radius: 12px;
      position: relative;
    }

    .ooo {
      position: relative;
      list-style: none;
      padding: 0;
      max-height: 300px;
       overflow-y: scroll;
        padding: 10px;
         scrollbar-color: #d5bdaf;
          scrollbar-width: thin;
           width: 72%;
            margin-left: -35px;

    }
      
    .hh6{
margin-left:5px;
font-size:14px;
 font-family:"Poppins",Sans-serif;}


     .hh61{
margin-left:5px;
font-size:14px;
 font-family:"Poppins",Sans-serif;}


    .ooo a {
      position: relative;
      display: block;
      padding: .4em 2em; /* Adjust padding for full width */
      color: #444;
      text-decoration: none;
      border-radius: .3em;
      left: -10px;
      transition: background 0.3s; /* Smooth transition */
    }

    // .ooo a:hover {
    //   background: #D8DFEA; /* Keep the same background on hover */
    // }

    .arrow {
      position: absolute;
      left: 10px; /* Adjust based on padding */
      color: #4B79C2; /* Arrow color */
      font-size: 16px; /* Size of the arrow */
      line-height: 1; /* Centering the arrow vertically */
      // transition: transform 0.3s ease; /* Transition effect for hover */
      margin-right: 10px; /* Add space between arrow and text */
    }

    .ooo a:hover .arrow {
      transform: translateX(5px); /* Move the arrow on hover */
    }

    .lihai {
      padding-bottom: 1.5rem;
      position: relative;
      padding-left: 30px; /* Increase padding for the arrow */
      margin-left: 10px;
    }
.nav-bar{
margin-top:-10px;}
.image{
margin-top:-3px;}


    @media screen and (max-width: 480px) {
      .noti {
        background-color: #D8DFEA;
        height: 43px;
        padding: 5px;
        width: 350px;
        margin-left: -40px;
        border-radius: 5px;
        margin-bottom: 10px;
      }


    .ooo{
    max-height: 380px;
     overflow-y: scroll;
      padding: 5px;
      width: 55%;
      margin-left: -37px;
       overflow-y: scroll;
        scrollbar-width: thin;
         scrollbar-color: #d5bdaf;
      }
.popup-container
{
margin-left:10px;
}
      .image{
      height:40px;
      width:40px;
      margin-top:-3px;

      }

.spann{
 color: #C80036;
  font-weight: 700;
  font-size: 12px;
  width:250px
  }
.hh6{
margin-left:20px}
.sticker{
margin-left:-250px;
}
.hh6{
margin-left:5px;}



.hh61{
margin-left:28px;}
      p {
        font-size: 0.9rem;
      }
    }
  `}
      </style>

      <div>
        {isPopupVisible && (
          <div className="popup-container">
            <div className="popup-content">
              <span className="close-btn" onClick={handleClosePopup}>
                &times;
              </span>
              <div className="nav-bar d-flex">
                <img src={b} className="image" alt="Logo" />

                <span className="spann">
                  SRI SATYA SAI UNIVERSITY OF TECHNOLOGY & MEDICAL SCIENCES
                </span>
              </div>
              <hr style={{ color: "black", height: "2px", marginTop: "2px" }} />
              <div className="tabs-container">
                <div
                  className={`tab ${activeTab === 1 ? "active" : ""}`}
                  onClick={() => handleTabClick(1)}
                >
                  Exam Time Table DEC-2024
                </div>

                <div
                  className={`tab ${activeTab === 0 ? "active" : ""}`}
                  onClick={() => handleTabClick(0)}
                >
                  Notice
                </div>
              </div>
              <div className="wrapper">
                {/* <h5 className="hh5">Important Notice</h5> */}

                <div>
                  {activeTab === 1 && (
                    <ol className="ooo">
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/AG_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              B.Sc.(Hons.) Agriculture Exam Time Table I
                              Semester [Regular/Ex]{" "}
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BABED_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              BABED Time Table I Semester (Regular/Ex) DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BE_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              BE Exam Time Table I / III Semester (Regular/Ex)
                              DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>

                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BED_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              BED Exam Time Table I Semester (Regular/Ex)
                              DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>

                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BHMCT_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              BHMCT Exam Time Table I Semester (Regular/Ex)
                              DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BLIB_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              B.LIB Exam Time Table I Semester (Regular/Ex)
                              DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BPED_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              BPED Exam Time Table I Semester (Regular/Ex)
                              DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BPHARMA_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              B.Pharma Exam Time Table I / III Semester
                              (Regular/Ex)
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/DIPLOMA_ENGG_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              Diploma-ENG Exam Time Table I / III Semester
                              (Regular/Ex) DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>

                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/LAW_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              LAW Time Table I Semester (Regular/Ex) DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/MA_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              MA Exam Time Table I Semester (Regular/Ex)
                              DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>

                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/MBA_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              MBA Exam Time Table I Semester [Regular/Ex]
                              DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/MCA_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              MCA Exam Time Table I Semester [Regular/Ex]
                              DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/MCOM_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              M.COM Exam Time Table I Semester (Regular/Ex)
                              DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/MPHARMA_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              M.Pharma Exam Time Table I / II Semester
                              (Regular/Ex) DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/MSC_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              MSC Exam Time Table I Semester (Regular/Ex)
                              DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/MASTER OF TECHNOLOGY.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              M.TECH Exam Time Table I Semester [Regular/Ex]
                              DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/PGDCA_I_SEM_DEC_2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              PGDCA Exam Time Table DEC-2024 I / II Semester
                              (Regular/Ex)
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BA I SEM DEC 2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              BA (NEP) I Semester (Regular/Ex) DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>

                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BARCH I SEM DEC 2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              BARCH I Semester (Regular/Ex) DEC-2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>

                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BBA I SEM DEC 2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              BBA (NEP) I Semester (Regular/Ex) DEC-2024{" "}
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>

                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BCA I SEM DEC 2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              BCA (NEP) I Semester (Regular/Ex) DEC-2024{" "}
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>

                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BCOM I SEM DEC 2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              BCOM (NEP) I Semester (Regular/Ex){" "}
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BSC I SEM DEC 2024.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh6">
                              {" "}
                              BSC (NEP) I Semester (Regular/Ex){" "}
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                    </ol>
                  )}
                  {activeTab === 0 && (
                    <ol className="ooo">
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/notification/NOTIFICATION.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                
                            <h6 className="hh6">
                              {" "}
                              M.TECH (Revised) Exam Time Table Notofication 2024{" "}
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>

                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/PDF/Exam_Notification_24-25.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh61">
                              {" "}
                              ExamForm Notification First Sem Dec 2024
                            </h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                      <div className="noti">
                        <li>
                          <Link
                            to="https://cdn.sssutms.ac.in/media/static/PDF/cvbnm.pdf"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh61">Notification For RDC</h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>

                      <div className="noti">
                        <li className="lii">
                          <Link
                            to="https://sssutms.ac.in/Epravesh/Student/Register"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh61">EPravesh Admission</h6>
                            <span
                              className="sticker"
                              style={{ cursor: "pointer" }}
                            >
                              Open
                            </span>
                          </Link>
                        </li>
                      </div>

                      <div className="noti">
                        <li className="lii">
                          <Link
                            to="https://sssutms.ac.in/admission/enquiry"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh61"> Admission Enquiry</h6>
                            <span
                              className="sticker"
                              style={{ cursor: "pointer" }}
                            >
                              Open
                            </span>
                          </Link>
                        </li>
                      </div>

                      <div className="noti">
                        <li>
                          <Link
                            to="https://sssutms.ac.in/erp/studentlogin"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh61"> Enrollment Form 2024</h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>

                      <div className="noti">
                        <li>
                          <Link
                            to="https://sssutms.ac.in/alumini-form"
                            target="_blank"
                            style={{ fontWeight: 700 }}
                          >
                            <h6 className="hh61"> Alumni Form 2024</h6>{" "}
                            <span className="sticker">Open</span>
                          </Link>
                        </li>
                      </div>
                    </ol>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          <Website />
        </div>
      </div>
    </>
  );
}

export default MainPage;
