
import React from 'react';
import Nav from '../../HomePage/Nav';
import Footerpage from '../../HomePage/Footerpage';
import { Link } from "react-router-dom";


function Criteria3() {
  return (
    <>
      <Nav />
      <style>
        {`

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }


  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:10px;
          margin-left:80px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){



.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}






h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}</style>


      <div className="cont"

      >
        <h2 className="h2 title-main" style={{ color: '#780000', marginTop: '10px' }}> Criteria Three</h2>
        <hr className="title-hr"></hr>
        <div className="row dirr">
          <div className="col-lg-9 padding-section">

            <br />

            <h5 style={{ color: 'orange', textAlign: 'center', fontFamily: "'poppins',sansSerif", marginBottom: '10px' }}>Criteria 3</h5>

            <style>
              {
                `
      //   ///////////////////////////////////////////////////////////////////////////////////
      .tann{
      margin-left:120px;
      }
      
      .table {
           border-collapse: collapse;
           width: 95%;
            margin-left:50px;
              font-family: 'poppins',sansSerif;
         }
         
         .table,
         .table th,
         .table td {
           border: 1px solid #ccc;
         }
         
         .table th,
         .table td {
           padding: 0.5rem;
         }
         
         .table th {
           position: relative;
            cursor: grab;
               user-select: none;
         }   
            .table th:active {
               cursor: grabbing;
             }
               
             .wide-cell {
      width: 200px; 
      text-align:center;/* Adjust width as needed */
    }`
              }
            </style>
            {/* <h5 style={{ color: 'orange', textAlign: 'center', fontFamily: "'poppins',sansSerif", marginBottom: '10px' }}>Training and Placement Cell</h5> */}
            <div className="tann">
              <table id="resizeMe" className="resizable-table table table-hover">

                <tbody>
                  <tr>
                    <td>
                      3.1.1
                    </td>
                    <td>Policies and Regulations for Conducting Research</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.1.1.pdf'>Click here </Link>
                    </td>

                  </tr>

                  <tr>
                    <td>
                      3.1.2
                    </td>
                    <td>
                      The institution provides seed money to its teachers for research (average per year INR in Lakhs)</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.1.2.pdf'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      3.1.3
                    </td>
                    <td>
                      The number of teachers who received national/international fellowship / financial support from various agencies for advanced studies/research year-wise during the last five years</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.1.3.pdf'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      3.1.5
                    </td>
                    <td>The institution has the following facilities to support research</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.1.5.pdf'>Click here </Link>
                    </td>

                  </tr>


                  <tr>
                    <td></td>
                    <td>Central Instrumentational lab</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/CIL.mp4'  rel="noopener noreferrer">
                        Click here
                      </Link>
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>MOOT COURT</td>     <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/MOOT COURT.mp4'  rel="noopener noreferrer">
                        Click here
                      </Link>
                    </td>

                  </tr>

                  <tr>
                    <td></td>
                    <td>CNC LAB</td>   <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/CNC lab.mp4'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td></td>
                    <td>Computer Lab</td>   <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/Computer lab.mp4'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td></td>
                    <td>Research & Statistics Lab</td>   <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/Research & Statistics lab.mp4'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td></td>
                    <td>Fabrication Lab</td>   <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/Fabrication lab.mp4'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td></td>
                    <td>Workshop Lab</td>   <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/Workshop lab.mp4'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      Art Gallery</td>   <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/.mp4'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td></td>
                    <td>Medicine Museam</td>   <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/Medicine Museam.mp4'>Click here </Link>
                    </td>

                  </tr>


                  <tr>
                    <td>
                      3.2.1
                    </td>
                    <td>
                      Extramural Funding for Research</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.2.1.pdf'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      3.2.2
                    </td>
                    <td>
                      Grants for research projects sponsored by the government agencies</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.2.2.pdf'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      3.2.3
                    </td>
                    <td>
                      Number of research projects per teacher funded by government and non-government agencies</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.2.3.pdf'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      3.3
                    </td>
                    <td>
                      Number of sanctioned posts year wise during the last five years</td>
                    <td className="wide-cell">

                    </td>

                  </tr>

                  <tr>
                    <td></td>
                    <td>Teaching 2017-18</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/teaching 2017-18.pdf'>Click here </Link>
                    </td>                </tr>




                  <tr>
                    <td></td>
                    <td>
                      Teaching 2018-19</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/teaching 2018-19.pdf'>Click here </Link>
                    </td>                </tr>



                  <tr>
                    <td></td>
                    <td>
                      Teaching 2019-20</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/teaching2019-20.pdf'>Click here </Link>
                    </td>                </tr>


                  <tr>
                    <td></td>
                    <td>Teaching 2020-21</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/teaching2020-21.pdf'>Click here </Link>
                    </td>                </tr>




                  <tr>
                    <td></td>
                    <td>
                      Teaching 2021-22</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/teaching2021-22.pdf'>Click here </Link>
                    </td>                </tr>



                  <tr>
                    <td>
                      3.3.2
                    </td>
                    <td>Number of workshops/seminars conducted on Research methodology, Intellectual Property Rights (IPR), entrepreneurship, and skill development during the last five years

                      The Institution ensures implementation of its stated Code of Ethics for research through the following:</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.3.2.pdf'>Click here </Link>
                    </td>

                  </tr>


                  <tr>
                    <td>3.4.1</td>
                    <td><ul style={{ marginLeft: '15px' }}> <li>Inclusion of research ethics in the research methodology course work</li>

                      <li>  Presence of Ethics Committee</li>
                      <li>  Plagiarism check through software</li>
                      <li>  Research Advisory Committee</li></ul>
                    </td> <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.4.1.pdf'>Click here </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      3.4.2
                    </td>
                    <td>
                      The institution provides incentives to teachers who receive state, national and international recognitions/awards</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.4.2.pdf'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      3.4.3.1
                    </td>
                    <td>Total number of Patents published/awarded year wise during the last five years</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.4.3.1.pdf'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      3.4.4
                    </td>
                    <td>
                      Number of Ph.D's awarded per teacher during the last five years.</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.4.4.pdf'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      3.4.5
                    </td>
                    <td>
                      Number of research papers per teacher in the Journals notified on UGC website during the last five years.</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.4.5.pdf'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      3.5.1
                    </td>
                    <td>Guidelines For Consultancy Services</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.5.1.pdf'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      3.5.2
                    </td>
                    <td>
                      Revenue generated from consultancy and corporate training during the last five years (INR in Lakhs).</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.5.2.pdf'>Click here </Link>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      3.6.3

                      & 3.6.4
                    </td>
                    <td>Number of extension and outreach programs conducted by the institution</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.6.3.pdf'>Click here </Link>
                    </td>

                  </tr>    <tr>
                    <td>
                      3.7.1
                    </td>
                    <td>
                      Number of collaborative activities with other institutions/ research establishments/ industry for research and academic development of faculty and students per year</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.7.1.pdf'>Click here </Link>
                    </td>

                  </tr>    <tr>
                    <td>
                      3.7.2
                    </td>
                    <td>
                      List of Memorandum of Understanding (MoUs)</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.7.2.pdf'>Click here </Link>
                    </td>

                  </tr>

                </tbody>
              </table>
            </div>





          </div>
          <div className="col-lg-3">

            <ul className='list-icon' >
              <h4 style={{ fontFamily: "'poppins',sansSerif", color: '#243642', textDecoration: 'underline' }}>NAAC</h4>
              <br></br>  <li className='lli'>
                <Link  to='/SSR'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>SSR </Link></li>
              <li className='lli'>
                <Link  to='/criteria1'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-1 </Link></li>
              <li className='lli'>
                <Link  to='/criteria2'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-2 </Link></li>
              <li className='lli'>
                <Link  to='/criteria3'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-3 </Link></li>

              <li className='lli'>
                <Link  to='/criteria4'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-4 </Link></li>



              <li className='lli'>
                <Link  to='/criteria5'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-5 </Link></li>

              <li className='lli'>
                <Link  to='/criteria6'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-6 </Link></li>



            </ul>
          </div>
        </div>







      </div>
      <br></br>
      {/* </section> */}
      <Footerpage /></>
  )
}

export default Criteria3







