// import React, { useState, useEffect } from "react";
// import DataTable from "react-data-table-component";
// import axios from "axios";
// import swal from "sweetalert";

// const ExamForm_HOD = ({ courseType, courseBranch, courseName, semester }) => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [rowsPerPage, setRowsPerPage] = useState(7);
//   const [selectedStudentIds, setSelectedStudentIds] = useState([]); 
//   const [examFormId, setExamFormId] = useState(""); // Input for Exam Form ID

//   useEffect(() => {
//     setTimeout(() => {
//       axios
//         .get(
//           `https://sssutms.ac.in/apitest/admin/forward-exam-form-list?courseType=${courseType}&courseBranch=${courseBranch}&courseName=${courseName}&semester=${semester}`
//         )
//         .then((response) => {
//           setData(response.data.data);
//           setExamFormId(response.data.data[0].formId);
//           setLoading(false);
//         })
//         .catch((error) => {
//           console.error("There was an error fetching the data:", error);
//           setLoading(false);
//         });
//     }, 2000);
//   }, [courseType, courseBranch, courseName, semester]);

//   const handleCheckboxChange = (studentId) => {
//     setSelectedStudentIds((prev) =>
//       prev.includes(studentId)
//         ? prev.filter((id) => id !== studentId)
//         : [...prev, studentId]
//     );
//   };

//   const handleSelectAll = (e) => {
//     if (e.target.checked) {
//       setSelectedStudentIds(data.map((student) => student.studentId)); // Select all
//     } else {
//       setSelectedStudentIds([]); // Clear all
//     }
//   };

//   const handleSubmit = async () => {
//     if (!examFormId) {
//       alert("Please provide an Exam Form ID.");
//       return;
//     }

//     try {
//       const response = await axios.post(
//         "https://sssutms.ac.in/apitest/v1/assignExamForm",
//         {
//           studentIds: selectedStudentIds,
//           examFormId,
//         }
//       );

//       swal({
//         title: "Success",
//         text: `Exam form assigned successfully!`,
//         icon: "success",
//         buttons: "OK",
//       });
//       // Remove assigned students from the table
//       setData((prevData) =>
//         prevData.filter(
//           (student) => !selectedStudentIds.includes(student.studentId)
//         )
//       );

//       // Clear selected students
//       setSelectedStudentIds([]);
//     } catch (error) {
//       console.error("Error submitting data:", error);
//       alert("Failed to assign exam form.");
//     }
//   };

//   const columns = [
//     {
//       name: "S.No.",
//       selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
//       sortable: true,
//       width: "50px", // Narrower width for SR.No.
//     },
//     {
//       name: "Enrollment No",
//       selector: (row) => row.enrollmentNumber,
//       sortable: true,
//       width: "150px", // Moderate width for Enrollment No.
//     },
//     {
//       name: "Candidate Name",
//       selector: (row) => row.name,
//       sortable: true,
//       wrap: true,
//       style: {
//         fontFamily: "'Poppins',Sans-serif",
//         maxWidth: "350px", // More space for Candidate Name
//         overflow: "hidden",
//       },
//     },
//     {
//       name: "Father Name",
//       selector: (row) => row.fathersname,
//       sortable: true,
//       wrap: true,
//       style: {
//         fontFamily: "'Poppins',Sans-serif",

//         maxWidth: "320px", // More space for Father's Name
//         overflow: "hidden",
//       },
//     },

//     {
//       width: "150px",
//       name: (
//         <div style={{ display: "flex", alignItems: "center", width: "20px" }}>
//           <input
//             type="checkbox"
//             style={{
//               marginRight: "10px",
//               accentColor: "#0a9396",
//               fontFamily: "'Poppins',Sans-serif",
//             }}
//             onChange={handleSelectAll}
//             checked={
//               selectedStudentIds.length === data.length && data.length > 0
//             }
//           />
//           All
//         </div>
//       ),
//       cell: (row) => (
//         <div style={{ display: "flex", alignItems: "center" }}>
//           <input
//             type="checkbox"
//             style={{ marginRight: "8px", accentColor: "#0a9396" }}
//             checked={selectedStudentIds.includes(row.studentId)}
//             onChange={() => handleCheckboxChange(row.studentId)}
//           />
//         </div>
//       ),
//       sortable: false,
//     },
//   ];

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const handleRowsPerPageChange = (newPerPage, page) => {
//     setRowsPerPage(newPerPage);
//     setCurrentPage(page);
//   };

//   const indexOfLastItem = currentPage * rowsPerPage;
//   const indexOfFirstItem = indexOfLastItem - rowsPerPage;
//   const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

//   return (
//     <>
//       <style>
//         {`
//         *{
//                fontFamily:"'Poppins',Sans-serif",
//                width:"100%
// }
//                `}
//       </style>
//       <div
//         className="container mt-4"
//         style={{
//           marginLeft: "15%",
//           width: "120%",
//           fontFamily: "'Poppins',Sans-serif",
//         }}
//       >
//         <div className="mb-3">
//           <input
//             type="text"
//             className="form-control"
//             placeholder="Enter Exam Form ID"
//             value={examFormId}
//             onChange={(e) => setExamFormId(e.target.value)}
//           />
//         </div>

//         {/* Table Wrapper without Scroll */}
//         <DataTable
//           title="EXAM FORM FORWARD"
//           columns={columns}
//           data={currentData}
//           pagination
//           paginationServer
//           paginationTotalRows={data.length}
//           paginationPerPage={rowsPerPage}
//           paginationRowsPerPageOptions={[100]} // 7 rows per page
//           onChangePage={handlePageChange}
//           onChangeRowsPerPage={handleRowsPerPageChange}
//           progressPending={loading}
//           persistTableHead
//           defaultSortField="name"
//           defaultSortAsc={true}
//           highlightOnHover
//           style={{
//             borderCollapse: "collapse",
//             fontFamily: "'Poppins',Sans-serif",
//           }}
//           className="table-bordered"
//           customStyles={{
//             headCells: {
//               style: {
//                 border: "1px solid #adb5bd",
//                 padding: "5px",
//                 backgroundColor: "#415a77",
//                 color: "white",
//                 fontSize: "10px",
//                 position: "sticky",
//                 top: 0,
//                 zIndex: 1,
//                 fontFamily: "'Poppins',Sans-serif",
//               },
//             },
//             cells: {
//               style: {
//                 border: "1px solid #adb5bd",
//                 // padding: "12px",

//                 fontFamily: "'Poppins',Sans-serif",
//               },
//             },
//           }}
//         />

//         {/* Submit Button Fixed at the Bottom */}
//         <button
//           className="btn btn-primary mt-3"
//           onClick={handleSubmit}
//           style={{
//             position: "fixed",
//             height: "40px",
//             bottom: "20px",

//             left: "40%",
//             padding: "5px",
//             transform: "translateX(-50%)",
//             zIndex: 10, // Keep the button on top of other content
//           }}
//         >
//           Submit Selected Students
//         </button>
//       </div>
//     </>
//   );
// };

// export default ExamForm_HOD;


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Checkbox,
//   Button,
//   CircularProgress,
//   Container,
//   ThemeProvider,
//   createTheme,
//   Box,
// } from "@mui/material";
// import SearchIcon from "@mui/icons-material/Search";

// const theme = createTheme();
// const ExamForm_HOD = ({ courseType, courseBranch, courseName, semester }) => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [selectedStudentIds, setSelectedStudentIds] = useState([]);
//   const [examFormId, setExamFormId] = useState("");
//   const [searchQuery, setSearchQuery] = useState("");

//   useEffect(() => {
//     setLoading(true);
//     axios
//       .get(
//         `https://sssutms.ac.in/apitest/admin/forward-exam-form-list?courseType=${courseType}&courseBranch=${courseBranch}&courseName=${courseName}&semester=${semester}`
//       )
//       .then((response) => {
//         setData(response.data.data || []);
//         setExamFormId(response.data.data[0]?.formId || "");
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//         setLoading(false);
//       });
//   }, [courseType, courseBranch, courseName, semester]);

//   const handleCheckboxChange = (studentId) => {
//     setSelectedStudentIds((prev) =>
//       prev.includes(studentId)
//         ? prev.filter((id) => id !== studentId)
//         : [...prev, studentId]
//     );
//   };

//   const handleSelectAll = (e) => {
//     if (e.target.checked) {
//       setSelectedStudentIds(data.map((student) => student.studentId));
//     } else {
//       setSelectedStudentIds([]);
//     }
//   };

//   const handleSubmit = async () => {
//     if (!examFormId) {
//       alert("Please provide an Exam Form ID.");
//       return;
//     }

//     try {
//       await axios.post(
//         "https://sssutms.ac.in/apitest/v1/assignExamForm",
//         {
//           studentIds: selectedStudentIds,
//           examFormId,
//         }
//       );

//       alert("Exam form assigned successfully!");
//       setData((prevData) =>
//         prevData.filter(
//           (student) => !selectedStudentIds.includes(student.studentId)
//         )
//       );
//       setSelectedStudentIds([]);
//     } catch (error) {
//       console.error("Error submitting data:", error);
//       alert("Failed to assign exam form.");
//     }
//   };

//   const filteredData = data.filter(
//     (item) =>
//       item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
//       item.enrollmentNumber.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   return (
//      <ThemeProvider theme={theme}>
//     <Container style={{ marginTop: "20px" }}>
//       {loading ? (
//         <CircularProgress style={{ margin: "20px auto", display: "block" }} />
//       ) : (
//         <>
//           <Box
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//               marginBottom: "20px",
//             }}
//           >
//             {/* Search Input */}
//             <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
//               <SearchIcon />
//               <input
//                 type="text"
//                 placeholder="Search by Enrollment or Name"
//                 value={searchQuery}
//                 onChange={(e) => setSearchQuery(e.target.value)}
//                 style={{
//                   padding: "8px",
//                   borderRadius: "4px",
//                   border: "1px solid #ccc",
//                   width: "300px",
//                   fontSize: "14px",
//                 }}
//               />
//             </Box>

//             {/* Submit Button */}
//             <Button
//               variant="outlined"
//               color="primary"
//               onClick={handleSubmit}
//               style={{ fontWeight: "bold" }}
//             >
//               Submit Selected Students
//             </Button>
//           </Box>

//           {/* Table */}
//           <TableContainer component={Paper} style={{ maxHeight: "500px" }}>
//             <Table stickyHeader>
//               <TableHead>
//                 <TableRow>
//                   <TableCell style={{ fontWeight: "bold" }}>
//                     <Checkbox
//                       onChange={handleSelectAll}
//                       checked={
//                         selectedStudentIds.length === data.length && data.length > 0
//                       }
//                     />
//                   </TableCell>
//                   <TableCell style={{ fontWeight: "bold" }}>S.No.</TableCell>
//                   <TableCell style={{ fontWeight: "bold" }}>Enrollment No</TableCell>
//                   <TableCell style={{ fontWeight: "bold" }}>Candidate Name</TableCell>
//                   <TableCell style={{ fontWeight: "bold" }}>Father Name</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {filteredData.map((student, index) => (
//                   <TableRow key={student.studentId}>
//                     <TableCell>
//                       <Checkbox
//                         checked={selectedStudentIds.includes(student.studentId)}
//                         onChange={() => handleCheckboxChange(student.studentId)}
//                       />
//                     </TableCell>
//                     <TableCell>{index + 1}</TableCell>
//                     <TableCell>{student.enrollmentNumber}</TableCell>
//                     <TableCell>{student.name}</TableCell>
//                     <TableCell>{student.fathersname}</TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </>
//       )}
//     </Container>
//     </ThemeProvider>
//   );
// };

// export default ExamForm_HOD;


import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ThemeProvider, createTheme,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import swal from "sweetalert";
const theme = createTheme();


const ExamForm_HOD = ({ courseType, courseBranch, courseName, semester }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(7); // Show 7 rows per page
  const [selectedStudentIds, setSelectedStudentIds] = useState([]); // Track selected student IDs
  const [examFormId, setExamFormId] = useState(""); // Input for Exam Form ID
  const [sortOrder, setSortOrder] = useState("asc"); // Sorting order

  useEffect(() => {
    setTimeout(() => {
      axios
        .get(
          `https://sssutms.ac.in/apitest/admin/forward-exam-form-list?courseType=${courseType}&courseBranch=${courseBranch}&courseName=${courseName}&semester=${semester}`
        )
        .then((response) => {
          setData(response.data.data);
          setExamFormId(response.data.data[0].formId);
          setLoading(false);
        })
        .catch((error) => {
          console.error("There was an error fetching the data:", error);
          setLoading(false);
        });
    }, 2000);
  }, [courseType, courseBranch, courseName, semester]);

  const handleCheckboxChange = (studentId) => {
    setSelectedStudentIds((prev) =>
      prev.includes(studentId)
        ? prev.filter((id) => id !== studentId)
        : [...prev, studentId]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedStudentIds(data.map((student) => student.studentId)); // Select all
    } else {
      setSelectedStudentIds([]); // Clear all
    }
  };

  const handleSubmit = async () => {
    if (!examFormId) {
      alert("Please provide an Exam Form ID.");
      return;
    }

    try {
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/v1/assignExamForm",
        {
          studentIds: selectedStudentIds,
          examFormId,
        }
      );

      swal({
        title: "Success",
        text: `Exam form assigned successfully!`,
        icon: "success",
        buttons: "OK",
      });
      // Remove assigned students from the table
      setData((prevData) =>
        prevData.filter(
          (student) => !selectedStudentIds.includes(student.studentId)
        )
      );

      // Clear selected students
      setSelectedStudentIds([]);
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Failed to assign exam form.");
    }
  };
  // Sort data by Enrollment Number
  const handleSort = () => {
    const sortedData = [...data].sort((a, b) => {
      const enrollmentA = a.enrollmentNumber.toLowerCase();
      const enrollmentB = b.enrollmentNumber.toLowerCase();
      if (sortOrder === "asc") {
        return enrollmentA.localeCompare(enrollmentB);
      } else {
        return enrollmentB.localeCompare(enrollmentA);
      }
    });
    setData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  return (
    <ThemeProvider theme={theme}>
    <Container style={{ marginTop: "80px", marginLeft: "100px", width: "90%" }}>
      {loading ? (
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h2>Exam Form</h2>
            {/* <input
              type="text"
              placeholder="Enter Exam Form ID"
              value={examFormId}
              onChange={(e) => setExamFormId(e.target.value)}
              style={{
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                flex: 1,
                marginRight: "10px",
              }}
            /> */}
            <Button
              variant="outlined"
              onClick={handleSubmit}
              disabled={selectedStudentIds.length === 0}
            >
              Submit Selected Students
            </Button>
          </Box>

          <TableContainer
            component={Paper}
            style={{ maxHeight: "500px", overflow: "auto", width: "135%" }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow style={{ backgroundColor: "#f0f0f0" }}>
                  <TableCell style={{ fontWeight: "bold", backgroundColor: "#023047", color: "#fff" }}>All
                    <Checkbox 
                
                      onChange={handleSelectAll}
                      checked={
                        selectedStudentIds.length === data.length && data.length > 0
                      } 
                    />
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", backgroundColor: "#023047", color: "#fff" }}>
                    S.No.
                  </TableCell>
                  <TableCell
                      style={{ fontWeight: "bold", backgroundColor: "#023047", color: "#fff", cursor: "pointer" }}
                      onClick={handleSort}
                    >
                      Enrollment No {sortOrder === "asc" ? "▲" : "▼"}
                    </TableCell>
                  <TableCell style={{ fontWeight: "bold", backgroundColor: "#023047", color: "#fff" }}>
                    Candidate Name
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold", backgroundColor: "#023047", color: "#fff" }}>
                    Father Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={item.studentId}>
                    <TableCell>
                      <Checkbox
                        checked={selectedStudentIds.includes(item.studentId)}
                        onChange={() => handleCheckboxChange(item.studentId)}
                      />
                    </TableCell>
                    <TableCell>{index + 1 + (currentPage - 1) * rowsPerPage}</TableCell>
                    <TableCell>{item.enrollmentNumber}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.fathersname}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Container>
    </ThemeProvider>
  );
};

export default ExamForm_HOD;


