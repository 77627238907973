import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import swal from "sweetalert";

const Manual_ExamF_Verifi_Table = ({
  courseType,
  courseBranch,
  courseName,
  semester,
  subBranch,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(7); // Show 7 rows per page
  const [selectedStudentIds, setSelectedStudentIds] = useState([]); // Track selected student IDs
  const [examFormId, setExamFormId] = useState(""); // Input for Exam Form ID

  useEffect(() => {
    setTimeout(() => {
      axios
        .get(
          `https://sssutms.ac.in/apitest/v1/assign-to-list?courseType=${courseType}&courseBranch=${courseBranch}&courseName=${courseName}&semester=${semester}&subBranch=${subBranch} `
        )
        .then((response) => {
          setData(response.data.students);
          setExamFormId(response.data.examFormId);
          setLoading(false);
        })
        .catch((error) => {
          console.error("There was an error fetching the data:", error);
          setLoading(false);
        });
    }, 2000);
  }, [courseType, courseBranch, courseName, semester, subBranch]);

  const handleCheckboxChange = (studentId) => {
    setSelectedStudentIds((prev) =>
      prev.includes(studentId)
        ? prev.filter((id) => id !== studentId)
        : [...prev, studentId]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedStudentIds(data.map((student) => student.studentId));
    } else {
      setSelectedStudentIds([]);
    }
  };

  const handleSubmit = async () => {
    if (!examFormId) {
      alert("Please provide an Exam Form ID.");
      return;
    }

    try {
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/students/assigned-forms",
        {
          studentIds: selectedStudentIds,
          examFormId,
        }
      );

      swal({
        title: "Success",
        text: `Exam form assigned successfully!`,
        icon: "success",
        buttons: "OK",
      });
///////////ye shi working me hai //////////
    // Remove the assigned students from the data
    setData((prevData) =>
      prevData.filter((student) => !selectedStudentIds.includes(student._id))
    );

    // Clear the selected student IDs
    setSelectedStudentIds([]);
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Failed to assign exam form.");
    }
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
      sortable: true,
      width: "80px",
    },
    {
      name: "Enrollment No",
      selector: (row) => row.enrollmentNumber,
      sortable: true,
      width: "150px",
    },
    {
      name: "Candidate Name",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
      style: {
        fontFamily: "'Poppins',Sans-serif",
        maxWidth: "320px",
        overflow: "hidden",
      },
    },
    {
      name: "Father Name",
      selector: (row) => row.fathersname,
      sortable: true,
      wrap: true,
      style: {
        fontFamily: "'Poppins',Sans-serif",

        maxWidth: "320px",
        overflow: "hidden",
      },
    },
    {
      name: "Fees Status",
      selector: (row) => row.website,
      sortable: true,
      width: "100px",
    },
    {
      name: "No Dues Status",
      selector: (row) => row.website,
      sortable: true,
      width: "100px",
    },
    {
      name: (
        <div style={{ display: "flex", alignItems: "center", width: "20px" }}>
          <input
            type="checkbox"
            style={{
              marginRight: "8px",
              accentColor: "#0a9396",
              fontFamily: "'Poppins',Sans-serif",
            }}
            onChange={handleSelectAll}
            checked={
              selectedStudentIds.length === data.length && data.length > 0
            }
          />
          All
        </div>
      ),
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="checkbox"
            style={{ marginRight: "8px", accentColor: "#0a9396" }}
            checked={selectedStudentIds.includes(row._id)}
            onChange={() => handleCheckboxChange(row._id)}
          />
        </div>
      ),
      sortable: false,
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <style>
        {`
        *{
               fontFamily:"'Poppins',Sans-serif",
               width:"100%
}
               `}
      </style>
      <div
        className="container mt-4"
        style={{
          marginLeft: "11%",
          width: "100%",
          fontFamily: "'Poppins',Sans-serif",
        }}
      >
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Enter Exam Form ID"
            value={examFormId}
            onChange={(e) => setExamFormId(e.target.value)}
          />
        </div>

        {/* Table Wrapper without Scroll */}
        <DataTable
          title="Exam Verification Data"
          columns={columns}
          data={currentData}
          pagination
          paginationServer
          paginationTotalRows={data.length}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[7]} // 7 rows per page
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          progressPending={loading}
          persistTableHead
          defaultSortField="name"
          defaultSortAsc={true}
          highlightOnHover
          style={{
            borderCollapse: "collapse",
            fontFamily: "'Poppins',Sans-serif",
          }}
          className="table-bordered"
          customStyles={{
            headCells: {
              style: {
                border: "1px solid #adb5bd",
                padding: "12px",
                backgroundColor: "#415a77",
                color: "white",
                position: "sticky",
                top: 0,
                zIndex: 1,
                fontFamily: "'Poppins',Sans-serif",
              },
            },
            cells: {
              style: {
                border: "1px solid #adb5bd",
                padding: "12px",

                fontFamily: "'Poppins',Sans-serif",
              },
            },
          }}
        />

        {/* Submit Button Fixed at the Bottom */}
        <button
          className="btn btn-primary mt-3"
          onClick={handleSubmit}
          style={{
            position: "fixed",
            height: "40px",
            bottom: "20px",

            left: "40%",
            padding: "5px",
            transform: "translateX(-50%)",
            zIndex: 10, // Keep the button on top of other content
          }}
        >
          Submit Selected Students
        </button>
      </div>
    </>
  );
};

export default Manual_ExamF_Verifi_Table;

// import React, { useState, useEffect } from "react";
// import DataTable from "react-data-table-component";
// import axios from "axios";
// import swal from "sweetalert";

// const ManualExamVerificationTable = ({
//   courseType,
//   courseBranch,
//   courseName,
//   semester,
//   subBranch,
// }) => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [rowsPerPage, setRowsPerPage] = useState(7);
//   const [selectedStudentIds, setSelectedStudentIds] = useState([]);
//   const [examFormId, setExamFormId] = useState("");

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `http://localhost:7786/apitest/v1/assign-to-list`,
//           {
//             params: {
//               courseType,
//               courseBranch,
//               courseName,
//               semester,
//               subBranch,
//             },
//           }
//         );
//         setData(response.data.students);
//         setExamFormId(response.data.examFormId);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [courseType, courseBranch, courseName, semester, subBranch]);

//   const handleCheckboxChange = (studentId) => {
//     setSelectedStudentIds((prev) =>
//       prev.includes(studentId)
//         ? prev.filter((id) => id !== studentId)
//         : [...prev, studentId]
//     );
//   };

//   const handleSelectAll = (e) => {
//     setSelectedStudentIds(e.target.checked ? data.map((student) => student._id) : []);
//   };

//   const handleSubmit = async () => {
//     if (!examFormId) {
//       swal("Error", "Please provide an Exam Form ID.", "error");
//       return;
//     }

//     try {
//       await axios.post("http://localhost:7786/apitest/students/assigned-forms", {
//         studentIds: selectedStudentIds,
//         examFormId,
//       });
//       swal("Success", "Exam form assigned successfully!", "success");
//     } catch (error) {
//       console.error("Error submitting data:", error);
//       swal("Error", "Failed to assign exam form. Please select student.", "error");
//     }
//   };

//   const columns = [
//     {
//       name: "S.No.",
//       selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
//       sortable: true,
//       width: "80px",
//     },
//     {
//       name: "Enrollment No",
//       selector: (row) => row.enrollmentNumber,
//       sortable: true,
//       width: "150px",
//     },
//     {
//       name: "Candidate Name",
//       selector: (row) => row.name,
//       sortable: true,
//       wrap: true,
//     },
//     {
//       name: "Father Name",
//       selector: (row) => row.fathersname,
//       sortable: true,
//       wrap: true,
//     },
//     {
//       name: "Select",
//       cell: (row, index) => (
//         <div style={{ display: "flex", alignItems: "center" }}>
//           <input
//             type="checkbox"
//             checked={selectedStudentIds.includes(row._id)}
//             onChange={() => handleCheckboxChange(row._id)}
//           />
//         </div>
//       ),
//       sortable: false,
//       width: "120px",
//     },
//   ];

//   return (
//     <div className="container mt-4" style={{ maxWidth: "95%" }}>
//       <div className="d-flex justify-content-between align-items-center mb-3">
//         <h5>Exam Verification Data</h5>
//         <button
//           className="btn btn-primary"
//           onClick={handleSubmit}
//         >
//           Submit Selected Students
//         </button>
//       </div>

//       <DataTable
//         title="Exam Verification Data"
//         columns={columns}
//         data={data}
//         pagination
//         paginationServer
//         paginationTotalRows={data.length}
//         paginationPerPage={rowsPerPage}
//         onChangePage={setCurrentPage}
//         onChangeRowsPerPage={setRowsPerPage}
//         progressPending={loading}
//         highlightOnHover
//         persistTableHead
//         subHeader
//         subHeaderComponent={
//           <div style={{ fontSize: "14px", fontWeight: "bold" }}>
//             {selectedStudentIds.length} {selectedStudentIds.length === 1 ? "item" : "Students"} Selected
//           </div>
//         }
//       />
//     </div>
//   );
// };

// export default ManualExamVerificationTable;
