import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../images/sssutms.jpg";

export default function Nav() {
  return (
    <>
      <style>
        {`
// .stickerr {
//       background-color: orange; /* Sticker background color */
//       color: white;          /* Text color */
//       font-size: 10px;       /* Sticker text size */
//       padding: 2px 6px;      /* Padding for the sticker */
//       border-radius: 5px;    /* Rounded corners */
//       position: absolute;
//       top: 10px;            /* Adjust the position relative to the text */
//       right: 7px;          /* Adjust the sticker position */

//             cursor: pointer;
//     font-weight: bold;
//     display: inline-block;
//     animation: blink 1s infinite;
//     transition: transform 0.3s ease;
 
        }
.navbar-nav .nav-link {
  color: #fff;
}
.dropend .dropdown-toggle {
  color: salmon;
   margin-left: 1em;
}


.dropdown-item{
 font-family: "Poppins", serif;

  font-style: normal;

  }

.dropdown .dropdown-menu {
  display: none;
}
.dropdown:hover > .dropdown-menu,
.dropend:hover > .dropdown-menu {
  display: block;
  margin-top: 0.125em;

}
@media screen and (min-width: 769px) {
  .dropend:hover > .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
  }
  .dropend .dropdown-toggle {
    margin-left: 0.5em;
  }
}
.nav-buttons {
  display: none; /* Hidden by default */
}
.brand-text {
  display: none;
}
      .loggo{
       
                  margin-left: -50px;

                  height: 70px;

                  width: 100px;
                  border-radius: 20px;
                }
      .navbar {
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
        background-color:#D2E0FB;
        position:sticky;
        margin-top:20px;
      }

.custom-sticky {
  position: sticky !important;
}

a.dropdown-item p.pstyle {
 
  font-size: 14px;
  color: black;
font-family: "Poppins", sans-serif;
 
  margin-bottom:-10px;
 
  padding: 0;
 
}

.down
{  
   font-size: 20px;
   font-family: 'Open Sans', sans-serif;
    margin-top:-8px;
      margin-bottom:-10px;
      padding:0 ;
     }


     
a.dropdown-item p.pstyle1 {
 
  font-size: 14px;
  color: #C80036;
font-family: "Poppins", sans-serif;
 
  margin-bottom:-8px;
 font-weight:700;
  padding: 0;
 
}

      // .navbar-brand{
      //   pading-right: 80px;
      // }
      .navbar .navbar-brand img {
        max-width: 100px;
      }
      .navbar .navbar-nav .nav-link {
        color: #000;
      }
      @media screen and (min-width: 1024px) {
        .navbar {
          letter-spacing: 0.1em;
        }
        .navbar .navbar-nav .nav-link {
          padding: 0.5em 1em;
        }
        .search-and-icons {
          width: 30%;
        }
        .search-and-icons form {
          flex: 1;
        }
      }

      @media screen and (max-width:480px){
 
     .nav-buttons {
    display: flex;
    flex-direction:row;
    width:220px;
   justify-content: space-between; /* Center the buttons */
    margin-top: 10px;
  }

  /* Adjust button styles for smaller screens */
  .bt {

    height: 20px;
    color:#B8001F;
    font-weight:700;
    font-size: 15px;
  }

  .loggo{
       
              margin-left: -5px;

                  height: 50px;

                  width: 50px;
                  border-radius: 20px;
                }}
      @media screen and (min-width: 768px) {
        .navbar .navbar-brand img {
          max-width: 4em;
        }


      .navbar-toggler{
          backgound-color:oragne;
      }

        .navbar .navbar-collapse {
          display: flex;
          flex-direction: column-reverse;
          align-items: flex-end;
        }
        .search-and-icons {
          display: flex;
          align-items: center;
        }
      }
      .search-and-icons form input {
        border-radius: 0;
        height: 2em;
        background: black
          url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='grey' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E")
          no-repeat 95%;
      }
      .search-and-icons form input:focus {
        background: black;
        box-shadow: none;
      }
      .search-and-icons .user-icons div {
        padding-right: 1em;
      }
      .contact-info p,
      .contact-info a {
        font-size: 0.7em;
        padding-right: 2em;
        // color: grey;
      }
     
      .contact-info a {
        padding-right: 0;
      }

      . {
        font-size: 0.8rem; /* Adjust the size as needed */
        font-weight:700er;

      }

  @import url('https://fonts.googleapis.com/css2?family=Basic&family=Doppio+One&display=swap')

  .dropdown-menu {
    max-height: 300px; /* Set a maximum height */
    overflow-y: auto;  /* Enable vertical scrolling */
    font-size: 0.8rem; /* Adjust the font size to be smaller */
}
 
       `}
      </style>

      <nav
        className="navbar navbar-expand-md bg-body-tertiary sticky-top"
        style={{ width: "100%", backgroundColor: "#D2E0FB" }}
      >
        <div className="container-xl" style={{ width: "100%" }}>
          <a className="navbar-brand d-flex align-items-center">
            <Link to="/">
              <img className="loggo" src={logo} alt="Logo" />
            </Link>
            <span className="brand-text">
              SRI SATYA SAI UNIVERSITY OF TECHNOLOGY & MEDICAL SCIENCES
            </span>
          </a>{" "}
          <div className="nav-buttons">
            <Link to="/erp">
              <h5 className="bt">ERP</h5>
            </Link>
            <Link to="/Epravesh/Student/Register">
              <h5 className="bt"> E-Pravesh</h5>
            </Link>
            <Link to="/admission/enquiry">
              <h5 className="bt"> Admission</h5>
            </Link>
          </div>
          <button
            style={{ backgroundColor: "#EEEEEE" }}
            className="navbar-toggler "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span>
              <i class="fa-solid fa-bars"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div
              className="contact-info d-md-flex"
              style={{ marginLeft: "-10px" }}
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {/* ==================================  Acadmic  =========================================== */}

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      fontFamily: "'Roboto',",
                      color: "#dd2d4a",
                      fontWeight: 800,
                      fontSize: "13px",
                    }}
                  >
                    Academic
                  </a>
                  <ul
                    className="dropdown-menu"
                    style={{ height: "auto", width: "230px" }}
                  >
                    <li
                      className="nav-item dropend"
                      style={{
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                      }}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          fontWeight: 700,
                          fontSize: "14px",
                          color: "#dd2d4a",
                        }}
                      >
                        Faculties & Department
                      </a>

                      <ul
                        className="dropdown-menu"
                        style={{
                          maxHeight: "520px",
                          overflowY: "auto",
                          width: "390px",
                          // scrollbarWidth: 'thin',
                          // scrollbarColor:'#1C325B',
                        }}
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="/enggtechno"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Engineering & Technology
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/pharma"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Pharmacy
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/edu"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Education
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/management"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Management
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/design"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Design
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/humanities&language"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Humanities & Language
                          </Link>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/commerce"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Commerce
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/science"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Science
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/ayurveda"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Ayurveda
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/law_dept"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Law
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/homeopathy"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Homeopathy
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href=""
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Paramedical
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href=""
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Nursing
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/computer_science"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Computer Science & Application
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Bacheloroflaws-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Hotel Management & Catering Technology
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/PolytechnicEngineerin-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Polytechnic (Engineering)
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/phd-page"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        PHD
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/event-calender"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        Event Calender
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/academic-calender"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        Acadmic Calender
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li
                      className="nav-item dropend"
                      style={{ marginBottom: "-7px", marginTop: "-5px" }}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                        }}
                      >
                        Committee
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            href="/anti-ragging"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Anti Ragging
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/proctorial-board"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Proctorial Board
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>

                        <li>
                          <a
                            className="dropdown-item"
                            href="/internal-complaint"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Internal Complaint
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/grievance-redressal"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Grievance Redressal
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/sc-st"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            For SC-ST
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/edc"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            EDC
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/international-higher-education-cell"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            International Higher Education Cell
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/incubation-cell"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Incubation Cell
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/scholarship"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        Scholarship
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li
                      className="nav-item dropend"
                      style={{ marginBottom: "-7px", marginTop: "-5px" }}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                        }}
                      >
                        Nacc
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            href="/SSR"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            SSR
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/criteria1"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Criteria-1
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/criteria2"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Criteria-2
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/criteria3"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Criteria-3
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/criteria4"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Criteria-4
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/criteria5"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Criteria-5
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/criteria6"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Criteria-6
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/constituent-units"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        Constituent Units
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li
                      className="nav-item dropend"
                      style={{ marginBottom: "-7px", marginTop: "-5px" }}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                        }}
                      >
                        Activites
                      </a>
                      <ul
                        className="dropdown-menu"
                        style={{ width: "230px", height: "200px" }}
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="/expert_lectures"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Expert Lecture
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/webinar"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Webinar
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/industrial_visits"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Industrial Visits
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/activity/events"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Events
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/fdp"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            FDP
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/workshop_seminars"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            WorkShop & Seminars
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Hei-handbook"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        HEL Handbook
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li
                      className="nav-item dropend"
                      style={{ marginBottom: "-7px", marginTop: "-5px" }}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                        }}
                      >
                        Training & Placement
                      </a>
                      <ul
                        className="dropdown-menu"
                        style={{ height: "80px", width: "280px" }}
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Academic/TrainingAndPlacementCell"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Training And Placement Cell
                          </a>
                        </li>{" "}
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Academic/TrainingPartner"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Training Partner
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/NIRF"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        NIRF
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/iqac_cell"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        IQAC Cell
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/mandatory_disclosures"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        Mandatory Disclosures
                      </a>
                    </li>
                  </ul>
                </li>

                {/* ================================== Faculty Of Education =========================================== */}

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      fontFamily: "'Roboto',",
                      color: "#dd2d4a",
                      fontWeight: 800,
                      fontSize: "13px",
                    }}
                  >
                    Faculty Of Education
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                          fontFamily: "'Poppins',Serif",
                        }}
                      >
                        School of Education
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item dropend">
                          <a
                            href="https://ncte.gov.in/website/wrc.aspx"
                            target="_blank"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Hyperlink of Council's Website
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Students
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/studentlist.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/22-23/StudentDetails23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Facilities added during last quarter
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/FacilityAdded.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/22-23/FacilityAdded23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Number of books in Library
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/LibFacility.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/22-23/LibraryFacility23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        {/* <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Sanctioned programme along with annual
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li> */}

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Sanctioned programme along with annual
                            intake
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/annual Intake.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/22-23/annual Intake23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Faculty
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/facultyD.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/facultyD.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Available infrastructure
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/infrastrucesoe21.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/22-23/infrastrucesoe23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Fees Charged from Students
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/FeeCharged.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/22-23/FeeCharged23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Biometric data
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/Biometric.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/Biometric.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>

                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Instructional facilities such as
                            Laboratory & Library
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                to="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/InstructionalFacility.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </Link>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/22-23/Instructional Facility23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Balance sheet as on the last date of the last year
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Balance%20Sheet%202022-2023.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Balance%20Sheet%202022-2023.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Income and Expenditure last financial year
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Expenditure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Expenditure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                          fontFamily: "'Poppins',Serif",
                        }}
                      >
                        School of Teaching Education
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item dropend">
                          <a
                            href="https://ncte.gov.in/website/wrc.aspx"
                            target="_blank"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Hyperlink of Council's Website
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Students
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/Dtails Of  Students 21-22 (BED)SOTE.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/StudentDetails.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Facilities added during last quarter
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/During the last Quarter 21-22 (BED)SOTE.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/FacilityLastQuarter.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Number of books in Library
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/Library Facility 21-22 (BED)SOTE.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/LibraryFacility.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Sanctioned programme along with annual
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/programme Anuual intake 21-22 (BED)SOTE.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/SanctionProgramI23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Faculty
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Faculty Details.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Faculty Details.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Available infrastructure
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/Infrastructure 21-22 (BED)SOTE.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Available_Infrastructure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Fees Charged from Students
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/Fees Chargred 21-22 (BED)SOTE.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/FeesCharged23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Biometric data
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/BIOTMETRIC.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/BIOTMETRIC.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>

                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of instructional facilities such as
                            Laboratory & Library
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/LABORATRY & LIB 21-22 (BED)SOTE.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/LibLab.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>

                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Balance sheet as on the last date of the last year
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/BalanceSheet22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Balance Sheet 2022-2023.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Income and Expenditure last financial year
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Expenditure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Expenditure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                          fontFamily: "'Poppins',Serif",
                        }}
                      >
                        School of Physical Education
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item dropend">
                          <a
                            href="https://ncte.gov.in/website/wrc.aspx"
                            target="_blank"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Hyperlink of Council's Website
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Students
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                to="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/details of students 2021-22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </Link>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/22-23/details of students 2022-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Facilities added during last quarter
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                to="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/added facilities 2021-22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </Link>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/22-23/added facilities 2022-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <Link
                            className="nav-link dropdown-toggle"
                            to={""}
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Number of books in Library
                          </Link>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                to="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/librararyfac.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </Link>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/22-23/library facilities 2022-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Receipt and Payment acccout for the last financial
                            year uploaded on Website
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/BalanceSheet22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Balance Sheet 2022-2023.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Sanctioned programme along with annual
                            intake
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/annual intake 2021-22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/22-23/annual intake 2022-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Faculty
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/facultyd.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/facultyd.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Available infrastructure
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/infrastructure facilities 2021-22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/22-23/instructional facilities 2022-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Fees Charged from Students
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                to="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/fees charged 2021-22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </Link>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/22-23/fees charged 2022-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Biometric data
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/biometric.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/biometric.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Balance sheet as on the last date of the last year
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/BalanceSheet22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Balance Sheet 2022-2023.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Instructional facilities such as
                            Laboratory & Library
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                to="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/instructional facilities 2021-22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </Link>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Income and Expenditure last financial year
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Expenditure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/22-23/incomeandexpenditure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                          fontFamily: "'Poppins',Serif",
                        }}
                      >
                        Faculty of Education (BABED)
                      </a>
                      <ul
                        className="dropdown-menu"
                        style={{ marginTop: "-80px" }}
                      >
                        <li className="nav-item dropend">
                          <a
                            href="https://ncte.gov.in/website/wrc.aspx"
                            target="_blank"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Hyperlink of Council's Website
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Students
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Studentlist2.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Dtails Of  Students 22-23 (BA BED).pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Facilities added during last quarter
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Facilityduringlastq.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/During The last Quarter (BABED) 22-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Number of books in Library
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Libraryfacilities.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Libarary (BABED)22-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Sanctioned programme along with annual
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/DetailsSanction.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Proframme Along (BAMBED)22-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Faculty
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/LIST1F.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Staff Membars  (BABED)22-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Available infrastructure
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Infrastructure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/infrastructure (BABED)22-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Fees Charged from Students
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/FeesCharged.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Fees Charged(BABED)22-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Biometric data
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/BIOMATRIC.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                {" "}
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/BIOMATRIC.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Balance sheet as on the last date of the last year
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Balance%20Sheet%202022-2023.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Balance sheet  (BABED)22-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of instructional facilities such as
                            Laboratory & Library
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/LabLib.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Laborty and lib 22-23 (BABED).pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Income and Expenditure last financial year
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Expenditure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/income  (BABED)22-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                {/* ==================================  DOWNLOAD  =========================================== */}

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      fontFamily: "'Roboto',",
                      color: "#dd2d4a",
                      fontWeight: 800,
                      fontSize: "13px",
                    }}
                  >
                    DOWNLOAD
                  </a>
                  <ul className="dropdown-menu" style={{ width: "280px" }}>
                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          fontFamily: "'Anton', sans-serif ",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        Outcome Based Curriculum
                      </a>
                      <ul
                        className="dropdown-menu"
                        style={{
                          maxHeight: "460px", // Adjust the height as needed
                          overflowY: "auto",
                        }}
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Engineering"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Engineering
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Pharmacy"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Pharmacy
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Educaion"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Education
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Physical-Education"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Physical Education
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/outcome/Management"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Management
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Computer-Application"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Computer Application
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BHMCT"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            BHMCT
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/outcome/Science"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Science
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Life-Science"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Life Science
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Arts-And-Humanities"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Arts And Humanities
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/outcome/Commerce"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Commerce
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li
                      className="nav-item dropend"
                      style={{ marginBottom: "-7px", marginTop: "-5px" }}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          fontFamily: "'Anton', sans-serif ",
                        }}
                      >
                        Scheme
                      </a>
                      <ul
                        className="dropdown-menu"
                        style={{
                          maxHeight: "430px", // Adjust the height as needed
                          overflowY: "auto",
                        }}
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="/B.E-Scheme"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            BE
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/MBA-Scheme"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            MBA
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/MCA-Scheme"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            MCA
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/M.Tech-Scheme"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            MTech
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BHMCT-Scheme"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            BHMCT
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BHMS-Scheme"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            BHMS
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/UTD-Scheme"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            UTD
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Education-Scheme"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Education
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Pharmacy-scheme"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Pharmacy
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Paramedical-Scheme"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Paramedical
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BLIBISC-Scheme"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            B.Lib.I.Sc.
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BSCHMCS-Scheme"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            B.Sc. [HMCS]
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BscHonsAg-Scheme"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            B.Sc.(Hons.) (Ag)
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/PhysicalEducation-Scheme"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Physical Education
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BachelorofLaws-Scheme"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Bachelor of Laws (LL.B.).
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/POLYTECHNIC(ENGINEERING)-Scheme"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            POLYTECHNIC (ENGINEERING)
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li
                      className="nav-item dropend"
                      style={{ marginBottom: "-7px", marginTop: "-5px" }}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          fontFamily: "'Anton', sans-serif ",
                        }}
                      >
                        Syllabus
                      </a>
                      <ul
                        className="dropdown-menu"
                        style={{
                          maxHeight: "430px", // Adjust the height as needed
                          overflowY: "auto",
                        }}
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="/B.E-Syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            BE
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/MBA-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            MBA
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/MCA-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            MCA
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/MTECH-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            MTech
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BHMCT-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            BHMCT
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BHMS-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            BHMS
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/UTD-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            UTD
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Education-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Education
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Pharmacy-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Pharmacy
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Paramedical-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Paramedical
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BLibISc -syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            B.Lib.I.Sc.
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BScHMCS-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            B.Sc. [HMCS]
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BScNursing-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            B.Sc.[Nursing]
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BScHonsAG-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            B.Sc.(Hons.) (Ag)
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Paramedical-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Paramedical
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/PhysicalEducation-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Physical Education
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Bacheloroflaws-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Bachelor of Laws (LL.B.).
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/PolytechnicEngineerin-syllabus"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            POLYTECHNIC (ENGINEERING)
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/phd-notofication"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        Notification of Ph.D Award{" "}
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/forms"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        Forms
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/Download/E-Content"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        E-Content
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/Download/Alumni"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        Alumni
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/Download/RTI"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        RTI
                      </a>
                    </li>
                  </ul>
                </li>

                {/* ==================================  RESEARCH  =========================================== */}

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      fontFamily: "'Roboto',",
                      color: "#dd2d4a",
                      fontWeight: 800,
                      fontSize: "13px",
                    }}
                  >
                    RESEARCH
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        href="/R&D-Cell"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        R & D Cell
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/Council-For-Research"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        Council For Research
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Research-Policy"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        Research Promotion Policy{" "}
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Consultancy-Services"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        Counsultancy Services
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Patent"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        Patents
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/IICCell"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        IIC Cell
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/E-Resources"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        E-Resources
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Exposition"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        Exposition
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        target="_blank"
                        href="https://cdn.sssutms.ac.in/media/static/notification/Phd%20Undertaking.pdf"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        PhD Undertaking
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        target="_blank"
                        href="https://cdn.sssutms.ac.in/media/static/notification/Research_Policy.pdf"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        Research Policy Office Order{" "}
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/UGAndPGScholarsProject"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        UG & PG Scholars Projects
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/NPTEL"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        NPTEL
                      </a>
                    </li>
                  </ul>
                </li>

                {/* ==================================  Admissions =========================================== */}

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      fontFamily: "'Roboto',",
                      color: "#dd2d4a",
                      fontWeight: 800,
                      fontSize: "13px",
                    }}
                  >
                    Admissions
                  </a>
                  <ul className="dropdown-menu" style={{ width: "250px" }}>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/Admission/AdmissionRegistration"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        }}
                      >
                        Admission Registration
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/Admission/AdmissionNotice"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        }}
                      >
                        Admission Notice
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Admission/UniversityAccountDetail"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        }}
                      >
                        University Account Detail
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Admission/FeesStructure"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        }}
                      >
                        Fees Structure
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Admission/AdmissionProcedure"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        }}
                      >
                        Admission Procedure
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Admission/Brochures"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        }}
                      >
                        Brochures
                      </a>
                    </li>
                  </ul>
                </li>

                {/* ==================================   Examination =========================================== */}

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      fontFamily: "'Roboto',",
                      color: "#dd2d4a",
                      fontWeight: 800,
                      fontSize: "13px",
                    }}
                  >
                    Examination
                  </a>
                  <ul className="dropdown-menu" style={{ width: "300px" }}>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/exam-timetable"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-5px",
                          marginTop: "-3px",
                          marginLeft: "-5px",
                        }}
                      >
                        Exam Time Table DEC-2024
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/entrance-exam-alert"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-5px",
                          marginTop: "-3px",
                          marginLeft: "-5px",
                        }}
                      >
                        Entrance Exam Alert
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/exam-notifications"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-5px",
                          marginTop: "-3px",
                          marginLeft: "-5px",
                        }}
                      >
                        Exam Notification
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/exam-schedule"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-5px",
                          marginTop: "-3px",
                          marginLeft: "-5px",
                        }}
                      >
                        Exam Schedule
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/result"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-5px",
                          marginTop: "-3px",
                          marginLeft: "-5px",
                        }}
                      >
                        Result
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/interface"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-5px",
                          marginTop: "-3px",
                          marginLeft: "-5px",
                        }}
                      >
                        Interface
                      </a>
                    </li>
                  </ul>
                </li>

                {/* ====================================== Alumni Form ================================== */}

                <Link to="/alumini-form">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      aria-current="page"
                      style={{
                        color: "#4B0082",
                        // animation: "blink 1s infinite",
                        fontSize: "inherit",
                        fontSize: "15px",
                      }}
                    >
                      {/* <h1
                        style={{
                          fontSize: "13px",
                          fontFamily: "'Anton', sans-serif ",
                          color: "purple",
                          textTransform: "uppercase",
                          fontWeight: "700er",
                        }}
                        // aria-disabled="true"
                      >
                        Entrance Exam
                      </h1> */}

                      <h1
                        style={{
                          fontSize: "13px",
                          fontFamily: "'Anton', sans-serif ",
                          color: "purple",
                          textTransform: "uppercase",
                          fontWeight: 800,
                        }}
                        // aria-disabled="true"
                      >
                        Alumni Form
                      </h1>
                    </a>
                    <style>
                      {`
                              @keyframes blink {
                              0% { opacity: 1; }
                             50% { opacity: 0; }
                             100% { opacity: 1; }
                               }
                             `}
                    </style>
                  </li>
                </Link>
              </ul>
            </div>

            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <Link to="/">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page">
                    <h1
                      style={{
                        fontSize: "13px",
                        fontFamily: "'Anton', sans-serif ",
                        textTransform: "uppercase",
                        fontWeight: 800,
                      }}
                    >
                      Home
                    </h1>
                  </a>
                </li>
              </Link>

              {/* ==================================  ABOUT =========================================== */}

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontFamily: "'Roboto',",
                    // color: "#dd2d4a",
                    fontWeight: 800,
                    fontSize: "13px",
                    marginTop: "-2px",
                  }}
                >
                  About
                </a>
                <ul
                  className="dropdown-menu"
                  style={{ height: "auto", width: "280px" }}
                >
                  <li>
                    <a
                      className="dropdown-item"
                      href="/History_Milestones"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Background
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/institutes"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "10px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Institutes
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li
                    className="nav-item dropend"
                    style={{
                      marginBottom: "-7px",
                      marginTop: "-10px",
                      fontWeight: 700,
                    }}
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        fontWeight: 700,
                        fontSize: "14px",
                        color: "#dd2d4a",
                      }}
                    >
                      University Officials
                    </a>

                    <ul
                      className="dropdown-menu"
                      style={{
                        maxHeight: "520px",
                        overflowY: "auto",
                        width: "300px",
                        // scrollbarWidth: 'thin',
                        // scrollbarColor:'#1C325B',
                      }}
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          href="/chancellor"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Chancellor
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/vice_chancellor"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Vice Chancellor
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/exam_controller"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Exam Controller
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/chief_vigilance_officer"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Chief Vigilance Officer
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/deputy_registrar"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Deputy Registrar
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/finance_officer"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Finance Officer
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/governing_body"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Governing Body
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/board_of_management"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Board Of management
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/board_of_studies"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Board Of Studies
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/academic_council"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Academic Council
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/finance_committee"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Finance Committee
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/standing_committee"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Standing Committee
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/statutes"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Statutes
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/audit_report"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Audit Report
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/dean"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Dean
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href=""
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Nursing
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/head_of_department"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Head Of The Department
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/promotingsociety"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Promoting Society
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li
                    className="nav-item dropend"
                    style={{ marginBottom: "-7px", marginTop: "-10px" }}
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        color: "#dd2d4a",
                        fontWeight: 700,
                        fontSize: "14px",
                      }}
                    >
                      Approvals & Ordinances
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          href="/approval"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Approvals
                        </a>
                      </li>

                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/ordiances"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Ordinances
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/vision-mission"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Vision & Mission
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/core-approvalsvalues"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Core Values
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/best-practices"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Best Practices
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li
                    className="nav-item dropend"
                    style={{
                      marginBottom: "-7px",
                      marginTop: "-10px",
                      fontWeight: 700,
                    }}
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        fontWeight: 700,
                        fontSize: "14px",
                        color: "#dd2d4a",
                      }}
                    >
                      Amenities
                    </a>

                    <ul
                      className="dropdown-menu"
                      style={{
                        maxHeight: "520px",
                        overflowY: "auto",
                        width: "260px",
                        // scrollbarWidth: 'thin',
                        // scrollbarColor:'#1C325B',
                      }}
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          href="/transprotation"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Transportation
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/Hostel"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Hostel
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/Library"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Library
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/Medicalfacility"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Medical Facillity
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/Guesthouse"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Guest House
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li
                    className="nav-item dropend"
                    style={{ marginBottom: "-7px", marginTop: "-10px" }}
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      // role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        color: "#dd2d4a",
                        fontWeight: 700,
                        fontSize: "14px",
                      }}
                    >
                      Collaboration & Mou
                    </a>
                    <ul
                      className="dropdown-menu"
                      style={{ height: "80px", width: "200px" }}
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          href="/mou-collaboration"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          NCC MOU
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/activity"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Activity
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/Public-Self-Disclosure"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Public Self Disclosure
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/Central_facilities"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Central Facilities
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/InstitutionalDevPlan "
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Institutional Development Plan
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/annual_reports"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Annual Reports
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/NSS"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      NSS
                    </a>
                  </li>
                </ul>
              </li>

              <Link to="">
                <li className="nav-item">
                  <a className="nav-link">
                    <h1
                      style={{
                        fontSize: "13px",
                        fontFamily: "'Anton', sans-serif ",
                        textTransform: "uppercase",
                        fontWeight: 800,
                      }}
                    >
                      Career
                    </h1>
                  </a>
                </li>
              </Link>

              <li className="nav-item">
                <a className="nav-link" href="/eventss">
                  <h1
                    style={{
                      fontSize: "13px",
                      fontFamily: "'Anton', sans-serif ",
                      textTransform: "uppercase",
                      fontWeight: 800,
                    }}
                  >
                    EVENT
                  </h1>
                </a>
              </li>

              <Link to="/contact">
                <li className="nav-item">
                  <a className="nav-link">
                    <h1
                      style={{
                        fontSize: "13px",
                        fontFamily: "'Anton', sans-serif ",
                        textTransform: "uppercase",
                        fontWeight: 800,
                      }}
                    >
                      Contact{" "}
                    </h1>
                  </a>
                </li>
              </Link>

              <li className="nav-item">
                <Link className="nav-link" to="/campus">
                  <h1
                    style={{
                      fontSize: "13px",
                      fontFamily: "'Anton', sans-serif ",

                      textTransform: "uppercase",
                      fontWeight: 800,
                    }}
                  >
                    {" "}
                    Campus
                  </h1>
                </Link>
              </li>

              {/* <li className="nav-item">
                <Link className="nav-link" to="/campus">
                  <h1
                    style={{
                      fontSize: "13px",
                      fontFamily: "'Anton', sans-serif ",

                      textTransform: "uppercase",
                      fontWeight: 800,
                    }}
                  >
                    {" "}
                    Carrer
                  </h1>
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/event-calender">
                  <h1
                    style={{
                      fontSize: "13px",
                      fontFamily: "'Anton', sans-serif ",
                      textTransform: "uppercase",
                      fontWeight: 800,
                    }}
                  >
                    EVENT-CALENDER
                  </h1>
                </Link>
              </li>

              <Link to="/Epravesh/Student/Register">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    style={{
                      color: "#4B0082",
                      fontSize: "15px",
                    }}
                  >
                    <h1
                      style={{
                        fontSize: "13px",
                        fontFamily: "'Anton', sans-serif ",

                        textTransform: "uppercase",
                        fontWeight: 800,
                      }}
                    >
                      {" "}
                      E-Pravesh{" "}
                    </h1>
                  </a>
                </li>
              </Link>

              <Link to="/erp">
                <li className="nav-item">
                  <button
                    type="button"
                    className="btn btn-danger"
                    style={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "8px",
                      height: "35px",
                      width: "60px",
                    }}
                  >
                    <b style={{ margin: "0" }}>ERP</b>
                  </button>
                </li>
              </Link>

              <Link to="/admission/enquiry">
                <li className="nav-item">
                  <button
                    type="button"
                    className="btn btn-danger"
                    style={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "8px",
                      height: "35px",
                      width: "120px",
                    }}
                  >
                    <b style={{ margin: "0", animation: "blink 1s infinite" }}>
                      ADMISSION
                    </b>
                  </button>
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
