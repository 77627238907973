import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Container } from "react-bootstrap";
import { Box, Input, Select } from "@chakra-ui/react";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import jsPDF from "jspdf";
import logo from "../../AdminComponent/12.png";

const RegisterDetailed = ({
  courseType,
  courseName,
  courseBranch,
  eligibility,
}) => {
  const [formData, setFormData] = useState({
    candidateName: "",
    fathersname: "",
    mothersname: "",
    gender: "",
    domicile: "",
    category: "",
    lastExamType: "",
    qualification: "",
    passingYear: "",
    qualificationPercentage: "",
    nationality: "",
    admissionSession: "",
    marksheet12th: "",
    marksheet10th: "",
    undergraduateCertificate: "",
    postgraduateCertificate: "",
    ItImarksheet: "",
    diplomaMarksheet: "",
    marks: "",
    totalMarks: "",
    QualifiedCourse: "",
    allotmentLetter: "",
    migrationCertificate: "",
    NeetPercentage: "",
    Concession: "",
    neetScoreCard: "",
  });
  const [selectedQualification, setSelectedQualification] = useState("");

  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("currentUser"));
  const { name, email, fathersname, mothersname, mobile, dob, _id } = userData;

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      candidateName: name,
      fathersname,
      mothersname,
      mobile,
      email,
      dob: formatDate(dob),
    }));
  }, [name, fathersname, mothersname, mobile, email, dob]);

  const [loading, setLoading] = useState(false);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  const handleFileChange = async (fieldName, e) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    const maxFileSizeKB = 1000;

    if (!allowedTypes.includes(file.type)) {
      swal({
        icon: "error",
        title: "Error",
        text: "Uploaded file is not a valid image. Only JPG, PNG and JPEG files are allowed",
      });
      e.target.value = null;
      return;
    }
    const fileSizeKB = file.size / 1024;
    if (fileSizeKB > maxFileSizeKB) {
      swal({
        icon: "error",
        title: "Error",
        text: `File size should not exceed ${maxFileSizeKB} KB. Please upload a smaller file.`,
      });
      e.target.value = null;
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "upload");

    try {
      const cloudinaryResponse = await fetch(
        `https://api.cloudinary.com/v1_1/dszawxz7y/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      const cloudinaryData = await cloudinaryResponse.json();
      const fileUrl = cloudinaryData.secure_url;

      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: fileUrl,
      }));
    } catch (error) {
      console.error("Error uploading file to Cloudinary:", error);
      swal({
        icon: "error",
        title: "Error",
        text: "Failed to upload the file. Please try again.",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      if (name === "marks" || name === "totalMarks") {
        const marks = parseFloat(updatedData.marks) || 0;
        const totalMarks = parseFloat(updatedData.totalMarks) || 0;

        if (marks > totalMarks) {
          swal({
            icon: "error",
            title: "Error",
            text: "Obtained marks cannot be greater than total marks.",
          });
          updatedData.marks = prevData.marks;
        } else if (totalMarks > 0) {
          const percentage = (marks / totalMarks) * 100;
          updatedData.qualificationPercentage = percentage.toFixed(2);
        }
      }

      return updatedData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      ...formData,
      courseType,
      courseName,
      courseBranch,
      eligibility,
      id: _id,
    };

    try {
      setLoading(true);
      const res = await fetch("https://sssutms.ac.in/apitest/registerupdates2", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (res.ok) {
        swal({
          title: `Congratulations ${name}`,
          content: {
            element: "p",
            attributes: {
              innerHTML: `Your Registration has been Done Successfully in <strong>${courseName}.</strong>`,
              style: "color: #05668d; font-size: 17px;",
            },
          },
          icon: "success",

          ok: "OK",
        }).then(() => {
          generatePDF();
          navigate("/studentWaiting");
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  /////////////////////////////////////////////////////

  const lastPassed = {
    UG: ["12th"],
    PG: ["UG"],
    Diploma: ["10th", "12th"],
    PG_Diploma: ["UG"],
  };
  const specificQualifications = {
    UG: {
      "BACHELOR OF ENGINEERING(Lateral)": ["Diploma", "BSC"],
      "BACHELOR OF LAWS": ["UG"],
      "BACHELOR OF EDUCATION": ["UG", "PG"],
      "BACHELOR OF PHARMACY(lateral)": ["Diploma"],
      "BACHELOR OF PHYSICAL EDUCATION(B. P. Ed.)": ["12th", "UG"],
      "BACHELOR OF PHYSICAL EDUCATION AND SPORTS(BPES)": ["12th", "UG"],
      "BACHELOR OF ARCHITECTURE": ["12th", "Diploma"],
      "BACHELOR OF LIBRARY SCIENCE": ["12th", "UG"],
    },
    PG: {
      "MASTER OF TECHNOLOGY": ["UG", "PG"],
    },
    Diploma: {
      "DIPLOMA ENGINEERING  (Lateral)": ["12th", "ITI"],
    },
  };
  function getLastPassedOptions(courseType, courseName) {
    if (
      specificQualifications[courseType] &&
      specificQualifications[courseType][courseName]
    ) {
      return specificQualifications[courseType][courseName];
    }
    return lastPassed[courseType] || [];
  }
  const courseToSubjects = {
    "BACHELOR OF PHYSIOTHERAPY": ["PCB", "All Subject"],
    "BACHELOR OF ENGINEERING": ["PCM"],
    "BACHELOR OF DESIGN": ["PCM", "PCB", "Arts", "Agriculture", "Commerce"],
    "BACHELOR OF PHARMACY": ["PCM", "PCB"],
    "BACHELOR OF EDUCATION": ["All Subject"],
    "BSC(Nursing)": ["PCB"],
    "BACHELOR OF HOTEL MANAGEMENT AND CATERING": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
    ],
    "BACHELOR OF PHYSICAL EDUCATION(B. P. Ed.)": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
      "Physical Education",
      "All Subject",
    ],
    "BACHELOR OF ARTS BACHELOR OF EDUCATION (B. A. B. Ed.)": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
      "All Subject",
    ],
    "BACHELOR OF ARCHITECTURE": ["PCM", "All Subject"],
    "BACHELOR OF SCIENCE": ["PCM", "PCB", "Arts", "Agriculture", "Commerce"],
    "BACHELOR OF BUSINESS ADMINISTRATION": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
      "All Subject",
    ],

    "BACHELOR OF ARTS": ["PCM", "PCB", "Arts", "Agriculture", "Commerce"],
    "BACHELOR OF COMMERCE": ["PCM", "PCB", "Arts", "Agriculture", "Commerce"],
    "BACHELOR OF COMPUTER APPLICATION": ["PCM", "PCB", "Commerce"],
    "BACHELOR OF ENGINEERING(Lateral)": [
      "AERONAUTICAL ENGINEERING",
      "CHEMICAL ENGINEERING",
      "CIVIL ENGINEERING",
      "COMPUTER SCIENCE AND ENGINEERING",
      "ELECTRICAL AND ELECTRONICS ENGINEERING",
      "ELECTRICAL ENGINEERING",
      "ELECTRONICS AND COMMUNICATION ENGINEERING",
      "ELECTRONICS AND INSTRUMENTATION ENGINEERING",
      "MECHANICAL ENGINEERING",
      "MINING ENGINEERING",
      "INFORMATION TECHONOLOGY",
      "Other Subject",
    ],
    "BACHELOR OF AYURVEDIC MEDICINE AND SURGERY": ["PCB"],
    "BACHELOR OF HOMEOPATHIC MEDICINE AND SURGERY(BHMS)": ["PCB"],
    "BACHELOR OF LAWS": ["All Subjects"],
    "BACHELOR OF LIBRARY SCIENCE": [
      "PCM",
      "PCB",
      "Arts",
      "BCA",
      "Agriculture",
      "Commerce",
    ],
    "BACHELOR OF PHYSICAL EDUCATION AND SPORTS(BPES)": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
      "All Subject",
    ],
    "BACHELOR OF PHARMACY(lateral)": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
      "All Subject",
    ],

    "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)": ["PCB"],
    "B.SC(Hons) AGRICULTURE": ["PCM", "PCB", "AGRICULTURE"],
    "BACHELOR OF PHSIOTHERAPY": ["PCB"],

    "MASTER OF TECHNOLOGY": ["BE", "B.TECH"],

    "MASTER OF PHARMACY": ["B.PHARMA"],
    "MASTER OF PHYSIOTHERAPHY (ORTHOPAEDIC)": ["B.PHARMA"],
    "MASTER OF MEDICAL LAB TECHNOLOGY(MMLT)": ["BMLT"],
    "MASTER OF COMPUTER APPLICATION": [
      "BSC (if 12th Passed with Maths)",
      "B.COM (if 12th Passed with Maths)",
      "BCA",
    ],
    "MASTER OF BUSINESS ADMINISTRATION(MBA)": ["All Subject"],
    "MASTER OF SCIENCE": [
      "BE",
      "BSC",
      "BSC(Hons)",
      "BSC(Hons)Maths",
      "BSC(Hons)CS",
      "BSC(Hons)IT",
      "BSC(Hons)PHYSICS",
      "BSC(Hons)CHEMISTRY",
      "BSC(Hons)BIOLOGY",
    ],
    "MASTER OF ARTS": ["UG"],
    "MASTER OF COMMERCE": ["BCOM"],
    "MASTER OF PHYSIOTHERAPY": ["BPT"],

    "DIPLOMA PHARMACY": ["All Subject"],
    "DIPLOMA BLOOD TRANSFUSION": ["PCB"],
    "DIPLOMA DIALYSIS TECHNICIAN": ["PCB"],
    "DIPLOMA PHARMACY (AYURVED)": ["PCB"],
    "DIPLOMA HUMAN NUTRITION": ["PCB"],
    "DIPLOMA MEDICAL LAB AND TECHNICIAN": ["PCB", "MLT"],
    "DIPLOMA X-RAY RADIOGRAPHER TECHNICIAN": ["PCB"],
    "DIPLOMA YOGA": ["PCB"],
    "DIPLOMA ENGINEERING": ["10th"],
    "DIPLOMA PHARMACY (HOMEOPATHY)": ["PCB"],
    "DIPLOMA PARAMEDICAL OPTHALMIC ASSISTENT": ["PCB"],
    "DIPLOMA ENGINEERING  (Lateral)": ["PCM"],

    "POST GRADUATION DIPLOMA IN COMPUTER APPLICATION": ["Any Graduate"],
  };

  const handleSelect = (e) => {
    setSelectedQualification(e.target.value);
  };
  ///////////////////////////////////////////////////////
  const generatePDF = async () => {
    const pdf = new jsPDF();

    pdf.addImage(logo, "JPEG", 8, 5, 20, 20);
    pdf.setTextColor(165, 42, 42);
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text(
      "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
      115,
      10,
      { align: "center" }
    );
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(11);
    pdf.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F),1956]",
      115,
      16,
      { align: "center" }
    );
    pdf.text(
      "SH-18, Bhopal-Indore Road, Opposite Oilfed Plant, Pachama,",
      115,
      22,
      { align: "center" }
    );

    pdf.setDrawColor(0);
    pdf.setFillColor(230, 230, 230);
    pdf.rect(65, 30, 80, 10, "F"); // Box
    pdf.setFontSize(14);
    pdf.setFont("helvetica", "bold");
    pdf.setTextColor(0, 0, 0);
    pdf.text("Registration Form", 105, 36, { align: "center" });

    // Dynamic form fields
    let y = 50;
    const addField = (label, value) => {
      pdf.setFontSize(10);
      pdf.setFont("helvetica", "normal");
      pdf.text(`${label} :`, 20, y);

      const fieldValue = value
        ? String(value)
        : "_______________________________";
      pdf.text(fieldValue, 80, y - 2); // Value just above the line
      pdf.line(80, y, 200, y); // Underline
      y += 7; //////////line space key value ka
    };

    // Dynamic fields from formData
    const formFields = [
      ["Admission Year", formData?.admissionSession || ""],
      ["Course for which admitted", courseName || ""],
      ["Specialization", courseBranch || ""],
      ["Name of Student", formData?.candidateName || ""],
      ["Father's Name", formData?.fathersname || ""],
      ["Mother's Name", formData?.mothersname || ""],
      ["Gender", formData?.gender || ""],
      ["Date of Birth", formatDate(userData?.dob) || ""],
      ["Email", formData?.email || ""],
      ["Mobile Number", formData?.mobile || ""],
      ["Domicile", formData?.domicile || ""],
      ["Category", formData?.category || ""],
      ["Nationality", formData?.nationality || ""],
    ];
    // Add the additional fields only for MBBS course
    if (courseName === "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)") {
      formFields.push(
        ["Neet Qualified Percentage", `${formData?.NeetPercentage}%` || ""],
        ["Admission Category", formData?.Concession || ""]
      );
    }

    formFields.forEach(([label, value]) => addField(label, value));

    // Table for Qualifying Exam details
    pdf.setFont("helvetica", "bold");
    pdf.text("Qualifying Exam Details:---", 20, y + 5); // Moved upward
    y += 8; //

    const tableData = [
      [
        "1",
        `${formData?.qualification}`,
        `${formData?.QualifiedCourse}`,
        `${formData?.passingYear}`,
        `${formData?.lastExamType}`,
        `${formData?.marks}`,
        `${formData?.totalMarks}`,
        `${formData?.qualificationPercentage}%`,
      ], // Example data
    ];

    pdf.autoTable({
      head: [
        [
          "S.No.",
          "Name of Exam",
          "Qualified Subject Group",
          "Passing Year",
          "Exam Type",
          "Obtain Marks",
          "Total Marks",
          "Percentage",
        ],
      ],
      body: tableData,
      startY: y,
      theme: "grid",
      styles: { fontSize: 8 },
      margin: { left: 20 },
    });

    y = pdf.lastAutoTable.finalY + 5;

    // Declaration Section
    pdf.setFont("helvetica", "bold");
    pdf.setTextColor(0, 0, 0);
    pdf.text("Declaration by Student/Parent/Guardian", 20, y);
    pdf.setFont("helvetica", "normal");
    y += 6; // Slightly reduced spacing after the heading
    const declaration = [
      "Self/on behalf of my ward hereby declare that:",
      "1. The information given by me in the application form and all enclosures are true to the best of my knowledge. However, should it, be found that any information/enclosures therein are untrue/wrong I am/my ward liable to be disqualified for admission",
      "2. If I am/my ward selected for admission I am/my promise to abide by the rules & regulations of the Institute/University and maintain the discipline in the institute and the hostel.",
      "3. Initially the admission is provisional and is subject to confirmation from the counseling authority concerned University and State Government.",
      "4. It is compulsory for me/my ward to appear for online counseling at any place directed by the counseling authority with in the specified date and time failing which I/my ward’s registration will be automatically cancelled without any refund of fee.",
      "5. I understand that if I get my admission/registration cancelled the fee deposited by me is nonrefundable.",
      "6. Cancellation of admission/registration is not possible without paying the full fees for the entire course.",
      "7. I undertake to abide by the “Student Leaving Midstream” clause of the prospectus and agree to pay fees for the whole course if I leave course in midstream.",
      "8. Any dispute is subject to Sehore Jurisdiction.",
    ];

    // Render the declaration with reduced spacing
    declaration.forEach((line, index) => {
      const wrappedText = pdf.splitTextToSize(line, 170); // Adjust width as per page margin
      pdf.text(wrappedText, 19, y); // Render text starting at (19, y)
      y += wrappedText.length * 5; // Reduced line spacing for compact layout
    });
    // Add Date field
    // Adjust Date and Signature section position
    y += 6; // Reduced spacing after the declaration
    pdf.text("Date : __________________________", 20, y); // Left-aligned
    pdf.text(
      "Signature of Student :            __________________________",
      100,
      y
    ); // Right-aligned

    // Additional fields below Date and Signature
    y += 6; // Slightly reduced spacing for neatness
    pdf.text("Place : __________________________", 20, y); // Below Date
    pdf.text(
      "Name of Declarer :                 __________________________",
      100,
      y
    ); // Below Signature
    y += 6; // Move to the next line
    pdf.text(
      "Relationship with Candidate :  __________________________",
      100,
      y
    ); // Below Sig

    // Save the PDF
    pdf.save(`${formData?.candidateName} Registration_Form.pdf`);
  };

  return (
    <Container
      fluid
      className="shadow p-3 mb-3 bg-body rounded"
      style={{ width: "90%", backgroundColor: "#e8e5d1", marginTop: "20px" }}
    >
      <Box
        marginBottom={"20px"}
        backgroundColor={"#264653"}
        p={4}
        color="white"
        mt={8}
        fontWeight={700}
        borderRadius="md"
        shadow="md"
      >
        Registration Details
      </Box>
      <div style={{ overflow: "hidden" }}>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={3}>
              <Form.Group className="mb-3" controlId="candidateName">
                <Form.Label style={{ fontFamily: "inherit" }}>
                  Candidate Name
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <Form.Control
                  style={{ height: "35px", fontSize: "14px" }}
                  type="text"
                  name="candidateName"
                  disabled
                  value={formData.candidateName}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3" controlId="fathersname">
                <Form.Label style={{ fontFamily: "inherit" }}>
                  Father's Name
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <Form.Control
                  style={{ height: "35px", fontSize: "14px" }}
                  type="text"
                  name="fathersname"
                  disabled
                  value={formData.fathersname}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3" controlId="mothersname">
                <Form.Label style={{ fontFamily: "inherit" }}>
                  Mother's Name
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <Form.Control
                  style={{ height: "35px", fontSize: "14px" }}
                  type="text"
                  name="mothersname"
                  disabled
                  value={formData.mothersname}
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label style={{ fontFamily: "inherit" }}>
                  Email
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <Form.Control
                  style={{ height: "35px", fontSize: "14px" }}
                  type="email"
                  name="email"
                  disabled
                  value={formData.email}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3" controlId="mobile">
                <Form.Label style={{ fontFamily: "inherit" }}>
                  Mobile Number
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <Form.Control
                  style={{ height: "35px", fontSize: "14px" }}
                  type="text"
                  name="mobile"
                  disabled
                  value={formData.mobile}
                />
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className="mb-3" controlId="dob">
                <Form.Label style={{ fontFamily: "inherit" }}>
                  Date of Birth
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <DatePicker
                    selected={userData?.dob ? new Date(userData?.dob) : null}
                    disabled
                    dateFormat="dd/MM/yyyy"
                    customInput={
                      <Form.Control
                        style={{
                          width: "100%",
                          height: "35px",
                          fontSize: "14px",
                        }}
                      />
                    }
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3" controlId="admissionSession">
                <Form.Label>
                  Current Admission Session
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <Form.Select
                  style={{ height: "35px", fontSize: "14px" }}
                  value={formData.admissionSession}
                  onChange={handleInputChange}
                  name="admissionSession"
                  required
                >
                  <option value="">Select Session</option>
                  <option value="2024">2024</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3" controlId="gender">
                <Form.Label style={{ fontFamily: "inherit" }}>
                  Gender
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <Form.Select
                  style={{ height: "35px", fontSize: "14px" }}
                  value={formData.gender}
                  onChange={handleInputChange}
                  name="gender"
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3" controlId="domicile">
                <Form.Label style={{ fontFamily: "inherit" }}>
                  Domicile
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <Form.Select
                  style={{ height: "35px", fontSize: "14px" }}
                  value={formData.domicile}
                  onChange={handleInputChange}
                  name="domicile"
                  required
                >
                  <option value="">Select Domicile</option>
                  <option value="ALL INDIA">ALL INDIA</option>
                  <option value="Madhya Pradesh">Madhya Pradesh</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3" controlId="category">
                <Form.Label style={{ fontFamily: "inherit" }}>
                  Category
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Select
                  style={{ height: "35px", fontSize: "14px" }}
                  value={formData.category}
                  onChange={handleInputChange}
                  name="category"
                  required
                >
                  <option value="">Select Category</option>
                  <option value="GENERAL">GENERAL</option>
                  <option value="OBC">OBC</option>
                  <option value="SC">SC</option>
                  <option value="ST">ST</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Form.Group className="mb-3" controlId="passingYear">
                <Form.Label>
                  Nationality
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <Form.Select
                  style={{ height: "35px", fontSize: "14px" }}
                  value={formData.nationality}
                  onChange={handleInputChange}
                  name="nationality"
                  required
                >
                  <option value="">Select Nationality</option>
                  <option value="INDIAN">INDIAN</option>
                  <option value="NRI">NRI</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3" controlId="lastExamType">
                <Form.Label style={{ fontFamily: "inherit" }}>
                  Last Exam Type
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <Form.Select
                  style={{ height: "35px", fontSize: "14px" }}
                  value={formData.lastExamType}
                  onChange={handleInputChange}
                  name="lastExamType"
                  required
                >
                  <option value="1">Select</option>
                  <option value="REGULAR">REGULAR</option>
                  <option value="PRIVATE">PRIVATE</option>
                </Form.Select>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className="mb-3" controlId="passingYear">
                <Form.Label style={{ fontFamily: "inherit" }}>
                  Last Passing Year <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "35px",
                    fontSize: "14px",
                  }}
                >
                  <DatePicker
                    selected={
                      formData.passingYear
                        ? new Date(formData.passingYear, 0)
                        : null
                    }
                    onChange={(date) =>
                      setFormData({
                        ...formData,
                        passingYear: date.getFullYear(),
                      })
                    }
                    showYearPicker
                    dateFormat="yyyy"
                    placeholderText="Select Year"
                    maxDate={new Date()} // Restricts future years
                    customInput={
                      <Input
                        size="md"
                        width="100%"
                        autoComplete="off"
                        style={{
                          padding: "8px",
                          minWidth: "280px",
                          height: "38px",
                          fontSize: "14px",
                        }}
                      />
                    }
                    autoComplete="off"
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3" controlId="totalMarks">
                <Form.Label style={{ fontFamily: "inherit" }}>
                  {formData.QualifiedCourse} Total Marks
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <Form.Control
                  style={{ height: "35px", fontSize: "14px" }}
                  type="number"
                  placeholder="Total Marks"
                  value={formData.totalMarks}
                  onChange={handleInputChange}
                  name="totalMarks"
                  required
                />
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className="mb-3" controlId="marks">
                <Form.Label style={{ fontFamily: "inherit" }}>
                  Marks Obtained
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <Form.Control
                  style={{ height: "35px", fontSize: "14px" }}
                  type="number"
                  placeholder="Marks Obtained"
                  value={formData.marks}
                  onChange={handleInputChange}
                  name="marks"
                  required
                />
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className="mb-3" controlId="qualificationPercentage">
                <Form.Label style={{ fontFamily: "inherit" }}>
                  Qualifying Exam Percentage
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <Form.Control
                  style={{ height: "35px", fontSize: "14px" }}
                  value={`${formData.qualificationPercentage}%`}
                  onChange={handleInputChange}
                  name="qualificationPercentage"
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Group className="mb-3" controlId="qualification">
                <Form.Label style={{ fontFamily: "inherit" }}>
                  Last Qualification
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                <Form.Select
                  style={{ height: "35px", fontSize: "14px" }}
                  name="qualification"
                  value={formData.qualification || ""} // Assuming `formData` holds the current state
                  onChange={(e) => {
                    handleInputChange(e);
                    handleSelect(e);
                  }}
                  required
                >
                  <option value="">Select</option>
                  {getLastPassedOptions(courseType, courseName)?.map(
                    (subject) => (
                      <option key={subject} value={subject}>
                        {subject}
                      </option>
                    )
                  )}
                </Form.Select>
              </Form.Group>
            </Col>

            {selectedQualification === "10th" && (
              <>
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="QualifiedCourse">
                    <Form.Label>
                      Qualified Subject Group
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Select
                      value={formData.QualifiedCourse}
                      onChange={handleInputChange}
                      name="QualifiedCourse"
                      required
                    >
                      <option value="">Select Subject</option>
                      {courseToSubjects[courseName]?.map((subject) => (
                        <option key={subject} value={subject}>
                          {subject}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="marksheet10th">
                    <Form.Label style={{ fontFamily: "inherit" }}>
                      Upload 10th Marksheet
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => handleFileChange("marksheet10th", e)}
                      accept="image/png, image/jpeg"
                      name="marksheet10th"
                      required
                    />
                  </Form.Group>
                </Col>
              </>
            )}
            {selectedQualification === "12th" && (
              <>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="QualifiedCourse">
                    <Form.Label>
                      Qualified Subject Group
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>

                    <Form.Select
                      style={{ height: "35px", fontSize: "14px" }}
                      value={formData.QualifiedCourse}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          QualifiedCourse: e.target.value,
                        })
                      }
                      name="QualifiedCourse"
                      required
                    >
                      <option value="">Select Subject</option>
                      {courseToSubjects[courseName]?.map((subject) => (
                        <option key={subject} value={subject}>
                          {subject}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="marksheet12th">
                    <Form.Label style={{ fontFamily: "inherit" }}>
                      Upload 12th Marksheet
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      style={{ height: "35px", fontSize: "14px" }}
                      type="file"
                      onChange={(e) => handleFileChange("marksheet12th", e)}
                      accept="image/png, image/jpeg"
                      name="marksheet12th"
                      required
                    />
                  </Form.Group>
                </Col>
              </>
            )}
            {selectedQualification === "UG" && (
              <>
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="QualifiedCourse">
                    <Form.Label>
                      Qualified Subject Group
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Select
                      style={{ height: "35px", fontSize: "14px" }}
                      value={formData.QualifiedCourse}
                      onChange={handleInputChange}
                      name="QualifiedCourse"
                      required
                    >
                      <option value="">Select Subject</option>
                      {courseToSubjects[courseName]?.map((subject) => (
                        <option key={subject} value={subject}>
                          {subject}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="undergraduateCertificate"
                  >
                    <Form.Label style={{ fontFamily: "inherit" }}>
                      Upload UG Marksheet
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      style={{ height: "35px", fontSize: "14px" }}
                      type="file"
                      onChange={(e) =>
                        handleFileChange("undergraduateCertificate", e)
                      }
                      accept="image/png, image/jpeg"
                      name="undergraduateCertificate"
                      required
                    />
                  </Form.Group>
                </Col>
              </>
            )}
            {selectedQualification === "PG" && (
              <>
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="QualifiedCourse">
                    <Form.Label>
                      Qualified Subject Group
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Select
                      style={{ height: "35px", fontSize: "14px" }}
                      value={formData.QualifiedCourse}
                      onChange={handleInputChange}
                      name="QualifiedCourse"
                      required
                    >
                      <option value="">Select Subject</option>
                      {courseToSubjects[courseName]?.map((subject) => (
                        <option key={subject} value={subject}>
                          {subject}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="postgraduateCertificate"
                  >
                    <Form.Label style={{ fontFamily: "inherit" }}>
                      Upload PG Marksheet
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      style={{ height: "35px", fontSize: "14px" }}
                      type="file"
                      onChange={(e) =>
                        handleFileChange("postgraduateCertificate", e)
                      }
                      accept="image/png, image/jpeg"
                      name="postgraduateCertificate"
                      required
                    />
                  </Form.Group>
                </Col>
              </>
            )}
            {selectedQualification === "Diploma" && (
              <>
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="QualifiedCourse">
                    <Form.Label>
                      Qualified Subject Group
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Select
                      style={{ height: "35px", fontSize: "14px" }}
                      value={formData.QualifiedCourse}
                      onChange={handleInputChange}
                      name="QualifiedCourse"
                      required
                    >
                      <option value="">Select Subject</option>
                      {courseToSubjects[courseName]?.map((subject) => (
                        <option key={subject} value={subject}>
                          {subject}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="diplomaMarksheet">
                    <Form.Label style={{ fontFamily: "inherit" }}>
                      Upload Diploma Marksheet
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      style={{ height: "35px", fontSize: "14px" }}
                      type="file"
                      onChange={(e) => handleFileChange("diplomaMarksheet", e)}
                      accept="image/png, image/jpeg"
                      name="diplomaMarksheet"
                      required
                    />
                  </Form.Group>
                </Col>
              </>
            )}
            {selectedQualification === "BSC" && (
              <>
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="QualifiedCourse">
                    <Form.Label>
                      Qualified Subject Group
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Select
                      style={{ height: "35px", fontSize: "14px" }}
                      value={formData.QualifiedCourse}
                      onChange={handleInputChange}
                      name="QualifiedCourse"
                      required
                    >
                      <option value="">Select Subject</option>
                      {courseToSubjects[courseName]?.map((subject) => (
                        <option key={subject} value={subject}>
                          {subject}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="undergraduateCertificate"
                  >
                    <Form.Label style={{ fontFamily: "inherit" }}>
                      Upload BSC Marksheet
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      style={{ height: "35px", fontSize: "14px" }}
                      type="file"
                      onChange={(e) =>
                        handleFileChange("undergraduateCertificate", e)
                      }
                      accept="image/png, image/jpeg"
                      name="undergraduateCertificate"
                      required
                    />
                  </Form.Group>
                </Col>
              </>
            )}
            {selectedQualification === "ITI" && (
              <>
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="QualifiedCourse">
                    <Form.Label>
                      Qualified Subject Group
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Select
                      value={formData.QualifiedCourse}
                      onChange={handleInputChange}
                      name="QualifiedCourse"
                      required
                    >
                      <option value="">Select Subject</option>
                      {courseToSubjects[courseName]?.map((subject) => (
                        <option key={subject} value={subject}>
                          {subject}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3" controlId="ItImarksheet">
                    <Form.Label style={{ fontFamily: "inherit" }}>
                      Upload ITI Marksheet
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => handleFileChange("ItImarksheet", e)}
                      accept="image/png, image/jpeg"
                      name="ItImarksheet"
                      required
                    />
                  </Form.Group>
                </Col>
              </>
            )}
            {courseName ===
              "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)" && (
              <>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="NeetPercentage">
                    <Form.Label style={{ fontFamily: "inherit" }}>
                      Neet Qualified Percentage
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      style={{ height: "35px", fontSize: "14px" }}
                      type="number"
                      value={formData.NeetPercentage}
                      onChange={handleInputChange}
                      name="NeetPercentage"
                      required
                    />
                  </Form.Group>
                </Col>
              </>
            )}
          </Row>
          {courseName === "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)" && (
            <>
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="Concession">
                    <Form.Label style={{ fontFamily: "inherit" }}>
                      Admission Category
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Select
                      style={{ height: "35px", fontSize: "14px" }}
                      value={formData.Concession}
                      onChange={handleInputChange}
                      name="Concession"
                      required
                    >
                      <option value="">Select</option>
                      <option value="Jankalyan Yojana">Jankalyan Yojana</option>
                      <option value="Mukhyamantri Medhavi Yojana">
                        Mukhyamantri Medhavi Yojana
                      </option>
                      <option value="Postmetric SC">Postmetric SC</option>
                      <option value="Postmetric ST">Postmetric ST</option>
                      <option value="Postmetric OBC">Postmetric OBC</option>
                      <option value="EWS">EWS</option>
                      <option value="NRI">NRI</option>
                      <option value="NoAny">No Any</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="migrationCertificate">
                    <Form.Label style={{ fontFamily: "inherit" }}>
                      Migration Certificate
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      style={{ height: "35px", fontSize: "14px" }}
                      type="file"
                      onChange={(e) =>
                        handleFileChange("migrationCertificate", e)
                      }
                      accept="image/png, image/jpeg"
                      name="migrationCertificate"
                      required
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group className="mb-3" controlId="allotmentLetter">
                    <Form.Label style={{ fontFamily: "inherit" }}>
                      Admission Slip/Allotment Letter
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      style={{ height: "35px", fontSize: "14px" }}
                      type="file"
                      onChange={(e) => handleFileChange("allotmentLetter", e)}
                      accept="image/png, image/jpeg"
                      name="allotmentLetter"
                      required
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group className="mb-3" controlId="NeetScoreCard">
                    <Form.Label style={{ fontFamily: "inherit" }}>
                      Neet Qualified Score Card
                      <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      style={{ height: "35px", fontSize: "14px" }}
                      type="file"
                      onChange={(e) => handleFileChange("neetScoreCard", e)}
                      accept="image/png, image/jpeg"
                      name="neetScoreCard"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}

          <Button
            variant="success"
            size="lg"
            type="submit"
            style={{
              margin: "20px auto",
              display: "block",
              backgroundColor: "#264653",
              color: "white",
              width: "w-30 w-md-auto",
              border: "none",
              borderRadius: "5px",
              fontSize: "15px",
              padding: "10px 20px",
            }}
          >
            {loading ? "Loading..." : "Register Now "}
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default RegisterDetailed;
