import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import swal from "sweetalert";

const Exam_Verification_table = ({
  courseType,
  courseBranch,
  courseName,
  semester,
  subBranch
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(7); // Show 7 rows per page
  const [selectedStudentIds, setSelectedStudentIds] = useState([]); // Track selected student IDs
  const [examFormId, setExamFormId] = useState(""); // Input for Exam Form ID

  useEffect(() => {
    setTimeout(() => {
      axios
        .get(
            `http://localhost:7786/apitest/admin/verification/admit_card_list?courseType=${courseType}&courseBranch=${courseBranch}&courseName=${courseName}&semester=${semester}&subBranch=${subBranch} `        )
        .then((response) => {
          setData(response.data.data);
          setExamFormId(response.data.data[0].formId);
          setLoading(false);
        })
        .catch((error) => {
          console.error("There was an error fetching the data:", error);
          setLoading(false);
        });
    }, 2000);
  }, [courseType, courseBranch, courseName, semester]);

  const handleCheckboxChange = (studentId) => {
    setSelectedStudentIds((prev) =>
      prev.includes(studentId)
        ? prev.filter((id) => id !== studentId)
        : [...prev, studentId]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedStudentIds(data.map((student) => student.studentId)); // Select all
    } else {
      setSelectedStudentIds([]); // Clear all
    }
  };

  const handleSubmit = async () => {
    if (!examFormId) {
      alert("Please provide an Exam Form ID.");
      return;
    }

    try {
      const response = await axios.post(
        "http://localhost:7786/apitest/admin/approve-admit-card",
        {
          studentIds: selectedStudentIds,
          examFormId,
        }
      );

      swal({
        title: "Success",
        text: `Admit Card Verified successfully!`,
        icon: "success",
        buttons: "OK",
      });
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Failed to assign exam form.");
    }
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
      sortable: true,
      width: "80px", // Narrower width for SR.No.
    },
    {
      name: "Enrollment No",
      selector: (row) => row.enrollmentNumber,
      sortable: true,
      width: "150px", // Moderate width for Enrollment No.
    },
    {
      name: "Candidate Name",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
      style: {
        fontFamily: "'Poppins',Sans-serif",
        maxWidth: "320px", // More space for Candidate Name
        overflow: "hidden",
      },
    },
    {
      name: "Father Name",
      selector: (row) => row.fathersname,
      sortable: true,
      wrap: true,
      style: {
        fontFamily: "'Poppins',Sans-serif",

        maxWidth: "320px", // More space for Father's Name
        overflow: "hidden",
      },
    },
    {
      name: "Fees Status",
      selector: (row) => row.website,
      sortable: true,
      width: "100px", // Moderate width for Fees Status
    },
    {
      name: "No Dues Status",
      selector: (row) => row.website,
      sortable: true,
      width: "100px", // Moderate width for Fees Status
    },
    {
      name: (
        <div style={{ display: "flex", alignItems: "center", width: "20px" }}>
          <input
            type="checkbox"
            style={{
              marginRight: "8px",
              accentColor: "#0a9396",
              fontFamily: "'Poppins',Sans-serif",
            }}
            onChange={handleSelectAll}
            checked={
              selectedStudentIds.length === data.length && data.length > 0
            }
          />
          All
        </div>
      ),
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="checkbox"
            style={{ marginRight: "8px", accentColor: "#0a9396" }}
            checked={selectedStudentIds.includes(row.studentId)}
            onChange={() => handleCheckboxChange(row.studentId)}
          />
        </div>
      ),
      sortable: false,
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <style>
        {`
        *{
               fontFamily:"'Poppins',Sans-serif",
               width:"100%
}
               `}
      </style>
      <div
        className="container mt-4"
        style={{
          marginLeft: "11%",
          width: "100%",
          fontFamily: "'Poppins',Sans-serif",
        }}
      >
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Enter Exam Form ID"
            value={examFormId}
            onChange={(e) => setExamFormId(e.target.value)}
          />
        </div>

        {/* Table Wrapper without Scroll */}
        <DataTable
          title="Exam Verification Data"
          columns={columns}
          data={currentData}
          pagination
          paginationServer
          paginationTotalRows={data.length}
          paginationPerPage={rowsPerPage}
          paginationRowsPerPageOptions={[7]} // 7 rows per page
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          progressPending={loading}
          persistTableHead
          defaultSortField="name"
          defaultSortAsc={true}
          highlightOnHover
          style={{
            borderCollapse: "collapse",
            fontFamily: "'Poppins',Sans-serif",
          }}
          className="table-bordered"
          customStyles={{
            headCells: {
              style: {
                border: "1px solid #adb5bd",
                padding: "12px",
                backgroundColor: "#415a77",
                color: "white",
                position: "sticky",
                top: 0,
                zIndex: 1,
                fontFamily: "'Poppins',Sans-serif",
              },
            },
            cells: {
              style: {
                border: "1px solid #adb5bd",
                padding: "12px",

                fontFamily: "'Poppins',Sans-serif",
              },
            },
          }}
        />

        {/* Submit Button Fixed at the Bottom */}
        <button
          className="btn btn-primary mt-3"
          onClick={handleSubmit}
          style={{
            position: "fixed",
            height: "40px",
            bottom: "20px",

            left: "40%",
            padding: "5px",
            transform: "translateX(-50%)",
            zIndex: 10, // Keep the button on top of other content
          }}
        >
          Submit Selected Students
        </button>
      </div>
    </>
  );
};

export default Exam_Verification_table;
