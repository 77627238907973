import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import {
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme();

const Showschemetable = ({
  courseType,
  courseBranch,
  courseName,
  semesterNumber,
  semesterSession,
}) => {
  const [data, setData] = useState([])

  // Fetch data from API when the component mounts
  useEffect(() => {
    fetch(
      `https://sssutms.ac.in/apitest/api/schemes?courseType=${courseType}&courseBranch=${courseBranch}&courseName=${courseName}&semesterNumber=${semesterNumber}&semesterSession=${semesterSession}`
    )
      .then((response) => response.json())
      .then((result) => {
        // Store the data in the state
        setData(result.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [courseType, courseBranch, courseName, semesterNumber, semesterSession]);

  return (
    <>
      <style>
        {`
        *
        {
                font-family:'Poppins',Sans-serif;
                }`}
      </style>
      <ThemeProvider theme={theme}>
        <div
          className="shadow p-3 bg-body rounded"
          style={{
            width: "100%",
            backgroundColor: "#00cdac",
            marginTop: "70px",
          }}
        >
          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: "520px" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {[
                      "S.No.",
                      "Subject Name",
                      "Subject Code",
                      "Subject Type",
                      "Elective",
                    ].map((header) => (
                      <TableCell
                        key={header}
                        align="left"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          position: "sticky",
                          top: 0,
                          height: "50px",
                          zIndex: 1,
                          fontFamily: "'Poppins', Sans-serif",
                          padding: "5px",
                          fontSize: "14px",
                          fontStyle: "normal",
                        }}
                      >
                        <b>{header}</b>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length > 0 &&
                    data[0].subjects.map((subject, index) => {
                      // Check for theory and practical assessments and determine the subject type
                      let subjectType = "";
                      if (subject.assessments.theory) subjectType += "Theory ";
                      if (subject.assessments.practical)
                        subjectType += "Practical";

                      return (
                        <TableRow key={subject._id}>
                          <TableCell
                            style={{ fontSize: "14px", padding: "10px" }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "400px",
                              fontSize: "14px",
                              padding: "10px",
                            }}
                          >
                            {subject.subjectName}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "150px",
                              fontSize: "14px",
                              padding: "10px",
                            }}
                          >
                            {subject.subjectCode}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "120px",
                              fontSize: "14px",
                              padding: "10px",
                            }}
                          >
                            {subjectType.trim()}
                          </TableCell>
                          <TableCell
                            style={{
                              width: "100px",
                              fontSize: "14px",
                              padding: "10px",
                            }}
                          >
                            {subject.compulsory ? "Compulsory" : "Optional"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </ThemeProvider>{" "}
    </>
  );
};

export default Showschemetable;
