
import React from "react";
import { Link } from "react-router-dom";
import "./Erp.css";
import OnlyHeader from "../../AdminComponent/OnlyHeader";
import student from "./../../images/IMG_ERP/student.png";
import library from "./../../images/IMG_ERP/ErpLibrary.png";
import adminn from "./../../images/IMG_ERP/adminn.png"
import Hod from "./../../images/IMG_ERP/employee.png"
import sodBuilding from '../../images/SOD-BUILDING.png';

const Card = ({ image, title, description, link }) => (

  <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3 col-xs-12">
    <Link to={link} style={{ textDecoration: "none" }}>
      <div className="card" style={{ marginTop: "20px" }}>
        <div className="card-body">
          <div className="card-image" style={{ color: "#213661" }}>
            <img
              src={image}
              style={{ width: "100px", height: "100px", textAlign: "center" }}
              alt={title}
            />
          </div>
          <p className="card-text">{title}</p>
          <p style={{ color: "black", fontSize: "14px", textAlign: "center" }}>
            {description}
          </p>
        </div>
      </div>
    </Link>
  </div>
);

function Erp() {
  return (
    <>
      <OnlyHeader />
      <div className="cardCont" style={{ position: 'relative', height: '100vh' }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${sodBuilding})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            filter: 'blur(8px)', // Use filter for blur on the background
            zIndex: -1, // Make sure it's behind the content
          }}
        />
        <div className="container" style={{ paddingTop: '150px', position: 'relative', zIndex: 1 }}>
          <div className="row">
            <Card
              image={student}
              title="STUDENT LOGIN"
              description="Click here for Student Login"
              link="/erp/studentlogin"
            />
            <Card
              image={Hod}
              title="HOD LOGIN"
              description="Click here for Hod Login"
              link="/erp/hodlogin"
            />
            <Card
              image={adminn}
              title="ADMIN LOGIN"
              description="Click here for Admin Login"
              link="/adminlogin"
            />
            <Card
              image={library}
              title="WEB MAIL LOGIN"
              description="Click here for Web Mail Login"
              link="https://sssutms.ac.in:2096/"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Erp;