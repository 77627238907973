import React, { useRef } from "react";
import speaker from "../images/megaphone.png";
import { Link } from "react-router-dom";

const Announcement = () => {
  const marqueeRef = useRef(null);

  return (
    <>
      <style>
        {`
      .announcediv{
        height:30x;
        weight:100%;
        display:flex
      }

      .marq{
        height:40px;
        display:flex;
        width:260px;
        background-color:#4D869C
       }


           marquee a{
          margin-top:20px;
          direction:scroll;
          color:red;
          gap:20px
        }

        .marquee {
          margin-top:10px;
        }

        .aa{
         font-size:16px;
        font-weight:600
        }
        .speaker{
        height:30px;
        width:30px;
        margin-left:10px;
        margin-top:5px;
        filter: invert(1) brightness(1000%);
        }


        .te{
        color:white;
        margin-top:7px;
        margin-left:10px
        }


        @media screen and (max-width: 400px) {
      .announcediv{
      height:30px;
      weight:100%;
      background-color:#EEF7FF;
      display:flex
      }




        marquee a{
        margin-top:5px;
        direction:scroll;
        color:red;
        gap:20px
        }

        .marquee {
        margin-top:2px;
      
        }

        .speaker{
        height:20px;
        width:20x;
        margin-left:10px;
        margin-top:5px;
       filter: invert(1) brightness(1000%);
        }


        .te{
        display:none
        }


        .marq{
         height:40px;
        display:flex;
        width:300px;
        background-color:#4D869C }
       
       
       
       
       
       }
      `}
      </style>

      <div className="announcediv">
        <div className="marq">
          <img className="speaker" src={speaker}></img>
          <span className="te">Announcements</span>
        </div>
        <div className="marquee">
          <marquee
            ref={marqueeRef}
            onMouseOver={() => marqueeRef.current.stop()}
            onMouseOut={() => marqueeRef.current.start()}
          >
            <Link className="aa" to="/admission/enquiry" target="_blank">
              Admission Open |
            </Link>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            <Link
              className="aa"
              to="https://cdn.sssutms.ac.in/media/static/PDF/Exam_Notification_24-25.pdf"
              target="_blank"
            >
              First Sem Exam Notification 2024 |
            </Link>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            <Link className="aa" to="/erp/studentregister" target="_blank">
              MBBS Registration 2024|
            </Link>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            <Link
              className="aa"
              to="https://cdn.sssutms.ac.in/media/static/TIME_TABLE/BHMSTT.pdf"
              target="_blank"
            >
              BHMS Examination Time-Table 2024 |
            </Link>
            {/* &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            <Link className="aa" to="/entrance/form" target="_blank">
              PH.D Entrance Form is open |
            </Link> */}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link
              className="aa"
              to="/Epravesh/Student/Register"
              target="_blank"
            >
              E-Pravesh Student Registration |
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link className="aa" href="/erp/studentlogin" target="_blank">
              Enrollment Form is open for all courses
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </marquee>
        </div>
      </div>
    </>
  );
};

export default Announcement;
