import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Container, Form } from "react-bootstrap";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Stack,
  FormControl,
  FormLabel,
  Select,
  Input,
} from "@chakra-ui/react";
import { Progress } from "@chakra-ui/react";
import OnlyHeader from "../../AdminComponent/OnlyHeader";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import jsPDF from "jspdf";
import logo from "../../AdminComponent/12.png";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
  },
});

const E_RegistrationForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      qualification: "",
    },
  });
  const location = useLocation();
  const { courseType, courseName, courseBranch, email } = location.state;

  const lastPassed = {
    UG: ["12th"],
    PG: ["UG"],
    Diploma: ["10th", "12th"],
    PG_Diploma: ["Graduation"],
  };

  const specificQualifications = {
    UG: {
      "BACHELOR OF ENGINEERING(Lateral)": ["Diploma", "BSC"],
      "BACHELOR OF LAWS": ["UG"],
      "BACHELOR OF EDUCATION": ["UG", "PG"],
      "BACHELOR OF PHARMACY(lateral)": ["Diploma"],
      "BACHELOR OF PHYSICAL EDUCATION(B. P. Ed.)": ["12th", "UG"],
      "BACHELOR OF PHYSICAL EDUCATION AND SPORTS(BPES)": ["12th", "UG"],
      "BACHELOR OF ARCHITECTURE": ["12th", "Diploma"],
     

      "BACHELOR OF LIBRARY SCIENCE": ["12th", "UG"],
    },
    PG: {
      "MASTER OF TECHNOLOGY": ["UG", "PG"],
    },
    Diploma: {
      "DIPLOMA ENGINEERING  (Lateral)": ["12th", "ITI"],
      // "DIPLOMA ENGINEERING": ["PCB"],
    },
  };

  function getLastPassedOptions(courseType, courseName) {
    if (
      specificQualifications[courseType] &&
      specificQualifications[courseType][courseName]
    ) {
      return specificQualifications[courseType][courseName];
    }
    return lastPassed[courseType] || [];
  }

  const courseToSubjects = {
    "BACHELOR OF PHYSIOTHERAPY": ["PCB", "All Subject"],
    "BACHELOR OF ENGINEERING": ["PCM"],
    "BACHELOR OF DESIGN": ["PCM", "PCB", "Arts", "Agriculture", "Commerce"],
    "BACHELOR OF PHARMACY": ["PCM", "PCB"],
    "BACHELOR OF EDUCATION": ["All Subject"],
    "BSC(Nursing)": ["PCB"],
    "BACHELOR OF HOTEL MANAGEMENT AND CATERING": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
    ],
    "BACHELOR OF PHYSICAL EDUCATION(B. P. Ed.)": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
      "Physical Education",
      "All Subject",
    ],
    "BACHELOR OF ARTS BACHELOR OF EDUCATION (B. A. B. Ed.)": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
      "All Subject",
    ],
    "BACHELOR OF ARCHITECTURE": ["PCM", "All Subject"],
    "BACHELOR OF SCIENCE": ["PCM", "PCB", "Arts", "Agriculture", "Commerce"],
    "BACHELOR OF BUSINESS ADMINISTRATION": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
      "All Subject",
    ],

    "BACHELOR OF ARTS": ["PCM", "PCB", "Arts", "Agriculture", "Commerce"],
    "BACHELOR OF COMMERCE": ["PCM", "PCB", "Arts", "Agriculture", "Commerce"],
    "BACHELOR OF COMPUTER APPLICATION": ["PCM", "PCB", "Commerce"],
    "BACHELOR OF ENGINEERING(Lateral)": [
      "AERONAUTICAL ENGINEERING",
      "CHEMICAL ENGINEERING",
      "CIVIL ENGINEERING",
      "COMPUTER SCIENCE AND ENGINEERING",
      "ELECTRICAL AND ELECTRONICS ENGINEERING",
      "ELECTRICAL ENGINEERING",
      "ELECTRONICS AND COMMUNICATION ENGINEERING",
      "ELECTRONICS AND INSTRUMENTATION ENGINEERING",
      "MECHANICAL ENGINEERING",
      "MINING ENGINEERING",
      "INFORMATION TECHONOLOGY",
      "Other Subject",
    ],
    "BACHELOR OF AYURVEDIC MEDICINE AND SURGERY": ["PCB"],
    "BACHELOR OF HOMEOPATHY MEDICINE AND SURGERY": ["PCB"],
    "BACHELOR OF LAWS": ["All Subjects"],
    "BACHELOR OF LIBRARY SCIENCE": [
      "PCM",
      "PCB",
      "Arts",
      "BCA",
      "Agriculture",
      "Commerce",
    ],
    "BACHELOR OF PHYSICAL EDUCATION AND SPORTS(BPES)": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
      "All Subject",
    ],
    "BACHELOR OF PHARMACY(lateral)": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
      "All Subject",
    ],
    "B.SC(Hons) AGRICULTURE": ["PCM", "PCB", "AGRICULTURE"],
    "BACHELOR OF PHSIOTHERAPY": ["PCB"],

    "MASTER OF TECHNOLOGY": ["BE", "B.TECH"],

    "MASTER OF PHARMACY": ["B.PHARMA"],
    "MASTER OF PHYSIOTHERAPHY (ORTHOPAEDIC)": ["B.PHARMA"],
    "MASTER OF MEDICAL LAB TECHNOLOGY(MMLT)": ["BMLT"],
    "MASTER OF COMPUTER APPLICATION": [
      "BSC (if 12th Passed with Maths)",
      "B.COM (if 12th Passed with Maths)",
      "BCA",
    ],
    "MD(HOMEOPATHY)":["BHMS"],
    "MASTER OF BUSINESS ADMINISTRATION(MBA)": ["All Subject"],
    "MASTER OF SCIENCE": [
      "BE",
      "BSC",
      "BSC(Hons)",
      "BSC(Hons)Maths",
      "BSC(Hons)CS",
      "BSC(Hons)IT",
      "BSC(Hons)PHYSICS",
      "BSC(Hons)CHEMISTRY",
      "BSC(Hons)BIOLOGY",
    ],
    "MASTER OF ARTS": ["UG"],
    "MASTER OF COMMERCE": ["BCOM"],
    "MASTER OF PHYSIOTHERAPY": ["BPT"],

    "DIPLOMA PHARMACY": ["All Subject"],
    "DIPLOMA BLOOD TRANSFUSION": ["PCB"],
    "DIPLOMA DIALYSIS TECHNICIAN": ["PCB"],
    "DIPLOMA PHARMACY (AYURVED)": ["PCB"],
    "DIPLOMA HUMAN NUTRITION": ["PCB"],
    "DIPLOMA MEDICAL LAB AND TECHNICIAN": ["PCB", "MLT"],
    "DIPLOMA X-RAY RADIOGRAPHER TECHNICIAN": ["PCB"],
    "DIPLOMA YOGA": ["PCB"],
    "DIPLOMA ENGINEERING": ["10th"],
    "DIPLOMA PHARMACY (HOMEOPATHY)": ["PCB"],
    "DIPLOMA PARAMEDICAL OPTHALMIC ASSISTENT": ["PCB"],
    "DIPLOMA ENGINEERING  (Lateral)": ["PCM"],

    "POST GRADUATION DIPLOMA IN COMPUTER APPLICATION": ["Any Graduate"],
  };

  const navigate = useNavigate();

  const [board, setBoard] = useState("");
  const [cgpa, setCgpa] = useState();
  const [marksheet12th, setMarksheet12th] = useState();
  const [marksheet10th, setMarksheet10th] = useState();
  const [diplomaMarksheet, setDiplomaMarksheet] = useState();
  const [marksheetUG, setMarksheetUG] = useState();
  const [marksheetPG, setMarksheetPG] = useState();
  const [markshhetITI, setMarksheetITI] = useState();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    const currentDate = new Date();
    const userEnteredDOB = new Date(watch("dob"));

    if (userEnteredDOB > currentDate) {
      swal("Error", "Please enter a valid date of birth", "error");
      return;
    }
    if (board === "CBSE") {
      if (!cgpa || cgpa === "" || parseFloat(cgpa) > 10) {
        swal(
          "Error",
          "CGPA is required and must be less than or equal to 10",
          "error"
        );
        return;
      }
    } else if (board === "MP") {
      const obtainMarksNum = parseFloat(obtainMarks);
      const totalMarksNum = parseFloat(totalMarks);

      if (
        isNaN(obtainMarksNum) ||
        isNaN(totalMarksNum) ||
        percentage === "" ||
        obtainMarksNum > totalMarksNum
      ) {
        swal(
          "Error",
          "Fill Obtain Marks, Total Marks, & Percentage correctly. Obtained marks cannot exceed total marks.",
          "error"
        );
        return;
      }
    }
    ///////////////////////////////////////////////////////
    const generatePDF = async () => {
      const pdf = new jsPDF();

      pdf.addImage(logo, "JPEG", 8, 5, 20, 20);
      pdf.setTextColor(165, 42, 42);
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(12);
      pdf.text(
        "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
        115,
        10,
        { align: "center" }
      );
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(11);
      pdf.text(
        "[Established Under Govt. of (M.P.) & Registered UGC 2(F),1956]",
        115,
        16,
        { align: "center" }
      );
      pdf.text("SH-18, Bhopal-Indore Road, Pachama,", 115, 22, {
        align: "center",
      });
      pdf.text("Sehore (M.P.) Pin Code-466001", 115, 28, { align: "center" });

      pdf.setDrawColor(0);
      pdf.setFillColor(230, 230, 230);
      pdf.rect(65, 30, 80, 10, "F"); // Box
      pdf.setFontSize(14);
      pdf.setFont("helvetica", "bold");
      pdf.setTextColor(0, 0, 0);
      pdf.text("Registration Form", 105, 36, { align: "center" });

      // Dynamic form fields
      let y = 50;
      const addField = (label, value) => {
        pdf.setFontSize(10);
        pdf.setFont("helvetica", "normal");
        pdf.text(`${label} :`, 20, y);

        const fieldValue = value
          ? String(value)
          : "_______________________________";
        pdf.text(fieldValue, 80, y - 2); // Value just above the line
        pdf.line(80, y, 200, y); // Underline
        y += 7; //////////line space key value ka
      };

      // Dynamic fields from formData
      const formFields = [
        ["Admission Year", formData?.admissionSession || ""],
        ["Course for which admitted", courseName || ""],
        ["Specialization", courseBranch || ""],
        ["Name of Student", formData?.name || ""],
        ["Father's Name", formData?.fathersname || ""],
        ["Mother's Name", formData?.mothersname || ""],
        ["Gender", formData?.gender || ""],
        ["Date of Birth", formData?.dob || ""],
        ["Email", formData?.email || ""],
        ["Mobile Number", formData?.mobile || ""],
        ["Domicile", formData?.domicile || ""],
        ["Category", formData?.category || ""],
        ["Nationality", formData?.nationality || ""],
      ];
      // Add the additional fields only for MBBS course
      if (courseName === "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)") {
        formFields.push(
          ["Neet Qualified Percentage", `${formData?.NeetPercentage}%` || ""],
          ["Admission Category", formData?.Concession || ""]
        );
      }

      formFields.forEach(([label, value]) => addField(label, value));

      // Table for Qualifying Exam details
      pdf.setFont("helvetica", "bold");
      pdf.text("Qualifying Exam Details:---", 20, y + 5); // Moved upward
      y += 8; //

      const tableData = [
        [
          "1",
          `${formData?.qualification}`,
          `${formData?.QualifiedCourse || formData?.lastPassedSubject}`,
          `${formData?.passingYear}`,
          `${formData?.lastExamType}`,
          `${formData?.board}`,
          `${formData?.ObtainedMarks ? formData?.ObtainedMarks : "CGPA"}`,
          `${
            formData?.ObtainedMarks
              ? formData?.LastPercentage + "%"
              : formData?.cgpa
          } `,
        ], // Example data
      ];

      pdf.autoTable({
        head: [
          [
            "S.No.",
            "Name of Exam",
            "Qualified Subject Group",
            "Passing Year",
            "Exam Type",
            "Exam Board",
            "Obtain Marks",
            "Percentage/CGPA",
          ],
        ],
        body: tableData,
        startY: y,
        theme: "grid",
        styles: { fontSize: 8 },
        margin: { left: 20 },
      });

      y = pdf.lastAutoTable.finalY + 5;

      // Declaration Section
      pdf.setFont("helvetica", "bold");
      pdf.setTextColor(0, 0, 0);
      pdf.text("Declaration by Student/Parent/Guardian", 20, y);
      pdf.setFont("helvetica", "normal");
      y += 6; // Slightly reduced spacing after the heading
      const declaration = [
        "Self/on behalf of my ward hereby declare that:",
        "1. The information given by me in the application form and all enclosures are true to the best of my knowledge. However, should it, be found that any information/enclosures therein are untrue/wrong I am/my ward liable to be disqualified for admission",
        "2. If I am/my ward selected for admission I am/my promise to abide by the rules & regulations of the Institute/University and maintain the discipline in the institute and the hostel.",
        "3. Initially the admission is provisional and is subject to confirmation from the counseling authority concerned University and State Government.",
        "4. It is compulsory for me/my ward to appear for online counseling at any place directed by the counseling authority with in the specified date and time failing which I/my ward’s registration will be automatically cancelled without any refund of fee.",
        "5. I understand that if I get my admission/registration cancelled the fee deposited by me is nonrefundable.",
        "6. Cancellation of admission/registration is not possible without paying the full fees for the entire course.",
        "7. I undertake to abide by the “Student Leaving Midstream” clause of the prospectus and agree to pay fees for the whole course if I leave course in midstream.",
        "8. Any dispute is subject to Sehore Jurisdiction.",
      ];

      // Render the declaration with reduced spacing
      declaration.forEach((line, index) => {
        const wrappedText = pdf.splitTextToSize(line, 170); // Adjust width as per page margin
        pdf.text(wrappedText, 19, y); // Render text starting at (19, y)
        y += wrappedText.length * 5; // Reduced line spacing for compact layout
      });
      // Add Date field
      // Adjust Date and Signature section position
      y += 6; // Reduced spacing after the declaration
      pdf.text("Date : __________________________", 20, y); // Left-aligned
      pdf.text(
        "Signature of Student :            __________________________",
        100,
        y
      ); // Right-aligned

      // Additional fields below Date and Signature
      y += 6; // Slightly reduced spacing for neatness
      pdf.text("Place : __________________________", 20, y); // Below Date
      pdf.text(
        "Name of Declarer :                 __________________________",
        100,
        y
      ); // Below Signature
      y += 6; // Move to the next line
      pdf.text(
        "Relationship with Candidate :  __________________________",
        100,
        y
      ); // Below Sig

      // Save the PDF
      pdf.save(`${formData?.name} Registration_Form.pdf`);
    };

    const formData = {
      name: watch("name").toUpperCase(),
      fathersname: watch("fathersname").toUpperCase(),
      mothersname: watch("mothersname").toUpperCase(),
      gender: watch("gender"),
      email: watch("email"),
      mobile: watch("mobile"),
      AbcId: watch("AbcId"),
      category: watch("category"),
      dob: watch("dob"),
      admissionSession: watch("admissionSession"),
      lastExamType: watch("lastExamType"),
      passingYear: watch("passingYear"),
      qualification: selectedQualification,
      nationality: watch("nationality").toUpperCase(),
      domicile: watch("domicile"),
      lastPassedSubject: watch("lastPassedSubject"),
      ObtainedMarks: obtainMarks,
      LastPercentage: percentage,
      board: board,
      courseType: courseType,
      courseBranch: courseBranch,
      courseName: courseName,
      cgpa: cgpa,
      marksheet12th: marksheet12th,
      diplomaMarksheet: diplomaMarksheet,
      marksheet10th: marksheet10th,
      undergraduateCertificate: marksheetUG,
      postgraduateCertificate: marksheetPG,
      ItImarksheet: markshhetITI,
    };

    try {
      const percentage = (
        (parseFloat(obtainMarks) / parseFloat(totalMarks)) *
        100
      ).toFixed(2);
      formData.LastPercentage = percentage;

      setLoading(true);
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/v2/e-pravesh/register",
        formData
      );
      const { name, randomId } = response.data.User;

      if (response.data.status === 200) {
        swal({
          title: `Congratulations ${name}`,

          content: {
            element: "p",
            attributes: {
              innerHTML: `Your Registration Number is <strong>${randomId}</strong> and Password has been sent on your email.`,
              style: "color: #05668d; font-size: 18px;",
            },
          },
          icon: "success",
          buttons: "OK",
        }).then(() => {
          generatePDF();
          navigate("/epravesh/waiting", {
            state: {
              courseType,
              courseName,
              courseBranch,
              id: response.data.User._id,
              name: response.data.User.name,
            },
            replace: true,
          });
        });
        reset(); // Clear form fields if needed
      }
    } catch (error) {
      swal("Error", "Try Again!!!", "error");
    } finally {
      setLoading(false);
    }
  };
  /////////////////////////////////
  const [FileUrl, setFileUrl] = useState("");
  const [setUploading, IsSetUploading] = useState(false);

  const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

  // //////////////////////////////////////////////////////PERCENTAGE LOGIC////////////////////////
  const [obtainMarks, setObtainMarks] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [percentage, setPercentage] = useState("");
  const [selectedQualification, setSelectedQualification] = useState("");

  const handleObtainMarksChange = (event) => {
    const obtainMarksValue = event.target.value;
    setObtainMarks(obtainMarksValue);
    setValue("obtainMarks", obtainMarksValue); // Update the form value
    calculatePercentage(obtainMarksValue, totalMarks);
  };

  const handleTotalMarksChange = (event) => {
    const totalMarksValue = event.target.value;
    setTotalMarks(totalMarksValue);
    setValue("totalMarks", totalMarksValue);
    calculatePercentage(obtainMarks, totalMarksValue);
  };

  const handleBoardChange = (event) => {
    setBoard(event.target.value);
    setCgpa(""); // Reset CGPA
    setObtainMarks(""); // Reset marks
    setTotalMarks(""); // Reset marks
    setPercentage(""); // Reset percentage
  };

  const calculatePercentage = (obtainMarks, totalMarks) => {
    if (!isNaN(obtainMarks) && !isNaN(totalMarks) && totalMarks !== "0") {
      const percentageValue = (
        (parseFloat(obtainMarks) / parseFloat(totalMarks)) *
        100
      ).toFixed(2);
      setPercentage(percentageValue);
    } else {
      setPercentage("");
    }
  };
  const handleSelect = (e) => {
    setSelectedQualification(e.target.value);
    setBoard(""); // Reset board when qualification changes
    setCgpa(""); // Reset CGPA
    setObtainMarks(""); // Reset marks
    setTotalMarks(""); // Reset marks
    setPercentage(""); // Reset percentage
  };
  /////////////////////////////////////////
  const currentYear = new Date().getFullYear();
  // const previousYear = currentYear - 1;
  const Options = [];
  for (let i = 0; i <= 20; i++) {
    Options.push(currentYear - i);
  }
  //////////////////////DOB validation///////////////////////////
  const validateDOB = (dob) => {
    const currentDate = new Date();
    const userDOB = new Date(dob);
    let age = currentDate.getFullYear() - userDOB.getFullYear();
    const monthDifference = currentDate.getMonth() - userDOB.getMonth();
    const dayDifference = currentDate.getDate() - userDOB.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age >= 17 || "You must be at least 17 years old";
  };
  /////////////////////marksheet///////////////////////
  const handleFileChange = async (e) => {
    const file = e.target.files[0];


    if (!file) return;
    try {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

      if (!allowedTypes.includes(file.type)) {
        swal({
          icon: "error",
          title: "Error",
          text: "Uploaded file is not a valid image. Only JPG, PNG and JPEG files are allowed",
        });
        return;
      }

      const formData4 = new FormData();
      formData4.append("file", file);
      formData4.append("upload_preset", "upload");

      IsSetUploading(true);

      const cloudinaryResponse = await fetch(
        `https://api.cloudinary.com/v1_1/dszawxz7y/image/upload`,
        {
          method: "POST",
          body: formData4,
        }
      );

      const cloudinaryData = await cloudinaryResponse.json();
      const fileUrl = cloudinaryData.secure_url;

      setFileUrl(fileUrl);
      if (courseType === "UG") {
        if (
          courseName === "BACHELOR OF ENGINEERING(Lateral)" ||
          courseName === "BACHELOR OF PHARMACY(lateral)"
        ) {
          if (
            courseName === "BACHELOR OF ENGINEERING(Lateral)" &&
            selectedQualification === "BSC"
          ) {
            setMarksheetUG(fileUrl);
          } else if (
            courseName === "BACHELOR OF ENGINEERING(Lateral)" &&
            selectedQualification === "Diploma"
          ) {
            setDiplomaMarksheet(fileUrl);
          }
        } else if (
          (courseName === "BACHELOR OF EDUCATION" &&
            selectedQualification === "UG") ||
          (courseName === "BACHELOR OF LAWS" &&
            selectedQualification === "UG") ||
          (courseName === "BACHELOR OF PHYSICAL EDUCATION AND SPORTS(BPES)" &&
            selectedQualification === "UG") ||
          (courseName === "BACHELOR OF PHYSICAL EDUCATION(B. P. Ed.)" &&
            selectedQualification === "UG") ||
          (courseName === "BACHELOR OF LIBRARY SCIENCE" &&
            selectedQualification === "UG")
        ) {
          setMarksheetUG(fileUrl);
        } else if (selectedQualification === "PG") {
          console.log("Ye chal ra");
          setMarksheetPG(fileUrl);
        } else {
          setMarksheet12th(fileUrl);
        }
      } else if (courseType === "Diploma") {
        if (
          (lastPassed === "12th" && courseName === "DIPLOMA PHARMACY") ||
          (lastPassed === "12th" &&
            courseName === "DIPLOMA MEDICAL LAB AND TECHNICIAN")
        ) {
          setMarksheet12th(fileUrl);
        } else if (
          lastPassed === "10th" &&
          courseName === "DIPLOMA MEDICAL LAB AND TECHNICIAN"
        ) {
          setMarksheet10th(fileUrl);
        } else if (courseName === "DIPLOMA ENGINEERING  (Lateral)") {
          setMarksheetITI(fileUrl);
        } else {
          setMarksheet10th(fileUrl);
        }
      } else if (courseType === "PG") {
        if (
          selectedQualification === "PG" &&
          courseName === "MASTER OF TECHNOLOGY" &&
          courseBranch === "INFORMATION TECHNOLOGY"
        ) {
          setMarksheetPG(fileUrl);
        } else {
          setMarksheetUG(fileUrl);
        }
      } else if (courseType === "PG_Diploma") {
        setMarksheetUG(fileUrl);
      } else {
        setMarksheetPG(fileUrl);
      }
    } catch (error) {
      console.error("Error uploading file to Cloudinary:", error);
    } finally {
      IsSetUploading(false);
    }
  };

  return (
    <>
      <ThemeProvider theme={theme} />
      <OnlyHeader />
      <Container
        className="container-overflow shadow p-3 mb-3 bg-body rounded"
        style={{ maxWidth: "90%", margin: "80px auto" }}
        ms
      >
        <Box
          marginBottom={"20px"}
          backgroundColor={"#264653"}
          p={4}
          h={50}
          color="white"
          mt={1}
          borderRadius="md"
          shadow="md"
          textAlign="center"
        >
          {courseName} {`(${courseBranch})`}
        </Box>

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={["column", "row"]} spacing={4} mt={8}>
              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Candidate Name <span style={{ color: "red" }}>*</span>
                </FormLabel>

                <Input
                  type="text"
                  size="md"
                  minWidth="280px"
                  {...register("name", {
                    required: true,
                    maxLength: 50,
                    pattern: /^[A-Za-z.\s]+$/i,
                  })}
                  autoComplete="off"
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
                {errors?.name?.type === "required" && (
                  <p style={{ color: "red" }}>Name is required</p>
                )}
                {errors?.name?.type === "maxLength" && (
                  <p style={{ color: "red" }}>
                    name cannot exceed 50 characters
                  </p>
                )}
              </FormControl>

              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Father's Name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  size="md"
                  minWidth="280px"
                  {...register("fathersname", {
                    required: true,
                    maxLength: 70,
                    pattern: /^[A-Za-z.\s]+$/i,
                  })}
                  autoComplete="off"
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
                {errors?.fathersname?.type === "required" && (
                  <p style={{ color: "red" }}>Father's name is required</p>
                )}
                {errors?.fathersname?.type === "maxLength" && (
                  <p style={{ color: "red" }}>
                    name cannot exceed 70 characters
                  </p>
                )}
              </FormControl>

              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Mother's Name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  size="md"
                  minWidth="280px"
                  {...register("mothersname", {
                    required: true,
                    maxLength: 70,
                    pattern: /^[A-Za-z.\s]+$/i,
                  })}
                  autoComplete="off"
                  onInput={(e) =>
                    (e.target.value = e.target.value.toUpperCase())
                  }
                />
                {errors?.mothersname?.type === "required" && (
                  <p style={{ color: "red" }}>Mother's name is required</p>
                )}
                {errors?.mothersname?.type === "maxLength" && (
                  <p style={{ color: "red" }}>
                    name cannot exceed 70 characters
                  </p>
                )}
              </FormControl>
            </Stack>
            <Stack direction={["column", "row"]} spacing={4} mt={4}>
              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Gender <span style={{ color: "red" }}>*</span>
                </FormLabel>

                <Select {...register("gender", { required: true })}>
                  <option value="">Select Gender</option>

                  <option value="FEMALE">Female</option>
                  <option value="MALE">Male</option>
                  <option value="OTHER">Other</option>
                </Select>

                {errors.gender && (
                  <p style={{ color: "red" }}>Please select your gender</p>
                )}
              </FormControl>

              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Email <span style={{ color: "red" }}>*</span>
                </FormLabel>

                <Input
                  size="md"
                  minWidth="280px"
                  defaultValue={email}
                  readOnly
                  {...register("email", {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  aria-invalid={errors.email ? "true" : "false"}
                />

                {errors.email && (
                  <p style={{ color: "red" }} role="alert">
                    {errors.email.message}
                  </p>
                )}
              </FormControl>

              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Mobile <span style={{ color: "red" }}>*</span>
                </FormLabel>

                <Input
                  size="md"
                  minWidth="280px"
                  {...register("mobile", {
                    required: "Mobile number is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Mobile number should contain only digits",
                    },
                    minLength: {
                      value: 10,
                      message:
                        "Mobile number should be at least 10 digits long",
                    },
                    maxLength: {
                      value: 10,
                      message: "Mobile number should not exceed 10 digits",
                    },
                  })}
                  autoComplete="off"
                />
                {errors.mobile && (
                  <p style={{ color: "red" }}>{errors.mobile.message}</p>
                )}
              </FormControl>
            </Stack>
            <Stack direction={["column", "row"]} spacing={4} mt={4}>
              {courseName !== "BACHELOR OF AYURVEDIC MEDICINE AND SURGERY" &&
              courseName !== "MD(HOMEOPATHY)" &&
                courseName !==
                  "BACHELOR OF HOMEOPATHY MEDICINE AND SURGERY" && (
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Abc ID <span style={{ color: "red" }}>*</span>
                      <a
                        href="https://www.digilocker.gov.in/"
                        target="_blank"
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "yellow",
                        }}
                      >
                        Make your ABC id (click here)
                      </a>
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      type="text" // Keep type as text for more control
                      {...register("AbcId", {
                        required: "ABC ID is required",
                        minLength: {
                          value: 12,
                          message: "ABC ID must be at least 12 characters",
                        },
                        maxLength: {
                          value: 12,
                          message: "ABC ID must not exceed 12 characters",
                        },
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "ABC ID must only contain numbers",
                        },
                      })}
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                      }
                      autoComplete="off"
                    />
                    {errors.AbcId && (
                      <p style={{ color: "red" }}>{errors.AbcId.message}</p>
                    )}
                  </FormControl>
                )}
              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Current Admission Session
                  <span style={{ color: "red" }}> *</span>
                </FormLabel>

                <Select
                  {...register("admissionSession", {
                    required: "Admission Session is required",
                  })}
                  size="md"
                  minWidth="280px"
                  aria-invalid={errors.admissionSession ? "true" : "false"} // Check if error exists for the field
                >
                  <option value="">Select Session</option>
                  <option value="2024">2024</option>
                </Select>

                {errors.admissionSession && (
                  <p style={{ color: "red" }}>
                    {errors.admissionSession.message}
                  </p>
                )}
              </FormControl>

              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Category <span style={{ color: "red" }}>*</span>
                </FormLabel>

                <Select
                  size="md"
                  minWidth="280px"
                  {...register("category", {
                    required: "Category is required",
                  })}
                  aria-invalid={errors.category ? "true" : "false"}
                >
                  <option value="">Select Category</option>
                  <option value="GENERAL">General</option>
                  <option value="OBC">OBC</option>
                  <option value="SC">SC</option>
                  <option value="ST">ST</option>
                </Select>

                {errors.category && (
                  <p style={{ color: "red" }}>{errors.category.message}</p>
                )}
              </FormControl>

              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Date of Birth <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  type="date"
                  size="md"
                  minWidth="280px"
                  {...register("dob", {
                    required: true,
                    validate: validateDOB,
                  })}
                />
                {errors?.dob?.type === "required" && (
                  <p style={{ color: "red" }}>DOB is required</p>
                )}
                {errors?.dob?.type === "validate" && (
                  <p style={{ color: "red" }}>{errors.dob.message}</p>
                )}
              </FormControl>
            </Stack>
            <Stack direction={["column", "row"]} spacing={4} mt={4}>
              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Last Exam Pass/Appear Time{" "}
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  size="md"
                  minWidth="280px"
                  {...register("lastExamType", {
                    required: "Last Exam Pass/Appear Time is required",
                  })}
                >
                  <option value="">Select </option>
                  <option value="Private">Private</option>
                  <option value="Regular">Regular</option>
                </Select>
                {errors.lastExamType && (
                  <p style={{ color: "red" }}>{errors.lastExamType.message}</p>
                )}
              </FormControl>

              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Last Qualification <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Controller
                  name="qualification"
                  control={control}
                  rules={{ required: "Qualification is required" }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={field.value || ""}
                      onChange={(e) => {
                        field.onChange(e);
                        handleSelect(e);
                      }}
                    >
                      <option value="">Select</option>
                      {getLastPassedOptions(courseType, courseName)?.map(
                        (subject) => (
                          <option key={subject} value={subject}>
                            {subject}
                          </option>
                        )
                      )}
                    </Select>
                  )}
                />
                {errors.qualification && (
                  <p style={{ color: "red" }}>{errors.qualification.message}</p>
                )}
              </FormControl>

              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Last Passing Year <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Controller
                    name="passingYear"
                    control={control}
                    rules={{ required: "Passing Year is required" }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        selected={value ? new Date(value, 0) : null}
                        onChange={(date) =>
                          onChange(date ? date.getFullYear() : "")
                        }
                        showYearPicker
                        dateFormat="yyyy"
                        placeholderText="Select Year"
                        maxDate={new Date(new Date().getFullYear(), 0)}
                        customInput={
                          <Input
                            size="md"
                            width="100%"
                            autoComplete="off"
                            style={{ padding: "8px", minWidth: "280px" }}
                          />
                        }
                        autoComplete="off"
                      />
                    )}
                  />
                </div>
                {errors.passingYear && (
                  <p style={{ color: "red" }}>{errors.passingYear.message}</p>
                )}
              </FormControl>
            </Stack>
            {selectedQualification === "12th" && (
              <>
                <Stack direction={["column", "row"]} spacing={4} mt={4}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Board <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select value={board} onChange={handleBoardChange}>
                      <option value="">Select Board</option>

                      <option value="MP">MP Board</option>
                      <option value="Maharashtra Board">MH Board</option>
                      <option value="CBSE">CBSE</option>
                      <option value="Other">Other</option>
                    </Select>
                    {errors.board && (
                      <p style={{ color: "red" }}>{errors.board.message}</p>
                    )}
                  </FormControl>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Last Passed Exam Subject{" "}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("lastPassedSubject", {
                        required: `Last Passed Subject is required`,
                      })}
                    >
                      <option value="">Select Subject</option>
                      {courseToSubjects[courseName]?.map((subject) => (
                        <option key={subject} value={subject}>
                          {subject}
                        </option>
                      ))}
                    </Select>
                    {errors.lastPassedSubject && (
                      <p style={{ color: "red" }}>
                        {errors.lastPassedSubject.message}
                      </p>
                    )}
                  </FormControl>
                  {board === "MP" && (
                    <Stack direction={["column", "row"]} spacing={4} mt={4}>
                      <FormControl flex="1">
                        <FormLabel style={{ fontFamily: "sans-serif" }}>
                          Obtain Marks <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          size="md"
                          minWidth="200px"
                          type="number"
                          value={obtainMarks}
                          autoComplete="off"
                          onChange={handleObtainMarksChange}
                        />
                        {errors.obtainMarks && (
                          <p style={{ color: "red" }}>
                            {errors.obtainMarks.message}
                          </p>
                        )}
                      </FormControl>

                      <FormControl flex="1">
                        <FormLabel style={{ fontFamily: "sans-serif" }}>
                          Total Marks <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          size="md"
                          minWidth="200px"
                          type="number"
                          autoComplete="off"
                          value={totalMarks}
                          onChange={handleTotalMarksChange}
                        />
                        {errors.totalMarks && (
                          <p style={{ color: "red" }}>
                            {errors.totalMarks.message}
                          </p>
                        )}
                      </FormControl>

                      <FormControl flex="1">
                        <FormLabel style={{ fontFamily: "sans-serif" }}>
                          Percentage
                        </FormLabel>
                        <Input
                          size="md"
                          minWidth="200px"
                          value={percentage}
                          readOnly
                        />
                      </FormControl>
                    </Stack>
                  )}
                  {board === "Maharashtra Board" && (
                    <Stack direction={["column", "row"]} spacing={4} mt={4}>
                      <FormControl flex="1">
                        <FormLabel style={{ fontFamily: "sans-serif" }}>
                          Obtain Marks <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          size="md"
                          minWidth="200px"
                          type="number"
                          value={obtainMarks}
                          autoComplete="off"
                          onChange={handleObtainMarksChange}
                        />
                        {errors.obtainMarks && (
                          <p style={{ color: "red" }}>
                            {errors.obtainMarks.message}
                          </p>
                        )}
                      </FormControl>

                      <FormControl flex="1">
                        <FormLabel style={{ fontFamily: "sans-serif" }}>
                          Total Marks <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          size="md"
                          minWidth="200px"
                          type="number"
                          autoComplete="off"
                          value={totalMarks}
                          onChange={handleTotalMarksChange}
                        />
                        {errors.totalMarks && (
                          <p style={{ color: "red" }}>
                            {errors.totalMarks.message}
                          </p>
                        )}
                      </FormControl>

                      <FormControl flex="1">
                        <FormLabel style={{ fontFamily: "sans-serif" }}>
                          Percentage
                        </FormLabel>
                        <Input
                          size="md"
                          minWidth="200px"
                          value={percentage}
                          readOnly
                        />
                      </FormControl>
                    </Stack>
                  )}

                  {board === "CBSE" && (
                    <Stack direction={["column", "row"]} spacing={4} mt={4}>
                      <FormControl flex="1">
                        <FormLabel style={{ fontFamily: "sans-serif" }}>
                          Total CGPA <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          type="number"
                          value={cgpa}
                          onChange={(e) => setCgpa(e.target.value)}
                          autoComplete="off"
                        />
                        {errors.cgpa && (
                          <p style={{ color: "red" }}>{errors.cgpa.message}</p>
                        )}
                      </FormControl>
                    </Stack>
                  )}
                </Stack>

                <Stack direction={["column", "row"]} spacing={4} mt={4}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Nationality <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("nationality", {
                        required: "Nationality is required",
                      })}
                    >
                      <option value="">Select Nationality</option>
                      <option value="INDIAN">INDIAN</option>
                    </Select>
                    {errors.nationality && (
                      <p style={{ color: "red" }}>
                        {errors.nationality.message}
                      </p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Domicile <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("domicile", {
                        required: "Domicile is required",
                      })}
                    >
                      <option value="">Select Domicile</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="All India">All India</option>
                    </Select>
                    {errors.domicile && (
                      <p style={{ color: "red" }}>{errors.domicile.message}</p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      12th Marksheet <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      type="file"
                      size="md"
                      minWidth="280px"
                      autoComplete="off"
                      {...register("marksheet12th", {
                        required: "12th Marksheet is required",
                        validate: {
                          validFile: (fileList) =>
                            fileList.length > 0 || "File is required",
                          fileType: (fileList) =>
                            (fileList.length > 0 &&
                              allowedTypes.includes(fileList[0].type)) ||
                            "Only JPG, PNG, and JPEG files are allowed",
                        },
                      })}
                      onChange={handleFileChange}
                    />
                    {setUploading ? <Progress size="xs" isIndeterminate /> : ""}
                    {errors.marksheet12th && (
                      <p style={{ color: "red" }}>
                        {errors.marksheet12th.message}
                      </p>
                    )}
                  </FormControl>
                </Stack>
              </>
            )}{" "}
            {selectedQualification === "ITI" && (
              <>
                <Stack direction={["column", "row"]} spacing={4} mt={4}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Last Passed Exam Subject{" "}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("lastPassedSubject", {
                        required: `Last Passed Subject is required`,
                      })}
                    >
                      <option value="">Select Subject</option>
                      <option value="ITI">ITI</option>
                    </Select>

                    {errors.lastPassedSubject && (
                      <p style={{ color: "red" }}>
                        {errors.lastPassedSubject.message}
                      </p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Obtain Marks <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      type="number"
                      value={obtainMarks}
                      onChange={handleObtainMarksChange}
                      // {...register("obtainMarks", {
                      //   required: "Obtained Marks is required",
                      //   min: {
                      //     value: 0,
                      //     message: "Obtained Marks cannot be negative",
                      //   },
                      //   validate: (value) =>
                      //     parseFloat(value) <= parseFloat(totalMarks) ||
                      //     "Obtained Marks cannot exceed Total Marks",
                      // })}
                    />
                    {errors.obtainMarks && (
                      <p style={{ color: "red" }}>
                        {errors.obtainMarks.message}
                      </p>
                    )}{" "}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Total Marks <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      type="number"
                      value={totalMarks}
                      onChange={handleTotalMarksChange}
                      // {...register("totalMarks", {
                      //   required: "Total Marks is required",
                      //   min: {
                      //     value: 1,
                      //     message: "Total Marks must be greater than 0",
                      //   },
                      // })}
                    />
                    {errors.totalMarks && (
                      <p style={{ color: "red" }}>
                        {errors.totalMarks.message}
                      </p>
                    )}{" "}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Percentage
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      value={percentage}
                      readOnly
                    />
                  </FormControl>
                </Stack>

                <Stack direction={["column", "row"]} spacing={4} mt={4}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Nationality <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("nationality", {
                        required: "Nationality is required",
                      })}
                    >
                      <option value="">Select Nationality</option>
                      <option value="INDIAN">INDIAN</option>
                    </Select>
                    {errors.nationality && (
                      <p style={{ color: "red" }}>
                        {errors.nationality.message}
                      </p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Domicile <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("domicile", {
                        required: "Domicile is required",
                      })}
                    >
                      <option value="">Select Domicile</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="All India">All India</option>
                    </Select>
                    {errors.domicile && (
                      <p style={{ color: "red" }}>{errors.domicile.message}</p>
                    )}
                  </FormControl>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Upload ITI Marksheet{" "}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      type="file"
                      size="md"
                      minWidth="280px"
                      autoComplete="off"
                      {...register("ItImarksheet", {
                        required: "ITI Marksheet is required",
                        validate: {
                          validFile: (fileList) =>
                            fileList.length > 0 || "File is required",
                          fileType: (fileList) =>
                            (fileList.length > 0 &&
                              allowedTypes.includes(fileList[0].type)) ||
                            "Only JPG, PNG, and JPEG files are allowed",
                        },
                      })}
                      onChange={handleFileChange}
                    />
                    {setUploading ? <Progress size="xs" isIndeterminate /> : ""}
                    {errors.ItImarksheet && (
                      <p style={{ color: "red" }}>
                        {errors.ItImarksheet.message}
                      </p>
                    )}
                  </FormControl>
                </Stack>
              </>
            )}
            {selectedQualification === "BSC" && (
              <>
                <Stack direction={["column", "row"]} spacing={4} mt={4}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Last Passed Exam Subject{" "}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("lastPassedSubject", {
                        required: `Last Passed Subject is required`,
                      })}
                    >
                      <option value="">Select Subject</option>
                      <option value="BSC">BSC</option>
                    </Select>

                    {/* Display error message if lastPassedSubject is not selected */}
                    {errors.lastPassedSubject && (
                      <p style={{ color: "red" }}>
                        {errors.lastPassedSubject.message}
                      </p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Obtain Marks <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      type="number"
                      value={obtainMarks}
                      onChange={handleObtainMarksChange}
                      // {...register("obtainMarks", {
                      //   required: "Obtained Marks is required",
                      //   min: {
                      //     value: 0,
                      //     message: "Obtained Marks cannot be negative",
                      //   },
                      //   validate: (value) =>
                      //     parseFloat(value) <= parseFloat(totalMarks) ||
                      //     "Obtained Marks cannot exceed Total Marks",
                      // })}
                    />
                    {errors.obtainMarks && (
                      <p style={{ color: "red" }}>
                        {errors.obtainMarks.message}
                      </p>
                    )}{" "}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Total Marks <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      type="number"
                      value={totalMarks}
                      onChange={handleTotalMarksChange}
                      // {...register("totalMarks", {
                      //   required: "Total Marks is required",
                      //   min: {
                      //     value: 1,
                      //     message: "Total Marks must be greater than 0",
                      //   },
                      // })}
                    />
                    {errors.totalMarks && (
                      <p style={{ color: "red" }}>
                        {errors.totalMarks.message}
                      </p>
                    )}{" "}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Percentage
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      value={percentage}
                      readOnly
                    />
                  </FormControl>
                </Stack>

                <Stack direction={["column", "row"]} spacing={4} mt={4}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Nationality <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("nationality", {
                        required: "Nationality is required",
                      })}
                    >
                      <option value="">Select Nationality</option>
                      <option value="INDIAN">INDIAN</option>
                    </Select>
                    {errors.nationality && (
                      <p style={{ color: "red" }}>
                        {errors.nationality.message}
                      </p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Domicile <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("domicile", {
                        required: "Domicile is required",
                      })}
                    >
                      <option value="">Select Domicile</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="All India">All India</option>
                    </Select>
                    {errors.domicile && (
                      <p style={{ color: "red" }}>{errors.domicile.message}</p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Upload BSC Marksheet{" "}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      type="file"
                      size="md"
                      minWidth="280px"
                      autoComplete="off"
                      {...register("marksheetUG", {
                        required: "BSC Marksheet is required",
                        validate: {
                          validFile: (fileList) =>
                            fileList.length > 0 || "File is required",
                          fileType: (fileList) =>
                            (fileList.length > 0 &&
                              allowedTypes.includes(fileList[0].type)) ||
                            "Only JPG, PNG, and JPEG files are allowed",
                        },
                      })}
                      onChange={handleFileChange}
                    />
                    {setUploading ? <Progress size="xs" isIndeterminate /> : ""}
                    {errors.marksheetUG && (
                      <p style={{ color: "red" }}>
                        {errors.marksheetUG.message}
                      </p>
                    )}
                  </FormControl>
                </Stack>
              </>
            )}
            {selectedQualification === "Diploma" && (
              <>
                <Stack direction={["column", "row"]} spacing={4} mt={4}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Last Passed Exam Subject{" "}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("lastPassedSubject", {
                        required: `Last Passed Subject is required`,
                      })}
                    >
                      <option value="">Select Subject</option>
                      {courseToSubjects[courseName]?.map((subject) => (
                        <option key={subject} value={subject}>
                          {subject}
                        </option>
                      ))}
                    </Select>

                    {errors.lastPassedSubject && (
                      <p style={{ color: "red" }}>
                        {errors.lastPassedSubject.message}
                      </p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Obtain Marks <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      type="number"
                      value={obtainMarks}
                      onChange={handleObtainMarksChange}
                    />
                    {errors.obtainMarks && (
                      <p style={{ color: "red" }}>
                        {errors.obtainMarks.message}
                      </p>
                    )}{" "}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Total Marks <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      autoComplete=""
                      type="number"
                      value={totalMarks}
                      onChange={handleTotalMarksChange}
                    />
                    {errors.totalMarks && (
                      <p style={{ color: "red" }}>
                        {errors.totalMarks.message}
                      </p>
                    )}{" "}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Percentage
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      value={percentage}
                      readOnly
                    />
                  </FormControl>
                </Stack>

                <Stack direction={["column", "row"]} spacing={4} mt={4}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Nationality <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("nationality", {
                        required: "Nationality is required",
                      })}
                    >
                      <option value="">Select Nationality</option>
                      <option value="INDIAN">INDIAN</option>
                    </Select>
                    {errors.nationality && (
                      <p style={{ color: "red" }}>
                        {errors.nationality.message}
                      </p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Domicile <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("domicile", {
                        required: "Domicile is required",
                      })}
                    >
                      <option value="">Select Domicile</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="All India">All India</option>
                    </Select>
                    {errors.domicile && (
                      <p style={{ color: "red" }}>{errors.domicile.message}</p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Diploma Marksheet <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      type="file"
                      size="md"
                      minWidth="280px"
                      autoComplete="off"
                      {...register("diplomaMarksheet", {
                        required: "Diploma Marksheet is required",
                        validate: {
                          validFile: (fileList) =>
                            fileList.length > 0 || "File is required",
                          fileType: (fileList) =>
                            (fileList.length > 0 &&
                              allowedTypes.includes(fileList[0].type)) ||
                            "Only JPG, PNG, and JPEG files are allowed",
                        },
                      })}
                      onChange={handleFileChange}
                    />
                    {setUploading ? <Progress size="xs" isIndeterminate /> : ""}
                    {errors.diplomaMarksheet && (
                      <p style={{ color: "red" }}>
                        {errors.diplomaMarksheet.message}
                      </p>
                    )}
                  </FormControl>
                </Stack>
              </>
            )}
            {selectedQualification === "Graduation" && (
              <>
                <Stack direction={["column", "row"]} spacing={4} mt={4}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Last Passed Exam Subject{" "}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("lastPassedSubject", {
                        required: `Last Passed Subject is required`,
                      })}
                    >
                      <option value="">Select Subject</option>
                      {courseToSubjects[courseName]?.map((subject) => (
                        <option key={subject} value={subject}>
                          {subject}
                        </option>
                      ))}
                    </Select>

                    {/* Display error message if lastPassedSubject is not selected */}
                    {errors.lastPassedSubject && (
                      <p style={{ color: "red" }}>
                        {errors.lastPassedSubject.message}
                      </p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Obtain Marks <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      type="number"
                      value={obtainMarks}
                      onChange={handleObtainMarksChange}
                  
                    />
                    {errors.obtainMarks && (
                      <p style={{ color: "red" }}>
                        {errors.obtainMarks.message}
                      </p>
                    )}{" "}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Total Marks <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      type="number"
                      value={totalMarks}
                      onChange={handleTotalMarksChange}
                  
                    />
                    {errors.totalMarks && (
                      <p style={{ color: "red" }}>
                        {errors.totalMarks.message}
                      </p>
                    )}{" "}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Percentage
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      value={percentage}
                      readOnly
                    />
                  </FormControl>
                </Stack>

                <Stack direction={["column", "row"]} spacing={4} mt={4}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Nationality <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("nationality", {
                        required: "Nationality is required",
                      })}
                    >
                      <option value="">Select Nationality</option>
                      <option value="INDIAN">INDIAN</option>
                    </Select>
                    {errors.nationality && (
                      <p style={{ color: "red" }}>
                        {errors.nationality.message}
                      </p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Domicile <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("domicile", {
                        required: "Domicile is required",
                      })}
                    >
                      <option value="">Select Domicile</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="All India">All India</option>
                    </Select>
                    {errors.domicile && (
                      <p style={{ color: "red" }}>{errors.domicile.message}</p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Graduation Marksheet{" "}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      type="file"
                      size="md"
                      minWidth="280px"
                      autoComplete="off"
                      {...register("marksheetUG", {
                        required: "Graduation Marksheet is required",
                        validate: {
                          validFile: (fileList) =>
                            fileList.length > 0 || "File is required",
                          fileType: (fileList) =>
                            (fileList.length > 0 &&
                              allowedTypes.includes(fileList[0].type)) ||
                            "Only JPG, PNG, and JPEG files are allowed",
                        },
                      })}
                      onChange={handleFileChange}
                    />
                    {setUploading ? <Progress size="xs" isIndeterminate /> : ""}
                    {errors.diplomaMarksheet && (
                      <p style={{ color: "red" }}>
                        {errors.diplomaMarksheet.message}
                      </p>
                    )}
                  </FormControl>
                </Stack>
              </>
            )}
            {selectedQualification === "10th" && (
              <>
                <Stack direction={["column", "row"]} spacing={4} mt={4}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Last Passed Exam Subject{" "}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("lastPassedSubject", {
                        required: `Last Passed Subject is required`,
                      })}
                    >
                      <option value="">Select Subject</option>
                      {courseToSubjects[courseName]?.map((subject) => (
                        <option key={subject} value={subject}>
                          {subject}
                        </option>
                      ))}
                    </Select>

                    {/* Display error message if lastPassedSubject is not selected */}
                    {errors.lastPassedSubject && (
                      <p style={{ color: "red" }}>
                        {errors.lastPassedSubject.message}
                      </p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Obtain Marks <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      type="number"
                      value={obtainMarks}
                      onChange={handleObtainMarksChange}
                    />
                    {errors.obtainMarks && (
                      <p style={{ color: "red" }}>
                        {errors.obtainMarks.message}
                      </p>
                    )}{" "}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Total Marks <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      type="number"
                      value={totalMarks}
                      onChange={handleTotalMarksChange}
                      // {...register("totalMarks", {
                      //   required: "Total Marks is required",
                      //   min: {
                      //     value: 1,
                      //     message: "Total Marks must be greater than 0",
                      //   },
                      // })}
                    />
                    {errors.totalMarks && (
                      <p style={{ color: "red" }}>
                        {errors.totalMarks.message}
                      </p>
                    )}{" "}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Percentage
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      value={percentage}
                      readOnly
                    />
                  </FormControl>
                </Stack>
                <Stack direction={["column", "row"]} spacing={4} mt={4}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Nationality <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("nationality", {
                        required: "Nationality is required",
                      })}
                    >
                      <option value="">Select Nationality</option>
                      <option value="indian">Indian</option>
                    </Select>
                    {errors.nationality && (
                      <p style={{ color: "red" }}>
                        {errors.nationality.message}
                      </p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Domicile <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("domicile", {
                        required: "Domicile is required",
                      })}
                    >
                      <option value="">Select Domicile</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="All India">All India</option>
                    </Select>
                    {errors.domicile && (
                      <p style={{ color: "red" }}>{errors.domicile.message}</p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      10th Marksheet <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      type="file"
                      size="md"
                      minWidth="280px"
                      autoComplete="off"
                      {...register("marksheet10th", {
                        required: "10th Marksheet is required",
                        validate: {
                          validFile: (fileList) =>
                            fileList.length > 0 || "File is required",
                          fileType: (fileList) =>
                            (fileList.length > 0 &&
                              allowedTypes.includes(fileList[0].type)) ||
                            "Only JPG, PNG, and JPEG files are allowed",
                        },
                      })}
                      onChange={handleFileChange}
                    />
                    {setUploading ? <Progress size="xs" isIndeterminate /> : ""}
                    {errors.marksheet10th && (
                      <p style={{ color: "red" }}>
                        {errors.marksheet10th.message}
                      </p>
                    )}
                  </FormControl>
                </Stack>
              </>
            )}{" "}
            {selectedQualification === "UG" && (
              <>
                <Stack direction={["column", "row"]} spacing={4} mt={4}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Last Passed Exam Subject{" "}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("lastPassedSubject", {
                        required: `Last Passed Subject is required`,
                      })}
                    >
                      <option value="">Select Subject</option>
                      {courseToSubjects[courseName]?.map((subject) => (
                        <option
                          key={subject}
                          value={
                            subject === "BSC (if 12th Passed with Maths)"
                              ? "BSC"
                              : subject === "B.COM (if 12th Passed with Maths)"
                              ? "B.COM"
                              : subject
                          } // Store only "BSC" or "B.COM" but show full label
                        >
                          {subject}
                        </option>
                      ))}
                    </Select>

                    {/* Display error message if lastPassedSubject is not selected */}
                    {errors.lastPassedSubject && (
                      <p style={{ color: "red" }}>
                        {errors.lastPassedSubject.message}
                      </p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Obtain Marks <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      type="number"
                      autoComplete="off"
                      value={obtainMarks}
                      onChange={handleObtainMarksChange}
                    />
                    {errors.obtainMarks && (
                      <p style={{ color: "red" }}>
                        {errors.obtainMarks.message}
                      </p>
                    )}{" "}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Total Marks <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      type="number"
                      value={totalMarks}
                      autoComplete="off"
                      onChange={handleTotalMarksChange}
                    />
                    {errors.totalMarks && (
                      <p style={{ color: "red" }}>
                        {errors.totalMarks.message}
                      </p>
                    )}{" "}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Percentage
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      value={percentage}
                      readOnly
                    />
                  </FormControl>
                </Stack>

                <Stack direction={["column", "row"]} spacing={4} mt={4}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Nationality <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("nationality", {
                        required: "Nationality is required",
                      })}
                    >
                      <option value="">Select Nationality</option>
                      <option value="indian">Indian</option>
                    </Select>
                    {errors.nationality && (
                      <p style={{ color: "red" }}>
                        {errors.nationality.message}
                      </p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Domicile <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("domicile", {
                        required: "Domicile is required",
                      })}
                    >
                      <option value="">Select Domicile</option>
                      <option value="All India">All India</option>
                      <option value="MP">Madhya Pradesh</option>
                    </Select>
                    {errors.domicile && (
                      <p style={{ color: "red" }}>{errors.domicile.message}</p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      UG Marksheet <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      type="file"
                      size="md"
                      minWidth="280px"
                      autoComplete="off"
                      {...register("undergraduateCertificate", {
                        required: "UG Marksheet is required",
                        validate: {
                          validFile: (fileList) =>
                            fileList.length > 0 || "File is required",
                          fileType: (fileList) =>
                            (fileList.length > 0 &&
                              allowedTypes.includes(fileList[0].type)) ||
                            "Only JPG, PNG, and JPEG files are allowed",
                        },
                      })}
                      onChange={handleFileChange}
                    />
                    {setUploading ? <Progress size="xs" isIndeterminate /> : ""}
                    {errors.undergraduateCertificate && (
                      <p style={{ color: "red" }}>
                        {errors.undergraduateCertificate.message}
                      </p>
                    )}
                  </FormControl>
                </Stack>
              </>
            )}
            {selectedQualification === "PG" && (
              <>
                <Stack direction={["column", "row"]} spacing={4} mt={4}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Last Passed Exam Subject{" "}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>

                    <Select
                      {...register("lastPassedSubject", {
                        required: `Last Passed Subject is required`,
                      })}
                    >
                      <option value="">Select Subject</option>
                      {courseName === "MASTER OF TECHNOLOGY" ? (
                        <>
                          {courseBranch === "INFORMATION TECHNOLOGY" ? (
                            <>
                              <option value="M.TECH">M.TECH</option>
                              <option value="MSC">MSC</option>
                              <option value="MCA">MCA</option>
                            </>
                          ) : courseBranch ===
                            "COMPUTER SCIENCE AND ENGINEERING" ? (
                            <>
                              <option value="M.TECH">M.TECH</option>
                              <option value="MCA">MCA</option>
                              <option value="MSC">MSC</option>
                            </>
                          ) : (
                            courseToSubjects[courseName]?.map((subject) => (
                              <option key={subject} value={subject}>
                                {subject}
                              </option>
                            ))
                          )}
                        </>
                      ) : (
                        /* For other courses */
                        courseToSubjects[courseName]?.map((subject) => (
                          <option key={subject} value={subject}>
                            {subject}
                          </option>
                        ))
                      )}
                    </Select>

                    {/* Display error message if lastPassedSubject is not selected */}
                    {errors.lastPassedSubject && (
                      <p style={{ color: "red" }}>
                        {errors.lastPassedSubject.message}
                      </p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Obtain Marks <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      type="number"
                      value={obtainMarks}
                      onChange={handleObtainMarksChange}
                    />
                    {errors.obtainMarks && (
                      <p style={{ color: "red" }}>
                        {errors.obtainMarks.message}
                      </p>
                    )}{" "}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Total Marks <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      type="number"
                      value={totalMarks}
                      onChange={handleTotalMarksChange}
                      // {...register("totalMarks", {
                      //   required: "Total Marks is required",
                      //   min: {
                      //     value: 1,
                      //     message: "Total Marks must be greater than 0",
                      //   },
                      // })}
                    />
                    {errors.totalMarks && (
                      <p style={{ color: "red" }}>
                        {errors.totalMarks.message}
                      </p>
                    )}{" "}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Percentage
                    </FormLabel>
                    <Input
                      size="md"
                      minWidth="200px"
                      value={percentage}
                      readOnly
                    />
                  </FormControl>
                </Stack>

                <Stack direction={["column", "row"]} spacing={4} mt={4}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Nationality <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("nationality", {
                        required: "Nationality is required",
                      })}
                    >
                      <option value="">Select Nationality</option>
                      <option value="indian">Indian</option>
                    </Select>
                    {errors.nationality && (
                      <p style={{ color: "red" }}>
                        {errors.nationality.message}
                      </p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      Domicile <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      {...register("domicile", {
                        required: "Domicile is required",
                      })}
                    >
                      <option value="">Select Domicile</option>
                      <option value="AllIndia">All India</option>
                      <option value="MP">Madhya Pradesh</option>
                    </Select>
                    {errors.domicile && (
                      <p style={{ color: "red" }}>{errors.domicile.message}</p>
                    )}
                  </FormControl>

                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      PG Marksheet <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      type="file"
                      size="md"
                      minWidth="280px"
                      autoComplete="off"
                      {...register("postgraduateCertificate", {
                        required: "PG Marksheet is required",
                        validate: {
                          validFile: (fileList) =>
                            fileList.length > 0 || "File is required",
                          fileType: (fileList) =>
                            (fileList.length > 0 &&
                              allowedTypes.includes(fileList[0].type)) ||
                            "Only JPG, PNG, and JPEG files are allowed",
                        },
                      })}
                      onChange={handleFileChange}
                    />
                    {setUploading ? <Progress size="xs" isIndeterminate /> : ""}
                    {errors.postgraduateCertificate && (
                      <p style={{ color: "red" }}>
                        {errors.postgraduateCertificate.message}
                      </p>
                    )}
                  </FormControl>
                </Stack>
              </>
            )}
            <Button
              type="submit"
              style={{ backgroundColor: "#598392", marginTop: "10px" }}
              disabled={setUploading}
            >
              {loading ? "Loading..." : "Register Now "}
            </Button>{" "}
            <Button
              type="button"
              style={{
                backgroundColor: "#dad7cd",
                color: "black",
                marginTop: "10px",
              }}
              onClick={() => reset()}
            >
              Cancel
            </Button>
          </Form>
        </Box>
      </Container>
    </>
  );
};
export default E_RegistrationForm;
