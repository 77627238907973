import React from "react";

import { Link } from "react-router-dom";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";

function Exam_TimeTable() {
  return (
    <>
    <Nav/>
      <br />

      <style>
        {`
    .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    } 
      
    
    table {
      width: 48%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    } 
      
    
    th, td {
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family: 'poppins',sansSerif;
    }    @media screen and (max-width: 768px) {
      .table-container {
        flex-direction: column; /* Stack tables on smaller screens */
      }      table {
        width: 100%; /* Make tables full-width on smaller screens */
      }
    }
  `}
      </style>

      <h4
        style={{
          //   textDecoration: "underline",
          color: "#4f000b",
          marginLeft: "3%",
          fontFamily: "'Poppins', Sans-serif",
        }}
      >
        Exam Time Table Dec-2024
      </h4>
      <br />

      <table
        style={{
          width: "80%",
          marginLeft: "70px",
          marginTop: "10px",
          borderCollapse: "collapse",
        }}
      >
        <tbody>
          <tr>
            {" "}
            <th>S.No</th> <th>Course </th> <th>Action</th>
          </tr>

          <tr>
            <td>1</td>
            <td>
              B.Sc.(Hons.) Agriculture Exam Time Table I Semester [Regular/Ex]
            </td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/AG_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td> BABED Time Table I Semester (Regular/Ex) 2024</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BABED_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>BE Exam Time Table I / III Semester (Regular/Ex) 2024</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BE_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>BED Exam Time Table I Semester (Regular/Ex) 2024</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BED_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>BHMCT Exam Time Table I Semester (Regular/Ex) 2024</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BHMCT_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>B.LIB Exam Time Table I Semester (Regular/Ex) 2024</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BLIB_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>BPED Exam Time Table I Semester (Regular/Ex) 2024 </td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BPED_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>8</td>
            <td>B.Pharma Exam Time Table I / III Semester (Regular/Ex)</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BPHARMA_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>9</td>
            <td>
              Diploma-ENG Exam Time Table I / III Semester (Regular/Ex) 2024
            </td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/DIPLOMA_ENGG_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>10</td>
            <td> LAW Time Table I Semester (Regular/Ex) 2024</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/LAW_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>11</td>
            <td>MA Exam Time Table I Semester (Regular/Ex) 2024</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/MA_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>12</td>
            <td> MBA Exam Time Table I Semester [Regular/Ex] 2024</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/MBA_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>13</td>
            <td>MCA Exam Time Table I Semester [Regular/Ex] 2024</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/MCA_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>14</td>
            <td>M.COM Exam Time Table I Semester (Regular/Ex) 2024</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/MCOM_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>15</td>
            <td> M.Pharma Exam Time Table I / II Semester (Regular/Ex) 2024</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/MPHARMA_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>16</td>
            <td>MSC Exam Time Table I Semester (Regular/Ex) 2024</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/MSC_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>17</td>
            <td> M.TECH Exam Time Table I Semester [Regular/Ex] 2024</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/MTECH_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>18</td>
            <td>PGDCA Exam Time Table 2024 I / II Semester (Regular/Ex)</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/PGDCA_I_SEM_DEC_2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>19</td>
            <td> BA (NEP) I Semester (Regular/Ex) 2024</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BA I SEM DEC 2024.pdf "
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>20</td>
            <td>BARCH I Semester (Regular/Ex) 20244</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BARCH I SEM DEC 2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>21</td>
            <td> BBA (NEP) I Semester (Regular/Ex) 2024</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BBA I SEM DEC 2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>22</td>
            <td> BCA (NEP) I Semester (Regular/Ex) 2024</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BCA I SEM DEC 2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>23</td>
            <td> BCOM (NEP) I Semester (Regular/Ex)</td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BCOM I SEM DEC 2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>

          <tr>
            <td>24</td>
            <td>BSC (NEP) I Semester (Regular/Ex) </td>
            <td style={{ color: "#03045e" }}>
              {" "}
              <Link
                target="_blank"
                to="https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BSC I SEM DEC 2024.pdf"
              >
                Click Here{" "}
              </Link>{" "}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
     <Footerpage/>
    </>
  );
}

export default Exam_TimeTable;
