import React,{useEffect,useState} from 'react'

import books from '../../images/books.png'
import Nav from '../../HomePage/Nav';
import Footerpage from '../../HomePage/Footerpage';



const Edu = () => {
    const [activeTab, setActiveTab] = useState('tab1');

  return (
    <>
 
 <style>
        {
          `
 .lli{
  font-size:14px; 
   margin-left:5px;
 margin-top:-3px;
   list-style-type: none; /* Removes bullets */
   }
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 3px 10px 5px 0;
      text-align: center;
      width: 28px;
  }

 .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-80px;
          margin-left:20px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  
.title-main{
  font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }

.cont{
margin-top:20px;
}

.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){

.head44{
font-size:10px;
margin-left:-5px;}

h5{
margin:20px;
padding:30px}

    .prog5{
     font-weight:700;
     color:white;

     margin-left:-20px;
     margin-top:-20px;
     font-family:'Open Sans', sans-serif
     }


.head4{

font-size:15px;
margin-left:-35px;}

 .list-icon {
 margin-top:50px;                                                                       
 margin-left:30px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }

.head5{
font-size:15px;
text-align:left;}



.h2 title-main{
margin-left:20px}



.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}


 

    
    
    `}</style>
   
   <Nav/>
         <div className="cont"
   
         >
           <h2 className="h2 title-main" style={{ marginLeft:'30px',color: '#780000' }}>FACULTY OF EDUCATION & PHYSICAL EDUCATION</h2>
           <hr className="title-hr"></hr>
   
   
   <br></br>
   
           <div className="row dirr">
   
   
           {/* <h5 style={{ fontFamily: "'poppins',sansSerif", marginLeft:'90px' }}>  INSTITUTE NAME:    Faculty Of Education -- (School Of Education)</h5><br></br> */}
   
             <div className="col-lg-9 padding-section">
             <style>
    {
        `
    .head5{
    font-family: "poppins",sans-serif;
      margin-left:20px;
     }

.head44{
font-weight:700;
font-family:'Open Sans', Sans-serif;
font-size:17px;
color:#264653;
}
     .head4{
     font-weight:700;
     font-family:'Open Sans', Sans-serif;
     color:#264653;
     }

     .prog5{
     font-weight:700;
     color:white;
     margin-left:30px;
     margin-top:30px;
     font-family:'Open Sans', sans-serif
     }

    .container {
      display: flex;
      width:100%;
      font-family: 'Open Sans', sans-serif;
    }
    .tabs {
      flex: 1;
      background-color: #023047;
      color: white;
      display: flex;

      flex-direction: column;
      padding: 10px;
    }
    .tabs button {
      background: none;
      border: none;
      color: white;
      padding: 10px;
      text-align: left;
      cursor: pointer;
      font-size: 16px;
      transition: color 0.3s;
    }


    .text{
    display:flex;
    }



.custom-img {
  width: 40px; /* Adjust the size as needed */
  height: 40px; /* Adjust the size as needed */
  // fill: red; /* Change the color to red */


  // transition: all 0.3s ease; /* Add transition for smooth effect */
}


    

    .tabs button.active {
      font-weight: bold;
      color: black;
    }
    .content {
      flex: 3;
      padding: 20px;
    }
    .grid {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    .grid article {
      // background: #f7f7f7;
      border: 1px solid #ddd;
      border-radius: 4px;
      flex: 1 1 calc(33.333% - 20px);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      transition: transform 0.3s;
    }
    .grid article:hover {
      transform: scale(1.05);
      color:#023047
    }
    .grid .text {
      text-align: center;
    }
    .grid img {
      max-width: 100px;
      height: auto;
      margin-bottom: 10px;
    }
    h4, h5, h6 {
      margin: 0;
      padding: 0;
    }








    
  `
    }
  </style>
   
   
    
     <div className="container" style={{marginTop:'50px'}}>
     <div className="tabs">
       <h5 style={{fontWeight:700,color:'white',marginLeft:'50px',marginTop:'30px',fontFamily:"'Open Sans', Sans-serif"}}>Program Levels</h5>
       <hr />
       <button
         style={{ color: activeTab === 'tab1' ? 'orange' : 'white' }}
         onClick={() => setActiveTab('tab1')}
         className={activeTab === 'tab1' ? 'active' : ''}
       >
         UG Programs
       </button>
       <hr />
      
     </div>
   
   
   
     <div className="content">
     {activeTab === 'tab1' && (
      <>
        <div data-aos='fade-right' className='title aos-init aos-animate' style={{fontFamily:"'Open Sans', Sans-serif", marginTop: '0px', marginLeft: '0px' }}>
        {/* <h4 style={{fontWeight:700,fontFamily:"'Open Sans', Sans-serif",color:'#264653',marginTop:'-30px',marginBottom:'30px'}}>  INSTITUTE NAME: FACULTY OF EDUCATION</h4> */}
          <h4 className='head44'> 
          INSTITUTE NAME : Faculty Of Education -- (School Of Education) - 2 YEARS</h4>
        </div>     
           <br></br>
        <div className="grid">
       <article>
            <div className="text">
            <img  className="custom-img"  src={books} alt="Medicine" />
              <h6 style={{fontSize:'15px',marginTop:'8px',marginLeft:'10px'}}>Bachelor of  Education (B.Ed.) </h6>
            </div>
          </article>    
            </div>     
           
         <br></br>
         <h4 className='head44'> INSTITUTE NAME : Faculty Of Education -- (School Of Teacher Education)  - 2 YEARS</h4>
          <br></br>  <div className="grid"><article>
            <div className="text">
            <img  className="custom-img"  src={books} alt="Medicine" />
              <h6 style={{fontSize:'15px',marginTop:'8px',marginLeft:'10px'}}>Bachelor of  Education (B.Ed.) </h6>
            </div>
          </article>
         </div>     
           
         <br></br>
         <h4 className='head44'>   INSTITUTE NAME : Faculty Of Education -- (School Of Physical Education) 2 YEARS</h4>
          <br></br>  <div className="grid"><article>
            <div className="text">
            <img  className="custom-img"  src={books} alt="Medicine" />
              <h6 style={{fontSize:'15px',marginTop:'8px',marginLeft:'10px'}}>Bachelor of Physical Education (B. P. Ed.)</h6>
            </div>
          </article>
         </div>     

  <br></br> 
  <h4 className='head44'>   INSTITUTE NAME : Faculty Of Education 4 YEARS</h4> <br></br><div className="grid"><article>
            <div className="text">
            <img  className="custom-img"  src={books} alt="Medicine" />
              <h6 style={{fontSize:'15px',marginTop:'8px',marginLeft:'10px',textAlign:'center'}}>Bachelor of Arts Bachelor of Education (B. A. B. Ed.)</h6>
            </div>
          </article>
         </div>     
        </>
    )}
   
   </div>
   </div>
    <br />
   
               <div className="tann">
    
   </div>
    </div>
             <div className="col-lg-3">
   
               <ul className='list-icon' >
                 <h5 style={{ fontFamily: "'poppins',sansSerif", color: '#243642' }}>Faculties And Departments</h5>
                 <br></br>  <li className='lli'>
                   <a href='/enggtechno'>
                     <i class="fa fa-adjust" aria-hidden="true"></i>Engineering And Technology</a></li>
                 <li className='lli'>
                 <a href='/pharma'>
                     <i class="fa fa-adjust" aria-hidden="true"></i>Pharmacy</a></li>
                 <li className='lli'>
                 <a href='/edu'>
                     <i class="fa fa-adjust" aria-hidden="true"></i>Education </a></li>
                 <li className='lli'>
                 <a href='/management'>
                     <i class="fa fa-adjust" aria-hidden="true"></i>Management</a></li>
                 <li className='lli'>
                 <a href='/design'>
                     <i class="fa fa-adjust" aria-hidden="true"></i>Design </a></li>
                 <li className='lli'>  
                 <a href='/humanities'>    
                             <i class="fa fa-adjust" aria-hidden="true"></i>Humanities And Languages </a></li>
    <li className='lli'>  
    <a href='/computer_science'>
                   <i class="fa fa-adjust" aria-hidden="true"></i>Computer Science And Application </a></li>
    <li className='lli'>  
    <a href='/commerce'>
                   <i class="fa fa-adjust" aria-hidden="true"></i>Commerce </a></li>
    <li className='lli'> 
    <a href='/science'>
                   <i class="fa fa-adjust" aria-hidden="true"></i>Science  </a></li>
    <li className='lli'>  
    <a href='/ayurveda'>
                   <i class="fa fa-adjust" aria-hidden="true"></i>Ayurveda  </a></li>
    <li className='lli'> 
    <a href='/law_dept'>
                   <i class="fa fa-adjust" aria-hidden="true"></i>Law </a></li>
    <li className='lli'>  
    <a href='/homeopathy'>
                   <i class="fa fa-adjust" aria-hidden="true"></i>Homeopathy   </a></li>
    <li className='lli'> 
    <a href='/paramedical'>
                   <i class="fa fa-adjust" aria-hidden="true"></i>Paramedical </a></li>
    <li className='lli'> 
    <a href='/nursing'>
                   <i class="fa fa-adjust" aria-hidden="true"></i>Nursing </a></li>
               </ul>
             </div>
           </div>
   
    </div>
   
   
   <Footerpage/>
   </>
)
}
export default Edu

