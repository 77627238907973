
import React from 'react';
import Nav from '../../HomePage/Nav';
import Footerpage from '../../HomePage/Footerpage';
import { Link } from "react-router-dom";


function Criteria1() {
  return (
    <>
      <Nav />
      <style>
        {`

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 900;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 90px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }


  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:10px;
          margin-left:80px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){



.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}






h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 900;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 90px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}</style>


      <div className="cont">
        <h2 className="h2 title-main" style={{ color: '#780000', marginTop: '10px' }}> Criteria One</h2>
        <hr className="title-hr"></hr>
        <div className="row dirr">
          <div className="col-lg-9 padding-section">

            <br />

            <h5 style={{ color: 'orange', textAlign: 'center', fontFamily: "'poppins',sansSerif", marginBottom: '10px' }}>Criteria 1</h5>

            <style>
              {`
    .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    } 
      
    
    table {
      width: 48%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    } 
      
    
    th, td {
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family: 'poppins',sansSerif;
    }    @media screen and (max-width: 768px) {
      .table-container {
        flex-direction: column; /* Stack tables on smaller screens */
      }      table {
        width: 100%; /* Make tables full-width on smaller screens */
      }
    }
  `}
            </style>





            <table style={{ width: '90%', marginLeft: '70px', marginTop: '10px', borderCollapse: 'collapse' }}>
              <tbody><tr> <th>S.No</th> <th>Facilities</th> <th>Session</th>
                <th>Report</th></tr><tr>
                  <td style={{ border: '1px solid #999', padding: '5px' }}>1 </td>
                  <td style={{ border: '1px solid #999', padding: '5px' }}>Academic Council Meeting</td>
                  <td style={{ border: '1px solid #999', padding: '5px' }}>2017-18 To 2021-22</td>
                  <td style={{ border: '1px solid #999', padding: '5px' }}>
                    <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/ACFINAL.pdf'>Click here</Link>
                  </td></tr><tr> <td style={{ border: '1px solid #999', padding: '5px' }}>2   </td>
                  <td style={{ border: '1px solid #999', padding: '5px' }}>Board of Mangement Meetings</td>
                  <td style={{ border: '1px solid #999', padding: '5px' }}>2017-18 To 2021-22</td>
                  <td style={{ border: '1px solid #999', padding: '5px' }}>
                    <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/BOMFINAL.pdf'>Click here
                    </Link></td></tr><tr> <td style={{ border: '1px solid #999', padding: '5px' }}>3 </td>
                  <td style={{ border: '1px solid #999', padding: '5px' }}>Board of Governance Meetings </td>
                  <td style={{ border: '1px solid #999', padding: '5px' }}> 2017-18 To 2021-22</td>
                  <td style={{ border: '1px solid #999', padding: '5px' }}>
                    <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/BOGFINAL.pdf'>Click here
                    </Link></td></tr></tbody>
            </table>
            <br></br>
            <h5 style={{ marginLeft: '70px' }}> Minutes of Relevant Board of Studies</h5>
            <br></br>



            <table style={{ marginLeft: '70px', width: '90%' }}><tbody>

              <tr> <th>School Name  </th> <th>Department  </th> <th>Program </th> <th>Session  (2017-18 To 2021-22)</th></tr><tr> <th rowSpan="21">School of Engineering </th> <td>Aeronautical Engineering </td> <td>   Bachelor of Engineering </td> <td>  <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/AE1.pdf'>Click here</Link></td></tr>
              <tr> <td>   Chemical Engineering </td> <td>   Bachelor of Engineering </td> <td>  <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/CE1.pdf'>Click here</Link></td></tr><tr> <td>Civil Engineering</td> <td>Bachelor of Engineering</td> <td rowSpan="2"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/CIVIL1.pdf">Click here</Link></td></tr><tr> <td>Structural Design</td> <td>Master of Technology</td></tr><tr> <td> Computer Science and Engineering </td> <td>Bachelor of Engineering  </td> <td rowSpan="3"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/CS1.pdf">Click here</Link></td></tr><tr> <td>Computer Science and Engineering </td> <td> Master of Technology  </td></tr><tr> <td>Software Engineering</td><td>   Master of Technology</td></tr><tr> <td>Electrical and Electronics Engineering</td><td>   Bachelor of Engineering </td><td> <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/EEE1.pdf'>Click here</Link></td></tr>
              <tr> <td> Electrical Engineering
              </td> <td>Bachelor of Engineering  </td> <td rowSpan="3"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/EE1.pdf">Click here</Link></td></tr><tr> <td>Electrical Power System
              </td> <td> Master of Technology  </td></tr><tr> <td>Power Electronics </td><td>   Master of Technology</td></tr>
              <tr> <td>Electronic & Communication Engineering </td> <td>Bachelor of Engineering  </td> <td rowSpan="3"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/EC1.pdf">Click here</Link></td></tr><tr> <td>Digital Communication </td> <td> Master of Technology  </td></tr><tr> <td>VLSI </td><td>   Master of Technology</td></tr>
              <tr> <td>Electronics & Instrumentation Engineering</td><td>   Bachelor of Engineering</td><td>    <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/EI1.pdf'>Click here</Link></td></tr><tr> <td>Information Technology </td> <td>Bachelor of Engineering  </td> <td rowSpan="2"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/IT1.pdf">Click here</Link></td></tr><tr> <td>Information Technology
              </td> <td> Master of Technology  </td></tr>
              <tr> <td>Mechanical Engineering </td> <td>Bachelor of Engineering  </td> <td rowSpan="3"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/MECHANICAL1.pdf">Click here</Link></td></tr><tr> <td>Industrial Design </td> <td> Master of Technology  </td></tr><tr> <td>Thermal Engineering </td><td>   Master of Technology</td></tr><tr> <td>Mining Engineering </td><td>   Bachelor of Engineering</td><td> <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/MINING1.pdf">Click here</Link></td></tr><tr> <th rowSpan="3">Faculty of Pharmacy</th> <td>Pharmacy </td> <td> Bachelor of Pharmacy</td> <td rowSpan="3"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/PHARMACY1.pdf">Click here</Link></td>
              </tr>
              <tr> <td>Pharmacology</td><td>   Master of Pharmacy</td></tr><tr> <td>Pharmaceutics</td><td>   Master of Pharmacy</td></tr>
              <tr> <th rowSpan="2">School of Management Studies</th> <td rowSpan="2">Management </td> <td>BBA</td> <td rowSpan="2"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">Click here</Link></td>
              </tr>
              <tr> <td>MBA</td></tr>
              <tr> <th>School of law</th><td>   Law</td><td>   Law</td><td>   Click here</td></tr>
              <tr> <th rowSpan="10">Faculty of Education</th> <td rowSpan="2">Arts </td> <td>Bachelor of Arts </td><td rowSpan="2"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/ARTS1.pdf">Click here</Link></td> </tr><td> Master of Arts</td>
              <tr> <td> Physical Education</td><td>Bachelor of Physical Education</td><td>Click Here</td></tr>
              <tr ><td rowSpan="2">Science</td> <td>Bachelor of Science</td>
                <td rowSpan="2"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/SCIENEC1.pdf">Click here</Link></td> </tr><td> Master  of Science</td>
              <tr ><td rowSpan="2">Commerce</td> <td>Bachelor of Commerce</td><td rowSpan="2"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">Click here</Link></td> </tr><td> Master  of Commerce</td>
              <tr ><td rowSpan="2">Education</td> <td>B.A. Ed</td><td rowSpan="2"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/BED1.pdf">Click here</Link></td> </tr><td> B.Ed</td>
              <tr> <td> Physical Education</td> <td>   Bachelor of Physical Education</td> <td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/bped1.pdf'>     Click here</Link></td></tr><tr> <th rowSpan="3">School Computer Application</th> <td rowSpan="3">Computer Application</td><td>P.G.D.C.A</td><td rowSpan="3"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">Click here</Link></td>
              </tr>
              <tr> <td>B.C.A</td></tr><tr> <td>M.C.A</td></tr>
              <tr> <th> School Of Hotel Mangement</th> <td>   HOTEL MANAGEMENT AND CATERING</td> <td>   Bachelor of HOTEL MANAGEMENT AND CATERING</td> <td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/HM1.pdf'>     Click her</Link></td></tr><tr> <th>School of Homeopathy</th> <td>   Homeopathy</td><td>   B.H.M.S</td><td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/HOMEO1.pdf'>     Click her</Link></td></tr>
              <tr> <th>School of Agriculture</th> <td>   Agriculture</td> <td>   Bachelor of Agriculture</td> <td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/AGRI1.pdf'>     Click her</Link></td></tr>
              <tr> <th>School of Ayurveda & Siddha Studies</th> <td>   Ayurveda</td> <td>   B.A.M.S</td><td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/AYURVED1.pdf'>     Click her</Link></td></tr>
              <tr> <th rowSpan="2">School of Nursing</th> <td rowSpan='2'>Nursing</td> <td>Bachelor of Nursing</td><td rowSpan="2"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/NURSING1.pdf">   Click here</Link></td></tr><tr> <td>P.B.Sc (Nursing)</td></tr>
              <tr> <th>School of Paramedical</th> <td>   Paramedical</td><td>   B.P.T</td> <td>   <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/PERAMEDICAL1.pdf">     Click here</Link></td></tr>
              <tr> <th> Faculty of Education</th> <td>   Ph .D.</td><td>   Arts</td><td>   <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">     Click here</Link></td></tr>
              <tr> <th> Faculty of Education</th> <td>   Ph .D.</td><td>   Science</td><td>   <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/SCIENCE12.pdf">     Click here</Link></td></tr><tr> <th> Faculty of Education</th> <td>   Ph .D.</td><td>   Commerce</td><td>   <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/COM12.pdf">     Click here</Link></td></tr><tr> <th>School of Management Studies</th> <td>
                Ph .D.</td><td>   Mangement</td><td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/MGT12.pdf'>Click here</Link></td></tr>
              <tr> <th> Faculty of Education</th> <td>   Ph .D.</td><td>   Physical Education</td><td>   <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/PED12.pdf">     Click here</Link></td></tr><tr> <th> Faculty of Education</th> <td>   Ph .D.</td><td>   Education</td><td>   <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/EDU12.pdf">     Click here</Link></td></tr>
              <tr> <th>School of Engineering</th> <td>   Ph .D.</td><td>   Engineering</td><td>   <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/ENGG12.pdf">     Click here</Link></td></tr><tr> <th>Faculty of Education</th> <td>   Ph .D.</td><td>   Library Science</td><td>   <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/LIB1.pdf">     Click here</Link></td></tr>
              <tr> <th>Faculty of Pharmacy</th> <td>   Ph .D.</td><td>   Pharmacy</td><td>   <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/PHARMACY12.pdf">     Click here</Link></td></tr>
            </tbody>
            </table>


            <br></br>

            <h6 style={{ marginLeft: '70px', lineHeight: '2.5', fontFamily: "'poppins',sansSerif", }}> <strong>1.1.1</strong>  Curriculam developed and implemented have relevance to the local, national, regional and global

              developmental needs which is reflected in Programme outcomes (POs), Programme Specific Outcomes

              (PSOs) and  Course Outcomes(COs) of the Programmes offered by the Institution.</h6>

            <br></br>
            <table style={{ marginLeft: '70px', width: '90%' }}>
              <tbody>    <tr> <th>School Name </th> <th>Department  </th> <th>Program </th> <th>Session  (2017-18 To 2021-22)</th></tr><tr> <th rowSpan="21">School of Engineering </th> <td>Aeronautical Engineering </td> <td>   Bachelor of Engineering </td> <td rowSpan="21"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/SOE_COMBINE.pdf">Click here</Link></td></tr><tr> <td>   Chemical Engineering </td> <td>   Bachelor of Engineering </td>
              </tr><tr> <td>Civil Engineering</td> <td>Bachelor of Engineering</td>
                </tr><tr> <td>Structural Design</td> <td>Master of Technology</td></tr><tr> <td> Computer Science and Engineering </td> <td>Bachelor of Engineering  </td>
                </tr><tr> <td>Computer Science and Engineering </td> <td> Master of Technology  </td></tr><tr> <td>Software Engineering</td><td>   Master of Technology</td></tr><tr> <td>Electrical and Electronics Engineering</td><td>   Bachelor of Engineering </td></tr>
                <tr> <td> Electrical Engineering </td> <td>Bachelor of Engineering  </td>
                </tr><tr> <td>Electrical Power System </td> <td> Master of Technology  </td></tr><tr> <td>Power Electronics </td><td>   Master of Technology</td>  </tr><tr> <td>Electronic & Communication Engineering </td> <td>Bachelor of Engineering  </td>
                </tr><tr> <td>Digital Communication </td> <td> Master of Technology  </td></tr><tr> <td>VLSI </td><td>   Master of Technology</td></tr>
                <tr> <td>Electronics & Instrumentation Engineering</td><td>   Bachelor of Engineering</td></tr><tr> <td>Information Technology </td> <td>Bachelor of Engineering  </td>
                </tr><tr> <td>Information Technology </td> <td> Master of Technology  </td></tr><tr> <td>Mechanical Engineering </td> <td>Bachelor of Engineering  </td></tr><tr> <td>Industrial Design </td> <td> Master of Technology  </td></tr><tr> <td>Thermal Engineering </td><td>   Master of Technology</td></tr><tr> <td>Mining Engineering </td><td>   Bachelor of Engineering</td></tr><tr> <th rowSpan="3">Faculty of Pharmacy</th> <td>Pharmacy </td> <td> Bachelor of Pharmacy</td> <td rowSpan="3"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/FOP_COMBINE.pdf">Click here</Link></td></tr>
                <tr> <td>Pharmacology</td><td>   Master of Pharmacy</td></tr><tr> <td>Pharmaceutics</td><td>   Master of Pharmacy</td></tr>
                <tr> <th rowSpan="2">School of Management Studies</th> <td rowSpan="2">Management </td> <td>BBA</td> <td rowSpan="2"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/MGMNT_COMBINE.pdf">Click here</Link></td>
                </tr>
                <tr> <td>MBA</td></tr>
                <tr> <th>School of law</th><td>   Law</td><td>   Law</td> <td ><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/LAW_COMBINE.pdf">Click here</Link></td></tr><tr> <th rowSpan="10">Faculty of Education</th> <td rowSpan="2">Arts </td> <td>Bachelor of Arts </td> <td rowSpan="2"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/ARTS_COMBINE.pdf">Click here</Link></td></tr><td> Master of Arts</td><tr> <td> Physical Education</td><td>Bachelor of Physical Education</td><td> <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/PHYEDU_COMBINE.pdf" >Click Here</Link></td></tr>
                <tr ><td rowSpan="2">Science</td> <td>Bachelor of Science</td>
                  <td rowSpan="2"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/SCIENCE_COMBINE.pdf">Click here</Link></td> </tr><td> Master  of Science</td>
                <tr ><td rowSpan="2">Commerce</td> <td>Bachelor of Commerce</td><td rowSpan="2"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/COM_COMBINE.pdf">Click here</Link></td> </tr><td> Master  of Commerce</td><tr ><td rowSpan="2">Education</td> <td>B.A. Ed</td><td rowSpan="2"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/EDUCATION_COMBINE.pdf">Click here</Link></td> </tr><td> B.Ed</td><tr> <td> Physical Education</td> <td>   Bachelor of Physical Education</td> <td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf'>     Click here</Link></td></tr><tr> <th rowSpan="3">School Computer Application</th> <td rowSpan="3">Computer Application</td><td>P.G.D.C.A</td><td rowSpan="3"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/CA_COMBINE.pdf">Click here</Link></td>
                </tr>
                <tr> <td>B.C.A</td></tr><tr> <td>M.C.A</td></tr>
                <tr> <th> School Of Hotel Mangement</th> <td>   HOTEL MANAGEMENT AND CATERING</td> <td>   Bachelor of HOTEL MANAGEMENT AND CATERING</td> <td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/HM_COMBINE.pdf'>     Click here</Link></td></tr><tr> <th>School of Homeopathy</th> <td>   Homeopathy</td><td>   B.H.M.S</td><td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/HOMEO_COMBINE.pdf'>     Click here</Link></td></tr>
                <tr> <th>School of Agriculture</th> <td>   Agriculture</td> <td>   Bachelor of Agriculture</td> <td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/AGRI_COMBINE.pdf'>     Click here</Link></td></tr>
                <tr> <th>School of Ayurveda & Siddha Studies</th> <td>   Ayurveda</td> <td>   B.A.M.S</td><td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/AYURVED_COMBINE.pdf'>     Click here</Link></td></tr>
                <tr> <th rowSpan="2">School of Nursing</th> <td rowSpan='2'>Nursing</td> <td>Bachelor of Nursing</td><td rowSpan="2"><Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/NURS_COMBINE.pdf">Click here</Link></td></tr><tr> <td>P.B.Sc (Nursing)</td></tr>
                <tr> <th>School of Paramedical</th> <td>   Paramedical</td><td>   B.P.T</td> <td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/PARAMED_COMBINE.pdf'>     Click here</Link></td></tr>
              </tbody>
            </table>


            <br></br><br></br>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4 style={{ marginLeft: '70px', fontFamily: "'poppins',sansSerif", }}>Prospectus</h4>
              <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/prospectus  Final.pdf'>Click here</Link>
            </div>

            <br>
            </br>

            <h6 style={{ marginLeft: '70px', lineHeight: '2.5', fontFamily: "'poppins',sansSerif", }}> <strong>1.1.3</strong>  Percentage of Programmes where syllabus revision was carried out during the last five years. Response</h6>
            <br></br>



            <table style={{ marginLeft: '70px', width: '90%' }}>
              <tbody>

                <tr> <th>School Name  </th> <th>Department  </th> <th>Program </th> <th >Session  (2017-18 To 2021-22)</th></tr><tr> <th rowSpan="21">School of Engineering </th> <td>Aeronautical Engineering </td> <td>   Bachelor of Engineering </td> <td ><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>   Chemical Engineering </td> <td>   Bachelor of Engineering </td> <td ><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>Civil Engineering</td> <td>Bachelor of Engineering</td> <td rowSpan='2'><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>Structural Design</td> <td>Master of Technology</td></tr><tr> <td> Computer Science and Engineering </td> <td>Bachelor of Engineering  </td> <td rowSpan='3'><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>Computer Science and Engineering </td> <td> Master of Technology  </td></tr><tr> <td>Software Engineering</td><td>   Master of Technology</td></tr><tr> <td>Electrical and Electronics Engineering</td><td>   Bachelor of Engineering </td> <td ><Link target='_blank' to="#">Click here</Link></td></tr>
                <tr> <td> Electrical Engineering </td> <td>Bachelor of Engineering  </td> <td rowSpan='3'><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>Electrical Power System </td> <td> Master of Technology  </td></tr><tr> <td>Power Electronics </td><td>   Master of Technology</td>  </tr><tr> <td>Electronic & Communication Engineering </td> <td>Bachelor of Engineering  </td> <td rowSpan='3'><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>Digital Communication </td> <td> Master of Technology  </td></tr><tr> <td>VLSI </td><td>   Master of Technology</td></tr>
                <tr> <td>Electronics & Instrumentation Engineering</td><td>   Bachelor of Engineering</td> <td ><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>Information Technology </td> <td>Bachelor of Engineering  </td> <td rowSpan='2'><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>Information Technology </td> <td> Master of Technology  </td></tr>
                <tr> <td>Mechanical Engineering </td> <td>Bachelor of Engineering  </td> <td rowSpan='3'><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>Industrial Design </td> <td> Master of Technology  </td></tr><tr> <td>Thermal Engineering </td><td>   Master of Technology</td></tr><tr> <td>Mining Engineering </td><td>   Bachelor of Engineering</td> <td  ><Link target='_blank' to="#">Click here</Link></td></tr><tr> <th rowSpan="3">Faculty of Pharmacy</th> <td>Pharmacy </td> <td> Bachelor of Pharmacy</td> <td rowSpan="3"><Link target='_blank' to="#">Click here</Link></td></tr>
                <tr> <td>Pharmacology</td><td>   Master of Pharmacy</td></tr><tr> <td>Pharmaceutics</td><td>   Master of Pharmacy</td></tr>
                <tr> <th rowSpan="2">School of Management Studies</th> <td rowSpan="2">Management </td> <td>BBA</td> <td rowSpan="2"><Link target='_blank' to="#">Click here</Link></td>
                </tr>
                <tr> <td>MBA</td></tr>
                <tr> <th>School of law</th><td>   Law</td><td>   Law</td> <td ><Link target='_blank' to="#">Click here</Link></td></tr><tr> <th rowSpan="10">Faculty of Education</th> <td rowSpan="2">Arts </td> <td>Bachelor of Arts </td> <td rowSpan="2"><Link target='_blank' to="#">Click here</Link></td></tr><td> Master of Arts</td><tr> <td> Physical Education</td><td>Bachelor of Physical Education</td><td>Click Here</td></tr>
                <tr ><td rowSpan="2">Science</td> <td>Bachelor of Science</td>
                  <td rowSpan="2"><Link target='_blank' to="#">Click here</Link></td> </tr><td> Master  of Science</td>
                <tr ><td rowSpan="2">Commerce</td> <td>Bachelor of Commerce</td><td rowSpan="2"><Link target='_blank' to="#">Click here</Link></td> </tr><td> Master  of Commerce</td><tr ><td rowSpan="2">Education</td> <td>B.A. Ed</td><td rowSpan="2"><Link target='_blank' to="#">Click here</Link></td> </tr><td> B.Ed</td><tr> <td> Physical Education</td> <td>   Bachelor of Physical Education</td> <td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf'>Click here</Link></td></tr><tr> <th rowSpan="3">School Computer Application</th> <td rowSpan="3">Computer Application</td><td>P.G.D.C.A</td><td rowSpan="3"><Link target='_blank' to="#">Click here</Link></td>
                </tr>
                <tr> <td>B.C.A</td></tr><tr> <td>M.C.A</td></tr>
                <tr> <th> School Of Hotel Mangement</th> <td>   HOTEL MANAGEMENT AND CATERING</td> <td>   Bachelor of HOTEL MANAGEMENT AND CATERING</td> <td>   Click her</td></tr><tr> <th>School of Homeopathy</th> <td>   Homeopathy</td><td>   B.H.M.S</td><td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf'>Click here</Link></td></tr>
                <tr> <th>School of Agriculture</th> <td>   Agriculture</td> <td>   Bachelor of Agriculture</td> <td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf'>Click here</Link></td></tr>
                <tr> <th>School of Ayurveda & Siddha Studies</th> <td>   Ayurveda</td> <td>   B.A.M.S</td><td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf'>Click here</Link></td></tr>
                <tr> <th rowSpan="2">School of Nursing</th> <td rowSpan='2'>Nursing</td> <td>Bachelor of Nursing</td><td rowSpan="2"><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>P.B.Sc (Nursing)</td></tr>
                <tr> <th>School of Paramedical</th> <td>   Paramedical</td><td>   B.P.T</td> <td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf'>Click here</Link></td></tr>
                <tr> <th> Faculty of Education</th> <td>   Ph .D.</td><td>   Arts</td><td>   Click here</td></tr>
                <tr> <th> Faculty of Education</th> <td>   Ph .D.</td><td>   Science</td><td>   Click here</td></tr><tr> <th> Faculty of Education</th> <td>   Ph .D.</td><td>   Commerce</td><td>   Click here</td></tr><tr> <th>School of Management Studies</th> <td>
                  Ph .D.</td><td>   Mangement</td><td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf'>Click here</Link></td></tr>
                <tr> <th> Faculty of Education</th> <td>   Ph .D.</td><td>   Physical Education</td><td>   Click here</td></tr><tr> <th> Faculty of Education</th> <td>   Ph .D.</td><td>   Education</td><td>   Click here</td></tr>
                <tr> <th>School of Engineering</th> <td>   Ph .D.</td><td>   Engineering</td><td>   Click here</td></tr><tr> <th>Faculty of Education</th> <td>   Ph .D.</td><td>   Library Science</td><td>   Click here</td></tr>
                <tr> <th>Faculty of Pharmacy</th> <td>   Ph .D.</td><td>   Pharmacy</td><td>   Click here</td></tr>
              </tbody></table>

            <br></br><br></br>
            <h6 style={{ fontFamily: "'poppins',sansSerif", marginLeft: "70px" }}> <strong>1.1.3</strong> Average percentage of courses having focus on employability/ entrepreneurship/ skill development

              offered by the institution during the last five years</h6><br></br><br></br>

            <table style={{ marginLeft: '70px', width: '90%' }}>

              <tbody><tr> <th>School Name </th> <th>Department  </th> <th>Program </th> <th >Session  (2017-18 To 2021-22)</th></tr><tr> <th rowSpan="21">School of Engineering </th> <td>Aeronautical Engineering </td> <td>   Bachelor of Engineering </td> <td ><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>   Chemical Engineering </td> <td>   Bachelor of Engineering </td> <td ><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>Civil Engineering</td> <td>Bachelor of Engineering</td> <td rowSpan='2'><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>Structural Design</td> <td>Master of Technology</td></tr><tr> <td> Computer Science and Engineering </td> <td>Bachelor of Engineering  </td> <td rowSpan='3'><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>Computer Science and Engineering </td> <td> Master of Technology  </td></tr><tr> <td>Software Engineering</td><td>   Master of Technology</td></tr><tr> <td>Electrical and Electronics Engineering</td><td>   Bachelor of Engineering </td> <td ><Link target='_blank' to="#">Click here</Link></td></tr>
                <tr> <td> Electrical Engineering </td> <td>Bachelor of Engineering  </td> <td rowSpan='3'><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>Electrical Power System </td> <td> Master of Technology  </td></tr><tr> <td>Power Electronics </td><td>   Master of Technology</td>  </tr><tr> <td>Electronic & Communication Engineering </td> <td>Bachelor of Engineering  </td> <td rowSpan='3'><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>Digital Communication </td> <td> Master of Technology  </td></tr><tr> <td>VLSI </td><td>   Master of Technology</td></tr>
                <tr> <td>Electronics & Instrumentation Engineering</td><td>   Bachelor of Engineering</td> <td ><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>Information Technology </td> <td>Bachelor of Engineering  </td> <td rowSpan='2'><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>Information Technology </td> <td> Master of Technology  </td></tr>
                <tr> <td>Mechanical Engineering </td> <td>Bachelor of Engineering  </td> <td rowSpan='3'><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>Industrial Design </td> <td> Master of Technology  </td></tr><tr> <td>Thermal Engineering </td><td>   Master of Technology</td></tr><tr> <td>Mining Engineering </td><td>   Bachelor of Engineering</td> <td  ><Link target='_blank' to="#">Click here</Link></td></tr><tr> <th rowSpan="3">Faculty of Pharmacy</th> <td>Pharmacy </td> <td> Bachelor of Pharmacy</td> <td rowSpan="3"><Link target='_blank' to="#">Click here</Link></td></tr>
                <tr> <td>Pharmacology</td><td>   Master of Pharmacy</td></tr><tr> <td>Pharmaceutics</td><td>   Master of Pharmacy</td></tr>
                <tr> <th rowSpan="2">School of Management Studies</th> <td rowSpan="2">Management </td> <td>BBA</td> <td rowSpan="2"><Link target='_blank' to="#">Click here</Link></td>
                </tr>
                <tr> <td>MBA</td></tr>
                <tr> <th>School of law</th><td>   Law</td><td>   Law</td> <td ><Link target='_blank' to="#">Click here</Link></td></tr><tr> <th rowSpan="10">Faculty of Education</th> <td rowSpan="2">Arts </td> <td>Bachelor of Arts </td> <td rowSpan="2"><Link target='_blank' to="#">Click here</Link></td></tr><td> Master of Arts</td><tr> <td> Physical Education</td><td>Bachelor of Physical Education</td><td>Click Here</td></tr>
                <tr ><td rowSpan="2">Science</td> <td>Bachelor of Science</td>
                  <td rowSpan="2"><Link target='_blank' to="#">Click here</Link></td> </tr><td> Master  of Science</td>
                <tr ><td rowSpan="2">Commerce</td> <td>Bachelor of Commerce</td><td rowSpan="2"><Link target='_blank' to="#">Click here</Link></td> </tr><td> Master  of Commerce</td><tr ><td rowSpan="2">Education</td> <td>B.A. Ed</td><td rowSpan="2"><Link target='_blank' to="#">Click here</Link></td> </tr><td> B.Ed</td><tr> <td> Physical Education</td> <td>   Bachelor of Physical Education</td> <td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf'>Click here</Link></td></tr><tr> <th rowSpan="3">School Computer Application</th> <td rowSpan="3">Computer Application</td><td>P.G.D.C.A</td><td rowSpan="3"><Link target='_blank' to="#">Click here</Link></td>
                </tr>
                <tr> <td>B.C.A</td></tr><tr> <td>M.C.A</td></tr>
                <tr> <th> School Of Hotel Mangement</th> <td>   HOTEL MANAGEMENT AND CATERING</td> <td>   Bachelor of HOTEL MANAGEMENT AND CATERING</td> <td>   Click her</td></tr><tr> <th>School of Homeopathy</th> <td>   Homeopathy</td><td>   B.H.M.S</td><td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf'>Click here</Link></td></tr>
                <tr> <th>School of Agriculture</th> <td>   Agriculture</td> <td>   Bachelor of Agriculture</td> <td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf'>Click here</Link></td></tr>
                <tr> <th>School of Ayurveda & Siddha Studies</th> <td>   Ayurveda</td> <td>   B.A.M.S</td><td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf'>Click here</Link></td></tr>
                <tr> <th rowSpan="2">School of Nursing</th> <td rowSpan='2'>Nursing</td> <td>Bachelor of Nursing</td><td rowSpan="2"><Link target='_blank' to="#">Click here</Link></td></tr><tr> <td>P.B.Sc (Nursing)</td></tr>
                <tr> <th>School of Paramedical</th> <td>   Paramedical</td><td>   B.P.T</td> <td>   <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf'>Click here</Link></td></tr>
                <br></br>
              </tbody></table>


            <br></br><br>
            </br><br></br>
            <style>
              {
                `
      //   ///////////////////////////////////////////////////////////////////////////////////
      .tann{
      margin-left:120px;
      }
      
      .table {
           border-collapse: collapse;
           width: 95%;
            margin-left:50px;
         }
         
         .table,
         .table th,
         .table td {
           border: 1px solid #ccc;
         }
         
         .table th,
         .table td {
           padding: 0.5rem;
         }
         
         .table th {
           position: relative;
            cursor: grab;
               user-select: none;
         }   
            .table th:active {
               cursor: grabbing;
             }
               
             .wide-cell {
      width: 200px; 
      text-align:center;/* Adjust width as needed */
    }`
              }
            </style>
            {/* <h5 style={{ color: 'orange', textAlign: 'center', fontFamily: "'poppins',sansSerif", marginBottom: '10px' }}>Training and Placement Cell</h5> */}
            <div className="tann">
              <table style={{ marginLeft: '70px', width: '90%' }}>


                <tbody>
                  <tr>
                    <td>
                      1.3.2
                    </td>
                    <td> Number of value-added courses for imparting transferable and life skills offered during last five years.   </td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/VAC UP FINAL1.pdf'>Click here</Link>
                    </td>

                  </tr>



                  <tr>
                    <td>
                      1.3.3
                    </td>
                    <td>Department wise Enrolled Students list of Value Added courses </td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/criteria 2.pdf'>Click here</Link>
                    </td>

                  </tr>

                  <tr>
                    <td>
                      1.3.4
                    </td>
                    <td>Percentage of students undertaking field projects / research projects / internships

                      (Data for the latest completed academic year). </td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/criteria 3.pdf'>Click here</Link>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      1.3.4.1
                    </td>
                    <td>  Number of students undertaking field project or research projects or internships</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/criteria 4.pdf'>Click here</Link>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      1.4.1 & 1.4.2
                    </td>
                    <td>  Structured feedback for design and review of syllabus – semester-wise / year-wise is received from 1) Students, 2) Teachers  3) Employers 4) Alumni 5) Professional and Action taken Report</td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/criteria 5.pdf'>Click here</Link>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      1.4.1.a
                    </td>
                    <td>   Sample feedback form the stakeholders        </td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/criteria 6.pdf'>Click here</Link>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      1.4.2.b
                    </td>
                    <td>  Feedback Process of the  Institutes </td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/criteria 7.pdf'>Click here</Link>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      1.4.3          </td>
                    <td>       Data
                    </td>
                    <td className="wide-cell">
                      <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/criteria 8.pdf'>Click here</Link>
                    </td>

                  </tr>



                </tbody>
              </table></div>


          </div>



          <div className="col-lg-3">

            <ul className='list-icon' >
              <h4 style={{ fontFamily: "'poppins',sansSerif", color: '#243642', textDecoration: 'underline' }}>NAAC</h4>
              <br></br>  <li className='lli'>
                <Link  to='/SSR'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>SSR</Link></li>
              <li className='lli'>
                <Link to='/criteria1'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-1</Link></li>
              <li className='lli'>
                <Link  to='/criteria2'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-2</Link></li>
              <li className='lli'>
                <Link  to='/criteria3'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-3</Link></li>

              <li className='lli'>
                <Link  to='/criteria4'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-4</Link></li>

              <li className='lli'>
                <Link  to='/criteria5'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-5</Link></li>

              <li className='lli'>
                <Link  to='/criteria6'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-6</Link></li>




            </ul>
          </div>
        </div>

      </div>
      <br></br>

      <Footerpage /></>
  )
}

export default Criteria1