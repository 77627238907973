import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import Box from "@mui/material/Box";
import { Grid, Button, Tab, Tabs } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DatePicker from "react-datepicker";
import logo from "../../AdminComponent/12.png";
import StudentDashboard from "../../AdminComponent/StudentDashboard";
import { useNavigate } from "react-router-dom";

const theme = createTheme();

function StudentAllDetail() {
  const [isEnrollGenerated, setIsEnrollGenerated] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    if (newValue === 1 && !(isRegistered && isEnrolled)) return;
    if (newValue === 2 && !(isRegistered && isEnrolled)) return;
    if (newValue === 3 && !(isRegistered && isEnrolled)) return;

    setTabValue(newValue);
  };

  const navigate = useNavigate();

  const userData = JSON.parse(sessionStorage.getItem("currentUser"));
  const isEnrolled = userData?.isEnrolled;
  const isRegistered = userData?.isRegistered;
  const courseName = userData?.courseName;

  const isProfessionalTabEnabled = isRegistered && isEnrolled;
  const isAcademicTabEnabled = isRegistered && isEnrolled;
  const isDocsTabEnabled = isRegistered && isEnrolled;

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("currentUser"));
    setIsEnrollGenerated(userData?.IsEnrollGenerated);

    if (userData.courseName) {
      if (userData.isEnrolled === false) {
        swal({
          icon: "warning",
          title: "Enrollment Required",
          text: "Please fill the enrollment form first.",
          buttons: {
            cancel: "Cancel",
            confirm: "OK",
          },
        }).then((value) => {
          if (value) {
            navigate("/student/Enrollement/Form");
          }
        });
      } else if (userData.isEnrollPaid === false) {
        swal({
          icon: "warning",
          title: "Payment Pending",
          text: "Please complete the payment for enrollment.",
          buttons: {
            cancel: "Cancel",
            confirm: "OK",
          },
        }).then((value) => {
          if (value) {
            navigate("/Epravesh/Student/EnrollementFee");
          }
        });
      }
    }
  }, []);

  const handleDownloadClick = () => {
    if (userData.isEnrollPaid === true) {
      generatePDF();
    } else {
      swal({
        icon: "error",
        title: "Error",
        text: "Please Fill Enrollment Form First!!",
      });
    }
  };

  const Admision_slip = () => {
    generatePDF1();
  };
  const shouldDisplaySection = (qualification1, qualification) => {
    const qualificationOrder = {
      "10th": 1,
      "12th": 2,
      ITI: 3,
      Diploma: 4,
      UG: 5,
      Graduation: 6,
      BSC: 7,
      PG: 8,
    };

    return (
      qualificationOrder[qualification1] <= qualificationOrder[qualification]
    );
  };

  const getDocumentsByQualification = () => {
    const qualification = userData?.qualification;

    switch (qualification) {
      case "PG":
        return [
          "applicantPhoto",
          "aadharCard",
          "applicantSignature",
          "domicileCertificate",
          "marksheet10th",
          "marksheet12th",
          "undergraduateCertificate",
          "migrationCertificate",
          "transferCertificate",
          "incomeCertificate",
          "otherCertificate",
        ];
      case "UG":
        return [
          "applicantPhoto",
          "aadharCard",
          "applicantSignature",
          "domicileCertificate",
          "marksheet10th",
          "marksheet12th",
          "undergraduateCertificate",
          "migrationCertificate",
          "transferCertificate",
          "incomeCertificate",
          "otherCertificate",
        ];

      case "12th":
        return [
          "applicantPhoto",
          "aadharCard",
          "applicantSignature",
          "marksheet10th",
          "marksheet12th",
          "migrationCertificate",
          "transferCertificate",
          "domicileCertificate",
          "incomeCertificate",
          "otherCertificate",
        ];
      case "10th":
        return [
          "applicantPhoto",
          "aadharCard",
          "applicantSignature",
          "marksheet10th",
          "domicileCertificate",
          "migrationCertificate",
          "transferCertificate",
          "incomeCertificate",
          "otherCertificate",
        ];
      case "Diploma":
        return [
          "applicantPhoto",
          "aadharCard",
          "applicantSignature",
          "marksheet10th",
          "diplomaMarksheet",
          "domicileCertificate",
          "migrationCertificate",
          "transferCertificate",
          "incomeCertificate",
          "otherCertificate",
        ];
      case "Graduation":
        return [
          "applicantPhoto",
          "aadharCard",
          "applicantSignature",
          "marksheet10th",
          "undergraduateCertificate",
          "diplomaMarksheet",
          "domicileCertificate",
          "migrationCertificate",
          "transferCertificate",
          "incomeCertificate",
          "otherCertificate",
        ];
      case "BSC":
        return [
          "applicantPhoto",
          "aadharCard",
          "applicantSignature",
          "marksheet10th",
          "marksheet12th",
          "undergraduateCertificate",
          "domicileCertificate",
          "migrationCertificate",
          "transferCertificate",
          "incomeCertificate",
          "otherCertificate",
        ];
      case "ITI":
        return [
          "applicantPhoto",
          "applicantSignature",
          "aadharCard",
          "marksheet10th",
          "ItImarksheet",
          "domicileCertificate",
          "migrationCertificate",
          "transferCertificate",
          "incomeCertificate",
          "otherCertificate",
        ];
      default:
        return [];
    }
  };

  const relevantDocuments = getDocumentsByQualification();
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  const generatePDF1 = async () => {
    const pdf = new jsPDF();

    // Add A4 page border
    pdf.setDrawColor(0); // Black color
    pdf.rect(5, 5, 200, 287);

    // Add University Logo
    pdf.addImage(logo, "JPEG", 8, 10, 20, 20);

    // Header Section
    pdf.setFont("helvetica", "sans-serif");
    pdf.setFontSize(12);
    pdf.text(
      "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
      32,
      15
    );

    pdf.setFont("helvetica", "serif");
    pdf.setFontSize(10);
    pdf.text("BACHELOR OF AYURVEDIC MEDICINE AND SURGERY", 60, 22);
    pdf.text("(AYURVEDIC MEDICINE AND SURGERY) EXAM : DEC-2024", 55, 28);

    pdf.setFontSize(13);
    pdf.setFont("helvetica", "Georgia");
    pdf.text("ADMIT CARD", 85, 34);

    // Draw underline under "ADMIT CARD"
    const admitCardX = 79;
    const admitCardY = 35;
    const admitCardWidth = 38;
    pdf.line(admitCardX, admitCardY, admitCardX + admitCardWidth, admitCardY);

    // Draw line under the header
    pdf.line(5, 45, 205, 45);

    // Second Box: Student Details
    const details = [
      { label: "Enrollment No", value: "741852963852" },
      { label: "Candidate Name", value: "Sxxjxn Sharma Sharma Sharma" },
      { label: "Father's Name", value: "Xjxjh Sharma Xhsu gfd Sharma" },
      { label: "Mother's Name", value: "Xjxjh Sharma Xhsu dfb  Sharma" },
      { label: "Semester/Year", value: "3" },
      { label: "Status", value: "Regular" },
    ];

    // Draw the enclosing box
    const boxStartX = 10;
    const boxStartY = 50;
    const boxWidth = 160;
    const boxHeight = 50;
    // pdf.rect(boxStartX, boxStartY, boxWidth, boxHeight);

    // Create table-like rows for details
    let yPos = boxStartY + 10;
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(10);

    details.forEach((detail, index) => {
      const columnX = index < 3 ? boxStartX + 5 : boxStartX + 85; // Left or right column
      if (index === 3) yPos = boxStartY + 10; // Reset for the right column
      const wrappedText = pdf.splitTextToSize(
        `${detail.label}: ${detail.value}`,
        55
      ); // Wrap text if too long
      pdf.text(wrappedText, columnX, yPos);
      yPos += 12; // Adjust spacing between rows
    });

    // Add applicant photo to the right of the details box
    const photoX = boxStartX + boxWidth + 5; // Positioned just right of the box
    const photoY = boxStartY + 5; // Centered vertically with the box
    // pdf.rect(photoX, boxStartY, 30, boxHeight); // Placeholder for the photo border
    const studentImage = userData?.Documents?.applicantPhoto;
    pdf.addImage(studentImage, "JPEG", photoX, photoY, 27, 35);
    pdf.text("Student's Sig.", photoX + 5, photoY + 45);

    // Add student's signature below the photo
    const signatureImage = userData?.Documents?.applicantSignature; // Signature image
    const signatureY = photoY + 10 + 25; // Positioned below the photo
    pdf.addImage(signatureImage, "JPEG", photoX, signatureY, 27, 10); // Adjusted dimensions
    // pdf.text("Student's Signature", photoX + 2, signatureY + 15);

    // Third Box: Subjects
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text(
      "Name of the Papers in Which Candidate is Appearing in the Examination",
      25,
      115
    );

    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(11);
    pdf.text("Exam Time: 11:00 AM - 02:00 PM", 57, 122);

    // Subject Table
    const headers = ["SNo", "Subject Code", "Subject Type", "Subject Name"];
    const subjects = [
      ["1", "MCA-301", "Theory", "Artificial Intelligence"],
      ["2", "MCA-302", "Theory", "Data Mining"],
      ["3", "MCA-303", "Practical", "Web Technology"],
      ["4", "MCA-304", "Practical", "Soft Computing"],
      ["5", "MCA-305", "Theory", "Advanced Databases"],
      ["1", "MCA-301", "Theory", "Artificial Intelligence"],
      ["2", "MCA-302", "Theory", "Data Mining"],
      ["3", "MCA-303", "Practical", "Web Technology"],
      ["4", "MCA-304", "Practical", "Soft Computing"],
      ["5", "MCA-305", "Theory", "Advanced Databases"],
    ];
    pdf.autoTable({
      head: [headers],
      body: subjects,
      startY: 130,
      styles: { fontSize: 10, textColor: 0 },
      headStyles: { fillColor: [230, 230, 230] }, // Light gray header background
      margin: { left: 15 },
      theme: "grid",
    });

    // Footer Instructions
    const footerY = pdf.autoTable.previous.finalY + 10;
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(10);
    pdf.text("Instructions:", 15, footerY);
    const instructions = [
      "1. Bring your examination admit card and original college ID card to the examination hall.",
      "2. Arrive at least 30 minutes before the start of the examination.",
      "3. Follow all instructions given by the invigilators during the examination.",
    ];
    instructions.forEach((instruction, i) => {
      pdf.text(instruction, 15, footerY + (i + 1) * 5);
    });

// Exam Controller Signature
const examControllerSignatureImage = "https://res.cloudinary.com/dszawxz7y/image/upload/v1708076754/sssutms/x8k947snao4btnzzuxnp.jpg";
pdf.addImage(examControllerSignatureImage, "JPEG", 150, footerY + 26, 40, 10); // Adjust position and size as necessary
pdf.text("Signature of Exam Controller", 150, footerY + 40);

    // Save PDF
    pdf.save("Admit_Card.pdf");
  };

  // const generatePDF = async () => {
  //   const pdf = new jsPDF();

  //   // Add the university logo
  //   pdf.addImage(logo, "JPEG", 8, 5, 20, 20);

  //   // Header text
  //   pdf.setTextColor(165, 42, 42);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.setFontSize(12);
  //   pdf.text(
  //     "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
  //     115,
  //     10,
  //     { align: "center" }
  //   );
  //   pdf.setFont("helvetica", "normal");
  //   pdf.setFontSize(11);
  //   pdf.text(
  //     "[Established Under Govt. of (M.P.) & Registered UGC 2(F),1956]",
  //     110,
  //     16,
  //     { align: "center" }
  //   );
  //   pdf.text("SH-18, Bhopal-Indore Road, Pachama,", 110, 22, {
  //     align: "center",
  //   });
  //   pdf.text("Sehore (M.P.) Pin Code - 466001", 110, 28, { align: "center" });

  //   // Academic year
  //   const nextYear = parseInt(userData?.admissionSession) + 1;
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text(
  //     `Academic Year ${userData?.admissionSession} - ${nextYear}`,
  //     115,
  //     34,
  //     { align: "center" }
  //   );

  //   // Enrollment form title
  //   pdf.setDrawColor(0);
  //   pdf.setFillColor(230, 230, 230);
  //   pdf.rect(65, 38, 85, 10, "F");
  //   pdf.setFontSize(12);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.setTextColor(0, 0, 0);
  //   pdf.text("Enrollment Form", 105, 45, { align: "center" });

  //   // Enrollment details (start below the form title)
  //   const detailsData = [
  //     ["Enrollment No.", userData?.enrollmentNumber || ""],
  //     ["Name", userData.name],
  //     ["Father's Name", userData.fathersname],
  //     ["Mother's Name", userData.mothersname],
  //     ["Gender", userData.gender],
  //     ["Date of Birth", formatDate(userData.dob)],
  //     ["Address", userData.address.address2],
  //     ["Domicile", userData.domicile],
  //     ["Category", userData.category],
  //     ["Mobile No.", userData.mobile],
  //     ["Email", userData.email],
  //     ["Course Name", userData.courseName],
  //     ["Course Type", userData.courseType],
  //     ["Specialization", userData.courseBranch],
  //     ["Institute", userData.assignedCollege || "Not assigned"],
  //     ["ABC ID", userData.AbcId || "Not Upload"],
  //   ];
  //   if (courseName === "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)") {
  //     detailsData.push(
  //       ["Neet Qualified Percentage", `${userData?.NeetPercentage}%` || ""],
  //       ["Admission Category", userData?.Concession || ""]
  //     );
  //   }

  //   // Start table below the form title
  //   const startY = 50;

  //   // Create the table for Enrollment details
  //   pdf.autoTable({
  //     body: detailsData,
  //     startY: startY, // Table starts below the form title
  //     margin: { left: 12 },
  //     tableWidth: "wrap",
  //     columnStyles: {
  //       0: { cellWidth: 80 }, // First column width
  //       1: { cellWidth: 110 }, // Second column width
  //     },
  //   });

  //   // Add student image directly to the PDF without base64 conversion
  //   const studentImage = userData?.Documents?.applicantPhoto;
  //   if (studentImage) {
  //     pdf.addImage(studentImage, "JPEG", 162, 50, 28, 34); // Position and dimensions
  //   }

  //   // Add Address Details heading below ABC ID
  //   pdf.setFont("helvetica", "bold");
  //   pdf.setFontSize(12);
  //   pdf.text("Address Details", 65, pdf.lastAutoTable.finalY + 10); // Position after ABC ID

  //   // Address details
  //   const addressData = [
  //     ["Address 1", userData?.address?.address1 || ""],
  //     ["Address 2", userData?.address?.address2 || ""],
  //     ["Country", userData?.address?.country || ""],
  //     ["State", userData?.address?.state || ""],
  //     ["District", userData?.address?.district || ""],
  //     ["Pin Code", userData?.address?.pinCode || ""],
  //   ];

  //   // Create the table for Address details
  //   pdf.autoTable({
  //     body: addressData,
  //     startY: pdf.lastAutoTable.finalY + 5, // Start just below the Address Details heading
  //     margin: { left: 12 },
  //     tableWidth: "wrap",
  //     columnStyles: {
  //       0: { cellWidth: 80 }, // First column width
  //       1: { cellWidth: 110 }, // Second column width
  //     },
  //   });

  //   // Add Professional Details heading below Address Details
  //   pdf.setFont("helvetica", "bold");
  //   pdf.setFontSize(12);
  //   pdf.text("Professional Details", 65, pdf.lastAutoTable.finalY + 10); // Position after the address table

  //   // Professional details
  //   const professionalData = [
  //     ["Handicapped", userData?.professional?.Handicapped || "No"],
  //     ["Medium", userData?.professional?.Medium || ""],
  //     ["Scholarship Required", userData?.professional?.ScholarshipRequired || "No"],
  //     ["Father's Occupation", userData?.professional?.FathersOccupation || ""],
  //     ["Mother's Income", userData?.professional?.MothersIncome || ""],
  //     ["Aadhar Number", userData?.professional?.AadharNumber || ""],
  //     ["Parent Mobile", userData?.professional?.ParentMobile || ""],
  //   ];

  //   // Create the table for Professional details
  //   pdf.autoTable({
  //     body: professionalData,
  //     startY: pdf.lastAutoTable.finalY + 5, // Start just below the Professional Details heading
  //     margin: { left: 12 },
  //     tableWidth: "wrap",
  //     columnStyles: {
  //       0: { cellWidth: 80 }, // First column width
  //       1: { cellWidth: 110 }, // Second column width
  //     },
  //   });

  //   // Save the PDF
  //   pdf.save(`${userData?.name} Enrollment Form.pdf`);
  // };

  // const generatePDF1 = async () => {
  //   const pdf = new jsPDF();

  //   pdf.setFontSize(14);
  //   pdf.text( "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE", 105, 15, { align: "center" });

  //   pdf.setFontSize(7);
  //   pdf.text("BACHELOR OF AYURVEDIC MEDICINE AND SURGERY: Dec-2024", 105, 30, { align: "center" });

  //   pdf.setFontSize(7);
  //   pdf.text("ADMIT CARD", 105, 40, { align: "center" });

  //   pdf.setFontSize(12);
  //   pdf.text("Details of Theory / Practical Examination", 20, 50);
  //   pdf.text("Time of Theory Examination -11:00 AM TO 02:00 PM", 20, 60);

  //   pdf.text("Enrollment No : 09CA231MC003", 20, 70);
  //   pdf.text("Candidate Name : ANKITA SHARMA     Father's Name : SANTOSH SHARMA", 20, 80);
  //   pdf.text("Semester/Year : 3       Status : Regular", 20, 90);

  //   // Signature space
  //   pdf.text("Signature of candidate", 160, 100);

  //   // Table
  //   pdf.autoTable({
  //     startY: 110,
  //     head: [["SNo.", "Subject Code", "Subject Name", "Exam Date"]],
  //     body: [
  //       ["1", "MCA-301", "Data Mining", "30-Dec-2024"],
  //       ["2", "MCA-302", "Artificial Intelligence", "31-Dec-2024"],
  //       ["3", "MCA-303", "Web Technology", "02-Jan-2025"],
  //       ["4", "MCA-304", "Soft Computing", "03-Jan-2025"],
  //       ["5", "MCA-305", "Advanced Databases", "04-Jan-2025"],
  //     ],
  //   });

  //   // Instructions
  //   const instructions = `
  // 1. Bring your examination admit card and original college ID card to the examination hall.
  // 2. Arrive at least 30 minutes before the start of the examination.
  // 3. Arrive at least 30 minutes before the start of the examination.
  // 4.Follow all instructions given by the invigilators during the examination.
  //   `;
  //   pdf.text(instructions, 20, pdf.lastAutoTable.finalY + 10);

  //   // Footer
  //   pdf.text("(Controller of Examination)", 20, pdf.internal.pageSize.height - 20);
  //   pdf.save(`${userData?.name} AdmitCard.pdf`);
  //   pdf.save("AdmitCard.pdf");
  // };

  const generatePDF = async () => {
    const pdf = new jsPDF();

    // Add the university logo
    pdf.addImage(logo, "JPEG", 8, 5, 20, 20);

    // Header text
    pdf.setTextColor(165, 42, 42);
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text(
      "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
      115,
      10,
      { align: "center" }
    );
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(11);
    pdf.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F),1956]",
      110,
      16,
      { align: "center" }
    );
    pdf.text("SH-18, Bhopal-Indore Road, Pachama,", 110, 22, {
      align: "center",
    });
    pdf.text("Sehore (M.P.) Pin Code - 466001", 110, 28, { align: "center" });

    // Academic year
    const nextYear = parseInt(userData?.admissionSession) + 1;
    pdf.setFont("helvetica", "bold");
    pdf.text(
      `Academic Year ${userData?.admissionSession} - ${nextYear}`,
      115,
      34,
      { align: "center" }
    );

    // Enrollment form title
    pdf.setDrawColor(0);
    pdf.setFillColor(230, 230, 230);
    pdf.rect(65, 38, 85, 10, "F");
    pdf.setFontSize(12);
    pdf.setFont("helvetica", "bold");
    pdf.setTextColor(0, 0, 0);
    pdf.text("Enrollment Form", 105, 45, { align: "center" });

    // Enrollment details (start below the form title)
    const detailsData = [
      ["Enrollment No.", userData?.enrollmentNumber || "Not Generated"],
      ["Name", userData.name],
      ["Father's Name", userData.fathersname],
      ["Mother's Name", userData.mothersname],
      ["Gender", userData.gender],
      ["Date of Birth", formatDate(userData.dob)],
      ["Domicile", userData.domicile],
      ["Category", userData.category],
      ["Mobile No.", userData.mobile],
      ["Email", userData.email],
      ["Course Name", userData.courseName],
      ["Course Type", userData.courseType],
      ["Specialization", userData.courseBranch],
      ["Institute", userData.assignedCollege || "Not assigned"],
      ["ABC ID", userData.AbcId || "Not Upload"],
      ["Last Passed Qualafication", userData.qualification || "Not Upload"],
      [
        "Last Passed Subject",
        userData.lastPassedSubject || userData.QualifiedCourse || "Not Upload",
      ],
    ];

    if (courseName === "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)") {
      detailsData.push(
        ["Neet Qualified Percentage", `${userData?.NeetPercentage}%` || ""],
        ["Admission Category", userData?.Concession || ""]
      );
    }

    // Start table below the form title
    let currentY = 50;

    // Create the table for Enrollment details
    pdf.autoTable({
      body: detailsData,
      startY: currentY, // Table starts below the form title
      margin: { left: 10 },
      tableWidth: "wrap",
      columnStyles: {
        0: { cellWidth: 80 }, // First column width
        1: { cellWidth: 110 }, // Second column width
      },
    });

    // Add student image directly to the PDF without base64 conversion
    const studentImage = userData?.Documents?.applicantPhoto;
    if (studentImage) {
      pdf.addImage(studentImage, "JPEG", 162, 50, 28, 34); // Position and dimensions
    }

    // Add Address Details heading below ABC ID
    currentY = pdf.lastAutoTable.finalY + 10;
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text("Address Details", 12, currentY);

    // Address details
    const addressData = [
      ["Local Address", userData?.address?.address1 || ""],
      ["Permanent Address", userData?.address?.address2 || ""],
      ["Country", userData?.address?.country || ""],
      ["State", userData?.address?.state || ""],
      ["District", userData?.address?.district || ""],
      ["Pin Code", userData?.address?.pinCode || ""],
    ];

    // Create the table for Address details
    pdf.autoTable({
      body: addressData,
      startY: currentY + 5,
      margin: { left: 12 },
      tableWidth: "wrap",
      columnStyles: {
        0: { cellWidth: 80 },
        1: { cellWidth: 110 },
      },
    });

    currentY = pdf.lastAutoTable.finalY + 10;
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text("Professional Details", 12, currentY);

    // Professional details
    const professionalData = [
      ["Handicapped", userData?.professional?.Handicapped || "No"],
      ["Medium", userData?.professional?.Medium || ""],
      // [
      //   "Scholarship Required",
      //   userData?.professional?.ScholarshipRequired || "No",
      // ],
      ["Father's Occupation", userData?.professional?.FathersOccupation || ""],
      ["Mother's Income", userData?.professional?.MothersIncome || ""],
      ["Aadhar Number", userData?.professional?.AadharNumber || ""],
      ["Parent Mobile", userData?.professional?.ParentMobile || ""],
    ];

    // Create the table for Professional details
    pdf.autoTable({
      body: professionalData,
      startY: currentY + 5, // Start just below the Professional Details heading
      margin: { left: 12 },
      tableWidth: "wrap",
      columnStyles: {
        0: { cellWidth: 80 }, // First column width
        1: { cellWidth: 110 }, // Second column width
      },
    });

    // Add Academic Details heading below Professional Details
    currentY = pdf.lastAutoTable.finalY + 15; // Update current Y position after the professional table
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text("Academic Details", 13, currentY); // Position after the professional table

    // Academic details
    const tableData = [
      [
        "Tenth Details",
        `${userData?.academicDetails?.tenthPassingYear || "N/A"}`,
        `${userData?.academicDetails?.tenthRollNo || "N/A"}`,
        `${userData?.academicDetails?.tenthBoard || "N/A"}`,
        `${userData?.academicDetails?.tenthMarksObtain || "CGPA"}`,
        `${userData?.academicDetails?.tenthMaxMarks || "CGPA"}`,
        `${
          userData?.academicDetails?.tenthPercentage
            ? userData?.academicDetails?.tenthPercentage
            : userData?.academicDetails?.cgpa || "N/A"
        }`,
      ],
      [
        "Twelfth/Diploma Details",
        `${userData?.academicDetails?.twelfthPassingYear || "N/A"}`,
        `${userData?.academicDetails?.twelfthRollNo || "N/A"}`,
        `${userData?.academicDetails?.twelfthBoard || "N/A"}`,
        `${userData?.academicDetails?.twelfthMarksObtain || "N/A"}`,
        `${userData?.academicDetails?.twelfthMaxMarks || "CGPA"}`,
        `${
          userData?.academicDetails?.twelfthPercentage
            ? userData?.academicDetails?.twelfthPercentage
            : userData?.academicDetails?.cgpa || "N/A"
        }`,
      ],
      [
        "Graduation Details",
        `${userData?.academicDetails?.graduationPassingYear || "N/A"}`,
        `${userData?.academicDetails?.graduationRollNo || "N/A"}`,
        `${userData?.academicDetails?.graduationUniversity || "N/A"}`,
        `${userData?.academicDetails?.graduationMarksObtain || "N/A"}`,
        `${userData?.academicDetails?.graduationMaxMarks || "N/A"}`,
        `${
          userData?.academicDetails?.graduationPercentage
            ? userData?.academicDetails?.graduationPercentage
            : userData?.academicDetails?.cgpa || "N/A"
        }`,
      ],
      [
        "PG Details",
        `${userData?.academicDetails?.postGraduationPassingYear || "N/A"}`,
        `${userData?.academicDetails?.postGraduationRollNo || "N/A"}`,
        `${userData?.academicDetails?.postGraduationUniversity || "N/A"}`,
        `${userData?.academicDetails?.postGraduationMarksObtain || "N/A"}`,
        `${userData?.academicDetails?.postGraduationMaxMarks || "CGPA"}`,
        `${
          userData?.academicDetails?.postGraduationPercentage
            ? userData?.academicDetails?.postGraduationPercentage
            : userData?.academicDetails?.cgpa || "N/A"
        }`,
      ],
    ];

    // Create the table for Academic details
    pdf.autoTable({
      head: [
        [
          "Qualification",
          "Passing Year",
          "Roll No.",
          "Board",
          "Obtained Marks",
          "Max Marks",
          "Percentage/CGPA",
        ],
      ],
      body: tableData,
      startY: currentY + 5, // Start just below the Academic Details heading
      theme: "grid",
      styles: { fontSize: 9 },
      margin: { left: 14 },
    });

    currentY = pdf.lastAutoTable.finalY + 10; // Update current Y position after the last section
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(10);
    pdf.text("Declaration:", 12, currentY); // Add the title "Declaration"
    currentY += 5;
    const declarationText = [
      "I hereby declare that the information provided in this form and its attachments is accurate to the best of my knowledge.",
      "I understand that any false information may result in disqualification.",
      "If selected for admission, I agree to abide by the rules and regulations of the university.",
      "I understand that the admission is provisional, subject to confirmation by the concerned authorities, and that the fee paid",
      " is non-refundable in case of cancellation.",
    ];
    declarationText.forEach((line, index) => {
      pdf.text(line, 12, currentY + index * 5);
    });

    // Signature Section
    currentY += 25; // Add space before signature section
    pdf.text("Certificate ………", 12, currentY);

    // Certificate Section
    currentY += 10; // Add space before certificate
    pdf.setFont("helvetica", "italic");
    pdf.setFontSize(10);
    pdf.text(
      "Certified that the above student has been admitted to this institution as per the procedure laid down by regulatory authorities.",
      12,
      currentY
    );
    currentY += 10;
    pdf.text(
      "The entries above have been checked and verified by me from the records.",
      12,
      currentY
    );
    currentY += 10;
    pdf.text(
      "Therefore, I recommend that he/she should be enrolled as requested by the student.",
      12,
      currentY
    );

    // Signature of Applicant (left side)
    const applicantSignature = userData?.Documents?.applicantSignature;
    if (applicantSignature) {
      pdf.addImage(applicantSignature, "JPEG", 20, currentY + 20, 40, 15);
      pdf.text("Applicant's Signature", 20, currentY + 40);
    }

    // Signature of the Dean with seal (right side)
    pdf.text("Signature of the Dean with seal", 140, currentY + 40);

    // Save the PDF
    pdf.save(`${userData?.name} Enrollment Form.pdf`);
  };

  return (
    <>
      <style>
        {`

         @import url("https://fonts.googleapis.com/css?family=Josefin+Sans|Mountains+of+Christmas&display=swap");
 

.table th, .table td {
font-size: 14px;
padding: 2px 5px;
color: #000;
}
.theadd{

                                  color: #2E236C;
                                  font-size: 15px;
                                  font-weight: bold;
                             }
.carad{
 height: 70%;
  border-radius: 20px;
   margin-left: 60px;
    margin-top: 50px;
    }




    .info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.info-row strong {
  flex: 0 0 40%; /* Adjust the width as needed */
}

.info-row span {
  flex: 1;
  text-align: left;
}
.tabu{
margin-top: 25px;
 margin-left: 20px;
 }
    .diss{
display: flex;
 gap: 30px;
 position: sticky;
  top: 550px;
 margin-top: 10px;
  margin-left: 60px;
  }
          .student-profile .card {
            border-radius: 10px;
            height:80px;
            width:80px;
          }

          .infoo{
          margin-left:20px;
          font-size:15px}

.card {
              width: 80%;
              margin-Top:40px;
              height:auto;
           }

          .student-profile .card h3 {
            font-size: 20px;
            font-weight: 700;
          }

          .student-profile .card p {
            font-size: 26px;
            color: #000;
          }


          .strong{
    font-size:15px;
    margin-left:-8px
    }
.boox{

            margin-top: 3%;
            // background-color: #b0c4b1;
            box-shadow: 2px solid black;
            margin-left: 30px;
          }



          .profile_img{
       
                      width: 120px;
                      height: 120px;
                      object-fit: cover;
                      border-radius: 50%;
                    }
          .student-profile .table th,
          .student-profile .table td {
            font-size: 40px;
            padding: 0px 10px;
            color: #000;
          }

          @media (max-width: 800px) {
            .card {
              width: 100%;
              margin-Top:75px;
            }
          }

       

          @media screen and (max-width: 480px) {
       
     
         .card {
              width: 80%;
              margin-Top:40px;
           }

    .diss{
display: flex;
 gap: 30px;

 position:sticky;
 
 
 margin-top: 50px;
  margin-left:-2px;
  }
       
  .tabu{
margin-top: 25px;
 margin-left: -30px;
 }
 
   .profile_img{
       
                      // width: 80px;
                      // height: 80px;
                      // object-fit: cover;
                      // border-radius: 50%;
                      display:none
                    }
 
  .student-profile .table th,
          .student-profile .table td {
            font-size: 25px;
            padding: 0px 5px;
            color: #000;
          }




             .card-body{
             margin-left:10px}

        .carad{
 height: auto;
 width:auto;
  border-radius: 20px;
    // margin-left: -5px;
    margin-left:-40px;
    margin-top: 50px;
    }
       
     .card-header {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .card-header img {
      margin-right: 10px;
      margin-right: auto; /* Pushes the image to the right */
    }
    .card-header h3 {
      margin-top: -50px;
      text-align: right;
     
    }    
       
       . table-bordered{
       margin-left:-10px}
          .bunnn{
          height:30px;
          width:160px;
           margin-top:-20px;
            font-size: 9px; /* Reduce the font size for smaller screens */
    padding: 5px 10px; /* Optionally adjust padding for a better fit */
          }
  .MuiTab-root {
    width: 80%; /* Make each tab take up the full width */
 
    font-size: 11px; /* Reduce the font size */
    padding: 0px ; /* Adjust padding to match the smaller text */

  }

  .MuiTabs-flexContainer {
    flex-direction: row; /* Stack tabs vertically */
  }

  .MuiTabs-root {
    width: 100%; /* Ensure the Tabs container takes up the full width */
  }
}

         
        `}
      </style>

      <ThemeProvider theme={theme}>
        <StudentDashboard />
        <Box className="boox">
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={12} sm={5}>
              <div
                class="card shadow-lg carad"
                style={{
                  position: "sticky",
                  top: "70px",
                  zIndex: 10,
                }}
              >
                <div
                  className="card-header bg-transparent text-right"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    className="profile_img"
                    src={userData?.Documents?.applicantPhoto}
                    alt="img"
                    style={{ marginRight: "10px" }}
                  />
                  <h3 style={{ color: "#921A40", margin: 0 }}>
                    {userData?.name}
                  </h3>
                </div>

                <div class="card-body">
                  <div class="info-row">
                    <strong className="strong">Admission Session:</strong>
                    <span className="infoo">{userData?.admissionSession}</span>
                  </div>
                  <div class="info-row">
                    <strong className="strong">Student Type:</strong>
                    <span className="infoo">
                      {userData?.StudentType || "Normal Admission"}
                    </span>
                  </div>
                  <div class="info-row">
                    <strong className="strong">Course Type:</strong>
                    <span className="infoo">{userData?.courseType}</span>
                  </div>
                  <div class="info-row">
                    <strong className="strong">Course Name:</strong>
                    <span className="infoo">{userData?.courseName}</span>
                  </div>
                  <div class="info-row">
                    <strong className="strong">Branch:</strong>
                    <span className="infoo">{userData?.courseBranch}</span>
                  </div>
                  <div class="info-row">
                    <strong className="strong">Fee Status:</strong>
                    <span
                      className="infoo"
                      style={{ color: userData?.isPaid ? "green" : "red" }}
                    >
                      {userData?.isPaid ? "Paid" : "Not Paid"}
                    </span>
                  </div>
                  <div class="info-row">
                    <strong className="strong">Enrollment Form Status:</strong>
                    <span
                      className="infoo"
                      style={{ color: userData?.isEnrolled ? "green" : "red" }}
                    >
                      {userData?.isEnrolled ? "Filled" : "Pending"}
                    </span>
                  </div>
                  <div class="info-row">
                    <strong className="strong">
                      Enrollment Form Fee Status:
                    </strong>
                    <span
                      className="infoo"
                      style={{
                        color: userData?.isEnrollPaid ? "green" : "red",
                      }}
                    >
                      {userData?.isEnrollPaid ? "Paid" : "Pending"}
                    </span>
                  </div>
                  <div class="info-row">
                    <strong className="strong">Enrollment Number</strong>
                    <span
                      className="infoo"
                      style={{
                        color: "red",
                      }}
                    >
                      {userData?.enrollmentNumber || "Not Generated"}
                    </span>
                  </div>
                </div>
              </div>

              <div className="diss">
                <Button
                  className="bunnn"
                  style={{ backgroundColor: "#405D72" }}
                  variant="contained"
                  color="success"
                  onClick={handleDownloadClick}
                  // disabled={true}
                >
                  Enrollment Details
                  <span style={{ marginLeft: "8px" }}>
                    <i className="fa-solid fa-download"></i>
                  </span>
                </Button>
                <Button
                  className="bunnn"
                  style={{ backgroundColor: "#405D72" }}
                  variant="contained"
                  color="success"
                  disabled={true}
                  // onClick={Admision_slip}
                >
                  Admission Slip
                  <span style={{ marginLeft: "8px" }}>
                    <i className="fa-solid fa-download"></i>
                  </span>
                </Button>
              </div>
            </Grid>

            <Grid item xs={12} sm={7}>
              <Box className="tabu">
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab
                    label={
                      window.innerWidth <= 480
                        ? "Personal"
                        : "Personal Information"
                    }
                  />
                  <Tab
                    label={
                      window.innerWidth <= 480
                        ? "Professional"
                        : "Professional Information"
                    }
                  />
                  <Tab
                    label={
                      window.innerWidth <= 480
                        ? "Academic"
                        : "Academic Information"
                    }
                  />
                  <Tab
                    label={window.innerWidth <= 480 ? "Docs" : "Documents"}
                  />
                </Tabs>

                {tabValue === 0 && (
                  <Box p={1}>
                    <hr></hr>

                    <div className="card-body pt-0">
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Gender
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.gender}</td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Category
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.category}</td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Father's Name
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.fathersname}</td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Mother's Name
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.mothersname}</td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Contact
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.mobile}</td>
                          </tr>{" "}
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Nationality
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.nationality || "Not Uploaded"}</td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              DOB
                            </th>
                            <td width="2%">:</td>
                            <td>
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={
                                  userData?.dob ? new Date(userData?.dob) : null
                                }
                                readOnly
                              />
                            </td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Email
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.email}</td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Domicile
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.domicile || "Not Uploaded"}</td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Last Exam Type
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.lastExamType}</td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Scholarship Required
                            </th>

                            <td width="2%">:</td>

                            <td>
                              {userData?.professional?.ScholarshipRequired
                                ? userData.professional.ScholarshipRequired
                                : "Not Eligible"}
                            </td>
                          </tr>{" "}
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Last Qualification
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.qualification}</td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Last Percentage/ CGPA
                            </th>
                            <td width="2%">:</td>
                            <td>
                              {userData?.qualificationPercentage ||
                                userData?.LastPercentage ||
                                userData?.cgpa ||
                                "Not Uploaded"}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Last Passed Subject
                            </th>
                            <td width="2%">:</td>
                            <td>
                              {userData?.lastPassedSubject || "Not Uploaded"}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Last Passing Year
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.passingYear}</td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              ABC Id
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.AbcId || "Not Available"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Box>
                )}

                {tabValue === 1 && isProfessionalTabEnabled && (
                  <Box p={3}>
                    <div className="card-header bg-transparent border-0 ">
                      <hr style={{ marginTop: "-15px" }}></hr>

                      <div className="card-body pt-0 ">
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <th
                                width="50%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Handicapped
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.professional?.Handicapped}</td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Medium
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.professional?.Medium}</td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Aadhar Number
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.professional?.AadharNumber}</td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Samagra ID
                              </th>
                              <td width="2%">:</td>
                              <td>
                                {userData?.professional?.SamagraId
                                  ? userData.professional.SamagraId
                                  : "Not Available"}
                              </td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Father's Occupation
                              </th>
                              <td width="2%">:</td>
                              <td>
                                {userData?.professional?.FathersOccupation}
                              </td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Mother's Occupation
                              </th>
                              <td width="2%">:</td>
                              <td>
                                {userData?.professional?.MothersOccupation}
                              </td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Father's Income
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.professional?.FathersIncome}</td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Mother's Income
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.professional?.MothersIncome}</td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Parent Mobile
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.professional?.ParentMobile}</td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Current Address{" "}
                              </th>
                              <td width="2%">:</td>
                              <td>
                                {userData?.address?.address1
                                  ? userData?.address?.address1
                                  : "Not Fill"}
                              </td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Permanent Address{" "}
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.address?.address2}</td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Country
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.address?.country}</td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                State
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.address?.state}</td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                District
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.address?.district}</td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Pin Code
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.address?.pinCode}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Box>
                )}

                {tabValue === 2 && isAcademicTabEnabled && (
                  <Box p={3}>
                    <div className="card-header bg-transparent border-0">
                      <hr style={{ marginTop: "-10px" }}></hr>
                      <div className="card-body pt-0">
                        <table className="table table-bordered">
                          {shouldDisplaySection(
                            "10th",
                            userData?.qualification
                          ) && (
                            <>
                              <th style={{ fontSize: "20px" }}>
                                <b>10th</b>
                              </th>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  School
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.tenthSchool}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Board
                                </th>
                                <td width="2%">:</td>
                                <td>{userData?.academicDetails?.tenthBoard}</td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Passing Year
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.tenthPassingYear}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Max. Marks
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.tenthMaxMarks}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Marks Obtained
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.tenthMarksObtain}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Percentage
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.tenthPercentage}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Roll No
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.tenthRollNo}
                                </td>
                              </tr>
                              <br></br>

                              {/* Add other 10th fields here */}
                            </>
                          )}

                          {shouldDisplaySection(
                            "12th" || "Diploma" || "ITI",
                            userData?.qualification
                          ) && (
                            <>
                              <tr style={{ fontSize: "20px" }}>
                                <b>
                                  {" "}
                                  {userData?.qualification === "ITI"
                                    ? "ITI"
                                    : "12th"}
                                </b>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  School
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.twelfthSchool}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Board
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.twelfthBoard}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Passing Year
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {
                                    userData?.academicDetails
                                      ?.twelfthPassingYear
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Max. Marks
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.twelfthMaxMarks}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Marks Obtained
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {
                                    userData?.academicDetails
                                      ?.twelfthMarksObtain
                                  }
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Percentage
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.twelfthPercentage}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Roll No
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.twelfthRollNo}
                                </td>
                              </tr>
                              <br></br>
                            </>
                          )}

                          {shouldDisplaySection(
                            "UG",
                            userData?.qualification
                          ) && (
                            <>
                              <tr style={{ fontSize: "20px" }}>
                                <b>Graduation</b>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  College
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.graduationCollege || "Not Uploaded"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  University
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.graduationUniversity || "Not Uploaded"}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Passing Year
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.graduationPassingYear || "Not Uploaded"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Max. Marks
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.graduationMaxMarks || "Not Uploaded"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Marks Obtained
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.graduationMarksObtain || "Not Uploaded"}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Percentage
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.graduationPercentage || "Not Uploaded"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Roll No
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.graduationRollNo || "Not Uploaded"}
                                </td>
                              </tr>
                              <br></br>
                            </>
                          )}

                          {shouldDisplaySection(
                            "PG",
                            userData?.qualification
                          ) && (
                            <>
                              <tr style={{ fontSize: "20px" }}>
                                <b>Post Graduation</b>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  College
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.postGraduationCollege || "Not Uploaded"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  University
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.postGraduationUniversity ||
                                    "Not Uploaded"}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Passing Year
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.postGraduationPassingYear ||
                                    "Not Uploaded"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Max. Marks
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.postGraduationMaxMarks || "Not Uploaded"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Marks Obtained
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.postGraduationMarksObtain ||
                                    "Not Uploaded"}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Percentage
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.postGraduationPercentage ||
                                    "Not Uploaded"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Roll No
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.postGraduationRollNo || "Not Uploaded"}
                                </td>
                              </tr>
                              <br></br>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  </Box>
                )}

                {tabValue === 3 && isDocsTabEnabled && (
                  <Box p={3}>
                    <div className="card-header bg-transparent border-0 ">
                      <hr />

                      <div class="card-body pt-0">
                        <table className="table table-bordered">
                          <tbody>
                            {relevantDocuments.includes("applicantPhoto") && (
                              <tr>
                                <th>Applicant Photo</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.applicantPhoto ? (
                                    <a
                                      href={userData.Documents.applicantPhoto}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Photo
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes(
                              "applicantSignature"
                            ) && (
                              <tr>
                                <th>Applicant Signature</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.applicantSignature ? (
                                    <a
                                      href={
                                        userData.Documents.applicantSignature
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Signature
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}

                            {relevantDocuments.includes("marksheet10th") && (
                              <tr>
                                <th>10th Marksheet</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.marksheet10th ? (
                                    <a
                                      href={userData.Documents.marksheet10th}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Marksheet
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes("marksheet12th") && (
                              <tr>
                                <th>12th Marksheet</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.marksheet12th ? (
                                    <a
                                      href={userData.Documents.marksheet12th}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Marksheet
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes(
                              "undergraduateCertificate"
                            ) && (
                              <tr>
                                <th>Undergraduate Certificate</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents
                                    ?.undergraduateCertificate ? (
                                    <a
                                      href={
                                        userData.Documents
                                          .undergraduateCertificate
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Certificate
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}

                            {relevantDocuments.includes("ItImarksheet") && (
                              <tr>
                                <th>ITI Marksheet</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.ItImarksheet ? (
                                    <a
                                      href={userData.Documents.ItImarksheet}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Marksheet
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes("diplomaMarksheet") && (
                              <tr>
                                <th>Diploma Marksheet</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.diplomaMarksheet ? (
                                    <a
                                      href={userData.Documents.diplomaMarksheet}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Marksheet
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}

                            {relevantDocuments.includes("aadharCard") && (
                              <tr>
                                <th>Aadhar Card</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.aadharCard ? (
                                    <a
                                      href={userData.Documents.aadharCard}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Aadhar
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes(
                              "migrationCertificate"
                            ) && (
                              <tr>
                                <th>Migration</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.migrationCertificate ? (
                                    <a
                                      href={
                                        userData.Documents.migrationCertificate
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Migration
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes(
                              "domicileCertificate"
                            ) && (
                              <tr>
                                <th>Domicile Certificate</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.domicileCertificate ? (
                                    <a
                                      href={
                                        userData.Documents.domicileCertificate
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Certificate
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes(
                              "transferCertificate"
                            ) && (
                              <tr>
                                <th>Transfer Certificate</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.transferCertificate ? (
                                    <a
                                      href={
                                        userData.Documents.transferCertificate
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Marksheet
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes(
                              "incomeCertificate"
                            ) && (
                              <tr>
                                <th>Income Certificate</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.incomeCertificate ? (
                                    <a
                                      href={
                                        userData.Documents.incomeCertificate
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Certificate
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes("otherCertificate") && (
                              <tr>
                                <th>Other Certificate</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.otherCertificate ? (
                                    <a
                                      href={userData.Documents.otherCertificate}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Certificate
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default StudentAllDetail;

// {
//   ["12th", "Diploma", "ITI"].includes(userData?.qualification) && (
//     <>
//       <tr style={{ fontSize: "20px" }}>
//         <b>
//           {userData?.qualification === "ITI"
//             ? "ITI"
//             : userData?.qualification === "Diploma"
//             ? "Diploma"
//             : "12th"}
//         </b>
//       </tr>
//     </>
//   );
// }
