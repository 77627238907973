import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import {
  Box,
  CardContent,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Checkbox,
} from "@mui/material";
import { Form, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import sssutms from "../../../Main_Admin/images/sssutms.jpg";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import StudentDashboard from "../../../AdminComponent/StudentDashboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import OnlyHeader from "../../../AdminComponent/OnlyHeader";

const theme = createTheme({
  typography: {
    fontWeightBold: 700,
  },
});

function Std_ExamForm() {
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [examForms, setExamForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ischecked, setIschecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiSuccess, setIsApiSuccess] = useState(false);
  const [ischecked2, setIschecked2] = useState(false);

  const [selectedSubjects, setSelectedSubjects] = useState({
    theory: null,
    practical: null,
  });

  const [compulsorySubjects, setCompulsorySubjects] = useState([]);
  const [nonCompulsorySubjects, setNonCompulsorySubjects] = useState([]);
  const [formData, setFormData] = useState(null);
  const [formData2, setFormData2] = useState(null);

  const userData = JSON.parse(sessionStorage.getItem("currentUser"));

  const handleSaveSubjects = async () => {
    setIsLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));

      const { theory, practical } = selectedSubjects;
      const selectedNonCompulsorySubjects = nonCompulsorySubjects
        .filter(
          (subject) =>
            (subject.subjectType === "Theory" &&
              selectedSubjects.theory?.subjectCode === subject.subjectCode) ||
            (subject.subjectType === "Practical" &&
              selectedSubjects.practical?.subjectCode === subject.subjectCode)
        )
        .map((subject) => ({
          subjectCode: subject.subjectCode,
          subjectName: subject.subjectName,
          subjectType: subject.subjectType,
        }));

      const updatedSubjects = [
        ...compulsorySubjects,
        ...selectedNonCompulsorySubjects,
      ];

      // API call
      const response = await fetch(
        "https://sssutms.ac.in/apitest/student/forward-form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            studentId: userData._id,
            examFormId: examId,
            subjects: updatedSubjects,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save subjects");
      }

      const responseData = await response.json();
      console.log("API response:", responseData);

      setIsApiSuccess(true);
    } catch (error) {
      console.error("Error saving subjects:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckbox = (e) => {
    if (e.target.checked) {
      // Check if there are any non-compulsory subjects
      const nonCompulsorySubjectsExist = nonCompulsorySubjects.length > 0;
      const anyNonCompulsorySubjectSelected = nonCompulsorySubjects.some(
        (subject) =>
          (subject.subjectType === "Theory" &&
            selectedSubjects.theory?.subjectCode === subject.subjectCode) ||
          (subject.subjectType === "Practical" &&
            selectedSubjects.practical?.subjectCode === subject.subjectCode)
      );

      if (nonCompulsorySubjectsExist && !anyNonCompulsorySubjectSelected) {
        swal({
          title: "Warning!",
          text: "Please select elective subjects before proceeding.",
          icon: "warning",
          button: "Ok",
        });

        setIschecked2(false);
        return;
      }
    }

    setIschecked2(e.target.checked);
    if (e.target.checked) {
      handleSaveSubjects();
    }
  };

  // ////////////////////////////////////////////////////////elective/////////////////////////////////////////////////////////

  useEffect(() => {
    // Initialize selectedSubjects with compulsory subjects that are checked by default
    const checkedCompulsorySubjects = compulsorySubjects
      .filter((subject) => subject.isChecked) // Filter compulsory subjects that are checked by default
      .map((subject) => ({
        subjectName: subject.subjectName,
        subjectType: subject.subjectType,
        subjectCode: subject.subjectCode,
      }));

    // Update state with the checked compulsory subjects
    setSelectedSubjects((prev) => ({
      ...prev,
      compulsory: checkedCompulsorySubjects,
    }));
  }, [compulsorySubjects]); // Runs when compulsorySubjects changes

  const handleCheckboxChange = (subject, event) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      // If the checkbox is checked
      if (subject.subjectType === "Theory") {
        setSelectedSubjects((prev) => ({
          ...prev,
          theory: subject, // Add the theory subject
          practical: {
            subjectName: `${subject.subjectName} Practical`,
            subjectType: "Practical",
            subjectCode: `${subject.subjectCode}`, // Automatically add the corresponding practical subject
          }, // Automatically add the corresponding practical subject
        }));
      } else if (subject.subjectType === "Practical") {
        setSelectedSubjects((prev) => ({
          ...prev,
          practical: subject, // Add the practical subject
          theory: {
            subjectName: `${subject.subjectName} Theory`,
            subjectType: "Theory",
            subjectCode: `${subject.subjectCode}`, // Automatically add the corresponding theory subject
          }, // Automatically add the corresponding theory subject
        }));
      }
    } else {
      // If the checkbox is unchecked
      if (subject.subjectType === "Theory") {
        setSelectedSubjects((prev) => ({
          ...prev,
          theory: null, // Remove the theory subject
          practical:
            prev.practical?.subjectName === `${subject.subjectName} Practical`
              ? null
              : prev.practical, // Remove the practical subject if it's related
        }));
      } else if (subject.subjectType === "Practical") {
        setSelectedSubjects((prev) => ({
          ...prev,
          practical: null, // Remove the practical subject
          theory:
            prev.theory?.subjectName === `${subject.subjectName} Theory`
              ? null
              : prev.theory, // Remove the theory subject if it's related
        }));
      }
    }
  };

  // Checkbox rendering for both compulsory and non-compulsory subjects
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const fetchExamForms = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v1/getExamForms?studentId=${userData._id}`
        );
        const result = await response.json();
        if (response.ok) {
          setExamForms(result.data);
        } else {
          console.error(result.message);
        }
      } catch (error) {
        console.error("Error fetching exam forms:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchExamForms();
  }, []);

  ///////////////////////////////////////////////for optional ////////////////////////////////////////////////////////////////

  const getSortedAndSlicedData = () => {
    const sortedData = [...examForms].sort((a, b) => {
      if (sortBy === "name") {
        return sortOrder === "asc"
          ? a.branchName.localeCompare(b.branchName)
          : b.branchName.localeCompare(a.branchName);
      }
      return 0;
    });
    const startIndex = page * rowsPerPage;
    return sortedData.slice(startIndex, startIndex + rowsPerPage);
  };

  const [show, setShow] = useState(false);

  // Handlers for Modal
  const handleClose = () => setShow(false);

  const handleShow = async (examId) => {
    // const fetchSubjects = async () => {
    try {
      const response = await fetch(
        `https://sssutms.ac.in/apitest/v1/student/exam-form5?studentId=${userData._id}&examFormId=${examId._id}&courseName=${userData.courseName}&courseBranch=${userData.courseBranch}&semesterNumber=${userData.currentSemester}`
      );
      const result = await response.json();
      console.log(result, "is result me dekh kya hai");

      if (response.ok) {
        // Filter subjects where compulsory is true and false
        const compulsorySubjects = result.subjects.filter(
          (subject) => subject.compulsory === true
        );
        const nonCompulsorySubjects = result.subjects.filter(
          (subject) => subject.compulsory === false
        );
        console.log(compulsorySubjects, "subjecttt");
        console.log(nonCompulsorySubjects, "dekh subjectt");
        // Set the filtered subjects into their respective states
        setCompulsorySubjects(compulsorySubjects);
        setNonCompulsorySubjects(nonCompulsorySubjects);

        // Optionally, you can set other data as well
        setFormData({ ...result, subjects: compulsorySubjects });
        setShow(true);
      } else {
        console.log(result.message);
      }
    } catch (error) {
      console.log("Error fetching subjects:", error);
    }
    // };
    // fetchSubjects();
  };

  // Handlers for Modal
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const handleShow1 = async (examId) => {
    try {
      const response = await fetch(
        `https://sssutms.ac.in/apitest/v1/student/exam-form2?examFormId=${examId._id}&id=${userData._id}`
      );
      const result = await response.json();

      setFormData2(result);
      setShow1(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const generatePDF = () => {
    const doc = new jsPDF();

    // Add University Logo
    doc.addImage(sssutms, "PNG", 10, 10, 20, 20);

    // University Header
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.text(
      "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
      35,
      15
    );

    // Add Course Name
    doc.setFontSize(11);
    doc.setFont("helvetica");
    doc.text(`${userData?.courseName?.toUpperCase()}`, 105, 23, {
      align: "center",
    });

    // Add header below course name
    doc.setFontSize(10);
    doc.text(`${userData.courseBranch}- EXAM FORM: DEC-2024`, 105, 31, {
      align: "center",
    });

    const infoStartY = 40;

    // Helper function to handle long text and adjust line breaks
    const writeText = (text, x, y, maxWidth = 45) => {
      const lines = doc.splitTextToSize(text, maxWidth);
      lines.forEach((line, index) => {
        doc.text(line, x, y + index * 6);
      });
    };

    // Enrollment No.
    doc.setFont("helvetica", "bold");
    doc.text("Enrollment No:", 15, infoStartY);
    doc.setFont("helvetica", "normal");
    doc.text(`${userData.enrollmentNumber}`, 48.5, infoStartY);

    // Candidate Name
    doc.setFont("helvetica", "bold");
    doc.text("Candidate Name:", 15, infoStartY + 6);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(9);
    writeText(
      `${userData.name}` || "Name Not Available",
      48.5,
      infoStartY + 6,
      80
    );
    doc.setFontSize(11);
    // Father's Name
    doc.setFont("helvetica", "bold");
    doc.text("Father's Name:", 108, infoStartY + 6);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(9);
    writeText(
      `${userData.fathersname}` || "Father's Name Not Available",
      140,
      infoStartY + 6,
      50
    );
    doc.setFontSize(11);

    // Semester
    doc.setFont("helvetica", "bold");
    doc.text("Semester:", 15, infoStartY + 12);
    doc.setFont("helvetica", "normal");
    doc.text(`${userData.currentSemester}`, 49, infoStartY + 12);

    // Status
    doc.setFont("helvetica", "bold");
    doc.text("Status:", 108, infoStartY + 12);
    doc.setFont("helvetica", "bold");
    doc.text("Regular", 140, infoStartY + 12);

    // Subjects Table
    const subjectRows = formData2.subjects.map((subject, index) => [
      index + 1,
      subject.subjectCode,
      subject.subjectName,
      subject.subjectType,
    ]);

    doc.autoTable({
      startY: infoStartY + 19,
      head: [["Sr. No.", "Subject Code", "Subject Name", "Subject Type"]],
      body: subjectRows,
      styles: { fontSize: 7.5, cellPadding: 2 },
      theme: "grid",
    });

    // Candidate Declaration
    const declarationStartY = doc.lastAutoTable.finalY + 10;
    doc.setFont("helvetica", "bold");
    doc.text("CANDIDATE’S DECLARATION:", 14, declarationStartY);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(9)
    const declaration = [
      "I certify that the information provided is correct, and I am responsible for its accuracy.",
      "I am a regular candidate for the examination conducted by Sri Satya Sai University of Technology and Medical Sciences, Sehore.",
      "I am not enrolled as a regular student at any other institution or appearing in any other examination.",
      "If the provided information is found false, my exam result will be canceled.",
      "I assure to complete the required attendance and sessional work for my course.",
      "I agree to abide by all rules and regulations set by the institution and the university.",
      "I have read and fulfilled all requirements outlined in the relevant ordinance and have no objection to appearing in the exam.",
      "I request the university not to disclose my results without my written consent, including under RTI.",
    ];

    const splitLines = declaration.map((line) =>
      doc.splitTextToSize(`• ${line}`, 180)
    );
    let currentY = declarationStartY + 6;
    splitLines.forEach((lines) => {
      lines.forEach((subLine) => {
        doc.text(subLine, 18, currentY);
        currentY += 6;
      });
    });

    doc.setFontSize(11);

    // Examination Fee Details
    const feeDetailsRows = formData2.Exam_fee.map((fee, index) => [
      index + 1,
      fee.txnId,
      new Date(fee.txnDate).toLocaleString(),
      `${fee.txnAmount}`,
    ]);

    const feeDetailsStartY = currentY + 3;

    doc.setFont("helvetica", "bold");
    doc.text("EXAMINATION FEE DETAILS :", 14, feeDetailsStartY);

    doc.autoTable({
      startY: feeDetailsStartY + 3,
      head: [["Sr. No.", "Transaction No.", "Paid On", "Exam Fee"]],
      body: feeDetailsRows,
      styles: { fontSize: 10, cellPadding: 2 },
      theme: "grid",
    });

    currentY = doc.lastAutoTable.finalY + 10;

    // Add Student Signature (if available)
    if (userData.applicantSignature) {
      const img = new Image();
      img.src = userData.applicantSignature;
      doc.addImage(img, "PNG", 150, currentY, 40, 20);
    }

    // Controller of Examination Signature placeholder
    doc.setFont("helvetica", "bold");
    doc.text("Student Signature", 160, currentY + 4);

    // Save PDF
    doc.save(`${userData.name} ExaminationForm.pdf`);
  };

  //////////////////////////////////////////////////////////////////////

  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === "[object Date]";
  }

  function isObj(val) {
    return typeof val === "object";
  }

  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }
  function buildForm({ action, params }) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(params)?.forEach((key) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", stringifyValue(params[key]));
      form.appendChild(input);
    });
    return form;
  }
  function post(details) {
    const form = buildForm(details);
    document.body.appendChild(form);
    setLoading(false);
    form.submit();
    form.remove();
  }

  const getData = (data) => {
    setLoading(false);
    return fetch("https://sssutms.ac.in/apitest/exam-form/payment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .catch((err) => console.log(err));
  };

  const id = userData.randomId;
  const id2 = userData._id;
  const name = userData.name;
  const email = userData.email;
  const course = userData.courseName;

  const examId = examForms[0]?.examFormId._id;

  const handlePayment = (e) => {
    <OnlyHeader />;
    setLoading(true);
    setTimeout(() => {
      getData({ amount: 1830, id, id2, name, course, examId }).then(
        (response) => {
          var information = {
            action: "https://securegw.paytm.in/order/process",
            params: response,
          };
          console.log(response);
          post(information);
        }
      );
    }, 1000);
  };
  return (
    <ThemeProvider theme={theme}>
      <StudentDashboard />
      <>
        <Box sx={{ width: "90%", marginLeft: "80px", marginTop: "50px" }}>
          {loading ? (
            <CircularProgress
              color="success"
              style={{ marginTop: "80px", marginLeft: "50%" }}
            />
          ) : (
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ justifyContent: "center", textAlign: "center" }}
              >
                Examination Form
              </Typography>
              <Paper sx={{ width: "105%", overflow: "auto" }}>
                <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                  <Typography
                    variant="button"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    <strong>Enrollment No:</strong> {userData?.enrollmentNumber}
                  </Typography>
                  <Typography
                    variant="button"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    <b> Candidate Name:</b> {userData?.name}
                  </Typography>
                  <Typography
                    variant="button"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    <b> Father Name: </b>
                    {userData?.fathersname}
                  </Typography>
                </Box>

                <TableContainer sx={{ maxHeight: 450 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            left: 0,
                            color: "white",
                          }}
                        >
                          S.No.
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            minWidth: "100px",
                            left: 50,
                            color: "white",
                          }}
                        >
                          Session
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Session Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                            minWidth: "70px",
                          }}
                        >
                          Semster/Year
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            minWidth: "100px",
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Category
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            minWidth: "200px",
                            color: "white",
                          }}
                        >
                          Form Status Account
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            minWidth: "150px",
                            color: "white",
                          }}
                        >
                          Click to show
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getSortedAndSlicedData().map((form, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align="left"
                            style={{
                              position: "sticky",
                              top: 0,
                              left: 0,
                              zIndex: 1,
                              backgroundColor: "white",
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              position: "sticky",
                              left: 50,
                              top: 0,
                              fontWeight: 700,
                              zIndex: 1,

                              backgroundColor: "white",
                              color: "red",
                            }}
                          >
                            {form.examFormId.session || "N/A"}
                          </TableCell>

                          <TableCell align="left">
                            {form.examFormId.sessionName || "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            {form.examFormId.semester || "N/A"}
                          </TableCell>

                          <TableCell
                            align="left"
                            sx={{
                              fontWeight: "bold",
                              position: "sticky",
                              left: 210,
                              backgroundColor: "white",
                            }}
                          >
                            {form.examFormId.category || "N/A"}
                          </TableCell>

                          <TableCell align="left">
                            {form.isForwarded === true
                              ? "Forwarded"
                              : "Form Not Forwarded"}
                          </TableCell>
                          <Button
                            variant="contained"
                            onClick={() => {
                              if (!form.isForwarded) {
                                // Show SweetAlert if form is not forwarded
                                swal({
                                  icon: "warning",
                                  title: "Form Not Forwarded",
                                  text: "Your form has not been forwarded. Please contact to the Department.",
                                  buttons: "OK",
                                });
                              } else if (form.isFilled && form.isPaid) {
                                handleShow1(form.examFormId);
                              } else {
                                handleShow(form.examFormId, form.studentId);
                              }
                            }}
                          >
                            {form.isFilled && form.isPaid
                              ? "Print Exam Form"
                              : "Show Form"}
                          </Button>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CardContent>
          )}

          <br></br>
        </Box>
        {/* //////////////////////////////////forward form model///////////////////////// */}
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          centered
          style={{ marginTop: "40px" }}
        >
          <Modal.Header closeButton style={{ color: "rgb(2, 48, 71)" }}>
            <Modal.Title
              variant="button"
              style={{
                height: "8px",
                marginTop: "2px",
                fontFamily: "Poppins,sans-serif",
              }}
            >
              Examination Form
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div id="exam-form" style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={sssutms}
                  alt="Logo"
                  style={{ width: "100px", marginRight: "20px" }}
                />
                <div>
                  <Typography
                    style={{ margin: 0, fontSize: "15px", fontWeight: "bold" }}
                  >
                    SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES,
                    SEHORE
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{ margin: 0, marginLeft: "90px" }}
                  >
                    ( {userData.courseBranch})
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ margin: 0, marginLeft: "160px" }}
                  >
                    Session: {examForms[0]?.examFormId?.sessionName}
                  </Typography>
                </div>
              </div>

              <Form>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <Form.Label>Enrollment No: </Form.Label>
                    <span> {userData?.enrollmentNumber}</span>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Semester/Year: </Form.Label>
                    <span> {examForms[0]?.examFormId?.semester}</span>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <Form.Label>Candidate's Name: </Form.Label>
                    <span> {userData?.name}</span>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Father's Name:</Form.Label>
                    <span> {userData?.fathersname}</span>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Mother's Name: </Form.Label>
                    <span> {userData?.mothersname}</span>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Status: </Form.Label>
                    <span> {examForms[0]?.examFormId?.category}</span>
                  </Form.Group>
                </Row>

                <Table striped bordered hover>
                  <TableHead
                    style={{
                      backgroundColor: "rgba(224, 224, 224, 1)",
                    }}
                  >
                    <TableRow
                      sx={{
                        height: "30px",
                      }}
                    >
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        S.No
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Subject Code
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Subject Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Subject Type
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Elective
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {compulsorySubjects
                      .concat(nonCompulsorySubjects)
                      .sort((a, b) => {
                        if (
                          a.subjectType === "Theory" &&
                          b.subjectType !== "Theory"
                        )
                          return -1;
                        if (
                          a.subjectType !== "Theory" &&
                          b.subjectType === "Theory"
                        )
                          return 1;
                        return 0;
                      })
                      .map((subject, index) => {
                        // Logic for selecting corresponding subjects automatically based on subjectName
                        const isTheorySelected =
                          selectedSubjects.theory?.subjectName ===
                          subject.subjectName;
                        const isPracticalSelected =
                          selectedSubjects.practical?.subjectName ===
                          subject.subjectName;

                        const isSelected =
                          subject.compulsory ||
                          isTheorySelected ||
                          isPracticalSelected ||
                          (subject.subjectType === "Theory" &&
                            selectedSubjects.practical?.subjectName ===
                              `${subject.subjectName} Practical`) ||
                          (subject.subjectType === "Practical" &&
                            selectedSubjects.theory?.subjectName ===
                              `${subject.subjectName} Theory`);

                        return (
                          <TableRow
                            key={`subject-${index}`}
                            sx={{ height: "10px" }}
                          >
                            <TableCell
                              sx={{ padding: "0px 10px", fontSize: "0.85rem" }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              sx={{ padding: "0px 10px", fontSize: "0.85rem" }}
                            >
                              {subject.subjectCode}
                            </TableCell>
                            <TableCell
                              sx={{ padding: "0px 10px", fontSize: "0.85rem" }}
                            >
                              {subject.subjectName}
                            </TableCell>
                            <TableCell
                              sx={{ padding: "0px 10px", fontSize: "0.85rem" }}
                            >
                              {subject.subjectType}
                            </TableCell>
                            <TableCell
                              sx={{ padding: "0px 10px", fontSize: "0.85rem" }}
                            >
                              <Checkbox
                                sx={{ transform: "scale(0.7)" }}
                                checked={isSelected}
                                disabled={subject.compulsory} // Compulsory subjects should be disabled
                                color="primary"
                                onChange={(e) =>
                                  handleCheckboxChange(subject, e)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>

                <div style={{ marginTop: "20px" }}>
                  <Typography variant="h6">CANDIDATE'S DESCRIPTION</Typography>
                  <hr
                    style={{
                      width: "40%",
                      height: "2px",
                      backgroundColor: "gray",
                      margin: "-2px 0",
                    }}
                  />
                  <Typography variant="body2" gutterBottom>
                    <ul style={{ marginLeft: "20px", marginTop: "10px" }}>
                      <li>
                        I certify that this application has been filled by me
                        and the information given their in is correct and I
                        shall be provisionally responsible for the same proved
                        false later on.
                      </li>
                      <li>
                        I also certify that i am apperaing as regularcandidate
                        only in the examination being conducted by the Sri Satya
                        Sai University of Technology and Medical Sciences,
                        Sehore.
                      </li>
                      <li>
                        I further d eclare that i am neither a regular student
                        of any other Education Instituation on apperaing in any
                        other examination as regular candidate.
                      </li>
                      <li>
                        I understand that if it is found later on that the
                        information furnished above is false then my result of
                        examination will bw cancelled
                      </li>
                      <li>
                        I assure that I will complete the require attendence and
                        sessional work prescribed for thye course of my
                        registration. Kindly permit me to appear in this
                        examination.
                      </li>
                      <li>
                        I accept to abide by all the rules and regulations of
                        study and examination as prescribed by the institution &
                        Sri Satya Sai University of Technology and Medical
                        Sciences, Sehore
                      </li>
                      <li>
                        I have read relevant ordinance applicable to be and have
                        completed all the requirements as given in it. I have
                        completed my studies and have no objection in appearing
                        in examination on the date declared by the university
                      </li>
                      <li>
                        <b>
                          I also requesting to the University that without my
                          written concent, do not disclose my result to anybody
                          in any manner including RTI.
                        </b>
                      </li>
                    </ul>
                    <Checkbox checked={ischecked2} onChange={handleCheckbox} />{" "}
                    I accept all above points.
                  </Typography>
                  {ischecked2 && (
                    <div
                      style={{
                        padding: "15px 20px",
                        marginTop: "-4%",
                        backgroundColor: "#f9f9f9",
                        border: "1px solid #ddd",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        Total Fees to Pay ₹ 1830 Only{" "}
                      </Typography>
                      {isLoading ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <CircularProgress size={24} />
                          <Typography>Loading...</Typography>
                        </div>
                      ) : (
                        isApiSuccess && (
                          <Button
                            style={{
                              backgroundColor: "rgb(2,48,71)",
                              color: "white",
                            }}
                            onClick={handlePayment}
                          >
                            PAY NOW
                          </Button>
                        )
                      )}
                    </div>
                  )}
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
        {/* //////////////////////////////////forward form model///////////////////////// */}

        {/* //////////////////////////////////Print form model///////////////////////// */}

        <Modal
          show={show1}
          onHide={handleClose1}
          size="lg"
          centered
          style={{ marginTop: "40px" }}
        >
          <Modal.Header closeButton style={{ color: "rgb(2, 48, 71)" }}>
            <Modal.Title
              variant="button"
              style={{
                height: "8px",
                marginTop: "2px",
                fontFamily: "Poppins,sans-serif",
              }}
            >
              Examination Form

            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div id="exam-form" style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={sssutms}
                  alt="Logo"
                  style={{ width: "100px", marginRight: "20px" }}
                />
                <div>
                  <Typography
                    style={{ margin: 0, fontSize: "15px", fontWeight: "bold" }}
                  >
                    SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES,
                    SEHORE
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{ margin: 0, textAlign: "center" }}
                  >
                    ( {userData.courseBranch})
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ margin: 0, textAlign: "center" }}
                  >
                    Session: {examForms[0]?.examFormId?.sessionName}
                  </Typography>
                </div>
              </div>

              <Form>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <Form.Label>Enrollment No: </Form.Label>
                    <span> {userData?.enrollmentNumber}</span>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Semester/Year: </Form.Label>
                    <span> {examForms[0]?.examFormId?.semester}</span>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <Form.Label>Candidate's Name: </Form.Label>
                    <span> {userData?.name}</span>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Father's Name:</Form.Label>
                    <span> {userData?.fathersname}</span>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Mother's Name: </Form.Label>
                    <span> {userData?.mothersname}</span>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Status: </Form.Label>
                    <span> {examForms[0]?.examFormId?.category}</span>
                  </Form.Group>
                </Row>

                <Table striped bordered hover>
                  <TableHead
                    style={{
                      backgroundColor: "rgba(224, 224, 224, 1)",
                    }}
                  >
                    <TableRow
                      sx={{
                        height: "30px",
                      }}
                    >
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Sr.No
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Subject Code
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Subject Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Subject Type
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {formData2?.subjects?.map((subject, index) => (
                      <TableRow key={index} sx={{ height: "30px" }}>
                        <TableCell
                          sx={{ padding: "4px 8px", fontSize: "0.85rem" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "4px 8px", fontSize: "0.85rem" }}
                        >
                          {subject.subjectCode}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "4px 8px", fontSize: "0.85rem" }}
                        >
                          {subject.subjectName}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "4px 8px", fontSize: "0.85rem" }}
                        >
                          {subject.subjectType}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <div style={{ marginTop: "20px" }}>
                  <Typography variant="h6">CANDIDATE'S DESCRIPTION</Typography>
                  <hr
                    style={{
                      width: "40%",
                      height: "2px",
                      backgroundColor: "gray",
                      margin: "-2px 0",
                    }}
                  />
                  <Typography variant="body2" gutterBottom>
                    <ul style={{ marginLeft: "20px", marginTop: "10px" }}>
                      <li>
                        I certify that this application has been filled by me
                        and the information given their in is correct and I
                        shall be provisionally responsible for the same proved
                        false later on.
                      </li>
                      <li>
                        I also certify that i am apperaing as regularcandidate
                        only in the examination being conducted by the Sri Satya
                        Sai University of Technology and Medical Sciences,
                        Sehore.
                      </li>
                      <li>
                        I further d eclare that i am neither a regular student
                        of any other Education Instituation on apperaing in any
                        other examination as regular candidate.
                      </li>
                      <li>
                        I understand that if it is found later on that the
                        information furnished above is false then my result of
                        examination will bw cancelled
                      </li>
                      <li>
                        I assure that I will complete the require attendence and
                        sessional work prescribed for thye course of my
                        registration. Kindly permit me to appear in this
                        examination.
                      </li>
                      <li>
                        I accept to abide by all the rules and regulations of
                        study and examination as prescribed by the institution &
                        Sri Satya Sai University of Technology and Medical
                        Sciences, Sehore
                      </li>
                      <li>
                        I have read relevant ordinance applicable to be and have
                        completed all the requirements as given in it. I have
                        completed my studies and have no objection in appearing
                        in examination on the date declared by the university
                      </li>
                      <li>
                        <b>
                          I also requesting to the University that without my
                          written concent, do not disclose my result to anybody
                          in any manner including RTI.
                        </b>
                      </li>
                    </ul>
                  </Typography>
                </div>
              </Form>
            </div>
          </Modal.Body>
          <div
            style={{
              padding: "15px 20px",
              marginTop: "-4%",
              backgroundColor: "#f9f9f9", // Light background
              border: "1px solid #ddd", // Border around the box
              borderRadius: "8px", // Rounded corners
            }}
          >
            <>
              <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                EXAMINATION FEE DETAILS
              </Typography>
              <hr
                style={{
                  width: "30%",
                  height: "2px",
                  backgroundColor: "#333",
                  margin: "-2px 0 10px 0",
                }}
              />
              {formData2?.Exam_fee?.map((fee, index) => (
                <Typography key={index} variant="subtitle2" gutterBottom>
                  <strong>Transaction No:</strong> {fee.txnId},{" "}
                  <strong>Paid On:</strong>{" "}
                  {new Date(fee.txnDate).toLocaleString()},{" "}
                  <strong>Exam Fee:</strong> ₹{fee.txnAmount}
                </Typography>
              ))}
            </>
          </div>

          {ischecked && (
            <div
              style={{
                padding: "15px 20px",
                marginTop: "-4%",
                backgroundColor: "#f9f9f9",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography variant="h6" gutterBottom>
                Total Fees to Pay ₹ 1830 Only{" "}
                <Button
                  style={{ backgroundColor: "rgb(2,48,71)", color: "white" }}
                  onClick={() => handlePayment()}
                >
                  PAY NOW
                </Button>
              </Typography>
            </div>
          )}
          <Modal.Footer>
            <Button variant="text" onClick={handleClose1}>
           
              Close
            </Button>
            <Button variant="outlined" onClick={generatePDF}>
              Download as PDF
            </Button>
          </Modal.Footer>
        </Modal>

        {/* //////////////////////////////////Print form model///////////////////////// */}
      </>
    </ThemeProvider>
  );
}
export default Std_ExamForm;
