import React, { useState } from "react";
import { FormControl } from "@mui/material";
import { Tabs, Tab, Button, Row } from "react-bootstrap";
import Examsessiontable from "./Exam_Session_Table";
import axios from "axios";
import swal from "sweetalert"
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";

function Examsessionmaster() {
  const [showTable, setShowTable] = useState(false);
  const [key, setKey] = useState("first");
  const [formData, setFormData] = useState({
    session: "", // For Exam Session
    sessionName: "", // For Session Name (e.g., Session 1, Session 2)
    year: "", // Year for the session
    IsAnnual: "", // Annual or Supplementary
    isSupply: "", // Is this a supply session?
  });
  const [sessions, setSessions] = useState([]);

  console.log(formData, "formdataaaa");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSearch = async () => {
    const { session, sessionName, year, isSupply, IsAnnual } = formData;

    // Validation
    if (!session || !sessionName || !year || !isSupply || !IsAnnual) {
      alert("Please fill all fields first");
      return;
    }

    try {
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/admin/add-session",
        {
          session, // Exam session value
          sessionName, // Name of the session
          year, // Year (e.g., 2023, 2024)
          isSupply, // Is Supply? (True or False)
          IsAnnual, // Annual or Supplementary
        }
      );
      setSessions(response.data.data); // Update sessions state with the fetched data
      alert(response.data.message);
     
      setShowTable(true); // Show the table after a successful API call
    } catch (error) {
      console.error("Error adding session:", error);
      alert("Failed to add session. Please try again.");
    }
  };

  return (
    <>
      <style>
        {`
          .shadow-box {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            padding: 10px;
            margin-top: 20px;
            width: 110%;
            margin-left: 75px;
            height: 350px;
          }
          .custom-tab {
            padding: 5px 10px;
            font-size: 14px;
          }
        `}
      </style>

      <AdminDashboard />

      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "20%",
            height: "550px",
            padding: "10px",
            marginTop: "60px",
          }}
        >
          <div className="shadow-box">
            <h6
              style={{
                fontSize: "15px",
                fontWeight: 700,
                color: "#A02334",
                marginBottom: "8px",
                fontFamily: "'Rubik', sans-serif",
              }}
            >
              Exam Session Master
            </h6>
            <Tabs
              id="admission-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="first" tabClassName="custom-tab">
                <form style={{ padding: "15px", marginTop: "-10px" }}>
                  <Row>
                    <FormControl>
                      <label>Year</label>
                      <select
                        className="form-select form-select-sm login-input"
                        name="year"
                        value={formData.year}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                      </select>
                    </FormControl>
                  </Row>

                  <Row>
                    <label>Session</label>
                    <input
                      type="text"
                      className="form-control"
                      name="session"
                      value={formData.session}
                      onChange={handleInputChange}
                      placeholder="Enter session"
                      required
                    />
                  </Row>

                  <Row>
                    <label>Session Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="sessionName"
                      value={formData.sessionName}
                      onChange={handleInputChange}
                      placeholder="Enter session name"
                      required
                    />
                  </Row>

                  <Row>
                    <FormControl>
                      <label>Annual</label>
                      <select
                        className="form-select form-select-sm login-input"
                        name="IsAnnual"
                        value={formData.IsAnnual}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Annual">Annual</option>
                        <option value="SEMESTER">SEMESTER</option>
                      </select>
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl>
                      <label>Is Supply</label>
                      <select
                        className="form-select form-select-sm login-input"
                        name="isSupply"
                        value={formData.isSupply}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                    </FormControl>
                  </Row>

                  <Row>
                    <Button
                      type="button"
                      style={{
                        marginLeft: "50px",
                        backgroundColor: "#004e92",
                        width: "130px",
                        height: "30px",
                        padding: "2px",
                      }}
                      onClick={handleSearch}
                    >
                      Save
                    </Button>
                  </Row>
                </form>
              </Tab>
            </Tabs>
          </div>
        </div>

        <div>
          <Examsessiontable />
        </div>
      </div>
    </>
  );
}

export default Examsessionmaster;
