import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import axios from "axios";
import { Container, Form } from "react-bootstrap";
import { State, City } from "country-state-city";
import { Progress } from "@chakra-ui/react";
import logo from "../AdminComponent/12.png";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import {
  Box,
  Stack,
  FormControl,
  FormLabel,
  Select,
  Input,
} from "@chakra-ui/react";
import OnlyHeader from "../AdminComponent/OnlyHeader";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
  },
});

const Alumini_Form = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    DOJ: "",
    isPassed: "",
    isPlaced: "",
    address: "",
    Reporting_comany_email: "",
    Reporting_company: "",
    Enrollment_No: "",
    PassingYear: "",
    fathersname: "",
    mothersname: "",
    admissionSession: "",
    mobile: "",
    email: "",
    courseType: "",
    courseName: "",
    courseBranch: "",
    country: "IN",
    state: "",
    city: "",
    Pincode: "",
    jobTittle: "",
    gender: "",
    collegeName: "",
    ifpassed: "",
    DOB: "",
    tc_no: "",
    mig_no: "",
    PassingYear: "",
    feedback_university: "",
    feedback_teacher: "",
    PassedMarksheet: "",
  });

  const [submittedData, setSubmittedData] = useState(null);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isOtherCountry, setIsOtherCountry] = useState(false);

  const [courseData, setCourseData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const session = new Date().getFullYear();
  const [fileUrl, setFileUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v2/admin/courses?session=2025`
        );
        const data = await response.json();

        const formattedData = {};

        data.forEach((course) => {
          if (!formattedData[course.courseType]) {
            formattedData[course.courseType] = {
              courses: [],
              branches: {},
            };
          }

          course.courseNames.forEach((courseName) => {
            formattedData[course.courseType].courses.push(
              courseName.courseName
            );
            formattedData[course.courseType].branches[courseName.courseName] =
              courseName.branches.map((branch) => branch.branchName);
          });
        });

        setCourseData(formattedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  const handleTypeChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      courseType: value,
      courseName: "",
      courseBranch: "",
    }));
  };

  const handleCourseChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      courseName: value,
      courseBranch: "",
    }));
  };

  const handleBranchChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      courseBranch: value,
    }));
  };

  useEffect(() => {
    if (formData.country === "IN") {
      const getAllStates = async () => {
        try {
          const statesData = await State.getStatesOfCountry("IN");
          setStates(statesData);
        } catch (err) {
          console.error("Error fetching states:", err);
        }
      };
      getAllStates();
    } else {
      setStates([]);
    }
  }, [formData.country]);

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    if (name === "state") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        city: "",
      }));
      if (formData.country === "IN") {
        const stateCities = City.getCitiesOfState("IN", value);
        setCities(stateCities);
      }
    } else if (name === "country") {
      if (value === "Other") {
        setIsOtherCountry(true);
      } else {
        setIsOtherCountry(false);
      }
      setFormData((prevData) => ({
        ...prevData,
        country: value,
        state: "",
        city: "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    if (!allowedTypes.includes(file.type)) {
      swal({
        icon: "error",
        title: "Error",
        text: "Uploaded file is not a valid image. Only JPG, PNG and JPEG files are allowed",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "upload");

    setUploading(true);

    try {
      const cloudinaryResponse = await fetch(
        `https://api.cloudinary.com/v1_1/dszawxz7y/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (!cloudinaryResponse.ok) {
        throw new Error("Cloudinary upload failed");
      }
      const cloudinaryData = await cloudinaryResponse.json();
      setFileUrl(cloudinaryData.secure_url);
    } catch (error) {
      console.error("Error uploading file to Cloudinary:", error);
    } finally {
      setUploading(false);
    }
  };

  const finalFormData = {
    ...formData,
    PassedMarksheet: fileUrl,
  };
  console.log(fileUrl, "fileurllllll");

  const generatePDF = async () => {
    const pdf = new jsPDF();

    pdf.setFontSize(10);

    const formatDate = (inputDate) => {
      const date = new Date(inputDate);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    };

    // Adding the logo and heading text
    pdf.addImage(logo, "JPEG", 8, 5, 20, 20);
    pdf.setTextColor(165, 42, 42);
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(11);
    pdf.text(
      "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
      105,
      10,
      { align: "center" }
    );
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(11);
    pdf.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F),1956]",
      105,
      16,
      { align: "center" }
    );
    pdf.text(
      "SH-18, Bhopal-Indore Road, Opposite Oilfed Plant, Pachama,",
      105,
      22,
      { align: "center" }
    );

    pdf.setTextColor(0, 0, 0);
    pdf.setFont("helvetica", "bold");
    pdf.text("ALUMNI FORM", 105, 35, { align: "center" });

    // Underline "ALUMNI FORM"
    const textWidth = pdf.getTextWidth("ALUMNI FORM");
    pdf.setLineWidth(0.5);
    pdf.line(105 - textWidth / 2, 38, 105 + textWidth / 2, 38);

    let yCoordinate = 45; // Adjust starting y-coordinate for table

    const detailsHeaders = ["", ""];
    const detailsData = [
      ["Enrollment Number", formData?.Enrollment_No],
      ["Name", formData.name.toUpperCase()],
      ["Father's Name", formData.fathersname.toUpperCase()],
      ["Mother's Name", formData.mothersname.toUpperCase()],
      ["Gender", formData.gender],
      ["Date of Birth", formatDate(formData.DOB)],
      ["Email", formData.email],
      ["Mobile Number", formData.mobile],
      ["Country", formData.country],
      ["State", formData.state],
      ["City", formData.city],
      ["Address", formData.address],
      ["Pincode", formData.Pincode],
      ["Course Type", formData.courseType],
      ["Course Name", formData.courseName],
      ["Specialization", formData.courseBranch],
      ["Institute", formData.collegeName],
      ["Admission Year", formData.admissionSession],
      ["Teacher Feedback", formData.feedback_teacher],
      ["University Feedback", formData.feedback_university],
    ];

    const tableOptions = {
      startY: yCoordinate,
      margin: { top: 5, bottom: 5 }, // Tighten margins
      columnStyles: { 0: { cellWidth: 65 }, 1: { cellWidth: 120 } }, // Adjust cell widths
      styles: { fontSize: 9, overflow: "linebreak" }, // Reduced font size for table
    };

    // First table
    pdf.autoTable(detailsHeaders, detailsData, tableOptions);

    let finalY = pdf.lastAutoTable.finalY + 10;

    // **Conditional Passed Details**

    pdf.setFont("helvetica", "bold");
    pdf.text("Last Passed Details:", 12, finalY);
    pdf.setFont("helvetica", "normal");

    const detailsData1 = [
      ["Passing Year", formData?.PassingYear || "Not Provided"],
      ["Migration Number", formData.mig_no || "Not Provided"],
      ["Transfer Certificate Number", formData.tc_no || "Not Provided"],
    ];


    pdf.autoTable(detailsHeaders, detailsData1, {
      startY: finalY + 5,
      margin: { top: 5, bottom: 5 },
      columnStyles: { 0: { cellWidth: 65 }, 1: { cellWidth: 120 } },
      styles: { fontSize: 9, overflow: "linebreak" },
    });

    finalY = pdf.lastAutoTable.finalY + 10;

    if (formData.isPlaced === "true") {
      pdf.setFont("helvetica", "bold");
      pdf.text("Placed Details:", 15, finalY);
      pdf.setFont("helvetica", "normal");

      const detailsData2 = [
        ["Company Name", formData.Reporting_company],
        ["Company Email", formData.Reporting_comany_email],
        ["Position", formData.jobTittle],
        ["Joining Date", formData.DOJ],
      ].filter(([, value]) => value); // Only include fields where value exists

      // Third table (Placed Details)
      pdf.autoTable(detailsHeaders, detailsData2, {
        startY: finalY + 5,
        margin: { top: 5, bottom: 5 },
        columnStyles: { 0: { cellWidth: 65 }, 1: { cellWidth: 120 } },
        styles: { fontSize: 9, overflow: "linebreak" },
      });
    }

    // Save the PDF with a dynamic file name
    pdf.save(`${formData?.name} Alumni Form.pdf`);
  };

  const handleSubmit = async () => {
    const {
      name,
      email,
      mobile,
      Enrollment_No,
      courseType,
      courseName,
      courseBranch,
      collegeName,
      fathersname,
      mothersname,
      gender,
      DOB,
      country,
      city,
      address,
      tc_no,
      mig_no,
      PassingYear,
      isPlaced,
      feedback_teacher,
      feedback_university,
      PassedMarksheet,
    } = formData;

    if (!name || name.trim() === "") {
      alert("Name is required!");
      return;
    }
    if (!PassingYear || isNaN(PassingYear)) {
      alert("Please enter a valid Passing Year!");
      return;
    }

    console.log(fileUrl, "value of PassedMarksheet");
    if (!fileUrl || fileUrl.trim() === "") {
      alert("Please upload the Passing Marksheet!");
      return;
    }

    if (!feedback_university || feedback_university.trim() === "") {
      alert("feedback_university is required!");
      return;
    }

    if (!feedback_teacher || feedback_teacher.trim() === "") {
      alert("feedback_teacher is required!");
      return;
    }
    if (!isPlaced || isPlaced.trim() === "") {
      alert("isPlaced is required!");
      return;
    }

    if (!mig_no || mig_no.trim() === "") {
      alert("mig_no is required!");
      return;
    }

    if (!tc_no || tc_no.trim() === "") {
      alert("tc_no is required!");
      return;
    }
    if (!city || city.trim() === "") {
      alert("city is required!");
      return;
    }
    if (!address || address.trim() === "") {
      alert("address is required!");
      return;
    }
    if (!country || country.trim() === "") {
      alert("country is required!");
      return;
    }
    if (!DOB || DOB.trim() === "") {
      alert("DOB  is required!");
      return;
    }
    if (!gender || gender.trim() === "") {
      alert("gender is required!");
      return;
    }
    if (!mothersname || mothersname.trim() === "") {
      alert("mothersname is required!");
      return;
    }
    if (!fathersname || fathersname.trim() === "") {
      alert("fathersname is required!");
      return;
    }

    if (!collegeName || collegeName.trim() === "") {
      alert("collegeName is required!");
      return;
    }
    if (!courseBranch || courseBranch.trim() === "") {
      alert("courseBranch is required!");
      return;
    }
    if (!courseName || courseName.trim() === "") {
      alert("courseName is required!");
      return;
    }
    if (!courseType || courseType.trim() === "") {
      alert("courseType is required!");
      return;
    }

    if (!Enrollment_No || Enrollment_No.trim() === "") {
      alert("Enrollment is required!");
      return;
    }

    if (!email || email.trim() === "") {
      alert("Email is required!");
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      alert("Please enter a valid email!");
      return;
    }

    if (!mobile || mobile.trim() === "") {
      alert("Mobile number is required!");
      return;
    }

    if (mobile.length !== 10) {
      alert("Mobile number must be 10 digits long!");
      return;
    }

    setLoading(true);

    setSubmittedData(formData);
    try {
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/v1/add-alumini",
        finalFormData
      );

      swal({
        title: `Congratulations, ${name}!`,
        content: {
          element: "p",
          attributes: {
            innerHTML: `Your alumni data has been saved successfully. Please check your email for your Alumni ID. Thank you!`,
            style: "color: #05668d; font-size: 18px;",
          },
        },
        icon: "success",
        buttons: {
          download: {
            text: "Download PDF",
            value: "download",
            className: "swal-button--download", // Class for custom styling if needed
          },

          ok: "OK",
        },
      }).then((value) => {
        switch (value) {
          case "download":
            generatePDF();
            navigate("/");
            break;
          default:
            setFormData({
              name: "",
              DOJ: "",
              isPassed: "",
              isPlaced: "",
              address: "",     
              Reporting_comany_email: "",
              Reporting_company: "",
              Enrollment_No: "",
              PassingYear: "",
              fathersname: "",
              mothersname: "",
              admissionSession: "",
              mobile: "",
              email: "",
              courseType: "",
              courseName: "",
              courseBranch: "",
              country: "IN",
              state: "",
              city: "",
              Pincode: "",
              jobTittle: "",
              gender: "",
              collegeName: "",
              ifpassed: "",
              DOB: "",
              tc_no: "",
              mig_no: "",
              feedback_university: "",
              feedback_teacher: "",
            });
            setFileUrl("");

            navigate("/");
        }
      });
    } catch (error) {
      if (error.response) {
        const { status, message } = error.response.data;

        if (status === 400) {
          swal({
            title: "Email Already Exists",
            text: "The email you entered is already registered. Please try with a different email address.",
            icon: "error",
            button: "OK",
          });
        } else {
          swal({
            title: "Submission Failed",
            text: "An error occurred while submitting your data. Please try again.",
            icon: "error",
            button: "OK",
          });
        }
      } else {
        console.error("Error submitting form:", error);
        swal({
          title: "Unexpected Error",
          text: "An unexpected error occurred. Please check your network connection and try again.",
          icon: "error",
          button: "OK",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  ///////////////////////////////////////////////////////////////////////

  const [years, setYears] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearRange = Array.from({ length: 20 }, (_, i) => currentYear - i);
    setYears(yearRange);
  }, []);

  const currentYear = new Date().getFullYear();
  return (
    <>
      <style>
        {`
        .swal-button--download {
  background-color: #05668d;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
}

      .form-background {
  background: url('path/to/your/logo.png') no-repeat center center;
  background-size: cover;
  padding: 20px;
  border-radius: 8px;
  position: relative;
}

.form-container {
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background for the form */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
}

      `}
      </style>

      <ThemeProvider theme={theme} />
      <OnlyHeader />

      <Container
        className="container-overflow shadow p-3 mb-3 bg-body rounded"
        style={{ maxWidth: "90%", margin: "80px auto" }}
        ms
      >
        <Box
          marginBottom={"20px"}
          backgroundColor={"#264653"}
          p={3}
          h={50}
          color="white"
          mt={1}
          borderRadius="md"
          shadow="md"
          textAlign="center"
        >
          ALUMNI FORM
        </Box>

        <Box>
          <Form>
            <Stack direction={["column", "row"]} spacing={4} mt={5}>
              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Enrollment Number <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  size="md"
                  minWidth="280px"
                  name="Enrollment_No"
                  autoComplete="off"
                  onChange={(e) =>
                    setFormData({ ...formData, Enrollment_No: e.target.value })
                  }
                />
              </FormControl>
              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Candidate Name <span style={{ color: "red" }}>*</span>
                </FormLabel>

                <Input
                  style={{ textTransform: "uppercase" }}
                  type="text"
                  size="md"
                  minWidth="280px"
                  autoComplete="off"
                  name="name"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      name: e.target.value.toUpperCase(),
                    })
                  }
                />
              </FormControl>

              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Father's Name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  style={{ textTransform: "uppercase" }}
                  size="md"
                  minWidth="280px"
                  name="fathersname"
                  autoComplete="off"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      fathersname: e.target.value.toUpperCase(),
                    })
                  }
                />
              </FormControl>
            </Stack>
            <Stack direction={["column", "row"]} spacing={4} mt={4} mb={0}>
              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Mother's Name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  style={{ textTransform: "uppercase" }}
                  size="md"
                  minWidth="280px"
                  name="mothersname"
                  autoComplete="off"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      mothersname: e.target.value.toUpperCase(),
                    })
                  }
                />
              </FormControl>
              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Gender <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  name="gender"
                  autoComplete="off"
                  onChange={(e) =>
                    setFormData({ ...formData, gender: e.target.value })
                  }
                >
                  <option selected>Select Gender</option>
                  <option value="MALE">MALE</option>
                  <option value="FEMALE">FEMALE</option>
                  <option value="OTHER">OTHER</option>
                </select>
              </FormControl>

              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Date of Birth <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  size="md"
                  minWidth="280px"
                  type="date"
                  name="DOB"
                  autoComplete="off"
                  onChange={(e) =>
                    setFormData({ ...formData, DOB: e.target.value })
                  }
                />
              </FormControl>
            </Stack>
            <Stack direction={["column", "row"]} spacing={4} mt={4} mb={0}>
              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Email <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  size="md"
                  minWidth="280px"
                  name="email"
                  // placeholder="@gmail.com"
                  autoComplete="off"
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </FormControl>
              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Mobile Number <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  size="md"
                  minWidth="280px"
                  name="mobile"
                  type="number"
                  autoComplete="off"
                  value={formData.mobile}
                  onChange={(e) =>
                    setFormData({ ...formData, mobile: e.target.value })
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid 10-digit mobile number.
                </Form.Control.Feedback>
              </FormControl>

              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Country <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  name="country"
                  onChange={handleSelectChange}
                  value={formData.country}
                >
                  <option selected>Select Country</option>
                  <option value="IN">India</option>
                  <option value="Other">Other</option>
                </Select>
              </FormControl>
            </Stack>
            {isOtherCountry ? (
              <>
                <Stack direction={["column", "row"]} spacing={4} mt={4} mb={0}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      State <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      name="state"
                      onChange={handleSelectChange}
                      value={formData.state}
                      placeholder="Enter your State"
                    />
                  </FormControl>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      City <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      name="city"
                      onChange={handleSelectChange}
                      value={formData.city}
                      placeholder="Enter your City"
                    />
                  </FormControl>
                </Stack>
              </>
            ) : (
              <>
                <Stack direction={["column", "row"]} spacing={4} mt={4} mb={0}>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      State <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      name="state"
                      onChange={handleSelectChange}
                      value={formData.state}
                    >
                      <option value="">Select State</option>
                      {states.map((state) => (
                        <option key={state.isoCode} value={state.isoCode}>
                          {state.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl flex="1">
                    <FormLabel style={{ fontFamily: "sans-serif" }}>
                      City <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Select
                      name="city"
                      onChange={handleSelectChange}
                      value={formData.city}
                    >
                      <option value="">Select City</option>
                      {cities.map((city) => (
                        <option key={city.name} value={city.name}>
                          {city.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </>
            )}
            <br></br>
            <Stack direction={["column", "row"]} spacing={4} mb={0}>
              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Full Address <span style={{ color: "red" }}>*</span>
                </FormLabel>

                <Input
                  size="md"
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  name="address"
                  autoComplete="off"
                  onChange={(e) =>
                    setFormData({ ...formData, address: e.target.value })
                  }
                ></Input>
              </FormControl>

              <FormControl flex="1">
                <FormLabel style={{ fontFamily: "sans-serif" }}>
                  Pin Code<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  size="md"
                  type="number"
                  minWidth="280px"
                  name="Pincode"
                  autoComplete="off"
                  onChange={(e) =>
                    setFormData({ ...formData, Pincode: e.target.value })
                  }
                />
              </FormControl>
            </Stack>
            <br></br>
            <h5 style={{ color: "#c1121f" }}>
              {" "}
              Please indicate all the SSSUTMS college/schools and programs from
              which you graduated:
            </h5>
            <Stack direction={["column", "row"]} spacing={4} mt={5} mb={3}>
              <FormControl flex="1">
                <FormLabel>
                  Course Type <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  name="courseType"
                  value={formData.courseType}
                  onChange={handleTypeChange}
                >
                  <option value="">Select Course Type</option>
                  {Object.keys(courseData).map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl flex="1">
                <FormLabel>
                  Course Name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  name="courseName"
                  value={formData.courseName}
                  onChange={handleCourseChange}
                >
                  <option value="">Select Course </option>
                  {formData.courseType &&
                    courseData[formData.courseType].courses.map((course) => (
                      <option key={course} value={course}>
                        {course}
                      </option>
                    ))}
                </Select>
              </FormControl>

              <FormControl flex="1">
                <FormLabel>
                  Branch <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  name="courseBranch"
                  value={formData.courseBranch}
                  onChange={handleBranchChange}
                >
                  <option value="">Select Branch</option>
                  {formData.courseName &&
                    courseData[formData.courseType].branches[
                      formData.courseName
                    ].map((branch) => (
                      <option key={branch} value={branch}>
                        {branch}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack direction={["column", "row"]} spacing={4} mb={0}>
              <FormControl flex="1">
                <FormLabel>
                  College Name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  style={{ textTransform: "uppercase" }}
                  type="text"
                  size="md"
                  placeHolder="College Name"
                  minWidth="280px"
                  name="collegeName"
                  autoComplete="off"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      collegeName: e.target.value.toUpperCase(),
                    })
                  }
                />
              </FormControl>
              <FormControl flex="1">
                <FormLabel>
                  Admission Year <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <DatePicker
                    selected={
                      formData.admissionSession
                        ? new Date(formData.admissionSession, 0)
                        : null
                    }
                    onChange={(date) =>
                      setFormData({
                        ...formData,
                        admissionSession: date.getFullYear(),
                      })
                    }
                    showYearPicker
                    dateFormat="yyyy"
                    placeholderText="Select Year"
                    maxDate={new Date(currentYear, 0)}
                    customInput={
                      <Input
                        size="md"
                        width="100%"
                        autoComplete="off"
                        style={{ padding: "8px", minWidth: "280px" }}
                      />
                    }
                    autoComplete="off"
                  />
                </div>
              </FormControl>
            </Stack>
            <br></br>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                backgroundColor: "#eaf4f4",
                color: "white",
                height: "60px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginLeft: "10px",
                  width: "100px",
                }}
              >
                <FormLabel
                  style={{
                    fontFamily: "sans-serif",
                    marginBottom: 0,
                    color: "black",
                  }}
                >
                  Passed Details
                  <span style={{ color: "red" }}> *</span>
                </FormLabel>
              </div>
            </div>
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={4}
              mb={2}
              mt={3}
            >
              <>
                <FormControl flex="1">
                  <FormLabel style={{ fontFamily: "sans-serif" }}>
                    Passing Year <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <DatePicker
                      selected={
                        formData.PassingYear
                          ? new Date(formData.PassingYear, 0)
                          : null
                      }
                      onChange={(date) =>
                        setFormData({
                          ...formData,
                          PassingYear: date.getFullYear(),
                        })
                      }
                      showYearPicker
                      dateFormat="yyyy"
                      placeholderText="Select Year"
                      maxDate={new Date(currentYear, 0)} // Restrict to current year or earlier
                      customInput={
                        <Input
                          size="md"
                          width="100%"
                          autoComplete="off"
                          style={{ padding: "8px", minWidth: "280px" }} // Adjust padding and width for consistency
                        />
                      }
                      autoComplete="off"
                    />
                  </div>
                </FormControl>
                <FormControl flex="1">
                  <FormLabel style={{ fontFamily: "sans-serif" }}>
                    Migration No. <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Input
                    size="md"
                    type="number"
                    minWidth="280px"
                    autoComplete="off"
                    name="mig_no"
                    onChange={(e) =>
                      setFormData({ ...formData, mig_no: e.target.value })
                    }
                  />
                </FormControl>
              </>
            </Stack>
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={4}
              mb={2}
              mt={2}
            >
              {" "}
              {/* Changed direction to "column" */}
              <>
                <FormControl flex="1">
                  <FormLabel style={{ fontFamily: "sans-serif" }}>
                    Transfer Certificate No.{" "}
                    <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Input
                    size="md"
                    minWidth="280px"
                    name="tc_no"
                    autoComplete="off"
                    type="number"
                    onChange={(e) =>
                      setFormData({ ...formData, tc_no: e.target.value })
                    }
                  />
                </FormControl>
                <FormControl flex="1">
                  <FormLabel style={{ fontFamily: "sans-serif" }}>
                    Upload Marksheet <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Input
                    type="file"
                    size="md"
                    minWidth="280px"
                    name="PassedMarksheet"
                    onChange={(e) => handleFileChange(e)}
                  />
                  {uploading ? <Progress size="xs" isIndeterminate /> : ""}
                  <span style={{ color: "#3a0ca3" }}>
                    Qualifying marksheet required for admission to the course at
                    SSSUTMS.
                  </span>
                </FormControl>
              </>
            </Stack>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                backgroundColor: "#eaf4f4",
                color: "white",
                height: "60px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginLeft: "10px",
                  width: "100px",
                }}
              >
                <FormLabel
                  style={{
                    fontFamily: "sans-serif",
                    marginBottom: 0,
                    color: "black",
                  }}
                >
                  Is Placed <span style={{ color: "red" }}>*</span>
                </FormLabel>
              </div>

              <Select
                size="md"
                name="isPlaced"
                style={{
                  border: "1px solid black",
                  minWidth: "200px",
                  width: "120px",
                  color: "black",
                  appearance: "none",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "white",
                }}
                onChange={(e) =>
                  setFormData({ ...formData, isPlaced: e.target.value })
                }
              >
                <option value="" selected>
                  Select
                </option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </Select>
            </div>
            {formData.isPlaced === "true" && (
              <>
                <h4
                  className="form-heading"
                  style={{
                    marginTop: "10px",
                    color: "#bb3e03",
                    marginLeft: "20px",
                  }}
                >
                  Employment (optional)
                </h4>
                <br></br>
                <Stack
                  direction={{ base: "column", md: "row" }}
                  spacing={4}
                  mb={0}
                >
                  <FormControl flex="1">
                    <Input
                      size="md"
                      placeHolder="Company/Business Name"
                      minWidth="280px"
                      autoComplete="off"
                      name="Reporting_company"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          Reporting_company: e.target.value,
                        })
                      }
                    />
                  </FormControl>

                  <FormControl flex="1">
                    <Input
                      size="md"
                      placeHolder="Job Title"
                      minWidth="280px"
                      autoComplete="off"
                      name="jobTittle"
                      onChange={(e) =>
                        setFormData({ ...formData, jobTittle: e.target.value })
                      }
                    />
                  </FormControl>
                </Stack>
                <br></br>
                <Stack
                  direction={{ base: "column", md: "row" }}
                  spacing={4}
                  mb={0}
                >
                  <FormControl flex="1">
                    <Input
                      size="md"
                      type="text"
                      placeholder="Joining Date"
                      minWidth="280px"
                      name="DOJ"
                      onChange={(e) =>
                        setFormData({ ...formData, DOJ: e.target.value })
                      }
                      onFocus={(e) => {
                        e.target.type = "date";
                      }}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          e.target.type = "text";
                        }
                      }}
                    />
                  </FormControl>

                  <FormControl flex="1">
                    <Input
                      size="md"
                      autoComplete="off"
                      placeHolder="Company Email"
                      minWidth="280px"
                      name="Reporting_comany_email"
                      emailPattern
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          Reporting_comany_email: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Stack>
              </>
            )}
            <br></br>
            <h5>
              {" "}
              University Feedback <span style={{ color: "red" }}>*</span>
            </h5>
            <textarea
              style={{ marginTop: "10px" }}
              class="form-control"
              id="exampleFormControlTextarea1"
              placeholder="Write your Feedback about University here...."
              name="feedback_university"
              autoComplete="off"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  feedback_university: e.target.value,
                })
              }
            ></textarea>
            <br />
            <h5>
              {" "}
              Teacher Feedback <span style={{ color: "red" }}>*</span>
            </h5>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              placeholder="Example: Which Teacher do you like most?"
              name="feedback_teacher"
              autoComplete="off"
              style={{ marginTop: "10px" }}
              onChange={(e) =>
                setFormData({ ...formData, feedback_teacher: e.target.value })
              }
            ></textarea>
            <Button
              onClick={() => handleSubmit()}
              style={{
                backgroundColor: "#264653",
                marginTop: "10px",
                width: "150px",
              }}
            >
              {loading ? "Loading..." : "Register Now"}
            </Button>{" "}
          </Form>
        </Box>
      </Container>
    </>
  );
};
export default Alumini_Form;
