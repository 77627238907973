import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  InputAdornment,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Search as SearchIcon } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import HodDashboard from "./HodDashboard";
import axios from "axios";
import * as XLSX from "xlsx";

const theme = createTheme({
  typography: {
    fontWeightBold: 700,
  },
});

const TotalEnrolled = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [studentData, setStudentData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [branches, setBranches] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCollege, setSelectedCollege] = useState("");

  const hod = JSON.parse(sessionStorage.getItem("AnkitHOD"));
  const branch1 = hod.Branch || "";
  const course1 = hod.courseName || "";
  const colleges = hod?.colleges || [];
  const courseName = hod?.courseName || "";
  const associate = hod?.Associated_branch || [];

  useEffect(() => {
    const associatedBranches = JSON.parse(
      sessionStorage.getItem("Associated_branch")
    );
    if (associatedBranches) {
      setAllBranches(associatedBranches);
    }
  }, []);

  const fetchData = async (
    branch = branch1,
    course = course1,
    college = selectedCollege
  ) => {
    try {
      const response = await axios.get(
        `https://sssutms.ac.in/apitest/students/enrolledlist?branch=${branch}&course=${course}&college=${college}`
      );

      const data = response.data;

      setStudentData(data.students);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleCollegeChange = (event) => {
    const college2 = event.target.value;
    setSelectedCollege(college2);
  };
  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Effect to handle search filtering whenever searchQuery or studentData changes
  useEffect(() => {
    let filtered = studentData;

    filtered = studentData.filter(
      (student) =>
        student.randomId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.courseType.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.courseName.toLowerCase().includes(searchQuery.toLowerCase()) 
       
    );
    if (selectedCollege) {
      filtered = filtered.filter(
        (student) => student.assignedCollege === selectedCollege
      );
    }
    setFilteredData(filtered);
    setPage(0); // Reset to first page on new search
  }, [searchQuery, studentData]);

  // Handler for search input changes
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handler for branch selection
  const handleChangeBranch = (event) => {
    setSelectedBranch(event.target.value);
  };

  // Handler for course selection
  const handleChangeCourse = (e) => {
    const courseName = e.target.value;
    setSelectedCourse(courseName);

    const filteredCourse = associate.find(
      (course) => course.courseName === courseName
    );
    const filteredBranches = filteredCourse
      ? filteredCourse.branches.map((branch) => branch.branchName)
      : [];

    setBranches(filteredBranches);
    setSelectedBranch(""); // Reset branch selection when course changes
  };

  // Handler for search button click
  const handleSearch = () => {
    fetchData(selectedBranch, selectedCourse);
  };

  // Handlers for pagination controls
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const excelData = filteredData.map((student) => ({
    Admitted_Date: new Date(student.createdAt).toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }),
    StudentType: student.StudentType || "Normal",
    Enrollment_No: student.enrollmentNumber || "Not generated",
    Random_Id: student.randomId,
    Random_Password: student.randomPassword,
    Name: student.name,
    Fathers_Name: student.fathersname,
    Mothers_Name: student.mothersname,
    Email: student.email,
    Mobile: student.mobile,
    Course_Type: student.courseType,
    Course: student.courseName,
    Branch: student.courseBranch,
    Assign_Clg: student.assignedCollege || "Not Assign",
    Enrolled: student.isEnrolled,
    Enrollment_Fee: student.isEnrollPaid,
  }));

  const ExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Enrolled Student");
    XLSX.writeFile(workbook, "Enrolled_Std_Data.xlsx");
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <ThemeProvider theme={theme}>
      <style>
        {`
        .conttable{

            background-color: #e8e5d5;
            width: 90%;
            margin-left: 120px;
            margin-right: auto;
            margin-top: 80px;
            text-align: center;
            max-width: 1200px;

        }


        .login-input{
        width:200px;
        margin-bottom:20px;
        height:35px;}
        @media screen and (max-width: 500px){
          .custom-card-content{
            width: 100%; /* Adjust the percentage as needed */
            max-width: 1200px; /* Set a maximum width */
            margin: 0 auto; /* Center align */
          }

          h4{
            margin-top:90px;
          }
        }

    `}
      </style>
      <HodDashboard />
      <Box sx={{ width: "90%", marginLeft: "100px", marginTop: "100px" }}>
        <Paper sx={{ width: "100%", overflow: "auto" }}>
          {/* <h4 style={{ textAlign: "center" }}>TOTAL ENROLLED STUDENT</h4> */}
          <TextField
            style={{
              float: "right",
              marginRight: "10px",
              marginBottom: "10px",
            }}
            variant="outlined"
            placeholder="Search by Name or ID"
            value={searchQuery}
            onChange={handleSearchChange}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ width: 300 }}
          />
          <Button
            style={{
              float: "right",
              marginRight: "10px",
              marginBottom: "10px",
            }}
            variant="outlined"
            onClick={ExportToExcel}
          >
            Export to Excel
          </Button>

          <div style={{ display: "flex", alignItems: "center" }}>
            <select
              className="form-select form-select-sm login-input"
              required={true}
              value={selectedCourse}
              onChange={handleChangeCourse} // Update the selected course
            >
              <option value="">Select Course</option>
              {associate.map((course, index) => (
                <option key={index} value={course.courseName}>
                  {course.courseName}
                </option>
              ))}
            </select>

            <select
              className="form-select form-select-sm login-input"
              required={true}
              value={selectedBranch}
              onChange={handleChangeBranch} // Update the selected branch
              disabled={!selectedCourse} // Disable until a course is selected
            >
              <option value="">Select Branch</option>
              {branches.map((branch, index) => (
                <option key={index} value={branch}>
                  {branch}
                </option>
              ))}
            </select>

            {(courseName === "BACHELOR OF EDUCATION"  || courseName === "BACHELOR OF PHARMACY") && (
                <>
                <select
                  className="form-select form-select-sm login-input"
                  required={true}
                  value={selectedCollege}
                  onChange={handleCollegeChange}
                >
                  <option value="">Select College</option>
                  {colleges.map((college, index) => (
                    <option key={index} value={college.collegeName}>
                      {college.collegeName}
                    </option>
                  ))}
                </select>
                </>
              )}

            <Button
              type="button"
              variant="contained"
              // color="primary"
              onClick={handleSearch}
              disabled={!selectedBranch}
              sx={{
                height: "35px",
                backgroundColor: "#023047",
                color: "white",
                marginBottom: "20px",
                marginLeft: "3px",
              }}
            >
              Submit
            </Button>
          </div>
          <TableContainer sx={{ maxHeight: "440px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* Define Table Headers */}
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "#023047",
                      color: "white",
                      position: "sticky",
                      top: 0,
                      zIndex: 2,
                      left: 0,
                    }}
                  >
                    S.No.
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "#023047",
                      minWidth: "150px",
                      color: "white",
                      position: "sticky",
                      top: 0,
                      zIndex: 2,
                      left: 50,
                    }}
                  >
                    Enrollment No.
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "#023047",
                      color: "white",
                      minWidth: "180px",
                      position: "sticky",
                      top: 0,
                      zIndex: 2,
                      left: 190,
                    }}
                  >
                    Candidate Name
                  </TableCell>

                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "#023047",
                      color: "white",
                      minWidth: "130px",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    Registration ID
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "#023047",
                      color: "white",
                      minWidth: "130px",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    Registration Password
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "#023047",
                      color: "white",
                      minWidth: "170px",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    Assigned Clg
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "#023047",
                      color: "white",
                      minWidth: "150px",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    Father's Name
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "#023047",
                      color: "white",
                      minWidth: "150px",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    Mother's Name
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "#023047",
                      color: "white",
                      position: "sticky",
                      top: 0,
                      minWidth: "100px",
                      zIndex: 1,
                    }}
                  >
                    Contact
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "#023047",
                      color: "white",
                      minWidth: "180px",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "#023047",
                      color: "white",
                      minWidth: "80px",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    Is Enrolled
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: "#023047",
                      color: "white",
                      minWidth: "80px",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    Enrollment Fee
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredData.length > 0 ? (
                  filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((student, index) => (
                      <TableRow key={student.randomId}>
                        <TableCell
                          align="left"
                          style={{
                            zIndex: 1,
                            top: 0,
                            left: 0,
                            position: "sticky",
                            backgroundColor: "white",
                          }}
                        >
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            position: "sticky",
                            backgroundColor: "white",
                            top: 0,
                            zIndex: 1,
                            left: 50,
                          }}
                        >
                          {student?.enrollmentNumber ? (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              {student?.enrollmentNumber}
                            </span>
                          ) : (
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              Not Generated
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#e85d04",
                            fontWeight: 700,
                            position: "sticky",
                            backgroundColor: "white",
                            top: 0,
                            zIndex: 1,
                            left: 190,
                          }}
                        >
                          {student?.name}
                        </TableCell>

                        <TableCell align="left" style={{ fontWeight: 700 }}>
                          {student?.randomId}
                        </TableCell>
                        <TableCell align="left" style={{ fontWeight: 700 }}>
                          {student?.randomPassword}
                        </TableCell>
                        <TableCell align="left">
                        {student?.assignedCollege}
                        </TableCell>
                        <TableCell align="left">
                          {student?.fathersname}
                        </TableCell>
                        <TableCell align="left">
                          {student?.mothersname}
                        </TableCell>
                        <TableCell align="left">{student?.mobile}</TableCell>
                        <TableCell align="left" style={{ fontWeight: "700" }}>
                          {student?.email}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            color: student?.isEnrolled ? "green" : "red",
                            fontWeight: 700,
                          }}
                        >
                          {student?.isEnrolled ? "Yes" : "No"}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            color: student?.isEnrollPaid ? "green" : "red",
                            fontWeight: 700,
                          }}
                        >
                          {student?.isEnrollPaid ? "Paid" : "Not Paid"}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={10}
                      style={{ padding: "20px" }}
                    >
                      No records found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[8, 25, 100]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <br></br>
      </Box>
    </ThemeProvider>
  );
};
export default TotalEnrolled;
