import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import { Link } from "react-router-dom";

function Institutes() {
  return (
    <>
      <Nav />
      <style>
        {`
    
    
    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    }
   
    
   
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}


.lli{
font-size:14px; 
margin-left:30px;
margin-top:px;
width:300px;
 list-style-type: none; /* Removes bullets */}


 .lli1{
 font-size:15px; 
// margin-left:20px;
margin-top:7px;
font-weight:700;
  font-family: 'poppins',sansSerif;
 list-style-type: none; /* Removes bullets */}

 }
 .list-icon {
        list-style-type: none; /* Removes bullets */
        padding:20px;
        margin-top:-40px;
        margin-left:30px;
        display: flex;
        width:350px;
        flex-direction: column; /* Default to vertical */
    }
.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 20px;
    line-height: 15px;
    margin: 5px 5px 5px 0;
    text-align: center;
    width: 32px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


 @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>
      <section className="section_all " id="about">
        <div className="container">
          <div class="two">
            <h1>INSTITUTES</h1>
          </div>
          <br></br>

          <div className="row">
            <div className="col-lg-9">
              <p style={{ fontFamily: "'poppins',sansSerif" }}>
                As Per ordinance of Sri Satya Sai University of Technology &
                Medical Sciences Sehore following institute as constituent unit
                of University.
              </p>

              <p>The following are University Institutes -</p>
              <ul>
                <li className="lli1">School of Engineering</li>
                <li className="lli1">School of Computer Application</li>
                <li className="lli1">School of Management Studies</li>
                <li className="lli1">Faculty of Pharmacy</li>
                <li className="lli1">School of Hotel Management</li>
                <li className="lli1">School of Paramedical Studies</li>
                <li className="lli1">Polytechnic (Engineering)</li>
                <li className="lli1">School of Law</li>
                <li className="lli1">School of Homoeopathy</li>
                <li className="lli1">Faculty of Education</li>
                <li className="lli1">School of Design</li>
                <li className="lli1">School of Ayurveda & Siddha Studies</li>
                <br></br>
                <p>
                  {" "}
                  As per approval accorded by Regulatory authorities, some new
                  Course/ Institution are scheduled from coming Academic years,
                  namely MBBS.
                </p>{" "}
              </ul>
            </div>
            <div className="col-lg-3">
              <ul className="list-icon">
                <h5
                  style={{
                    fontFamily: "'poppins',sansSerif",
                    color: "#243642",
                    marginLeft: "50px",
                  }}
                >
                  Institutes
                </h5>
                <br></br>{" "}
                <li className="lli">
                  <Link to="/History_Milestones">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Background
                  </Link>
                </li>
                <li className="lli">
                  <Link to="/institutes">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Institutes
                  </Link>
                </li>
                <li className="lli">
                  <Link to="/promotingsociety">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Promoting
                    Society
                  </Link>
                </li>
                <li className="lli">
                  <Link to="/vision-mission">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Vision &
                    Mission
                  </Link>
                </li>
                <li className="lli">
                  <Link to="/core-approvalsvalues">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Core Values
                  </Link>
                </li>
                <li className="lli">
                  {" "}
                  <Link to="/best-practices">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Best
                    Practices
                  </Link>
                </li>
                <li className="lli">
                  {" "}
                  <Link to="/Public-Self-Disclosure">
                    <i class="fa fa-adjust" aria-hidden="true"></i> Public Self
                    Disclosure
                  </Link>
                </li>
                <li className="lli">
                  {" "}
                  <Link to="/Central_facilities">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Central
                    Facilities{" "}
                  </Link>
                </li>
                <li className="lli">
                  {" "}
                  <Link to="/InstitutionalDevPlan">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Institutional
                    Development Plan{" "}
                  </Link>
                </li>
                <li className="lli">
                  {" "}
                  <Link to="/Linknnual_reports">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Annual
                    Reports{" "}
                  </Link>
                </li>
                <li className="lli">
                  {" "}
                  <Link to="/NSS">
                    <i class="fa fa-adjust" aria-hidden="true"></i> NSS
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footerpage />
    </>
  );
}

export default Institutes;
