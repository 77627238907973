import React, { useState, useEffect } from "react";
import bg from "../../src/Main_Admin/images/b.png";
import b from "../images/sssutms.jpg";
import scientist from "../images/scientist.png";
import ra from "../images/radiograph.png";
import yoga from "../images/yoga.png";
import fop4 from "../images/fop4.png";
import ed from "../images/education.png";
import pe from "../images/Admission_Enquiry/pe.png";
import com from "../images/Admission_Enquiry/com.png";
import hm from "../images/Admission_Enquiry/hm.png";
import ar from "../images/Admission_Enquiry/ar.png";
import folder from "../images/Admission_Enquiry/folder.png";
import ec from "../images/Admission_Enquiry/EC.png";
import SKETCH from "../images/Admission_Enquiry/sketch.png";
import inn from "../images/Admission_Enquiry/INN.png";
import art from "../images/Admission_Enquiry/art.png";
import useFetch from "../CUSTOM_HOOK/Usefetch";
import { State, City } from "country-state-city";
import hum from "../images/Admission_Enquiry/vitamin.png";
import { Form, Button } from "react-bootstrap";
import blood from "../images/blood.png";
import fop3 from "../images/Admission_Enquiry/fop3.png";
import ee from "../images/Admission_Enquiry/ee.png";
import me from "../images/Admission_Enquiry/me.png";
import dc from "../images/Admission_Enquiry/dc.png";
import law from "../images/Admission_Enquiry/law.png";
import se from "../images/Admission_Enquiry/se.png";
import axios from "axios";

import {useNavigate } from "react-router-dom";

import NURSE from "../images/Admission_Enquiry/nurse.png";
import AYURVEDA from "../images/Admission_Enquiry/AYURVEDA.png";
import HOMEO from "../images/Admission_Enquiry/homeopathy.png";
import physio from "../images/Admission_Enquiry/physio.png";

import agro from "../images/Admission_Enquiry/agro.png";

import swal from "sweetalert";
import { Link } from "react-router-dom";

const Admission_Enquiry = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [courseData, setCourseData] = useState({});
  const [courseType, setCourseType] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseBranch, setCourseBranch] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    country: "IN",
    state: "",
    city: "",
    mobile: "",
    courseType: "",
    courseName: "",
    courseBranch: "",
    terms: false,
  });
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const session = new Date().getFullYear();
  const { data, loading, error } = useFetch(
    process.env.REACT_APP_STD_COURSE + `${session}`
  );

  useEffect(() => {
    if (data) {
      const formattedData = {};

      data.forEach((course) => {
        if (!formattedData[course.courseType]) {
          formattedData[course.courseType] = {
            courses: [],
            branches: {},
          };
        }

        course.courseNames.forEach((courseName) => {
          formattedData[course.courseType].courses.push(courseName.courseName);
          formattedData[course.courseType].branches[courseName.courseName] =
            courseName.branches.map((branch) => branch.branchName);
        });
      });

      setCourseData(formattedData);
    }
  }, [data]);

  useEffect(() => {
    const getAllStates = async () => {
      try {
        const statesData = await State.getStatesOfCountry("IN");
        setStates(statesData);
      } catch (err) {
        // Handle error if necessary
      }
    };

    getAllStates();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setErrors({
      ...errors,
      [name]: undefined,
      // or null if your validation check requires a falsy value
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    if (name === "state") {
      setCities([]);
      try {
        const stateCities = City.getCitiesOfState("IN", value);
        setCities(stateCities);
      } catch (err) {
        // Handle error if necessary
      }
    }
  };

  const handleTypeChange = (e) => {
    setCourseType(e.target.value);
    setCourseName("");
    setCourseBranch("");
  };

  const handleCourseTypeChange = (e) => {
    setCourseType(e.target.value);
    setFormData({ ...formData, courseType: e.target.value });
    setErrors({
      ...errors,
      courseType: undefined, // Clear error when course is selected
    });
  };

  const handleCourseChange = (e) => {
    setCourseName(e.target.value);
    // console.log(e.target.value);
    setFormData({ ...formData, courseName: e.target.value });
    setErrors({
      ...errors,
      course: undefined, // Clear error when course is selected
    });
  };

  const handleBranchChange = (e) => {
    setCourseBranch(e.target.value);
    setFormData({ ...formData, courseBranch: e.target.value });
    setErrors({
      ...errors,
      specialization: undefined, // Clear error when specialization is selected
    });
  };

  const validate = () => {
    let newErrors = {};
    if (!formData.fullname) newErrors.fullname = "Full Name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.city) {
      newErrors.city = "City is required";
    } else {
      newErrors = "";
    }
    if (!courseType) newErrors.courseType = "Course Type is required";
    if (!courseName) newErrors.course = "Course is required";
    if (!courseBranch) newErrors.specialization = "Specialization is required";

    // Existing validations...
    if (!formData.mobile) {
      newErrors.mobile = "Phone Number is required";
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = "Invalid phone number (must be 10 digits)";
    }
    // Existing validations...

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // console.log(formData, courseType, courseName, courseBranch, "/kl;.;lpkk;l");
  // formData={
  //   courseType,
  //   courseName,
  //   courseBranch
  // }
  const handleSubmit = async (e) => {
    //console.log("Ye click hua ")
    e.preventDefault();

    try {
      if (validate()) {
        const response = await axios.post(
          "https://sssutms.ac.in/apitest/v1/admission-enquiry",
          formData
        )

        setFormData({
          fullname: "",
          email: "",
          country: "IN",
          state: "",
          city: "",
          mobile: "",
          courseType: "",
          courseName: "",
          courseBranch: "",
          terms: false,
        });

        if (response.data.status === 200) {
          swal({
            title: `Thank You for Enquiry!!`,

            content: {
              element: "p",
              attributes: {
                innerHTML:
                  "We will contact you soon regarding your admission to the university.",
                style: "color: #05668d; font-size: 18px;",
              },
            },
            icon: "success",
            buttons: "OK",
          }).then(() => {
            navigate("/");
          });
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Optionally set error state here
    }
  };

  return (
    <>
      {" "}
      <main className="mainnn">
        <body class="color-vars">
          <div className="bg-img ">
            <header className="header-section">
              <div className="coner">
                <div className="nav-bar d-flex">
                  <Link to="/" className="contact-number d-flex">
                    <span>
                      <img src={b} className="image" alt="Logo" />
                    </span>
                    <span className="spann" style={{ color: "white" }}>
                      SRI SATYA SAI UNIVERSITY OF TECHNOLOGY & MEDICAL SCIENCES
                    </span>
                  </Link>

                  <div>
                    <Link to="#" className="toggle-btn" />
                    <div className="nav-right">
                      <div className="call-content">
                        <span className="call-title">Admission Enquiry</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>

            <div className="hero-section">
              <div className="container">
                <div className="hero-wrapper d-flex">
                  <div className="content-rgt">
                    <h2 className="hero-heading">ADMISSION Open</h2>
                  </div>
                  <div className="hero-form">
                    <div className="title-wrapper d-flex">
                      <h3 className="form-title" style={{ color: "white" }}>
                        Enquiry Now{" "}
                      </h3>
                      <div className="ribbin"></div>
                    </div>
                    <p className="form-text" style={{ color: "white" }}>
                      Fill out the enquiry form , will call you back ASAP!
                    </p>
                    <div className="form-callback">
                      <Form onSubmit={handleSubmit}>
                        <Form.Group className="form-item">
                          <Form.Control
                            className="f-field"
                            type="text"
                            name="fullname"
                            value={formData.fullname}
                            onChange={handleInputChange}
                            placeholder="Full Name*"
                            isInvalid={!!errors.fullname}
                            autoComplete="off"
                          />
                          {errors.fullname && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="error-message"
                            >
                              {errors.fullname}
                            </Form.Control.Feedback>
                          )}

                          <Form.Control
                            style={{ marginLeft: "5px" }}
                            className="f-field"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="Email*"
                            isInvalid={!!errors.email}
                            autoComplete="off"
                          />

                          <div className="error-message">{errors.email}</div>
                        </Form.Group>

                        <Form.Group className="form-item">
                          <Form.Control
                            as="select"
                            name="state"
                            value={formData.state}
                            onChange={handleInputChange}
                            className="f-field"
                            isInvalid={!!errors.state}
                          >
                            <option value="">State</option>
                            {states.map((state) => (
                              <option key={state.isoCode} value={state.isoCode}>
                                {state.name}
                              </option>
                            ))}
                          </Form.Control>
                          {errors.state && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="error-message"
                            >
                              {errors.state}
                            </Form.Control.Feedback>
                          )}

                          <Form.Control
                            as="select"
                            name="city"
                            value={formData.city}
                            onChange={handleInputChange}
                            className="f-field"
                            style={{ marginLeft: "5px" }}
                            isInvalid={!!errors.city}
                          >
                            <option value="">City*</option>
                            {cities.map((city) => (
                              <option key={city.name} value={city.name}>
                                {city.name}
                              </option>
                            ))}
                          </Form.Control>
                          {errors.district && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="error-message"
                            >
                              {errors.district}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <Form.Group className="form-item">
                          <Form.Control
                            as="select"
                            value={formData.courseType}
                            onChange={handleCourseTypeChange}
                            className="f-field"
                            isInvalid={!!errors.courseType}
                          >
                            <option value="" disabled>
                              Course Type *
                            </option>
                            {Object.keys(courseData).map((type) => (
                              <option key={type} value={type}>
                                {type}
                              </option>
                            ))}
                          </Form.Control>
                          {errors.courseType && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="error-message"
                            >
                              {errors.courseType}
                            </Form.Control.Feedback>
                          )}

                          <Form.Control
                            as="select"
                            value={formData.courseName}
                            onChange={handleCourseChange}
                            className="f-field"
                            style={{ marginLeft: "5px" }}
                            isInvalid={!!errors.course}
                          >
                            <option value="" disabled>
                              Courses *
                            </option>
                            {courseType &&
                              courseData[courseType].courses.map((course) => (
                                <option key={course} value={course}>
                                  {course}
                                </option>
                              ))}
                          </Form.Control>
                          {errors.course && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="error-message"
                            >
                              {errors.course}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <Form.Group className="form-item">
                          <Form.Control
                            as="select"
                            value={formData.courseBranch}
                            onChange={handleBranchChange}
                            className="f-field"
                            isInvalid={!!errors.specialization}
                          >
                            <option value="">Specialization *</option>
                            {courseName &&
                              courseData[courseType].branches[courseName].map(
                                (branch) => (
                                  <option key={branch} value={branch}>
                                    {branch}
                                  </option>
                                )
                              )}
                          </Form.Control>
                          {errors.specialization && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="error-message"
                            >
                              {errors.specialization}
                            </Form.Control.Feedback>
                          )}
                          <Form.Control
                            className="f-field"
                            type="Number"
                            name="mobile"
                            style={{ marginLeft: "5px" }}
                            value={formData.mobile}
                            onChange={handleInputChange}
                            placeholder="Phone Number*"
                            isInvalid={!!errors.mobile}
                            autoComplete="off"
                          />
                          {errors.mobile && (
                            <Form.Control.Feedback
                              type="invalid"
                              className="error-message"
                            >
                              {errors.mobile}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <div className="form-item">
                          <textarea
                            name="message"
                            rows={30}
                            placeholder=" Any other information..."
                          />
                        </div>
                        {/* <Form.Group>
                      <Form.Check
                        type="checkbox"
                        name="terms"
                        checked={formData.terms}
                        onChange={handleInputChange}
                        label="I agree to Terms and Conditions."
                        isInvalid={!!errors.terms}
                        feedback={errors.terms}
                        feedbackType="invalid"
                        className="checkbox-label"
                        style={{ color: 'white' }}
                      />
                    </Form.Group> */}

                        <div className="submit-btn d-flex">
                          <Button type="submit" className="btn">
                            Submit
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>

        <style>
          {`
    :root {
  --primary-text: #f5b41c;
  --secondary-text: #163f58;
  --white-text-white: #fff;
  --gray-text: #464646;
  --light-white: #c8c8c8;
}
.mainnn{
overflow-x:hidden;


}
//  .color-vars {
//     --primary-text: #f5b41c;
//     --secondary-text: #163f58;
//     --white-text- white: #fff;
//     --gray-text: #464646;
//     --light-white: #c8c8c8;

//     /* Define other variables referencing root variables */
//     --primary-color: var(--primary-text);
//     --secondary-color: var(--secondary-text);
//     --white-color: var(--white-text-white);
//     --gray-color: var(--gray-text);
//     --light-color: var(--light-white);
//   }

@font-face {
  font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../font/Poppins/Poppins-Regular.woff2') format('woff2'),
        url('../font/Poppins/Poppins-Regular.woff') format('woff');
}
*/
.image{
 height: 50px;
  width: 50px;
   border-radius: 10px
   }



// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }

// body {
//   font-family: "Raleway", sans-serif;
//   font-size: 16px;
// }

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  display: inline-block;
}

img {
  max-width: 100%;
  object-fit: cover;
}

.text-center {
  text-align: center;
}




.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



// .position-relative {
//   position: relative;
// }

.coner {
  width: 100%;
  /* max-width: 1140px; */
  max-width: 1166px;
  margin-left: auto;
  margin-right: auto;
  padding-inline: 15px;
}

.section-heading {
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 35px;
  text-transform: uppercase;
  color: var(--secondary-text);
  font-weight: 800;
  font-family: "Roboto";
  text-align: center;
  margin-bottom: 64px;
}






.bg-img {
  background-image: linear-gradient(45deg, #0215209e, #163f5894),
  url(${bg});
  width: 100%;
  /* height: 100vh; */
  height: 830px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 10;
}

.bg-img::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 230px;
  z-index: -10;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23ffffff' fill-opacity='1' d='M0,192L1440,64L1440,320L0,320Z' /%3E%3C/svg%3E");
  background-size: cover;
  background-repeat: no-repeat;
}

/* header start*/
.header-section {
  background-color: rgb(89 89 89 / 50%);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
}

.nav-bar {
  padding: 10px 0;
  margin-left:-50px
}

.brand-logo {
  height: 7px;
  width: 100%;
}

.nav-right {
  position: relative;
  width: 200px;
  margin-left:180px
}
 
.toggle-btn {
  display: none;
}

.contact-number {
  font-size: 20px;
  color: var(--white-text-white);
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  text-align: center;
}

.contact-number.d-flex {
  gap: 20px;
}

.contact-number .tell-icon {
  width: 32px;
}

.call-content {
  height: 60px;
  width: 458px;
  padding-left: 25px;
  background-color: var(--primary-text);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  right: 0;
  transform: translateX(-17%);
}

.nav-right .call-content::before {
  content: "";
  position: absolute;
  left: -13%;
  top: -2px;
  border-bottom: 62px solid var(--primary-text);
  border-left: 62px solid transparent;
}

.call-title {
  font-size: 20px;
  color: var(--white-text-white);
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  text-align: center;

}

/* header end */

/* hero section start */
.hero-section {
  padding-top: 206px;
  padding-bottom: 36px;
}

.hero-heading {
  font-size: 56px;
  letter-spacing: -2px;
  line-height: 70px;
  text-transform: uppercase;
  color: orange;
  font-weight: 800;
  font-family: "Roboto";
  max-width: 500px;
  margin-bottom: 46px;
}

.hero-wrapper .content-rgt {
  width: 627px;
  // margin-top:200px;
}

.hero-wrapper.d-flex {
  align-items: baseline;
}

.content-rgt .card-wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 30px;
   
  margin-bottom: 62px;
}




.hero-form {
  width: 469px;
  background-color: var(--secondary-text);
  border: 4px solid var(--white-text-white);
  border-radius: 5px;
  padding-inline: 34px;
  padding-bottom: 20px;
  padding-top: 34px;
  height:600px
}

.hero-form .form-title {
  font-size: 30px;
  letter-spacing: 0px;
  line-height: 35px;
  text-transform: uppercase;
  color: var(--white-text-white);
  font-weight: 800;
  font-family: "Roboto";
  margin-bottom: 20px;
}

.ribbin {
  position: relative;
  width: 159px;
}

.form-label-ribbin {
  position: absolute;
  transform: translateY(-60px);
  z-index: 10;
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 27px;
  color: var(--secondary-text);
  font-weight: bold;
  font-family: "Raleway", sans-serif;
  text-align: center;
}


.form-label-ribbin::before {
  content: "";
  position: absolute;
  top: -28px;
  right: 0;
  z-index: -10;
  width: 150px;
  height: 103px;
  background-image: url(../images/label-ribbin.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-form .form-text {
  width: 381px;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 27px;
  color: var(--white-text-white);
   font-family: "Raleway", sans-serif;
}

.form-callback {
  margin-top: 22px;
}

.form-item {
  margin-bottom: 14px;

  display:flex;
  justify:content:space-between
}

.form-item .f-field,
.form-item textarea {
 width: 200px;
  height: 44px;
  border-radius: 2px;
  padding: 10px;
  justify-content:space-between;
  background-color: var(--white-text-white);
  border: 1px solid var(--light-white);
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 27px;
  color: var(--gray-text);
  font-family: "Raleway", sans-serif;
}

.form-item textarea {
  width: 100%;
  height: 104px;
}

.submit-btn.d-flex {
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.submit-btn .btn {
  width: 159px;
  height: 46px;
  padding: 9px 49px;
  background-color: var(--primary-text);
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 27px;
  color: var(--white-text-white);
  font-weight: 500;
  font-family: "Poppins";
  text-align: center;
  border: 1px solid var(--primary-text);
  transition: 0.3s;
  cursor: pointer;
  margin-top:20px
}

.submit-btn .btn:hover {
  border: 1px solid var(--white-text-white);
  background-color: var(--white-text-white);
  color: var(--primary-text);
}

.submit-btn .checkbox-label {
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 27px;
  color: var(--white-text-white);
  font-family: "Poppins";
  text-align: center;
}

.submit-btn .checkbox-label span,
.f-items .copyright-l a {
  text-decoration: underline;
  cursor: pointer;
  color: white;
}



/* media query */
@media screen and (max-width: 786px) {
  .bg-img {
    height: 1040px;
  }
}

.call-wrapper .call-content {
  transform: translateX(-42%);
  justify-content: flex-end;
  gap: 20px;
  padding-right: 16px;
    margin-top:500px;
}






.content-rgt
{
margin-left:-30px;
font-size:15px;
margin-top:-200px}




h6{
font-size:13px;
font-weight: 700;
      font-family: 'Nunito', sans-serif;}

 .contain {
  display: grid;
  margin-top:10px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
 padding:30px

}


.text {
  display: flex;
  flex-direction: row; /* Flex direction set to column */
  align-items: center; /* Align items horizontally in the center */
  padding: 15px;
  gap: 10px; /* Adjust spacing between items */
}


.custom-img {
  width: 40px; /* Adjust the size as needed */
  height: 40px; /* Adjust the size as needed */

}


.custom-imgs {
  width: 40px; /* Adjust the size as needed */
  height: 40px; /* Adjust the size as needed */
  // filter: brightness(0) invert(1); /* Change image color to white */
}


.text h6 {
  margin: 0; /* Remove default margin */
}

// ////////////////////////////////////////////////////////////////////////////////////////////////////

h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color:#973131;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

h1 span {
  display: block;
  font-size: 0.5em;
  line-height: 1.3;
  color:#973131;
}
h1 em {
  font-style: normal;
  font-weight: 800px;  color:#973131;
}
  

.three{
margin-left:500px;
margin-top:40px;
}

.three h1 {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
    color:#E4003A;
}
.three h1:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 65px;
  background-color: #973131;;
}
.three h1:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 355px;
  background-color: #333;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 50px;
  width:190px;
  margin-left:-170px
}

.errors{
  margin-top:-10px;
  margin-left:-20px
  }
  
@media screen and (max-width: 480px) {


.form-item .f-field,
.form-item textarea {
 width: 160px;
  height: 44px;
  border-radius: 2px;
  padding: 10px;
  justify-content:space-between;
  background-color: var(--white-text-white);
  border: 1px solid var(--light-white);
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 27px;
  color: var(--gray-text);
  font-family: "Raleway", sans-serif;
}



.bg-img {
  background-image: linear-gradient(45deg, #0215209e, #163f5894),
  url(${bg});
  width: 100%;
  /* height: 100vh; */
  height: 830px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 10;
}

.nav-bar{
margin-top:-50px;
}

.call-content
{
margin-left:-500px;
margin-top:140px;
width:250px;
height:50px

}


.three{
font-size:15px;
// margin-top:30px

}
.call-title{
font-size:15px;
margin-left:50px
}


.hero-heading {
  font-size: 15px;
  letter-spacing: -2px;
  line-height: 70px;
  text-transform: uppercase;
  color: orange;
  font-weight: 800;
  font-family: "Roboto";
  max-width: 380px;
  // margin-bottom: 46px;
}



.hero-form {
  width: 380px;
   height:700px
  background-color: var(--secondary-text);
  border: 4px solid var(--white-text-white);
  border-radius: 5px;
  padding-inline: 34px;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-left:-596px;
  margin-top:-180px;
}
.header-section {
  background-color: rgb(89 89 89 / 50%);
   position: relative;
  left: 0;
  top: 0;
  // z-index: 999;
  width: 10%;
}

.contact-number {
width:20px;
margin-top:-10px;
margin-left:30px

}
span{
font-size:13px;
margin-left:20px}

.hero-form .form-title {
  font-size: 20px;
  width:400px;
  letter-spacing: 0px;
  line-height: 35px;
  text-transform: uppercase;
  color: var(--white-text-white);
  font-weight: 800;
  font-family: "Roboto";
  // margin-bottom: 20px;
}

.spann{
margin-left:10px;
width:320px}

.form-text{
width:90px;
font-size:10px;
margin-left:-20px;
margin-top:-10px}


  .three h1 {
    font-size: 25px; /* Adjust as needed for smaller screens */
  }

  .three{
  margin-top:80px;
  width:280px;
  margin-left:70px}
}
.image{
margin-left:10px;
height:40px;
width:40px;}







.form-item {
  margin-bottom: 14px;
margin-left:-20px;
  display:flex;
  justify:content:space-between
}

// .form-callback {
//   margin-left: 3px;
// }

.form-item textarea {
  width: 100%;
  height: 104px;
}

coner{
position:relative;
margin-left:20px;
}
       }
`}
        </style>
        {/* our services section*/}
        <div class="three">
          <h1>COURSES OFFERED</h1>
        </div>
        <style>
          {`
    .art {
      background-color: orange;
    }

    .container {
      display: flex;
      width: 100%;
      font-family: 'Open Sans', sans-serif;
      margin-top: 30px;
      height:auto;
      margin-bottom: 20px;
    }

    .tabs {
      flex: 1;
      background-color: #023047;
      color: white;
      display: flex;
      flex-direction: column;
      padding: 10px;
    }

    .tabs button {
      background: none;
      border: none;
      color: white;
      padding: 10px;
      text-align: left;
      cursor: pointer;
      font-size: 16px;
      transition: color 0.3s;
    }

    .text {
      display: flex;
    }

    .custom-img {
      width: 40px; /* Adjust the size as needed */
      height: 40px; /* Adjust the size as needed */
    }

    .tabs button.active {
      font-weight: bold;
      color: black;
    }

    .content {
      flex: 3;
      padding: 20px;
      margin-top: -30px;
      width: 100%;
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
      gap: 20px;
      height: 390px; /* Adjust height as needed */
      overflow-y: auto;
    }

    .grid article {
      border: 1px solid #ddd;
      border-radius: 4px;
      flex: 1 1 calc(33.333% - 10px);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      transition: transform 0.3s;
    }

    .grid article:hover {
      transform: scale(1.05);
      color: #023047;
    }

    .grid .text {
      text-align: center;
    }

    .grid img {
      max-width: 100px;
      height: auto;
      margin-bottom: 10px;
    }

    h4, h5, h6 {
      margin: 0;
      padding: 0;
    }

    @media (max-width: 480px) {
      .container {
        flex-direction: column;
        align-items: center;

      }

      .tabs {
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        height:auto;
        margin-bottom: 10px;
      }

      .tabs h5 {
        display: none;
      }

      .tabs button {
        padding: 5px 10px;
        font-size: 14px;
      }

      .content {
        margin-top: -20px;
        width: 100%;
    
        
      }

      .grid {
        height: auto; /* Remove fixed height */
         overflow-y: visible; /* Remove scroll */
        //  gap:5px;
      }

      .grid article {
        height:auto; /* Adjust height */
      }

      .grid .text {
        display: flex;
        margin-top: -10px;
      }
    }
  `}
        </style>

        <div className="container">
          <div className="tabs">
            <h5
              style={{
                fontWeight: 700,
                color: "white",
                marginLeft: "50px",
                marginTop: "30px",
                fontFamily: "'Open Sans', Sans-serif",
              }}
            >
              Program Levels
            </h5>
            <hr />
            <button
              style={{ color: activeTab === "tab1" ? "orange" : "white" }}
              onClick={() => setActiveTab("tab1")}
              className={activeTab === "tab1" ? "active" : ""}
            >
              UG Programs
            </button>
            <hr />
            <button
              style={{ color: activeTab === "tab2" ? "orange" : "white" }}
              onClick={() => setActiveTab("tab2")}
              className={activeTab === "tab2" ? "active" : ""}
            >
              PG Programs
            </button>
            <hr />
            <button
              style={{ color: activeTab === "tab3" ? "orange" : "white" }}
              onClick={() => setActiveTab("tab3")}
              className={activeTab === "tab3" ? "active" : ""}
            >
              Diploma
            </button>
            <hr />
            <button
              style={{ color: activeTab === "tab4" ? "orange" : "white" }}
              onClick={() => setActiveTab("tab4")}
              className={activeTab === "tab4" ? "active" : ""}
            >
              PG Diploma
            </button>
          </div>
          <div className="content">
            {activeTab === "tab1" && (
              <>
                <br></br>
                <div className="grid">
                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={SKETCH}
                        alt=" Engineering"
                      />
                      <h6> Bachelor of Design</h6>
                    </div>
                  </article>
                  <article className="art">
                    <div className="text">
                      <img
                        className="custom-imgs"
                        src={law}
                        alt=" Engineering"
                      />
                      <h6> Bachelor of Law</h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={folder}
                        alt="education"
                      />
                      <h6> Bachelor of Buisness Administration </h6>
                    </div>
                  </article>{" "}
                  <article>
                    <div className="text">
                      <img className="custom-img" src={hm} alt="education" />
                      <h6> Bachelor of Hotel Management & Catering</h6>
                    </div>
                  </article>
                  <article className="art">
                    <div className="text">
                      <img className="custom-imgs" src={pe} alt="education" />
                      <h6 className="headd">
                        {" "}
                        Bachelor of Physical Education(B.P.Ed.)
                      </h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img className="custom-img" src={art} alt="education" />
                      <h6>
                        {" "}
                        Bachelor of Arts Bachelor of Education (B.A.B.Ed.)
                      </h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img className="custom-img" src={ed} alt="education" />
                      <h6> Bachelor of Education</h6>
                    </div>
                  </article>
                  <article className="art">
                    <div className="text">
                      <img className="custom-imgs" src={inn} alt="education" />
                      <h6> Bachelor of Science</h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img className="custom-img" src={ar} alt="education" />
                      <h6> Bachelor of Engineering </h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img className="custom-img" src={fop4} alt=" pharmacy" />
                      <h6> Bachelor of Pharmacy</h6>
                    </div>
                  </article>
                  <article className="art">
                    <div className="text">
                      <img className="custom-imgs" src={com} alt="education" />
                      <h6> Bachelor of Commerce</h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img className="custom-img" src={ec} alt="education" />
                      <h6> Bachelor of Computer Application </h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img className="custom-img" src={NURSE} alt="education" />
                      <h6> B.SC. (NURSING) </h6>
                    </div>
                  </article>
                  <article className="art">
                    <div className="text">
                      <img className="custom-imgs" src={com} alt="education" />
                      <h6> Bachelor of Architecture</h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img className="custom-img" src={art} alt="education" />
                      <h6> Bachelor of Arts </h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img className="custom-img" src={ar} alt="education" />
                      <h6> Bachelor of Engineering (Lateral) </h6>
                    </div>
                  </article>
                  <article className="art">
                    <div className="text">
                      <img
                        className="custom-img"
                        src={AYURVEDA}
                        alt="education"
                      />
                      <h6>
                        {" "}
                        Bachelor of Ayurvedic Medicine and Surgery (B.A.M.S){" "}
                      </h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img className="custom-img" src={HOMEO} alt="education" />
                      <h6>
                        {" "}
                        Bachelor of Homeopathic Medicine and Surgery (B.H.M.S){" "}
                      </h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={physio}
                        alt="education"
                      />
                      <h6> Bachelor of Physiotherapy</h6>
                    </div>
                  </article>
                  <article className="art">
                    <div className="text">
                      <img className="custom-img" src={ed} alt="education" />
                      <h6> Bachelor of Library Science </h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img className="custom-img" src={agro} alt="education" />
                      <h6> B.Sc. (Hons) AGRICULTURE </h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img className="custom-img" src={fop4} alt="education" />
                      <h6> Bachelor of Pharmacy (Lateral) </h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img className="custom-img" src={pe} alt="education" />
                      <h6>
                        {" "}
                        Bachelor of Physical education and Sports (BPES){" "}
                      </h6>
                    </div>
                  </article>
                </div>
              </>
            )}
            {activeTab === "tab2" && (
              <>
                <br></br>
                <div className="grid">
                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={dc}
                        alt="Digital Communication"
                      />
                      <h6>Master Of Technology</h6>
                    </div>
                  </article>

                  <article className="art">
                    <div className="text">
                      <img
                        className="custom-img"
                        src={folder}
                        alt="Digital Communication"
                      />
                      <h6>Master Of Buisness Administration</h6>
                    </div>
                  </article>

                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={se}
                        alt="Digital Communication"
                      />
                      <h6>Master Of Computer Application</h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={inn}
                        alt="Digital Communication"
                      />
                      <h6>Master Of Science</h6>
                    </div>
                  </article>

                  <article className="art">
                    <div className="text">
                      <img
                        className="custom-img"
                        src={fop4}
                        alt="Digital Communication"
                      />
                      <h6>Master Of Pharmacy </h6>
                    </div>
                  </article>

                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={folder}
                        alt="Digital Communication"
                      />
                      <h6>Master Of Physiotherapy (MPT)</h6>
                    </div>
                  </article>

                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={fop4}
                        alt="Digital Communication"
                      />
                      <h6>Master Of Medical Laboratory Technology (MMLT) </h6>
                    </div>
                  </article>

                  <article className="art">
                    <div className="text">
                      <img
                        className="custom-img"
                        src={com}
                        alt="Digital Communication"
                      />
                      <h6>Master Of Commerce</h6>
                    </div>
                  </article>

                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={art}
                        alt="Digital Communication"
                      />
                      <h6>Master of Arts</h6>
                    </div>
                  </article>
                </div>
              </>
            )}
            {activeTab === "tab3" && (
              <>
                <br></br>
                <div className="grid">
                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={fop3}
                        alt="Computer Science and Engineering"
                      />
                      <h6>Diploma Pharmacy</h6>
                    </div>
                  </article>
                  <article className="art">
                    <div className="text">
                      <img
                        className="custom-img"
                        src={blood}
                        alt="Electrical Engineering"
                      />
                      <h6>Diploma Blood Transfusion</h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={me}
                        alt="Mechanical Engineering"
                      />
                      <h6>Diploma Dialysis Technician</h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={fop4}
                        alt="Computer Science and Engineering"
                      />
                      <h6>Diploma Pharmacy (AYURVED)</h6>
                    </div>
                  </article>{" "}
                  <article className="art">
                    <div className="text">
                      <img
                        className="custom-img"
                        src={hum}
                        alt="Electronic & Communication Engineering"
                      />
                      <h6>Diploma Human Nutrition</h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={scientist}
                        alt="Electrical and Electronics Engineering"
                      />
                      <h6>Diploma Medical Lab Technician</h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={ra}
                        alt="Electrical and Electronics Engineering"
                      />
                      <h6>Diploma X-Ray Radiographer Technician</h6>
                    </div>
                  </article>
                  <article className="art">
                    <div className="text">
                      <img
                        className="custom-img"
                        src={ee}
                        alt="Electrical and Electronics Engineering"
                      />
                      <h6>Diploma Engineering (Lateral )</h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={scientist}
                        alt="Electrical and Electronics Engineering"
                      />
                      <h6>Diploma Paramedical Opthalmic Assistent</h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={fop4}
                        alt="Electrical and Electronics Engineering"
                      />
                      <h6>Diploma Pharmacy (Homeopathy)</h6>
                    </div>
                  </article>
                  <article className="art">
                    <div className="text">
                      <img
                        className="custom-img"
                        src={inn}
                        alt="Electrical and Electronics Engineering"
                      />
                      <h6>Diploma Engineering</h6>
                    </div>
                  </article>
                  <article>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={yoga}
                        alt="Electrical and Electronics Engineering"
                      />
                      <h6>Diploma Yoga</h6>
                    </div>
                  </article>
                </div>
              </>
            )}

            {activeTab === "tab4" && (
              <>
                <br></br>
                <br></br>
                <div className="grid" style={{ height: "auto" }}>
                  <article className="art" style={{ width: "300px" }}>
                    <div className="text">
                      <img
                        className="custom-img"
                        src={ec}
                        alt="Computer Science and Engineering"
                      />
                      <h6>Post Graduation Diploma in Computer Application</h6>
                    </div>
                  </article>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Admission_Enquiry;
