import React from "react";
import Nav from "../HomePage/Nav";
import Footerpage from "../HomePage/Footerpage";

function 

BOS

() {
  return (
    <>
      <Nav />
      <style>
        {`

.leadership{
position:relative;
height:300px;
width:100%;
}





h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  .alt-two h1 {
    text-align:center;
  }
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }
  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 20px;
      line-height: 15px;
      margin: 5px 5px 5px 0;
      text-align: center;
      width: 28px;
  }



  .fa {
      display: inline-block;
      font: normal normal normal 14px / 1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  
     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 20px; 
      //  margin-top:-180px;
          
          display: flex;
        
          flex-direction: column; /* Default to vertical */
      }
  




.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }





.cont{
margin-top:20px;
}
.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: 0rem;
margin-bottom:0rem
}

.chan{
margin-left:30px;
font-size:30px;
margin-top:80px;
color:#E67E23
}











@media only screen and (max-width: 480px){

.contentleader
{
text-align:justify;
 font-family:poppins,sans-serif;
  font-weight: 900;
  font-style: normal;
}


.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}






.contentleader{
margin-left:20px;
font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;}
}



h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>
     
      <div className="cont">
        <h2 className="h2 title-main" style={{ color: "#780000" }}>
        Board of Studies
        </h2>
        <hr className="title-hr"></hr>
       
        <div className="row dirr">
       
          <div className="col-lg-9 padding-section">
          
           
          
            <h2
          className="h3  chan text-bold"
          style={{ fontFamily: "'poppins',sansSerif", }}
        >
Board of Studies     --- <span style={{color:'blue',fontSize:'18px',marginLeft:'300px'}}><a  target='_blank' href="https://cdn.sssutms.ac.in/media/static/PDF/BOS_07122024_0448.pdf">
                Click Here
              </a></span>   </h2>
             


            <br />

          </div>
          <div className="col-lg-3">
            <ul className="list-icon">
              <h5
                style={{ marginTop:'-50px',fontFamily: "'poppins',sansSerif", color: "#243642" }}
              >
                University Officials
              </h5>
              {/* <br></br>{" "} */}
              <li className="lli">
                <a href="/chancellor">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Chancellor
                </a>
              </li>
              <li className="lli">
                <a href="/vice_chancellor">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Vice Chancellor
                </a>
              </li>
              <li className="lli">
                <a href="/registrar">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Registrar{" "}
                </a>
              </li>
              <li className="lli">
                <a href="/finance_officer">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Finance Officer{" "}
                </a>
              </li>
             
              <li className="lli">
                <a href="/deputy_registrar">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Deputy
                  Registrar{" "}
                </a>
              </li>
              <li className="lli">
                <a href="/exam_controller">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Exam Controller
                </a>
              </li>

              <li className="lli">
                <a href="/chief_vigilance_officer">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Chief Vigilance Officer
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/governing_body">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Governing Body{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/board_of_management">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Board of
                  Management{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/board_of_studies">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Board of
                  Studies{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/academic_council">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Academic
                  Council{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/finance_committee">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Finance
                  committee{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/standing_committee">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Standing
                  Committee{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/statutes">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Statutes{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/audit_report">
                  <i class="fa fa-adjust" aria-hidden="true"></i>AUDIT REPORT{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/dean">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Dean{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/head_of_department">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Head of the
                  Department{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <Footerpage />
    </>
  );
}

export default BOS;