import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Container,
  Button,
  ThemeProvider,
  createTheme,
  Box,
  Checkbox,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import * as XLSX from "xlsx";

const theme = createTheme();

const ExamFormCheckStatus = ({
  courseType,
  courseBranch,
  courseName,
  semester,
  SubBranch,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [examId, setExamId] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://sssutms.ac.in/apitest/v1/admin/forwarded-exam-form-list?courseType=${courseType}&SubBranch=${SubBranch}&courseBranch=${courseBranch}&courseName=${courseName}&semester=${semester}`
      )
      .then((response) => {
        setData(response.data.data || []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [courseType, courseBranch, courseName, semester, SubBranch]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = data.filter(
    (item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.enrollmentNumber.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSelectStudent = (studentId) => {
    setSelectedStudents((prev) =>
      prev.includes(studentId)
        ? prev.filter((id) => id !== studentId)
        : [...prev, studentId]
    );
  };

  const handleCancelExamForm = async () => {
    if (selectedStudents.length === 0) {
      alert("Please select at least one student to cancel the exam form.");
      return;
    }

    try {
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/v1/cancel-exam-form",
        {
          studentIds: selectedStudents,
          examFormId:  data[0].formId,
        }
      );

      if (response.status === 200) {
        alert("Exam form cancelled successfully.");
        setSelectedStudents([]);

        setLoading(true);
        axios
          .get(
            `https://sssutms.ac.in/apitest/v1/admin/forwarded-exam-form-list?courseType=${courseType}&SubBranch=${SubBranch}&courseBranch=${courseBranch}&courseName=${courseName}&semester=${semester}`
          )
          .then((response) => {
            setData(response.data.data || []);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setLoading(false);
          });
      }
    } catch (error) {
      console.error("Error cancelling exam form:", error);
      alert("Failed to cancel exam form. Please try again.");
    }
  };
  
  const exportToExcel = () => {
    // Exclude 'studentId' column from the data
    const modifiedData = data.map(({ studentId, formId, ...rest }) => rest);

    const worksheet = XLSX.utils.json_to_sheet(modifiedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Enrolled Students");
    XLSX.writeFile(workbook, "ExamFormStatus_Std.xlsx");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        style={{ marginTop: "80px", marginLeft: "100px", width: "90%" }}
      >
        {loading ? (
          <CircularProgress style={{ margin: "20px auto", display: "block" }} />
        ) : (
          <>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px",
                borderRadius: "4px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <SearchIcon sx={{ mr: 1 }} />
                <input
                  type="text"
                  placeholder="Search by Name& Enroll"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{
                    padding: "5px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
              </Box>
              <Button
                variant="outlined"
                onClick={exportToExcel}
                style={{
                  float: "right",
                }}
              >
                Export to Excel
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleCancelExamForm}
                disabled={selectedStudents.length === 0}
              >
                Forward Cancel
              </Button>
            </Box>

            <TableContainer
              component={Paper}
              style={{
                maxHeight: "500px",
                overflow: "auto",
                width: "100%",
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: "#f0f0f0",
                      height: "20px",
                      padding: "5px",
                    }}
                  >
                     <TableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#fff",
                        backgroundColor: "#023047",
                      }}
                    >
                      Cancel Student
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#fff",
                        backgroundColor: "#023047",
                      }}
                    >
                      S.No.
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#fff",
                        backgroundColor: "#023047",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#fff",
                        backgroundColor: "#023047",
                      }}
                    >
                      Enrollment
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#fff",
                        backgroundColor: "#023047",
                      }}
                    >
                      Father's Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#fff",
                        backgroundColor: "#023047",
                      }}
                    >
                      Fee Status
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#fff",
                        backgroundColor: "#023047",
                      }}
                    >
                      Form Status
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#fff",
                        backgroundColor: "#023047",
                      }}
                    >
                      Forwarded
                    </TableCell>
                  
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          checked={selectedStudents.includes(item.studentId)}
                          onChange={() => handleSelectStudent(item.studentId)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {currentPage * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.enrollmentNumber}</TableCell>
                      <TableCell>{item.fathersname}</TableCell>
                      <TableCell
                        style={{
                          color: item.isPaid ? "green" : "red",
                          fontWeight: "bold",
                        }}
                      >
                        {item.isPaid ? "Paid" : "Unpaid"}
                      </TableCell>
                      <TableCell
                        style={{
                          color: item.isFilled ? "green" : "red",
                          fontWeight: "bold",
                        }}
                      >
                        {item.isFilled ? "Filled" : "Not Fill"}
                      </TableCell>
                      <TableCell
                        style={{
                          color: item.isForwarded ? "green" : "red",
                          fontWeight: "bold",
                        }}
                      >
                        {item.isForwarded ? "Forward" : "Not Forward"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default ExamFormCheckStatus;
