import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Box } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const E_eligibility_Form = ({
  courseName,
  courseType,
  courseBranch,
  eligible,
  eligibilityGradPer,
}) => {
  const navigate = useNavigate();
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [timer, setTimer] = useState(180);
  const [disableSendOTP, setDisableSendOTP] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    otp: "",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        clearInterval(interval);
        setDisableSendOTP(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value.trim(),
    });
  };

  const sendOTP = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      return alert("Please enter a valid email address.");
    }
    setLoading(true);
    axios
      .post(process.env.REACT_APP_STD_OTP, { email: formData.email })
      .then((response) => {
        if (response.data && response.data.error === "OTP expired") {
          setLoading(false);
          return sendOTP();
        }
        swal("Success", "OTP sent successfully", "success");
        setShowOtpInput(true);
        setDisableSendOTP(true);
        setTimer(180);
      })
      .catch((error) => {
        swal(
          "Error",
          "Failed to send OTP! This Email is already  registered",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const verifyOTP = () => {
    const otp = formData.otp.trim();

    setFormData({ ...formData, otp: "" });
    axios
      .post(process.env.REACT_APP_VERIFY_OTP, {
        email: formData.email,
        otp,
      })
      .then((response) => {
        swal("Success", "OTP verified successfully", "success");
        setShowOtpInput(false);
        setOtpVerified(true);
        setDisableSendOTP(false);
      })
      .catch((error) => {
        swal("Error", "Incorrect OTP", "error");
      });
  };

  function FormSubmit() {
    if (!formData.email || !otpVerified) {
      alert("fill the email & otp first!!");
      return;
    }

    const email = formData.email;
    if (!checked) {
      alert("check the check box first!!");
      return;
    }

    navigate("/Epravesh/Student/RegisterForm", {
      state: { courseType, courseName, courseBranch, email },
    });
  }

  const handleCheckBox = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <Container
      fluid
      className="shadow p-3  mb-9 bg-body rounded"
      style={{ maxWidth: "90%", height: "50vh", overflowX: "hidden" }}
    >
      <Box
        marginBottom={"20px"}
        backgroundColor={"#264653"}
        p={4}
        h={50}
        color="white"
        mt={8}
        borderRadius="md"
        shadow="md"
      >
        Check Eligibility
      </Box>
      <Table>
        <tbody>
          <tr>
            <td>Eligibility</td>
            <td>:</td>
            <td
              style={{
                color: "#e63946",
                fontFamily: "inherit",
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              {eligible.eligibility}
            </td>
          </tr>
          <tr>
            <td>Eligibility %</td>
            <td>:</td>
            <td>
              <Row className="equal-width-boxes">
                <Col xs={6} md={2}>
                  General: {eligibilityGradPer.gen}
                </Col>
                <Col xs={6} md={2}>
                  SC: {eligibilityGradPer.sc}
                </Col>
                <Col xs={6} md={2}>
                  ST: {eligibilityGradPer.st}
                </Col>
                <Col xs={6} md={2}>
                  OBC: {eligibilityGradPer.obc}
                </Col>
                <Col xs={6} md={2}>
                  PH: {eligibilityGradPer.ph}
                </Col>
              </Row>
            </td>
          </tr>
        </tbody>
      </Table>

     
<style>
  {
    `
    /* Base Styles */
.form-input {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

input,
otp-button{
  width: 80%;
  padding: 10px 10px;
  // margin-bottom: 10px;
  font-size: 15px;
  border-radius: 5px;
  border: none;
}

otp-button:disabled {
  opacity: 0.5;
}

/* Button States */
otp-button.loading {
  background-color: #ccc; /* Disabled button state */
}

otp-button.otp-sent {
  background-color: #28a745; /* Green button for OTP Sent */
}

otp-button.resend {
  background-color: #ffc107; /* Yellow button for Resend OTP */
}

/* Media Query for 480px and below */
@media (max-width: 480px) {
  /* Stack form elements vertically */
  .form-input {
    flex-direction: column;
    gap: 8px; /* Reduced gap between elements */
  }

  /* Make input fields full-width */
  input {
    width: 100%;
    font-size: 14px; /* Slightly smaller font size for better fit */
    padding: 8px 16px; /* Reduced padding for small screens */
  }

  /* Adjust buttons to be full-width */
  otp-button {
    width: 100%;
    padding: 10px 20px;
    font-size: 14px;
    margin-top: 10px;
  }

  /* Button styling changes */
  .otp-button {
    width:50%;
  }

  /* Adjust spacing and button layout for OTP inputs */
  .otp-input {
    margin-bottom: 12px;
  }
}
`
  }
</style>
<Row style={{ display: "flex" }}>
  <Row className="form-input">
    <Col>
      <Input
        type="email"
        placeholder="Enter Email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        autoComplete="off"
      />
    </Col>

    <Col>
      <Button
        className={`otp-button ${
          loading ? 'loading' : disableSendOTP ? 'otp-sent' : ''
        }`}
        onClick={sendOTP}
        disabled={!formData.email || disableSendOTP || loading}
      >
        {loading
          ? "Sending OTP..."
          : disableSendOTP
          ? `Resend OTP(${timer}s)`
          : `Send OTP`}
      </Button>
    </Col>

    {showOtpInput && (
      <>
        <Col>
          <Input
            type="text"
            placeholder="Enter OTP"
            name="otp"
            value={formData.otp}
            onChange={handleChange}
          />
        </Col>
        <Col>
          <Button
            className="verify-button"
            onClick={verifyOTP}
          >
            Verify OTP
          </Button>
        </Col>
      </>
    )}
  </Row>
</Row>



      <style jsx>{`
  @media (max-width: 480px) {
    button {
      font-size: 14px; /* Adjust font size for small screens */
      padding: 8px 16px; /* Reduce padding */
      width: 50%; /* Full-width buttons */
    }
    input {
      width: 100%; /* Full-width inputs */
    }
    .col {
      margin-bottom: 10px; /* Add spacing between rows */
    }
  // .elig_dis {
  //   flex-direction: column; /* Stack checkbox and text vertically on small screens */
  //   align-items: flex-start; /* Align items to the start */
  //   gap: 4px; /* Adjust spacing for smaller screens */
  // }

  }




.elig_dis {
  display: flex;
  align-items: center; /* Align checkbox and text vertically */
  gap: 8px; /* Space between checkbox and text */
}

.elig_span {
  white-space: nowrap; /* Prevent text wrapping */
}


  .elig_button{
    margin: 20px auto;
          display: block;
          background-color: #264653;
          color: white;
          width: w-20 w-md-auto;
          border: none;
          border-radius: 5px;
          font-size: 15px;
          padding: 10px 20px;
          }

 .elig_button:hover{
  background-color: #264653;
 }

`}



</style>
      <Box
  className="elig_dis"
  style={{
    display: "flex",
    alignItems: "center", // Align checkbox and text vertically
    gap: "8px", // Space between the checkbox and text
  }}
>
  <input
    className="check_box"
    type="checkbox"
    checked={checked}
    onChange={handleCheckBox}
    style={{
      width: "16px", // Optional: adjust checkbox size
      height: "16px",
    }}
  />
  <b className="elig_span">I have read all the information</b>
</Box>

      <Button
       className='elig_button'
        onClick={FormSubmit}
      >
        Continue
      </Button>
    </Container>
  );
};

export default E_eligibility_Form;