import React, { useState } from "react";
import { FormControl } from "@mui/material";
import { Tabs, Tab, Button, Row } from "react-bootstrap";
import axios from "axios"; // For API requests
import AdminDashboard from "../AdminDashboard";
import Manually_FormForTable from "./Manually_FormForTable";


function Manually_FormForByAdmin() {
  const [showTable, setShowTable] = useState(false);
  const [key, setKey] = useState("first");

  // States for the form fields
  const [semester, setSemester] = useState("");
  const [category, setCategory] = useState("");
  const [examType, setExamType] = useState("");
  const [enrollmentNo, setEnrollmentNo] = useState("");

  // State for API data
  const [apiData, setApiData] = useState(null);

  // Handle search button click
  const handleSearch = async () => {
    // Validate form fields if necessary
    if (!semester || !category || !examType || !enrollmentNo) {
      alert("Please fill in all fields");
      return;
    }

    try {
      // Make an API call with query parameters
      const response = await axios.get(
        `https://sssutms.ac.in/apitest/get-student-exam-details?enrollmentNumber=${enrollmentNo}&semester=${semester}&exam_type=${examType}&category=${category}`
      );

      // Store the response data
      setApiData(response.data);

      // Show the table after data is fetched
      setShowTable(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Failed to fetch data");
    }
  };
  return (
    <>
      <AdminDashboard />
      <style>
        {`

        *{
  font-family: 'Poppins', Sans-serif,
}
          .shadow-box {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            padding: 10px;
            margin-top: 5px;
            width: 110%;
            margin-left:75px;
            height:370px;
          }
          .custom-tabs .nav-item {
            height: 30px;
          }
          .custom-tabs .nav-link {
            padding: 0px;
            font-size: 14px;
          }
          .custom-tab {
            padding: 5px 10px;
            font-size: 14px;
          }
          #admission-tabs .nav-link {
            padding: 5px 10px;
            font-size: 14px;
          }
          .header {
            height: 40px;
            background-color: #001d3d;
            border-radius: 10px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-family: italic;
          }
          .login-input {
            margin-bottom:8px;
          }
          .login-input:focus {
            outline: none;
            border-bottom: 2px solid #344e41;
          }
          @media (max-width: 768px) {
            .shadow-box {
              padding: 10px;
              margin-top: 10px;
            }
            .header {
              font-size: 16px;
              height: 40px;
            }
            .login-input {
              font-size: 0.9rem;
            }
          }
          @media (max-width: 576px) {
            .shadow-box {
              margin: 10px;
              margin-Left:76px;
            }
            .container-fluid {
              width: 100%;
              padding-right: 0;
              padding-left: 0;
            }
          }
          @media (min-width: 992px) {
            .custom-laptop-style {
              margin-left: 5%;
            }
          }
        `}
      </style>

      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "20%",
            height: "300px",
            padding: "10px",
            marginTop: "60px",
          }}
        >
          <div className="shadow-box">
            <h6
              style={{
                fontSize: "15px",
                fontWeight: 700,
                color: "#A02334",
                marginBottom: "8px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Verification By Admin
            </h6>
            <Tabs
              id="admission-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="first" tabClassName="custom-tab">
                <form style={{ padding: "10px" }}>
                  <Row>
                    <FormControl>
                      <label>Semster</label>
                      <select
                        className="form-select form-select-sm login-input"
                        value={semester}
                        onChange={(e) => setSemester(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        {/* Add options for semesters */}
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        {/* Add other options as needed */}
                      </select>
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl>
                      <label>Category</label>
                      <select
                        className="form-select form-select-sm login-input"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Regular">Regular</option>
                        {/* Add other categories */}
                      </select>
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl>
                      <label> Exam Type</label>
                      <select
                        className="form-select form-select-sm login-input"
                        value={examType}
                        onChange={(e) => setExamType(e.target.value)}
                        required
                      >
                        <option value="">Select</option>
                        <option value="main">main</option>
                        {/* Add other exam types */}
                      </select>
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl>
                      <label>Enrollment No.</label>
                      <input
                        type="text"
                        value={enrollmentNo}
                        onChange={(e) => setEnrollmentNo(e.target.value)}
                        style={{
                          borderColor: "black",
                          borderWidth: "1px",
                          borderRadius: "5px",
                          padding: "5px",
                        }}
                        required
                      />
                    </FormControl>
                  </Row>
                  <br />

                  <Row>
                    <Button
                      type="button"
                      style={{
                        marginLeft: "50px",
                        backgroundColor: "#004e92",
                        width: "150px",
                        height: "30px",
                        padding: "2px",
                      }}
                      onClick={handleSearch}
                    >
                      View Records
                    </Button>
                  </Row>
                </form>
              </Tab>
            </Tabs>
          </div>
        </div>

        <div>
          {showTable && apiData && (
            <div>
              <Manually_FormForTable data={apiData} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default Manually_FormForByAdmin;
