import React, { useState, useEffect } from "react";
import { FormControl } from "@mui/material";
import { Row } from "react-bootstrap";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Form } from "react-bootstrap";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import swal from "sweetalert";
import AdminDashboard from "../AdminDashboard";
import ExamCenterAllocate from "./ExamCenterAllocate_Table";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
const theme = createTheme();

const ExamCenterAllocateSearch = () => {
  const [courseData, setCourseData] = useState({});
  const [selectedType, setSelectedType] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [loading, setLoading] = useState(false);
  const session = new Date().getFullYear();
  const [selectedSemester, setSelectedSemester] = useState("");
  const [selectedStartMeridian, setSelectedStartMeridian] = useState("AM");
  const [selectedEndMeridian, setSelectedEndMeridian] = useState("AM");
  const [selectedCenter, setSelectedCenter] = useState("");
  const [centercode, setCentercode] = useState("");
  const [selectedExamSession, setSelectedExamSession] = useState("");
  const [selectedStartTime, setSelectedStartTime] = useState("10:00");
  const [selectedEndTime, setSelectedEndTime] = useState("18:00");

  const hours = Array.from({ length: 24 }, (_, i) =>
    i.toString().padStart(2, "0")
  );
  const minutes = Array.from({ length: 60 }, (_, i) =>
    i.toString().padStart(2, "0")
  );
  const centerCodes = {
    "School of Engineering": "1301",
    "Faculty Of Education": "EDU456",
    "College Of Pharmacy": "PHR789",
  };

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v2/admin/courses?session=${session}`
        );
        const data = await response.json();
        const formattedData = {};

        data.forEach((course) => {
          if (!formattedData[course.courseType]) {
            formattedData[course.courseType] = {
              courses: [],
              branches: {},
            };
          }

          course.courseNames.forEach((courseName) => {
            formattedData[course.courseType].courses.push(
              courseName.courseName
            );
            formattedData[course.courseType].branches[courseName.courseName] =
              courseName.branches.map((branch) => branch.branchName);
          });
        });

        setCourseData(formattedData);
      } catch (error) {
        console.error("Error fetching course data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [session]);

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setSelectedCourse("");
    setSelectedBranch("");
  };

  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
  };

  const handleBranchChange = (e) => {
    setSelectedBranch(e.target.value);
  };

  const handleAllotCenter = async () => {
    if (
      !selectedSemester ||
      !selectedExamSession ||
      !selectedType ||
      !selectedCourse ||
      !selectedBranch ||
      !selectedCenter ||
      !centercode ||
      !selectedStartTime ||
      !selectedStartMeridian ||
      !selectedEndTime ||
      !selectedEndMeridian
    ) {
      swal({
        icon: "warning",
        title: "Error! allocate center",
        text: "Please fill all fields first.",
        buttons: "OK",
      });
      return;
    }

    const formattedStartTime = `${selectedStartTime} ${selectedStartMeridian}`;
    const formattedEndTime = `${selectedEndTime} ${selectedEndMeridian}`;

    const payload = {
      Semester: selectedSemester,
      EndTime: formattedEndTime,
      StartTime: formattedStartTime,
      CenterName: selectedCenter,
      centerCode: centercode,
      Exam_Session: selectedExamSession,
      courseBranch: selectedBranch,
      courseType: selectedType,
      courseName: selectedCourse,
    };

    try {
      const response = await fetch(
        "https://sssutms.ac.in/apitest/admin/save-center",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const result = await response.json();

        swal({
          title: "Success",
          text: `Center saved successfully!\nExam Start Time: ${formattedStartTime}\nExam End Time: ${formattedEndTime}`,
          icon: "success",
          buttons: "OK",
        });

        // Reset all fields after success
        setSelectedSemester("");
        setSelectedExamSession("");
        setSelectedType("");
        setSelectedCourse("");
        setSelectedBranch("");
        setSelectedCenter("");
        setCentercode("");
        setSelectedStartTime("");
        setSelectedStartMeridian("");
        setSelectedEndTime("");
        setSelectedEndMeridian("");
      } else {
        const error = await response.json();
        alert(`Error saving center: ${error.message}`);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while saving the center.");
    }
  };

  // Handle center change and update center code dynamically
  const handleCenterChange = (e) => {
    const center = e.target.value;
    setSelectedCenter(center);
    setCentercode(centerCodes[center] || ""); // Update the center code
  };

  console.log(setSelectedStartTime, "dhshvsvsS");
  return (
    <>
      <style>
        {`.time-picker {
  border: 1px solid black;
  border-radius: 5px;
  width: 150px;
  height: 30px;
}

*{
  font-family: 'Poppins',Sans-serif;
  }


.exam_center{
 width: 90%;
background-color: #00cdac;
margin-top: 80px;
margin-left: 100px;
min-height: 100px;
    }
              
             
    .center_h6{
                padding: 10px;
                  // margin: 0;
                 margin-top:-10px;
                  color: black;
                  text-align: center;
                  font-weight:700;
                  font-family: 'Poppins',Sans-serif;
    }

    
                  label{
                     color:black;  
                       font-size: 17px;
          font-family: 'Poppins',Sans-serif;
          //  font-weight:700;

          }
          
          
        .custom-select {
  font-family: 'Poppins', Sans-serif;
  font-size:15px;
// font-weight:700;
   border-color: black;
     border-radius: 5px;
}

.custom-select option {
  font-family: 'Poppins', Sans-serif;
   font-size:15px;
}

`}
      </style>

      <AdminDashboard />
      <ThemeProvider theme={theme}>
        <div className="shadow p-3 bg-body rounded exam_center">
          <h6 className="center_h6"> SELECTION PANEL</h6>

          <Grid container spacing={2}>
            {/* Course Type */}
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <label> Course Type </label>
                <Form.Select
                  className="custom-select"
                  value={selectedType}
                  onChange={handleTypeChange}
                >
                  <option value="">Select Type</option>
                  {Object.keys(courseData).map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Select>
              </FormControl>
            </Grid>

            {/* Course */}
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <label>Course</label>
                <Form.Select
                  className="custom-select"
                  value={selectedCourse}
                  onChange={handleCourseChange}
                  disabled={!selectedType}
                >
                  <option value="">Select Course</option>
                  {selectedType &&
                    courseData[selectedType].courses
                      .filter(
                        (course) =>
                          course !==
                          "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)"
                      )
                      .map((course) => (
                        <option key={course} value={course}>
                          {course}
                        </option>
                      ))}
                </Form.Select>
              </FormControl>
            </Grid>

            {/* Branch */}
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <label>Branch</label>
                <Form.Select
                  className="custom-select"
                  value={selectedBranch}
                  onChange={handleBranchChange}
                  disabled={!selectedCourse}
                >
                  <option value="">Select Branch</option>
                  {selectedCourse &&
                    courseData[selectedType].branches[selectedCourse].map(
                      (branch) => (
                        <option key={branch} value={branch}>
                          {branch}
                        </option>
                      )
                    )}
                </Form.Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <label>Semester</label>
                <Form.Select
                  className="custom-select"
                  required
                  value={selectedSemester}
                  onChange={(e) => setSelectedSemester(e.target.value)}
                >
                  <option value="">Select</option>
                  {Array.from({ length: 8 }, (_, i) => (
                    <option key={i + 1} value={i + 1}>
                      {i + 1} SEMESTER
                    </option>
                  ))}
                </Form.Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt="1px">
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <label>Center</label>
                <Form.Select
                  className="custom-select"
                  required
                  value={selectedCenter}
                  onChange={handleCenterChange}
                >
                  <option value="">Select</option>
                  <option value="School of Engineering">
                    School of Engineering
                  </option>
                  <option value="Faculty Of Education">
                    Faculty of Education
                  </option>
                  <option value="College Of Pharmacy">
                    College of Pharmacy
                  </option>
                </Form.Select>
              </FormControl>
            </Grid>

            {/* Center Code */}
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <label>Center Code</label>
                <Form.Select
                  className="custom-select"
                  required
                  value={centercode}
                  disabled
                  onChange={(e) => setCentercode(e.target.value)}
                >
                  <option value="">Select</option>
                  {Object.entries(centerCodes).map(([center, code]) => (
                    <option key={code} value={code}>
                      {code}
                    </option>
                  ))}
                </Form.Select>
              </FormControl>
            </Grid>

            {/* Exam Session */}
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <label>Exam Session</label>
                <Form.Select
                  className="custom-select"
                  required
                  value={selectedExamSession}
                  onChange={(e) => setSelectedExamSession(e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="DEC-23">DEC-2023</option>
                  <option value="DEC-24">DEC-2024</option>
                </Form.Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt="1px">
            <Grid item xs={12} md={3}>
              <label>Start Time</label>{" "}
              <div
                style={{
                  display: "flex", // Use Flexbox
                  alignItems: "center", // Vertically align items
                  gap: "10px", // Add spacing between elements
                }}
              >
                <TimePicker
                  onChange={setSelectedStartTime}
                  value={selectedStartTime}
                  disableClock={true} // Hide clock icon
                  format="HH:mm" // 24-hour format
                  clearIcon={null} // Remove clear button
                  className="time-picker"
                />
                <Form.Select
                  className="custom-select"
                  value={selectedStartMeridian}
                  onChange={(e) => setSelectedStartMeridian(e.target.value)}
                  style={{
                    borderRadius: "5px",
                    width: "150px",
                    height: "30px",
                    borderColor: "black",
                  }}
                >
                  <option value="">Select</option>
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </Form.Select>
              </div>
            </Grid>

            <Grid item xs={12} md={3}>
              <label>End Time</label>{" "}
              <div
                style={{
                  display: "flex", // Use Flexbox
                  alignItems: "center", // Vertically align items
                  gap: "10px", // Add spacing between elements
                }}
              >
                <TimePicker
                  onChange={setSelectedEndTime}
                  value={selectedEndTime}
                  disableClock={true} // Hide clock icon
                  format="HH:mm" // 24-hour format
                  clearIcon={null} // Remove clear button
                  className="time-picker"
                />

                <Form.Select
                  className="custom-select"
                  value={selectedEndMeridian}
                  onChange={(e) => setSelectedEndMeridian(e.target.value)}
                  style={{
                    borderRadius: "5px",
                    width: "80px",
                    height: "30px",
                    borderColor: "black",
                  }}
                >
                  <option value="">Select</option>
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </Form.Select>
              </div>
            </Grid>

            <Row>
              <Button
                type="button"
                style={{
                  marginLeft: "35%",
                  backgroundColor: "#023047",
                  width: "150px",
                  height: "40px",
                  padding: "2px",
                  color: "white",
                  marginTop: "35px",
                }}
                onClick={handleAllotCenter}
              >
                Allot Center
              </Button>
            </Row>
          </Grid>
        </div>

        <br></br>
        <br></br>

        <div>
          <ExamCenterAllocate />
        </div>
      </ThemeProvider>
    </>
  );
};

export default ExamCenterAllocateSearch;
