import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";

const Examsessiontable = () => {
  const [sessions, setSessions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(7);


  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await axios.get(
          "https://sssutms.ac.in/apitest/v1/admin/all-exam-sessions"
        );
        setSessions(response.data.Sessions); 
      } catch (error) {
        console.error("Error fetching sessions:", error);
      }
    };
  
    fetchSessions(); 
  }, []);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
      sortable: true,
      width: "80px",
    },
    {
      name: "Session",
      selector: (row) => row.session,
      sortable: true,
      wrap: true,
    },
    {
      name: "Session Name",
      selector: (row) => row.sessionName,
      sortable: true,
      wrap: true,
    },
    {
      name: "Anually",
      selector: (row) => row.IsAnnual,
      sortable: true,
      wrap: true,
    },
    {
      name: "Year",
      selector: (row) => row.year,
      sortable: true,
      width: "150px",
    },
    {
      name: "Is Supply",
      selector: (row) => (row.isSupply ? "True" : "False"),
      sortable: true,
      width: "100px",
    },
  ];

  const indexOfLastItem = currentPage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentData = sessions.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div
      className="container mt-4"
      style={{
        marginLeft: "15%",
        width: "110%",
        fontFamily: "'Poppins',Sans-serif",
      }}
    >
      <DataTable
        title="Exam Session"
        columns={columns}
        data={currentData}
        pagination
        paginationServer
        paginationTotalRows={sessions.length}
        paginationPerPage={rowsPerPage}
        paginationRowsPerPageOptions={[7]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        highlightOnHover
        style={{ borderCollapse: "collapse" }}
        className="table-bordered"
        customStyles={{
          headCells: {
            style: {
              border: "1px solid #adb5bd",
              padding: "12px",
              backgroundColor: "#415a77",
              color: "white",
              fontFamily: "'Poppins',Sans-serif",
            },
          },
          cells: {
            style: {
              border: "1px solid #adb5bd",
              padding: "12px",
              fontFamily: "'Poppins',Sans-serif",
            },
          },
        }}
      />
    </div>
  );
};

export default Examsessiontable;
