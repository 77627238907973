import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import { Link } from "react-router-dom";

function NIRF() {
  return (
    <>
      <Nav />
      <style>
        {`

  

.cont{
margin-top:20px;}



h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  .alt-two h1 {
    text-align:center;
  }
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }
  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
.listy{
font-size:21px; 
 margin-left:15px;
 margin-top:8px;
// font-weight:700;
 list-style-type: disc; /* This will add bullets to the list */

  
}

   .llis{
  font-size:17px; 
 margin-left:15px;
 margin-top:18px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



 
  
     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-40px;
          margin-left:50px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 80px;
border-top: 5px solid #FA812F;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}


 



@media only screen and (max-width: 480px){




// .h2 title-main{
// margin-left:18px}




h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2 className="h2 title-main" style={{ color: "#780000" }}>
        NIRF
        </h2>
        <hr className="title-hr"></hr>
      <br/>
        <div className="row dirr">
          <div className="col-lg-9 padding-section">
            <h5
              // className="mt-1"
              style={{
                textAlign: "center",
              marginTop:'-20px',
                fontFamily: "'poppins',sansSerif",
               
                 color:"#264653"
              }}
            >
          National Institutional Ranking Framework
            </h5>

            <p
  className="mt-1"
  style={{
    textAlign: "center",
    fontFamily: "'Poppins', sans-serif",
    lineHeight: "1.2", // Adjust this value to control spacing between lines
  }}
>
  <span style={{ color: "#bc4749" }}>Ministry of Education</span>
  <br />
  <span style={{ color: "#bc4749" }}>Government of India</span>
  <br />
  <span style={{ color: "#bc4749" }}>Welcome to Data Capturing System: OVERALL</span>
</p>


            <br />

            <h6
              style={{
                fontFamily: "'poppins',sansSerif",
                marginLeft: "80px",
                color: "#4C1F7A",
              }}
            >
              {" "}
              Submitted Institute Data for NIRF '2024' <br/>
              Institute Name: SRI SATYA SAI UNIVERSITY OF TECHNOLOGY & MEDICAL SCIENCES, SEHORE [IR-O-U-0728]
            </h6>

           
            <br></br>
        


            <style>
  {
    `
  .tann{
  margin-left:40px;}
 @media (min-width: 48em) {
   .container {
     margin: 2%;
  }
}
 @media (min-width: 75em) {
   .container {
     margin: 2em auto;
     max-width: 75em;
  }
}
 .responsive-table {
   width: 100%;
   margin-bottom: 1.5em;
   border-spacing: 0;
}
 @media (min-width: 48em) {
   .responsive-table {
     font-size: 0.9em;
  }
}
 @media (min-width: 62em) {
   .responsive-table {
     font-size: 1em;
  }
}
 .responsive-table thead {
   position: absolute;
   clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
   padding: 0;
   border: 0;
   height: 1px;
   width: 1px;
   overflow: hidden;
}
 @media (min-width: 48em) {
   .responsive-table thead {
     position: relative;
     clip: auto;
     height: auto;
     width: auto;
     overflow: auto;
  }
}
 .responsive-table thead th {
   background-color: #387478;
   border: 1px solid rgba(134, 188, 37, 1);
   font-weight: normal;
   text-align: center;
   color: white;
}
 .responsive-table thead th:first-of-type {
   text-align: left;
}
 .responsive-table tbody, .responsive-table tr, .responsive-table th, .responsive-table td {
   display: block;
   padding: 0;
   text-align: left;
   white-space: normal;
}
 @media (min-width: 48em) {
   .responsive-table tr {
     display: table-row;
  }
}
 .responsive-table th, .responsive-table td {
   padding: 0.5em;
   vertical-align: middle;
}
 @media (min-width: 30em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em 0.5em;
  }
}
 @media (min-width: 48em) {
   .responsive-table th, .responsive-table td {
     display: table-cell;
     padding: 0.5em;
  }
}
 @media (min-width: 62em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em 0.5em;
  }
}
 @media (min-width: 75em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em;
  }
}
 .responsive-table caption {
   margin-bottom: 1em;
   font-size: 1em;
   font-weight: bold;
   text-align: center;
}
 @media (min-width: 48em) {
   .responsive-table caption {
     font-size: 1.5em;
  }
}
 .responsive-table tfoot {
   font-size: 0.8em;
   font-style: italic;
}
 @media (min-width: 62em) {
   .responsive-table tfoot {
     font-size: 0.9em;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody {
     display: table-row-group;
  }
}
 .responsive-table tbody tr {
   margin-bottom: 1em;
}
 @media (min-width: 48em) {
   .responsive-table tbody tr {
     display: table-row;
     border-width: 1px;
  }
}
 .responsive-table tbody tr:last-of-type {
   margin-bottom: 0;
}
 @media (min-width: 48em) {
   .responsive-table tbody tr:nth-of-type(even) {
     background-color: rgba(0, 0, 0, .12);
  }
}
 .responsive-table tbody th[scope="row"] {
   background-color: rgba(38, 137, 13, 1);
   color: white;
}
 @media (min-width: 30em) {
   .responsive-table tbody th[scope="row"] {
     border-left: 1px solid rgba(134, 188, 37, 1);
     border-bottom: 1px solid rgba(134, 188, 37, 1);
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody th[scope="row"] {
     background-color: transparent;
     color: rgba(0, 0, 1, );
     text-align: left;
  }
}
 .responsive-table tbody td {
   text-align: right;
}
 @media (min-width: 48em) {
   .responsive-table tbody td {
     border-left: 1px solid rgba(134, 188, 37, 1);
     border-bottom: 1px solid rgba(134, 188, 37, 1);
     text-align: center;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody td:last-of-type {
     border-right: 1px solid rgba(134, 188, 37, 1);
  }
}
 .responsive-table tbody td[data-type=currency] {
   text-align: right;
}
 .responsive-table tbody td[data-title]:before {
   content: attr(data-title);
   float: left;
   font-size: 0.8em;
   color: rgba(0, 0, 0, .54);
}
 @media (min-width: 30em) {
   .responsive-table tbody td[data-title]:before {
     font-size: 0.9em;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody td[data-title]:before {
     content: none;
  }
}
 `
  }
  </style>
            <div className="tann">
  <table className="responsive-table" style={{width:"50%", marginLeft:"20%"}}>
  
    <thead>
      <tr>
        <th scope="col">S. No.</th>
        <th scope="col">Form</th>
        <th scope="col">Link</th> 
      
      
      </tr>
    </thead>
   
    <tbody>
   
      <tr>
        <td >1</td>
        <td> ENGINEERING</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/NIRF/NRIF_Eng.5e3ba35bf9376cd308b6.pdf' target='_blank'><b> Click here</b></Link></td>    
      </tr>
      <tr>
        <td >2</td>
        <td>    
        PHARMACY</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/NIRF/NRIF_Agri.f7230e609082a9d1b2cd.pdf' target='_blank'><b>    Click here</b></Link></td>
      </tr>
      <tr>
        <td >3</td>
        <td>AGRICULTURE AND ALLIED SECTORS</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/NIRF/NIRF_Phar..67f8cc4e882a0eb616a4.pdf' target='_blank'><b>Click here</b></Link></td>
      </tr>
      
    
    </tbody>
  </table>
</div>
         
          </div>
          <div className="col-lg-3">
            <ul className="list-icon">
              <h5
                style={{ fontFamily: "'poppins',sansSerif", color: "#243642" }}
              >
                Research
              </h5>
              <br></br>{" "}
              <li className="lli">
                <a href="/Director">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Director (R&D)
                </a>
              </li>
              <li className="lli">
                <a href="/R&D-Cell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>R & D Cell
                </a>
              </li>
              <li className="lli">
                <a href="/Council-For-Research">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Council For
                  Research{" "}
                </a>
              </li>
              <li className="lli">
                <a href="/Research-Policy">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Research Policy
                </a>
              </li>
              <li className="lli">
                <a href="/Consultancy-Services">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Consultancy
                  Services{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/Patents">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Patents{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Collaboration &
                  Mou{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/IICCell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>IIC Cell{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/E-Resources">
                  <i class="fa fa-adjust" aria-hidden="true"></i>E-Resources{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/Exposition">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Exposition{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/UGAndPGScholarsProject">
                  <i class="fa fa-adjust" aria-hidden="true"></i>UG & PG
                  Scholars Project{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/NPTEL">
                  <i class="fa fa-adjust" aria-hidden="true"></i>NPTEL{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* </section> */}
      <Footerpage />
    </>
  );
}

export default  NIRF ;