import React from 'react'


import Nav from '../../HomePage/Nav';
import Footerpage from '../../HomePage/Footerpage';

const Design = () => {

  return (
  <>
 
 <style>
        {
          `
 .lli{
  font-size:14px; 
   margin-left:5px;
 margin-top:-3px;
   list-style-type: none; /* Removes bullets */
   }
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 8px 10px 5px 0;
      text-align: center;
      width: 28px;
  }

 .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-80px;
          margin-left:20px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  
.title-main{
  font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }

.cont{
margin-top:20px;
}

.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){


h5{
margin:20px;
padding:30px}

    .prog5{
     font-weight:700;
     color:white;

     margin-left:-20px;
     margin-top:-20px;
     font-family:'Open Sans', sans-serif
     }


.head4{

font-size:15px;
margin-left:-35px;}

 .list-icon {
 margin-top:50px;                                                                       
 margin-left:30px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }

.head5{
font-size:15px;
text-align:left;}



.h2 title-main{
margin-left:20px}



.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}


 

    
    
    `}</style>

<Nav/>
     
     
     
      <div className="cont" >
        <h2 className="h2 title-main" style={{ marginLeft:'30px',color: '#780000' }}>Design
        </h2>
        <hr className="title-hr"></hr><br></br>
 <div className="row dirr">

          <div className="col-lg-9 padding-section">
          <style>
    {
        `
    .head5{
    font-family: "poppins",sans-serif;
      margin-left:20px;
     }


     .head4{
     font-weight:700;
     font-family:'Open Sans', Sans-serif;
     color:#264653;
     margin-left:80px;
     }

  
    .container {
      display: flex;
      width:100%;
      font-family: 'Open Sans', sans-serif;
    }
   

    .content {
      flex: 3;
      padding: 20px;
    }
    








    
  `
    }
  </style>

 <div className="container">
 

  <div className="content">
   
          <h6 className='head4'>  INSTITUTE NAME :SCHOOL OF DESIGN    </h6><br></br>
 <style>
              {`
    .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    } 
      
    
    table {
      width: 48%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    } 
      
    
    th, td {
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family: 'poppins',sansSerif;
    }    @media screen and (max-width: 768px) {
      .table-container {
        flex-direction: column; /* Stack tables on smaller screens */
      }      table {
        width: 100%; /* Make tables full-width on smaller screens */
      }
    }
  `}
            </style>





            <table style={{ width: '90%',marginLeft:'70px', marginTop: '10px', borderCollapse: 'collapse' }}>
              <tbody><tr> <th>Course</th> <th>Specialization</th> <th>Duration in Year

</th>
           </tr>
           <tr>
                 <td style={{ border: '1px solid #999', padding: '5px' }}>Bachelor of Design </td> 
                 <td style={{ border: '1px solid #999', padding: '5px' }}>Industrial Design

</td>
                  <td style={{ border: '1px solid #999', padding: '5px' }}>4 YEARS</td>
                  </tr>
                  <tr>
                 <td style={{ border: '1px solid #999', padding: '5px' }}>Bachelor of Design </td> 
                 <td style={{ border: '1px solid #999', padding: '5px' }}>Communication Design


</td>
                  <td style={{ border: '1px solid #999', padding: '5px' }}>4 YEARS</td>
                  </tr>
               
                  <tr>
                 <td style={{ border: '1px solid #999', padding: '5px' }}>Bachelor of Design </td> 
                 <td style={{ border: '1px solid #999', padding: '5px' }}>Textile Design


</td>
                  <td style={{ border: '1px solid #999', padding: '5px' }}>4 YEARS</td>
                  </tr>
                  <tr>
                 <td style={{ border: '1px solid #999', padding: '5px' }}>Bachelor of Design </td> 
                 <td style={{ border: '1px solid #999', padding: '5px' }}>Interior Design


</td>
                  <td style={{ border: '1px solid #999', padding: '5px' }}>4 YEARS</td>
                  </tr>
                  <tr>
                 <td style={{ border: '1px solid #999', padding: '5px' }}>Bachelor of Design </td> 
                 <td style={{ border: '1px solid #999', padding: '5px' }}>Product Design


</td>
                  <td style={{ border: '1px solid #999', padding: '5px' }}>4 YEARS</td>
                  </tr>
                  <tr>
                 <td style={{ border: '1px solid #999', padding: '5px' }}>Bachelor of Architecture

</td> 
                 <td style={{ border: '1px solid #999', padding: '5px' }}>Architecture


</td>
                  <td style={{ border: '1px solid #999', padding: '5px' }}>5 YEARS</td>
                  </tr>
              

                     </tbody>
                          </table>

    
                
              
                        
</div></div><br />
 </div>
          <div className="col-lg-3">

            <ul className='list-icon' >
              <h5 style={{ fontFamily: "'poppins',sansSerif", color: '#243642' }}>Faculties And Departments</h5>
              <br></br>  <li className='lli'>
                <a href='/enggtechno'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Engineering And Technology</a></li>
              <li className='lli'>
              <a href='/pharma'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Pharmacy</a></li>
              <li className='lli'>
              <a href='/edu'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Education </a></li>
              <li className='lli'>
              <a href='/management'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Management</a></li>
              <li className='lli'>
              <a href='/design'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Design </a></li>
              <li className='lli'>  
              <a href='/humanities'>    
                          <i class="fa fa-adjust" aria-hidden="true"></i>Humanities And Languages </a></li>
 <li className='lli'>  
 <a href='/computer_science'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Computer Science And Application </a></li>
 <li className='lli'>  
 <a href='/commerce'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Commerce </a></li>
 <li className='lli'> 
 <a href='/science'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Science  </a></li>
 <li className='lli'>  
 <a href='/ayurveda'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Ayurveda  </a></li>
 <li className='lli'> 
 <a href='/law_dept'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Law </a></li>
 <li className='lli'>  
 <a href='/homeopathy'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Homeopathy   </a></li>
 <li className='lli'> 
 <a href='/paramedical'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Paramedical </a></li>
 <li className='lli'> 
 <a href='/nursing'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Nursing </a></li>
            </ul>
          </div>
        </div>

 </div>


<Footerpage/>
</>
)
}
export default Design