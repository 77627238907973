import React, { useState } from "react";
import { FormControl } from "@mui/material";
import { Button, Row } from "react-bootstrap";
import AdminDashboard from "../Masters/Admin_Dashboard/AdminDashboard";
import axios from "axios";

function Std_Session_Change() {
  const [enteredValue, setEnteredValue] = useState(""); // Std Id input
  const [admissionSession, setAdmissionSession] = useState(""); // Admission Session input
  const [randomId, setRandomId] = useState(null); 
  const [password, setPassword] = useState(null);

  const handleSearchById = () => {
    if (!enteredValue || !admissionSession) {
      alert("Please enter both Student ID and Admission Session.");
      return;
    }

    axios
      .post("http://sssutms/apitest/admin/change-session", {
        randomId: enteredValue,
        admissionSession: admissionSession,
      })
      .then((response) => {
        if (response.data) {
          alert("Session Updated Successfully");
          console.log("Updated Student:", response.data);

          // Set the updated randomId in the state
          setRandomId(response.data.randomId);
          setPassword(response.data.randomPassword);
          console.log(response.data.randomId,"setrandom id")
        } else {
          alert("Update failed. No matching student found.");
        }
      })
      .catch((error) => {
        console.error("Error updating session:", error);
        alert("An error occurred. Please try again later.");
      });
  };

  return (
    <>
      <AdminDashboard />
      <div style={{ padding: "15px", marginTop: "80px", marginLeft: "100px" }}>
        <h1 style={{ fontSize: "20px", fontWeight: 700, color: "#A02334" }}>
          Admission Session Update
        </h1>

        <form>
          <Row style={{ alignItems: "center" }}>
            <FormControl style={{ flex: 1 }}>
              <input
                type="number"
                className="form-control"
                placeholder="Std Id"
                value={enteredValue}
                onChange={(e) => setEnteredValue(e.target.value)}
              />
            </FormControl>
            <FormControl style={{ flex: 1 }}>
              <input
                type="number"
                className="form-control"
                placeholder="Admission Session"
                value={admissionSession}
                onChange={(e) => setAdmissionSession(e.target.value)}
              />
            </FormControl>
            <Button
              style={{
                marginLeft: "10px",
                backgroundColor: "#004e92",
                width: "90px",
                height: "35px",
              }}
              onClick={handleSearchById}
            >
              Update
            </Button>
          </Row>
        </form>

        {/* Display the updated Random ID */}
        {randomId && (
          <div style={{ marginTop: "20px", fontWeight: "700", color: "#2a9d8f" }}>
            <h3>Updated Random ID: {randomId}</h3>
            <h3>Updated Password: {password}</h3>
          </div>
        )}
      </div>
    </>
  );
}

export default Std_Session_Change;


