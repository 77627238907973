import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import axios from "axios";

const Manually_FormForTable = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    if (data && data.subjects && Array.isArray(data.subjects)) {
      setSubjects(data.subjects);
      setLoading(false);
      // Pre-select compulsory subjects
      const preSelectedIds = data.subjects
        .filter((subject) => subject.compulsory)
        .map((subject) => subject._id);
      setSelectedStudentIds(preSelectedIds);
    } else {
      console.error("Data is not in the expected format:", data);
      setLoading(false);
    }
  }, [data]);

  const handleCheckboxChange = (subjectId) => {
    setSelectedStudentIds((prev) =>
      prev.includes(subjectId)
        ? prev.filter((id) => id !== subjectId)
        : [...prev, subjectId]
    );
  };
  const handleSubmit = async () => {
    const selectedSubjects = subjects
      .filter((subject) => selectedStudentIds.includes(subject._id))
      .map((subject) => {
        let subjectType = "";

        // Check for the subject type based on assessments
        if (subject.assessments) {
          if (subject.assessments.practical === true) {
            subjectType = "Practical";
          } else if (subject.assessments.theory === true) {
            subjectType = "Theory";
          }
        } else {
          console.warn("Missing assessments for subject:", subject.subjectName);
        }

        return {
          subjectName: subject.subjectName,
          subjectCode: subject.subjectCode,
          subjectType: subjectType || "Unknown", // Default if no assessment type is found
        };
      });

    const payload = {
      studentId: data.studentId,
      examFormId: data.examFormId,
      subjects: selectedSubjects,
    };

  

    swal({
      title: "Are you sure?",
      text: `You have selected ${selectedSubjects.length} subjects. Do you want to submit?`,
      icon: "warning",
      buttons: ["Cancel", "Submit"],
      dangerMode: true,
    }).then(async (willSubmit) => {
      if (willSubmit) {
        try {
          const response = await axios.post(
            "https://sssutms.ac.in/apitest/admin/forward-form",
            payload
          );
          if (response.status === 200) {
            swal(
              "Success!",
              "Your subjects have been submitted successfully.",
              "success"
            );
          } else {
            swal(
              "Error!",
              "There was an issue submitting your subjects. Please try again.",
              "error"
            );
          }
        } catch (error) {
          console.error("API Error:", error);
          swal(
            "Error!",
            "Failed to submit subjects. Please try again.",
            "error"
          );
        }
      } else {
        swal("Cancelled", "Your submission was cancelled.", "info");
      }
    });
  };

  const columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
      sortable: true,
      width: "70px",
      style: { fontSize: "13px" },
    },
    {
      name: "Paper Code",
      selector: (row) => row.paperCode,
      sortable: true,
      width: "130px",
      style: { fontSize: "13px" },
    },
    {
      name: "Subject Code",
      selector: (row) => row.subjectCode,
      sortable: true,
      width: "140px",
      style: { fontSize: "13px" },
    },
    {
      name: "Sub_Seq",
      selector: (row) => row.subSeq,
      sortable: true,
      width: "100px",
      style: { fontSize: "13px" },
    },
    {
      name: "Subject Name",
      selector: (row) => row.subjectName.toUpperCase(),
      sortable: true,
      width: "420px",
      style: { textTransform: "uppercase", fontSize: "13px" },
    },
    {
      name: "Select",
      width: "70px",
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="checkbox"
            style={{ marginRight: "8px", accentColor: "#0a9396" }}
            checked={selectedStudentIds.includes(row._id) || row.compulsory}
            onChange={() => handleCheckboxChange(row._id)}
            disabled={row.compulsory} // Prevent unchecking compulsory subjects
          />
        </div>
      ),
      sortable: false,
    },
  ];

  const indexOfLastItem = currentPage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentData = subjects.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      <style>
        {`
          * {
            font-family: 'Poppins', Sans-serif;
          }
          .btn {
            height: 40px;
            width: 100px;
            padding: 5px;
               background-color:#004e92;
          }
          .sub_div {
            margin-left: 11%;
            width: 100%;
            margin-top: 80px;
            font-family: 'Poppins', Sans-serif;
          }
          .subtab_div {
            display: flex;
            font-size:15px;
            justify-content: space-between;
            margin-top: 70px;
            // margin-bottom: 5px;
            margin-left: 20px;
          }
        `}
      </style>

      <div className="sub_div">
        <div className="subtab_div">
          <h6>Subject [Select Subjects As per the Scheme]</h6>
          <button className="btn btn-primary" onClick={handleSubmit}>
            Submit
          </button>
        </div>
        <DataTable
          columns={columns}
          data={currentData}
          progressPending={loading}
          persistTableHead
          defaultSortField="subjectName"
          defaultSortAsc={true}
          highlightOnHover
          className="table-bordered"
          customStyles={{
            headCells: {
              style: {
                border: "1px solid #adb5bd",
                padding: "5px",
                backgroundColor: "#415a77",
                color: "white",
                position: "sticky",
                top: 0,
                fontSize: "15px",
                zIndex: 1,
                fontFamily: "'Poppins',Sans-serif",
              },
            },
            cells: {
              style: {
                border: "1px solid #adb5bd",
                padding: "5px",
                fontSize: "13px",
                fontFamily: "'Poppins',Sans-serif",
              },
            },
          }}
        />
        <br></br>
      </div>
    </>
  );
};
export default Manually_FormForTable;
