import React from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import OnlyHeader from '../../AdminComponent/OnlyHeader';

function EXAM_PSuccess() {
    const navigate = useNavigate();

  const handleRedirect = () => {
    // Redirect to the desired route
    navigate('/erp/student/studentexamform');
  };

  return (
    <>
    <OnlyHeader/>
    <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <Card style={{ width: '100%', maxWidth: '500px', padding: '30px', textAlign: 'center' }}>
        <Card.Body>
          <Card.Title as="h3" className="mb-3" style={{ color: '#28a745' }}>
            Payment Successful!
          </Card.Title>
          <Card.Text style={{ fontSize: '18px', marginBottom: '30px' }}>
            Your payment has been successfully processed. You can now proceed to the next step.
          </Card.Text>
          <Button 
            variant="success" 
            size="lg" 
            onClick={handleRedirect} 
            style={{ width: '100%', fontSize: '18px', padding: '10px' }}
          >
           Go Back
          </Button>
        </Card.Body>
      </Card>
    </Container>
    </>
  );
}

export default EXAM_PSuccess;
