import React from "react";
import Nav from "../HomePage/Nav";
import Footerpage from "../HomePage/Footerpage";
import CFO from "../images/Uni_Officials/CFO.png";

function Finance_Officer() {
  return (
    <>
      <Nav />
      <style>
        {`
    .contentleader
{
text-align:justify;
width:100%;
margin-top:20px;   padding: 15px; /* Adds padding around the content */
 line-height: 1.6; /* Adjusts the space between lines */

}
  

 
.leadership{
position:relative;
height:300px;
width:100%;
}




// .dean-img{
// height:340px;
// width:300px
// }


h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  .alt-two h1 {
    text-align:center;
  }
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }
  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



  .fa {
      display: inline-block;
      font: normal normal normal 14px / 1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  
     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-50px;
          display: flex;
        
          flex-direction: column; /* Default to vertical */
      }
  

.img-fluid {
  max-width: 100%;
  height: 180px;
  border-radius: 10px;
  margin-top: 30px;
  margin-left: auto; /* Center horizontally */
  margin-right: auto; /* Center horizontally */
  display: block; /* Required for margin auto to work */
  align-items: center; /* Optional if inside flex container */
}



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }





.cont{
margin-top:20px;
}
.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: 0rem;
margin-bottom:0rem
}

.chan{
margin-left:30px;
font-size:15px;
}
// @media only screen and (min-width: 768px) {
 






// .dean-img-box {
// max-width: 100%;
// background-color: #f3f3f3;
// padding: .8rem;
// height:340px;


// border: 10px solid #023047;
// display: inline-block;
// position: relative;
// margin-left:50px;
// margin-top:50px

// }


.sidcontent{
margin-left:100px;
margin-top:30px;
width:80%;
font-size:17px;

}







@media only screen and (max-width: 480px){

.contentleader
{
text-align:justify;
 font-family:poppins,sans-serif;
  font-weight: 900;
  font-style: normal;
}


.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}


.sidcontent{
  margin-left:30px;
   font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;

}


.contentleader{
margin-left:20px;
font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;}
}



h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2 className="h2 title-main" style={{ color: "#780000" }}>
          Mr. Vimal Nath(CFO)
        </h2>
        <hr className="title-hr"></hr>
        <h2
          className="h3  chan text-bold"
          style={{ fontFamily: "'poppins',sansSerif" }}
        >
          Chief Finance & Account Officer
        </h2>
        <div className="row dirr">
          <div className="col-lg-10 padding-section">
            <div className="sidcontent">
              <img className="img-fluid max-height-500" src={CFO} alt="img" />

              <div
                className="contentleader"
                style={{ fontFamily: "'poppins',sansSerif" }}
              >
                Mr. Vimal Nath is a highly skilled Finance Controller with over
                25 years of experience in managing financial operations and
                strategic financial planning. Known for her strong analytical
                abilities, she has a proven track record in driving financial
                performance, improving internal controls, and ensuring
                compliance with financial regulations. With expertise in
                budgeting, forecasting, and financial reporting, Mr. Vimal Nath
                is adept at optimizing operational efficiencies, streamlining
                financial processes, and guiding senior management in making
                data-driven financial decisions.
              </div>
            </div>

            <br />
          </div>
          <div className="col-lg-2">
            <ul className="list-icon">
              <h5
                style={{ fontFamily: "'poppins',sansSerif", color: "#243642" }}
              >
                University Officials
              </h5>
              <br></br>{" "}
              <li className="lli">
                <a href="/chancellor">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Chancellor
                </a>
              </li>
              <li className="lli">
                <a href="/vice_chancellor">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Vice Chancellor
                </a>
              </li>
              <li className="lli">
                <a href="/registrar">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Registrar{" "}
                </a>
              </li>
              <li className="lli">
                <a href="/finance_officer">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Finance Officer{" "}
                </a>
              </li>
              <li className="lli">
                <a href="/deputy_registrar">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Deputy
                  Registrar{" "}
                </a>
              </li>
              <li className="lli">
                <a href="/exam_controller">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Exam Controller
                </a>
              </li>
              <li className="lli">
                <a href="/chief_vigilance_officer">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Chief Vigilance
                  Officer
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/governing_body">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Governing Body{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/board_of_management">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Board of
                  Management{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/board_of_studies">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Board of
                  Studies{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/academic_council">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Academic
                  Council{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/finance_committee">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Finance
                  committee{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/standing_committee">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Standing
                  Committee{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/statutes">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Statutes{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/audit_report">
                  <i class="fa fa-adjust" aria-hidden="true"></i>AUDIT REPORT{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/dean">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Dean{" "}
                </a>
              </li>
              <li className="lli">
                {" "}
                <a href="/head_of_department">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Head of the
                  Department{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footerpage />
    </>
  );
}

export default Finance_Officer;
