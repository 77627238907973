///////////////////Update al std details///////////////////////////////

import React, { useState, useEffect } from "react";
import { Container, Button, Form, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import useFetch from "../../CUSTOM_HOOK/Usefetch";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
import DatePicker from "react-datepicker";
import { FormControl } from "react-bootstrap";

const AdminUpdtStdt_Detail = ({ userData }) => {
  const { id } = useParams();
  const userID = id;

  const [formData, setFormData] = useState({
    branch: "",
    assignedCollege: "",
    courseName: "",
    studentId: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setFormData(userData); 
    };
    fetchData();
  }, [userID]);

  const [courses, setCourses] = useState([]); // State to store courses
  const [filteredCourses, setFilteredCourses] = useState([]); // State to store filtered courses based on courseType
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [colleges, setColleges] = useState([]); // State to store colleges

  const [editableData, setEditableData] = useState({
    branch: selectedBranch,
    college: "",
    studentId: userData._id,
    courseName: selectedCourse,
  });
  // Fetch courses from API
  const session = new Date().getFullYear();
  const { data, loading, error } = useFetch(
    `https://sssutms.ac.in/apitest/v2/admin/courses?session=${session}`
  );
  //   ///////////////////// Datepicker ///////////////////
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (data && data.length) {
      setCourses(data); // Store fetched courses
      // Filter courses based on courseType from userData
      const filtered = data.filter(
        (course) => course.courseType === userData.courseType // Assuming userData has courseType
      );
      setFilteredCourses(filtered); // Store filtered courses
    }
  }, [data, userData.courseType]); // Run when data or courseType changes

  const handleEditableChange = (e) => {
    const { name, value } = e.target;
    setEditableData({ ...editableData, [name]: value });
  };

  const handleCourseChange = async (e) => {
    const { value } = e.target;
    setSelectedCourse(value);
    setEditableData({
      ...editableData,
      courseName: value,
      assignedCollege: value,
    });
    // Reset the selected branch when course changes
    setSelectedBranch("");

    // Fetch colleges based on selected course
    if (value) {
      try {
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v1/hod/colleges/course?courseName=${value}`
        );
        if (response.ok) {
          const collegeData = await response.json();
          setColleges(collegeData);
        } else {
          // Handle error response
          setColleges([]); // Clear colleges if there's an error
        }
      } catch (error) {
        console.error("Error fetching colleges:", error);
        setColleges([]); // Clear colleges on error
      }
    } else {
      setColleges([]); // Clear colleges if no course is selected
    }
  };

  const handleBranchChange = (e) => {
    const { value } = e.target;
    setSelectedBranch(value);
    setEditableData({ ...editableData, branch: value });
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(
        `https://sssutms.ac.in/apitest/admin/update-course`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userID,
            formData: editableData,
            selectedCourse,
            selectedBranch,
          }),
        }
      );

      // Check if response status is successful (200)
      if (response.status === 200) {
        const responseData = await response.json(); // Parse response data

        // Check if there are any errors in the response data
        if (responseData.errors && responseData.errors.length > 0) {
          swal({
            icon: "error",
            title: "Error",
            text: `Error: ${responseData.errors.join(", ")}`,
          });
        } else {
          swal({
            title: "Success",
            icon: "success",
            text: `Data updated successfully!`,
            button: "OK",
          }).then(() => {
            // Reset formData and editableData after confirmation
            setFormData({
              branch: "",
              assignedCollege: "",
              courseName: "",
              studentId: "",
            });
            setEditableData({
              branch: "",
              college: "",
              studentId: userData._id,
              courseName: "",
            });
            setSelectedCourse("");
            setSelectedBranch("");
            setColleges([]); // Clear colleges
          });
        }
        return;
      }

      // Handle other response statuses
      const errorData = await response.json(); // Parse error data
      swal({
        icon: "error",
        title: "Error",
        text: `Error: ${
          errorData.errors ? errorData.errors.join(", ") : "Unknown error"
        }`,
      });
    } catch (error) {
      swal({
        title: "Error",
        text: "An error occurred while updating data.",
        icon: "error",
        buttons: "OK",
      });
    }
  };

  return (
    <>
      <style>
        {`
        .custom-input{
        font-family: "Inter", sans-serif;}`}
      </style>
      <AdminDashboard />
      <Container
        className="shadow p-3 bg-body rounded"
        style={{ marginTop: "80px", marginLeft: "11%" }}
      >
        <hr />
        <Form>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="enrollmentNumber">
              <Form.Label
                style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
              >
                Enrollment No.
              </Form.Label>
              <Form.Control
                type="text"
                name="enrollmentNumber"
                value={formData.enrollmentNumber || "Not Generated"}
                
                className="custom-input"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="name">
              <Form.Label
                style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
              >
                Name
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                
                className="custom-input"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="fathersname">
              <Form.Label
                style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
              >
                Father's Name
              </Form.Label>
              <Form.Control
                type="text"
                name="fathersname"
                value={formData.fathersname}
                
                className="custom-input"
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="name">
              <Form.Label
                style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
              >
                Date of Birth
              </Form.Label>

              <div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={
                    selectedDate ||
                    (formData?.dob ? new Date(formData?.dob) : null)
                  }
                  name="dob"
                  onChange={(date) => handleDateChange(date)}
                  required
                  className="custom-input" // Apply your custom class here
                  readOnly={true}
                  customInput={<FormControl style={{ width: "159%" }} />}
                />
              </div>
            </Form.Group>

            <Form.Group as={Col} controlId="gender">
              <Form.Label
                style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
              >
                Gender
              </Form.Label>
              <Form.Control
                type="text"
                name="gender"
                value={formData?.gender}
                
                className="custom-input"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="mobile">
              <Form.Label
                style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
              >
                Mobile
              </Form.Label>
              <Form.Control
                type="text"
                name="mobile"
                value={formData?.mobile}
                
                className="custom-input"
              />
            </Form.Group>
          </Row>

          {/* Third Row - Non-editable */}
          <Row className="mb-3">
            <Form.Group as={Col} controlId="courseName">
              <Form.Label
                style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
              >
                Course Name
              </Form.Label>
              <Form.Control
                type="text"
                name="courseName"
                value={formData?.courseName}
                
                className="custom-input"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="branch">
              <Form.Label
                style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
              >
                Course Branch
              </Form.Label>
              <Form.Control
                type="text"
                name="branch"
                value={formData?.courseBranch}
                
                className="custom-input"
              />
            </Form.Group>

            <Form.Group as={Col} controlId="assignedCollege">
              <Form.Label
                style={{ fontFamily: "Lato, sans-serif", fontWeight: 700 }}
              >
                College
              </Form.Label>
              <Form.Control
                type="text"
                name="assignedCollege"
                value={formData?.assignedCollege}
                
                className="custom-input"
              />
            </Form.Group>
          </Row>
         

          <Button
            style={{
              backgroundColor: "#1E3E62",
              fontFamily: "Lato, sans-serif",
              height: "35px",
              width: "100px",
              padding: "5px",
            }}
            onClick={handleSubmit}
          >
            Update
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default AdminUpdtStdt_Detail;
