import React,{useEffect,useState} from 'react'

import { Plane, FlaskConical,HardHat  } from 'lucide-react';
import cs from '../../images/cs.png'
import ee from '../../images/Admission_Enquiry/ee.png'
import ELECTRICAL from '../../images/ELECTRICAL.png'
import EC from '../../images/Admission_Enquiry/EC.png'
import einstru from '../../images/einstru.png'
import it from '../../images/it.png'
import me from '../../images/Admission_Enquiry/me.png'
import mie from '../../images/mie.png'
import sd from '../../images/sd.png'
import dc from '../../images/Admission_Enquiry/dc.png'
import id from '../../images/id.png'
import te from '../../images/te.png'
import techno2 from '../../images/techno2.jpg'
import se from '../../images/Admission_Enquiry/se.png'
import Nav from '../../HomePage/Nav';
import Footerpage from '../../HomePage/Footerpage';

const EnggTechno = () => {
const [activeTab, setActiveTab] = useState('tab1');

  return (
  <>
 
 <style>
        {
          `
 .lli{
  font-size:14px; 
   margin-left:5px;
 margin-top:-3px;
   list-style-type: none; /* Removes bullets */
   }
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 3px 10px 5px 0;
      text-align: center;
      width: 28px;
  }

 .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-80px;
          margin-left:20px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  
.title-main{
  font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }

.cont{
margin-top:20px;
}

.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){


h5{
margin:20px;
padding:30px}

    .prog5{
     font-weight:700;
     color:white;

     margin-left:-20px;
     margin-top:-20px;
     font-family:'Open Sans', sans-serif
     }


.head4{

font-size:15px;
margin-left:-35px;}

 .list-icon {
 margin-top:50px;                                                                       
 margin-left:30px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }

.head5{
font-size:15px;
text-align:left;}



.h2 title-main{
margin-left:20px}



.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}


 

    
    
    `}</style>

<Nav/>
     
     
     
      <div className="cont" >
        <h2 className="h2 title-main" style={{ marginLeft:'30px',color: '#780000' }}>Faculty of Engineering and Technology</h2>
        <hr className="title-hr"></hr><br></br>
 <div className="row dirr">
<h5 className='head5'>  INSTITUTE NAME:    SCHOOL OF ENGINEERING</h5><br></br>

          <div className="col-lg-9 padding-section">
          <style>
    {
        `
    .head5{
    font-family: "poppins",sans-serif;
      margin-left:20px;
     }


     .head4{
     font-weight:700;
     font-family:'Open Sans', Sans-serif;
     color:#264653;
     }

     .prog5{
     font-weight:700;
     color:white;
     margin-left:30px;
     margin-top:30px;
     font-family:'Open Sans', sans-serif
     }

    .container {
      display: flex;
      width:100%;
      font-family: 'Open Sans', sans-serif;
    }
    .tabs {
      flex: 1;
      background-color: #023047;
      color: white;
      display: flex;

      flex-direction: column;
      padding: 10px;
    }
    .tabs button {
      background: none;
      border: none;
      color: white;
      padding: 10px;
      text-align: left;
      cursor: pointer;
      font-size: 16px;
      transition: color 0.3s;
    }


    .text{
    display:flex;
    }



.custom-img {
  width: 40px; /* Adjust the size as needed */
  height: 40px; /* Adjust the size as needed */
  // fill: red; /* Change the color to red */


  // transition: all 0.3s ease; /* Add transition for smooth effect */
}


    

    .tabs button.active {
      font-weight: bold;
      color: black;
    }
    .content {
      flex: 3;
      padding: 20px;
    }
    .grid {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    .grid article {
      // background: #f7f7f7;
      border: 1px solid #ddd;
      border-radius: 4px;
      flex: 1 1 calc(33.333% - 20px);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      transition: transform 0.3s;
    }
    .grid article:hover {
      transform: scale(1.05);
      color:#023047
    }
    .grid .text {
      text-align: center;
    }
    .grid img {
      max-width: 100px;
      height: auto;
      margin-bottom: 10px;
    }
    h4, h5, h6 {
      margin: 0;
      padding: 0;
    }








    
  `
    }
  </style>

 <div className="container" style={{marginTop:'50px'}}>
  <div className="tabs">
    <h5 className='prog5'>Program Levels</h5>
    <hr />
    <button
      style={{ color: activeTab === 'tab1' ? 'orange' : 'white' }}
      onClick={() => setActiveTab('tab1')}
      className={activeTab === 'tab1' ? 'active' : ''}
    >
      UG Programs
    </button>
    <hr />
    <button
      style={{ color: activeTab === 'tab2' ? 'orange' : 'white' }}
      onClick={() => setActiveTab('tab2')}
      className={activeTab === 'tab2' ? 'active' : ''}
    >
      PG Programs
    </button>
    <hr />
    <button
      style={{ color: activeTab === 'tab3' ? 'orange' : 'white' }}
      onClick={() => setActiveTab('tab3')}
      className={activeTab === 'tab3' ? 'active' : ''}
    >
      Diploma
    </button>
  </div>

  <div className="content">
    {activeTab === 'tab1' && (
      <>
        <div data-aos='fade-right' className='title aos-init aos-animate' style={{fontFamily:"'Open Sans', Sans-serif", marginTop: '5px', marginLeft: '50px' }}>
          <h4 className='head4'>  SCHOOL OF ENGINEERING (4 Year)</h4>
        </div>
        <br></br>
        <div className="grid">
          <article>
            <div className="text">
             <Plane/>
              <h6 style={{ fontSize: '15px', marginTop: '6px', marginLeft: '10px' }}>Aeronautical Engineering</h6>
            </div>
          </article>
          <article>
            <div className="text">
              {/* <img src={chemical} alt="Chemical Engineering" /> */}
              < FlaskConical/>
              <h6 style={{ fontSize: '15px', marginTop: '6px', marginLeft: '16px' }}>Chemical Engineering</h6>
            </div>
          </article>
          <article>
            <div className="text">
              {/* <img src={civil} alt="Civil Engineering" /> */}<HardHat />
              <h6 style={{ fontSize: '15px', marginTop: '6px', marginLeft: '10px' }}>Civil Engineering</h6>
            </div>
          </article> <article>
            <div className="text">
              <img className="custom-img"  src={it} alt="Information Technology" />
              <h6 style={{ fontSize: '15px', marginTop: '12px', marginLeft: '7px' }}>Information Technology</h6>
            </div>
          </article>
          <article>
            <div className="text">
              <img  className="custom-img" src={mie} alt="Mining Engineering" />
              <h6 style={{ fontSize: '15px', marginTop: '12px', marginLeft: '10px', }}>Mining Engineering</h6>
            </div>
          </article> 
           <article>
            <div className="text">
              <img className="custom-img" src={me} alt="Mechanical Engineering" />
              <h6 style={{ fontSize: '15px', marginTop: '12px', marginLeft: '5px'}}>Mechanical Engineering</h6>
            </div>
          </article>
        
          <article>
            <div className="text">
              <img  className="custom-img"src={ELECTRICAL} alt="Electrical Engineering"   style={{ fill: "red" }} />
              <h6 style={{ fontSize: '15px', marginTop: '12px', marginLeft: '10px' }}>Electrical Engineering</h6>
            </div>
          </article>
          <article>
            <div className="text">
              <img  className="custom-img" src={EC} alt="Electronic & Communication Engineering" />
              <h6 style={{ fontSize: '15px', marginTop: '8px', marginLeft: '2px' }}>Electronic & Communication Engineering</h6>
            </div>
          </article>
          <article>
            <div className="text">
              <img  className="custom-img" src={einstru} alt="Electronics & Instrumentation Engineering" />
              <h6 style={{ fontSize: '15px', marginTop: '8px', marginLeft: '3px' }}>Electronics & Instrumentation Engineering</h6>
            </div>
          </article>
          <article>
            <div className="text">
              <img className="custom-img" src={ee} alt="Electrical and Electronics Engineering" />
              <h6 style={{ fontSize: '15px', marginTop: '8px', marginLeft: '10px' }}>Electrical and Electronics Engineering</h6>
            </div>
          </article>
        
          <article>
            <div className="text">
              <img className="custom-img" src={cs} alt="Computer Science and Engineering" />
              <h6 style={{ fontSize: '15px', marginTop: '8px', marginLeft: '10px' }}>Computer Science and Engineering</h6>
            </div>
          </article>
        </div>
      </>
    )}
    {activeTab === 'tab2' && (
      <>
        <div data-aos='fade-right' className='title aos-init aos-animate' style={{fontFamily:"'Open Sans', Sans-serif", marginTop: '0px', marginLeft: '50px' }}>
          <h4 className='head4'>  SCHOOL OF ENGINEERING (2 Year)</h4>
        </div>        <br></br>
        <div className="grid">
       
          <article>
            <div className="text">
              <img className='custom-img' src={dc} alt="Digital Communication" />
              <h6 style={{fontSize:'15px',marginTop:'12px',marginLeft:'10px'}}>Digital Communication</h6>
            </div>
          </article>
          <article>
            <div className="text">
              <img  className="custom-img" src={ELECTRICAL} alt="Electrical Power System" />
              <h6 style={{fontSize:'15px',marginTop:'8px',marginLeft:'10px'}}>Electrical Power System</h6>
            </div>
          </article>
          <article>
            <div className="text">
              <img  className="custom-img" src={id} alt="Industrial Design" />
              <h6 style={{fontSize:'15px',marginTop:'8px',marginLeft:'10px'}}>Industrial Design</h6>
            </div>
          </article>
          <article>
            <div className="text">
              <img  className="custom-img" src={it} alt="Information Technology" />
              <h6 style={{fontSize:'15px',marginTop:'12px',marginLeft:'10px'}}>Information Technology</h6>
            </div>
          </article>
          <article>
            <div className="text">
              <img className="custom-img" src={einstru} alt="Power Electronics" />
              <h6 style={{fontSize:'15px',marginTop:'8px',marginLeft:'10px'}}>Power Electronics</h6>
            </div>
          </article>
          <article>
            <div className="text">
              <img className='custom-img' src={se} alt="Software Engineering" />
              <h6 style={{fontSize:'15px',marginTop:'8px',marginLeft:'10px'}}>Software Engineering</h6>
            </div>
          </article>
          <article>
            <div className="text">
              <img className='custom-img' src={sd} alt="Structural Design" />
              <h6 style={{fontSize:'15px',marginTop:'12px',marginLeft:'10px'}}>Structural Design</h6>
            </div>
          </article>
          <article>
            <div className="text">
              <img  className="custom-img" src={te} alt="Thermal Engineering" />
              <h6 style={{fontSize:'15px',marginTop:'12px',marginLeft:'10px'}}>Thermal Engineering</h6>
            </div>
          </article>
          <article>
            <div className="text">
              <img className="custom-img" src={it} alt="VLSI" />
              <h6 style={{fontSize:'15px',marginTop:'12px',marginLeft:'10px'}}>VLSI</h6>
            </div>
          </article>   <article>
            <div className="text">
              <img className="custom-img" src={cs} alt="Computer Science and Engineering" />
              <h6 style={{fontSize:'15px',marginTop:'12px',marginLeft:'7px'}}>Computer Science and Engineering</h6>
            </div>
          </article>
          <article>
            <div className="text">
              <img  className="custom-img"src={cs} alt="Computer Technology & Applications" />
              <h6 style={{fontSize:'15px',marginTop:'12px',marginLeft:'16px'}}>Computer Technology & Applications</h6>
            </div>
          </article>
        </div>
      </>
    )}
    {activeTab === 'tab3' && (
      <>
        <div data-aos='fade-right' className='title aos-init aos-animate' style={{fontFamily:"'Open Sans', Sans-serif", marginTop: '0px', marginLeft: '50px' }}>
          <h4 className='head4'>  POLYTECHNIC ENGINEERING (3 Year)</h4>
        </div>        <br></br>
        <div className="grid">
          <article>
            <div className="text">
            <HardHat style={{marginTop:'10px'}} />
              <h6 style={{fontSize:'15px',marginTop:'12px',marginLeft:'12px'}}>Civil Engineering</h6>
            </div>
          </article>
          
          <article>
            <div className="text">
              <img className='custom-img' src={ELECTRICAL} alt="Electrical Engineering" />
              <h6 style={{fontSize:'15px',marginTop:'12px',marginLeft:'10px'}}>Electrical Engineering</h6>
            </div>
          </article>
        
          <article>
            <div className="text">
              <img className='custom-img' src={me} alt="Mechanical Engineering" />
              <h6 style={{fontSize:'15px',marginTop:'12px',marginLeft:'10px'}}>Mechanical Engineering</h6>
            </div>
          </article><article>
            <div className="text">
              <img  className='custom-img' src={cs} alt="Computer Science and Engineering" />
              <h6 style={{fontSize:'15px',marginTop:'10px',marginLeft:'10px'}}>Computer Science and Engineering</h6>
            </div>
          </article>  <article>
            <div className="text">
              <img className='custom-img'src={einstru} alt="Electronic & Communication Engineering" />
              <h6 style={{fontSize:'15px',marginTop:'12px'}}>Chemical Engineering</h6>
            </div>
          </article>
      
        </div>
      </>
    )}

</div></div><br />
 </div>
          <div className="col-lg-3">

            <ul className='list-icon' >
              <h5 style={{ fontFamily: "'poppins',sansSerif", color: '#243642' }}>Faculties And Departments</h5>
              <br></br>  <li className='lli'>
                <a href='/enggtechno'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Engineering And Technology</a></li>
              <li className='lli'>
              <a href='/pharma'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Pharmacy</a></li>
              <li className='lli'>
              <a href='/edu'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Education </a></li>
              <li className='lli'>
              <a href='/management'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Management</a></li>
              <li className='lli'>
              <a href='/design'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Design </a></li>
              <li className='lli'>  
              <a href='/humanities'>    
                          <i class="fa fa-adjust" aria-hidden="true"></i>Humanities And Languages </a></li>
 <li className='lli'>  
 <a href='/computer_science'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Computer Science And Application </a></li>
 <li className='lli'>  
 <a href='/commerce'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Commerce </a></li>
 <li className='lli'> 
 <a href='/science'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Science  </a></li>
 <li className='lli'>  
 <a href='/ayurveda'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Ayurveda  </a></li>
 <li className='lli'> 
 <a href='/law_dept'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Law </a></li>
 <li className='lli'>  
 <a href='/homeopathy'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Homeopathy   </a></li>
 <li className='lli'> 
 <a href='/paramedical'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Paramedical </a></li>
 <li className='lli'> 
 <a href='/nursing'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Nursing </a></li>
            </ul>
          </div>
        </div>

 </div>


<Footerpage/>
</>
)
}
export default EnggTechno